import React, { useContext } from 'react';
import { LessonsContext } from '../../../contexts/lessonsContext';
import { ModalContext } from '../../../contexts/modalContext';
import {
  ActionButtonGreen,
  ActionButtonOrangeSecondary,
} from '../../Buttons/ActionButton';
import { ModalWrapper, DiscardModalWrapper } from '../Modals.styles';

const DeleteSkillsModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal } = useContext(ModalContext);
  const { deleteSkillsCheck, setDeleteSkillsCheck, skills, setSkills } =
    useContext(LessonsContext);

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <DiscardModalWrapper>
            <h2>Do you want to delete the folowing skills?</h2>
            <h4>
              {deleteSkillsCheck.skills.map((skill, index) =>
                index === deleteSkillsCheck.skills.length - 1 ? (
                  <span key={skill.id}>{skill.name}</span>
                ) : (
                  <span key={skill.id}>{`${skill.name}, `}</span>
                )
              )}
            </h4>

            <div className="modal__buttons--discard-skill">
              <ActionButtonGreen
                text="Delete"
                onClick={() => {
                  setDeleteSkillsCheck({ ...deleteSkillsCheck, check: true });
                  setShowModal(false);
                }}
              />
              <ActionButtonOrangeSecondary
                text="Cancel"
                onClick={() => {
                  setSkills(
                    skills.map((skill) => {
                      skill.toDelete = false;
                      skill.hideSkill = false;
                      return skill;
                    })
                  );
                  setShowModal(false);
                }}
              />
            </div>
          </DiscardModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default DeleteSkillsModal;
