import Resizer from 'react-image-file-resizer';
import { errors, isValidDate } from './validation';
import debounce from 'lodash.debounce';
import AWS from 'aws-sdk';

export const errorStatus = [100, 101, 102, 103, 104, 105, 107, 112];

export const genders = [
  { value: 'Female', label: 'Female' },
  { value: 'Male', label: 'Male' },
];

export const minDate = new Date('2000-01-01T00:00:00.000');
export const maxDate = new Date();

export const createDate = (date) => {
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  const month =
    date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1;
  const year = date.getFullYear();

  return {
    todayFormat: `${day.toString().padEnd(3, '/')}${month
      .toString()
      .padEnd(3, '/')}${year}`,
    dobFormat: `${year}-${month}-${day}`,
  };
};

export const formatDate = (studentDob) => {
  if (!studentDob) {
    return false;
  }

  const year = studentDob.substring(0, 4);
  const month = studentDob.substring(5, 7);
  const day = studentDob.substring(8, 10);

  return {
    newDate: `${year}/${month}/${day}`,
    displayDate: `${day}/${month}/${year}`,
  };
};

export const handleDateInput = (
  e,
  setDate,
  state,
  setState,
  error,
  materialDate = false
) => {
  setState({ ...state, dob: `${createDate(e).dobFormat}` });
  materialDate ? setDate(e) : setDate(createDate(e).todayFormat);
  delete error.dateError;
};

export const handleUserDateInput = (
  e,
  setDisplayDate,
  setDate,
  state,
  setState,
  error,
  setError
) => {
  setDisplayDate(e.target.value);
  const year = e.target.value.substring(6);
  const month = e.target.value.substring(3, 5);
  const day = e.target.value.substring(0, 2);
  let errorCopy = { ...error };

  const isValid = isValidDate(e.target.value);

  if (e.target.value.length === 10 && isValid) {
    setState({ ...state, dob: `${year}-${month}-${day}` });
    setDate(new Date(`${year}-${month}-${day}`));
    delete errorCopy.dateError;
  } else {
    errorCopy.dateError = errors.dateErrorMessage;
  }

  setError(errorCopy);
};

export const ITEMS_PER_PAGE = 10;

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      250,
      250,
      'JPEG',
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      'base64'
    );
  });

export const handleUploadImage = async (
  e,
  studentState,
  setStudentState,
  error,
  setError
) => {
  let errorCopy = { ...error };
  try {
    const file = e.target.files[0];
    const image = await resizeFile(file);
    setStudentState({ ...studentState, photo: image });
    delete errorCopy.imageError;
  } catch {
    errorCopy.imageError = 'Failed to upload image.';
  }

  e.target.value = '';
  e.target.files = null;
  setError(errorCopy);
};

export const handleRemoveImage = (state, setState, error, setError) => {
  let errorCopy = { ...error };
  errorCopy.imageError && delete errorCopy.imageError;
  let stateCopy = { ...state };
  delete stateCopy.photo;

  setError(errorCopy);
  setState({ ...stateCopy });
};

export const defaultCoordinates = {
  lat: -25.355078,
  lng: 28.889428,
};

export const header = {
  noSchoolOrOrganization:
    'Please select both a school and an organization to get started',
  noSchool: 'Please select a school to get started',
  schoolAndOrganization: 'groups',
  noOrganization: 'Please select an organization to get started',
};

export const calcPageCount = (array) => {
  return Math.ceil(array.length / ITEMS_PER_PAGE);
};

export const canNextPage = (displayItemsLength, itemsLength, currentPage) => {
  return (
    displayItemsLength !== ITEMS_PER_PAGE ||
    itemsLength / ITEMS_PER_PAGE === currentPage
  );
};

export const jumpToPage = (setCurrentPage, pageCount = 1) => {
  setCurrentPage(pageCount);
};

export const addElement = (state, setState, newElement, reversed = null) => {
  if (reversed) {
    setState([newElement, ...state]);
    return false;
  }
  setState([...state, newElement]);
};

export const editElement = (id, array, setArray, keys, newElement) => {
  let arrayCopy = [...array];
  const elementIndex = array.findIndex((element) => element.id === id);
  keys.forEach((key) => (arrayCopy[elementIndex][key] = newElement[key]));
  setArray(arrayCopy);
};

export const editElementByLinkId = (id, array, setArray, keys, newElement) => {
  let arrayCopy = [...array];
  const elementIndex = array.findIndex((element) => element.linkId === id);
  keys.forEach((key) => (arrayCopy[elementIndex][key] = newElement[key]));
  setArray(arrayCopy);
};

export const removeElement = (id, array, setArray) => {
  setArray(array.filter((elem) => elem.id !== id));
};

export const removeElementByLinkId = (id, array, setArray) => {
  setArray(array.filter((elem) => elem.linkId !== id));
};

export const handleResetSearchValues = (
  setDisplaySearchValue,
  setSearchValue
) => {
  setDisplaySearchValue('');
  setSearchValue('');
};

export const handleSearchChange = (
  e,
  searchSetters,
  setCurrentPage,
  setFilterActive
) => {
  searchSetters.displaySetter(e.target.value);
  const deb = debounce(() => {
    searchSetters.valueSetter(e.target.value);
    setCurrentPage && setCurrentPage(0);
  }, 2000);
  deb();
  setFilterActive && setFilterActive(true);
};

export const defaultOrganizationFilter = ['', ''];

export const defaultStudentsFilter = {
  groupId: '',
  schoolId: '',
  year: '',
  facilitatorId: '',
};

export const defaultDateFilters = {
  startDate: '',
  endDate: '',
};

export const limit = 10;

export const handleOnSubmit = (e, callback) => {
  e.preventDefault();
  callback();
};

AWS.config.update({
  region: String(process.env.REACT_APP_AWS_REGION),
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  },
});

const s3 = new AWS.S3();

const checkIfFileExists = async (filePath) => {
  try {
    await s3
      .headObject({
        Key: filePath,
        Bucket: process.env.REACT_APP_AWS_BUCKET || '',
      })
      .promise();
    return true;
  } catch (e) {
    return false;
  }
};

export const fileCheckTimeout = (path) => {
  return new Promise(function (resolve) {
    setTimeout(() => {
      resolve(checkIfFileExists(path));
    }, 3000);
  });
};
