import React, { useContext, useEffect, useState } from 'react';
import { GetGrades } from '../api';
import { LogInContext } from './logInContext';
import { OrganizationsContext } from './organizationsContext';

export const GradesContext = React.createContext();

const GradesProvider = (props) => {
  const [grades, setGrades] = useState([]);
  const [gradeOptions, setGradeOptions] = useState([]);
  const { organizationId } = useContext(OrganizationsContext);
  const { token } = useContext(LogInContext);

  useEffect(() => {
    async function fetchGrades() {
      const responseData = await GetGrades(organizationId, token);

      if (responseData.status === "success") {
        setGrades(responseData.data.grades);
      } else {
        setGrades([]);
      }
    }
    fetchGrades();

    // eslint-disable-next-line
  }, [token, setGrades]);

  useEffect(() => {
    setGradeOptions(
      grades.map((grade) => ({
        value: grade.name,
        label: grade.name,
        name: grade.name,
        id: grade.id,
      }))
    );
  }, [grades]);

  return (
    <GradesContext.Provider
      value={{
        grades,
        setGrades,
        gradeOptions,
      }}
    >
      {props.children}
    </GradesContext.Provider>
  );
};

export default GradesProvider;
