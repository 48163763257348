import React, { useState } from 'react';

export const MenuContext = React.createContext();

const MenuProvider = (props) => {
  const [menuDisplay, setMenuDisplay] = useState(true);
  const [isHomePage, setIsHomePage] = useState(false);

  return (
    <MenuContext.Provider
      value={{
        menuDisplay,
        setMenuDisplay,
        isHomePage,
        setIsHomePage,
      }}
    >
      {props.children}
    </MenuContext.Provider>
  );
};

export default MenuProvider;
