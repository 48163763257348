import axios from 'axios';
import { errorStatus } from '../utils/constants';
const baseUrl = process.env.REACT_APP_API_URL + 'v1/';

const addOrganizationId = (organizationId) => {
  let url = '';
  organizationId && (url += `organizationId=${organizationId}`);

  return url;
};

const authHeader = (token) => ({
  Authorization: `Bearer ${token}`,
});

const apiWrapper = async (
  url,
  requestMethod,
  body,
  token = null,
  status = 201
) => {
  let auth = '';
  token && (auth = { headers: authHeader(token) });
  let res = '';

  try {
    if (body) {
      res = await axios[requestMethod](
        `${baseUrl}${url}`,
        body,
        auth
      );
    } else
      res = await axios[requestMethod](
        `${baseUrl}${url}`,
        auth
      );
    if (res.status === status) {
      return { data: res.data, status: 'success' };
    }

    return { status: 'failed' };
  } catch (err) {
    if (errorStatus.includes(err?.response?.data?.code)) {
      return {
        data: err?.response?.data?.message,
        code: err?.response?.data?.code,
        status: 'failed',
      };
    }
    return { status: false };
  }
};

export const LogInSubmit = async (user) => {
  let url = 'admin/login';
  let requestMethod = 'post';

  return await apiWrapper(url, requestMethod, user, null);
};

export const SocialLogInSubmit = async (user) => {
  let url = 'admin/socialLogin';
  let requestMethod = 'post';

  return await apiWrapper(url, requestMethod, user, null);
};

export const ChangePasswordSubmit = async (info) => {
  const url = 'me/password';
  const requestMethod = 'put';

  return await apiWrapper(url, requestMethod, info, null);
};

export const verifyLink = async (email) => {
  const url = `verifyLink`;
  const requestMethod = 'put';
  const body = { email };

  return await apiWrapper(url, requestMethod, body, null);
};

export const RecoverPassword = async (email) => {
  const url = 'password-reset';
  const requestMethod = 'put';
  const body = { email };

  return await apiWrapper(url, requestMethod, body, null);
};

export const GetMail = async (token) => {
  const url = 'getMail';
  const requestMethod = 'get';

  return await apiWrapper(url, requestMethod, null, token, 201);
};

export const GetMailCategory = async (token, mail) => {
  const url = `getMailCategory/${mail}`;
  const requestMethod = 'get';

  return await apiWrapper(url, requestMethod, null, token, 201);
};

export const CreateMail = async (token, info) => {
  const url = `createMail`;
  const requestMethod = 'post';
  const body = info;

  return await apiWrapper(url, requestMethod, body, token, 201);
};

export const GetOrganizations = async (token) => {
  const url = 'organizations';
  const requestMethod = 'get';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token);
};

export const GetSchoolOrganizations = async (token, schoolId) => {
  const url = `organizations/${schoolId}`;
  const requestMethod = 'get';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token);
};

export const GetOrganization = async (token, name) => {
  const url = 'getOrganization';
  const requestMethod = 'post';
  const body = {name: name};

  return await apiWrapper(url, requestMethod, body, token);
};

export const GetOrganizationList = async (token, limit, offset, search) => {
  const url = `getOrganizationsList?limit=${limit}&offset=${offset}&search=${!!search ? search : ''}`;
  const requestMethod = 'get';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token);
};

export const GetOrg = async (token, limit, offset, schoolId, organizationId, search, status) => {
  const url = `getOrg?limit=${limit}&offset=${offset}&schoolId=${!!schoolId ? schoolId : ''}&search=${!!search ? search : ''}&status=${status}&${addOrganizationId(organizationId)}`;
  const requestMethod = 'get';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token);
};

export const GetAssignOrg = async (token, schoolId) => {
  const url = `getAssignOrg?schoolId=${!!schoolId ? schoolId : ''}`;
  const requestMethod = 'get';

  return await apiWrapper(url, requestMethod, null, token, 201);
};

export const GetSchools = async (token) => {
  const url = 'schools';
  const requestMethod = 'get';

  return await apiWrapper(url, requestMethod, null, token);
};

export const GetCountry = async (token) => {
  const url = 'getCountry';
  const requestMethod = 'get';

  return await apiWrapper(url, requestMethod, null, token, 201);
};

export const GetAllSchoolsList = async (token, organizationId) => {
  const url = `getAllSchoolsList/?${addOrganizationId(organizationId)}`;
  const requestMethod = 'get';

  return await apiWrapper(url, requestMethod, null, token);
};

export const GetAllSchools = async (token, organizationId) => {
  const url = `getAllSchools/?${addOrganizationId(organizationId)}`;
  const requestMethod = 'get';

  return await apiWrapper(url, requestMethod, null, token);
};

export const GetAllFacilitatorSchools = async (token, userId) => {
  const url = `getAllFacilitatorSchools/?userId=${userId}`;
  const requestMethod = 'get';

  return await apiWrapper(url, requestMethod, null, token);
};

export const GetSchoolsList = async (token, limit, offset, organizationId, isActive, isModify, status, search, sortBy, sortType) => {
  const queries = `?limit=${limit}&offset=${offset}&sortBy=${sortBy}&sortType=${sortType}&isActive=${!!isActive ? isActive : ''}&isModify=${isModify}&status=${status}&search=${!!search ? search : ''}&`;
  const url = `getSchoolsList/${queries}${addOrganizationId(organizationId)}`;

  const requestMethod = 'get';

  return await apiWrapper(url, requestMethod, null, token);
};

export const GetApproveSchool = async (token, limit, offset, flag) => {
  const queries = `?limit=${limit}&offset=${offset}&flag=${flag}&`;
  const url = `getApproveSchoolList/${queries}`;

  const requestMethod = 'get';

  return await apiWrapper(url, requestMethod, null, token);
};

export const GetAssignSchool = async (organizationId, token, limit, offset, search) => {
  const url = `getFacilitatorUsers?limit=${limit}&offset=${offset}&search=${!!search ? search : ''}&${addOrganizationId(organizationId)}`;
  const requestMethod = 'get';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token);
};

export const AssignSchool = async (info, token) => {
  const url = `facilitators/schools`;
  const requestMethod = 'post';
  const body = info;

  return await apiWrapper(url, requestMethod, body, token, 201);
};

export const RemoveSchool = async (info, token) => {
  const url = `facilitators/schools/remove`;
  const requestMethod = 'post';
  const body = info;

  return await apiWrapper(url, requestMethod, body, token, 201);
};

export const RemoveOrg = async (info, token) => {
  const url = `organizations/schools/remove`;
  const requestMethod = 'post';
  const body = info;

  return await apiWrapper(url, requestMethod, body, token, 201);
};

export const GetApproveLesson = async (token, limit, offset) => {
  const queries = `?limit=${limit}&offset=${offset}&`;
  const url = `approveLessonTopics/${queries}`;

  const requestMethod = 'get';

  return await apiWrapper(url, requestMethod, null, token);
};

export const LessonToggleStatus = async (lessonId, token) => {
  const url = `lessonTopics/${lessonId}/toggle-status`;
  const requestMethod = 'patch';
  const body = {};

  return await apiWrapper(url, requestMethod, body, token);
};


export const GetSchool = async (token, id) => {
  const url = `getSchool/${id}`;

  const requestMethod = 'get';

  return await apiWrapper(url, requestMethod, null, token);
};

export const GetModifySchool = async (token, id) => {
  const url = `getModifySchool/${id}`;

  const requestMethod = 'get';

  return await apiWrapper(url, requestMethod, null, token);
};

export const CreateSchool = async (organizationId, info, token) => {
  const url = `schools/?${addOrganizationId(organizationId)}`;
  const requestMethod = 'post';
  const body = info;

  return await apiWrapper(url, requestMethod, body, token);
};

export const MergeSchool = async (info, token) => {
  const url = `mergeSchool`;
  const requestMethod = 'post';
  const body = info;

  return await apiWrapper(url, requestMethod, body, token);
};

export const CreateOrgSchool = async (organizationId, schoolId, token) => {
  const url = `addSchool/?${addOrganizationId(organizationId)}`;
  const requestMethod = 'post';
  const body = {schoolId: schoolId};

  return await apiWrapper(url, requestMethod, body, token);
};

export const CreateUserSchool = async (userId, schoolId, token) => {
  const url = `addSchoolUser`;
  const requestMethod = 'post';
  const body = {schoolId: schoolId, userId: userId};

  return await apiWrapper(url, requestMethod, body, token);
};

export const EditSchool = async (organizationId, id, info, token) => {
  const url = `schools/${id}?${addOrganizationId(organizationId)}`;
  const requestMethod = 'put';
  const body = info;

  return await apiWrapper(url, requestMethod, body, token);
};

export const SchoolToggleStatus = async (schoolId, token) => {
  const url = `schools/${schoolId}/toggle-status`;
  const requestMethod = 'patch';
  const body = {};

  return await apiWrapper(url, requestMethod, body, token);
};

export const DeleteSchool = async (schoolId, token) => {
  const url = `schools/${schoolId}`;
  const requestMethod = 'delete';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token, 201);
};

export const SchoolToggleActive = async (schoolId, token, flag, status) => {
  const url = `schools/${schoolId}/toggle-active`;
  const requestMethod = 'patch';
  const body = {
    flag: flag,
    status: status
  };

  return await apiWrapper(url, requestMethod, body, token);
};

export const ApproveModifySchool = async (schoolId, token) => {
  const url = `approveModifySchool/${schoolId}`;
  const requestMethod = 'put';
  const body = {};

  return await apiWrapper(url, requestMethod, body, token);
};

export const GetGroups = async (schoolId, organizationId, token) => {
  let queries = '?';
  schoolId && (queries = `?schoolId=${schoolId}&`);
  const url = `groups/${queries}${addOrganizationId(organizationId)}`;

  const requestMethod = 'get';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token, 200);
};

export const GetSearchGroupsList = async (schoolId, organizationId, token) => {
  let queries = '?';
  schoolId && (queries = `?schoolId=${schoolId}&`);
  const url = `getSearchGroupsList/${queries}${addOrganizationId(organizationId)}`;

  const requestMethod = 'get';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token, 201);
};

export const GetAssignGroups = async (schoolId, organizationId, token) => {
  let queries = '?';
  schoolId && (queries = `?schoolId=${schoolId}&`);
  const url = `getAssignGroups/${queries}${addOrganizationId(organizationId)}`;

  const requestMethod = 'get';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token, 201);
};

export const GetGroupList = async (token, limit, offset, schoolId, grpId, organizationId) => {
  let queries = '?';
  schoolId && (queries = `?limit=${limit}&offset=${offset}&schoolId=${schoolId}&groupId=${!!grpId ? grpId : ''}&${addOrganizationId(organizationId)}`);
  const url = `getGroups/${queries}`;

  const requestMethod = 'get';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token, 201);
};

export const GetDeAssignGroups = async (token, limit, offset, schoolId, grpId, organizationId) => {
  let queries = '?';
  schoolId && (queries = `?limit=${limit}&offset=${offset}&schoolId=${schoolId}&groupId=${!!grpId ? grpId : ''}&${addOrganizationId(organizationId)}`);
  const url = `getDeAssignGroups/${queries}`;

  const requestMethod = 'get';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token, 201);
};

export const GetGroupsList = async (schoolId, organizationId, token, limit, offset) => {
  let queries = '?';
  schoolId && (queries = `?schoolId=${schoolId}&limit=${limit}&offset=${offset}&`);
  const url = `getGroupsList/${queries}${addOrganizationId(organizationId)}`;

  const requestMethod = 'get';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token, 200);
};

export const AddGroup = async (organizationId, schoolId, gradeId, friendlyName, token) => {
  const url = `groups?${addOrganizationId(organizationId)}`;
  const requestMethod = 'post';
  const body = { schoolId, gradeId, friendlyName };

  return await apiWrapper(url, requestMethod, body, token, 200);
};

export const CreateGroupsToUser = async (info, token) => {
  const url = `createGroupsToUser`;
  const requestMethod = 'post';
  const body = info;

  return await apiWrapper(url, requestMethod, body, token, 201);
};

export const GetStudents = async (
  token,
  organizationId,
  searchValue = '',
  groupId = '',
  schoolId = '',
  year = '',
  facilitatorId = '',
  limit = 1000000000,
  offset = 0
) => {
  const search = searchValue;
  const requestMethod = 'get';

  let url = `students?limit=${limit}&offset=${offset}&search=${search}&schoolId=${schoolId}&facilitatorId=${facilitatorId}&year=${year}&groupId=${groupId}&${addOrganizationId(
    organizationId
  )}`;

  return await apiWrapper(url, requestMethod, null, token, 200);
};

export const GetStudentsList = async (
  token,
  organizationId,
  searchValue,
  groupId,
  limit,
  offset,
  sortBy,
  sortType,
  schoolId,
  year,
  facilitatorId,
) => {
  const search = searchValue;
  const requestMethod = 'get';

  let url = `getStudentsList?limit=${limit}&offset=${offset}&sortBy=${sortBy}&sortType=${sortType}&search=${!!search ? search : ''}&schoolId=${!!schoolId ? schoolId : ''}&facilitatorId=${!!facilitatorId ? facilitatorId : ''}&year=${!!year ? year : ''}&groupId=${!!groupId ? groupId : ''}&${addOrganizationId(
    organizationId
  )}`;

  return await apiWrapper(url, requestMethod, null, token, 200);
};

export const GetStudList = async (
  token,
  organizationId,
  searchValue,
  limit,
  offset,
  schoolId,
  isActive
) => {
  const search = searchValue;
  const requestMethod = 'get';

  let url = `getStudList?limit=${limit}&offset=${offset}&search=${!!search ? search : ''}&schoolId=${!!schoolId ? schoolId : ''}&isActive=${!!isActive ? isActive : ''}&${addOrganizationId(
    organizationId
  )}`;

  return await apiWrapper(url, requestMethod, null, token, 200);
};

export const GetAllStudents = async (
  token,
  organizationId,
  searchValue,
  groupId,
  limit,
  offset,
  sortBy,
  sortType,
  schoolId,
  year,
  facilitatorId,
  date
) => {
  const search = searchValue;
  const requestMethod = 'get';

  let url = `getAllStudents?limit=${limit}&offset=${offset}&sortBy=${sortBy}&sortType=${sortType}&search=${!!search ? search : ''}&schoolId=${!!schoolId ? schoolId : ''}&facilitatorId=${!!facilitatorId ? facilitatorId : ''}&year=${!!year ? year : ''}&groupId=${!!groupId ? groupId : ''}&date=${!!date ? date : ''}&${addOrganizationId(
    organizationId
  )}`;

  return await apiWrapper(url, requestMethod, null, token, 200);
};

export const GetReasonStudentsList = async (
  token,
  groupId,
  limit,
  offset,
  sortBy,
  sortType
) => {
  const requestMethod = 'get';

  let url = `getReasonStudentsList?limit=${limit}&offset=${offset}&sortBy=${sortBy}&sortType=${sortType}&groupId=${groupId}`;

  return await apiWrapper(url, requestMethod, null, token, 200);
};

export const GetAllStudentsList = async (
  token,
  limit,
  offset,
  sortBy,
  sortType,
) => {
  const requestMethod = 'get';

  let url = `getAllStudentsList?limit=${limit}&offset=${offset}&sortBy=${sortBy}&sortType=${sortType}`;

  return await apiWrapper(url, requestMethod, null, token, 200);
};

export const GetStudentsFilters = async (
  organizationId,
  token
) => {
  const requestMethod = 'get';
  let url = `students/filters?${addOrganizationId(
    organizationId
  )}`;

  return await apiWrapper(url, requestMethod, null, token, 200);
};

export const GetStudentProfile = async (organizationId, studentId, token) => {
  const requestMethod = 'get';
  let url = `students/${studentId}/profile?${addOrganizationId(
    organizationId
  )}`;

  return await apiWrapper(url, requestMethod, null, token, 200);
};

export const AddStudent = async (organizationId, studentInfo, token) => {
  const url = `students?${addOrganizationId(organizationId)}`;
  const requestMethod = 'post';
  const body = studentInfo;

  return await apiWrapper(url, requestMethod, body, token);
};

export const CreateAllStudent = async (organizationId, studentInfo, token) => {
  const url = `createStudents?${addOrganizationId(organizationId)}`;
  const requestMethod = 'post';
  const body = {students: studentInfo};;

  return await apiWrapper(url, requestMethod, body, token);
};

export const checkStudent = async (organizationId, studentInfo, token) => {
  const url = `check/student?${addOrganizationId(organizationId)}`;
  const requestMethod = 'post';
  const body = {students: studentInfo};

  return await apiWrapper(url, requestMethod, body, token);
};

export const MoveStudent = async (grpId, active, reason, reasonId, stud, token) => {
  const url = `moveStudents`;
  const requestMethod = 'post';
  const body = {
    groupId: grpId,
    isActive: active,
    student: stud,
    reason: reason,
    reasonId: reasonId
  };

  return await apiWrapper(url, requestMethod, body, token);
};

export const MoveExStudent = async (stud, token) => {
  const url = `moveExStudent`;
  const requestMethod = 'post';
  const body = {
    student: stud
  };

  return await apiWrapper(url, requestMethod, body, token);
};

export const RemoveStudent = async (organizationId, studentId, token) => {
  const url = `students/${studentId}`;
  const requestMethod = 'delete';
  const body = {
    data: { organizationId: organizationId },
    headers: authHeader(token),
  };

  return await apiWrapper(url, requestMethod, body, null, 200);
};

export const EditStudent = async (
  organizationId,
  studentId,
  studentInfo,
  token
) => {
  const url = `students/${studentId}?${addOrganizationId(organizationId)}`;
  const requestMethod = 'put';
  const body = studentInfo;

  return await apiWrapper(url, requestMethod, body, token);
};

export const ChangeStudentID = async (
  organizationId,
  studentId,
  studentInfo,
  token
) => {
  const url = `changeStudentID/${studentId}?${addOrganizationId(organizationId)}`;
  const requestMethod = 'put';
  const body = studentInfo;

  return await apiWrapper(url, requestMethod, body, token);
};

export const StudentToggleStatus = async (studentId, token) => {
  const url = `students/${studentId}/toggle-status`;
  const requestMethod = 'patch';
  const body = {};

  return await apiWrapper(url, requestMethod, body, token);
};

export const ApproveStudent = async (studentId, token) => {
  const url = `approveStudent/${studentId}`;
  const requestMethod = 'put';
  const body = {};

  return await apiWrapper(url, requestMethod, body, token);
};

export const GetGrades = async (organizationId, token) => {
  const url = `grades?${addOrganizationId(organizationId)}`;
  const requestMethod = 'get';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token, 200);
};

export const LinkStudentAssessment = async (
  assessmentInfo,
  organizationId,
  token
) => {
  const assessmentId = assessmentInfo.id;
  const link = assessmentInfo.link;
  const studentId = assessmentInfo.studentId;
  const url = `students/${studentId}/assessments/${assessmentId}`;
  const requestMethod = 'post';
  const body = { link: link, organizationId: organizationId };

  return await apiWrapper(url, requestMethod, body, token);
};

export const GetMaterials = async (organizationId, token) => {
  const url = `materials?${addOrganizationId(organizationId)}`;
  const requestMethod = 'get';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token, 200);
};

export const GetMaterialsList = async (organizationId, token, limit, offset, type) => {
  const url = `getMaterialsList?type=${type}&limit=${limit}&offset=${offset}&${addOrganizationId(organizationId)}`;
  const requestMethod = 'get';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token, 200);
};

export const AddMaterial = async (organizationId, materialInfo, token) => {
  const url = `materials?${addOrganizationId(organizationId)}`;
  const requestMethod = 'post';
  const body = materialInfo;
  
  return await apiWrapper(url, requestMethod, body, token, 200);
};

export const EditMaterial = async (materialId, materialInfo, token) => {
  const url = `materials/${materialId}`;
  const requestMethod = 'put';
  const body = materialInfo;

  return await apiWrapper(url, requestMethod, body, token, 200);
};

export const DeleteMaterial = async (materialId, organizationId, token) => {
  const url = `materials/${materialId}`;
  const requestMethod = 'delete';
  const body = {
    data: { organizationId: organizationId },
    headers: authHeader(token),
  };

  return await apiWrapper(url, requestMethod, body, null, 200);
};

export const GetFeedback = async (organizationId, token, limit, offset, sortBy, sortType) => {
  const url = `feedbacks?${addOrganizationId(organizationId)}&limit=${limit}&offset=${offset}&sortBy=${sortBy}&sortType=${sortType}`;
  const requestMethod = 'get';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token, 200);
};

export const GetUsers = async (organizationId, isActive, token, limit, offset, search, role) => {
  const url = `users?limit=${limit}&offset=${offset}&${addOrganizationId(organizationId)}&isActive=${!!isActive ? isActive : ''}&search=${!!search ? search : ''}&role=${!!role ? role : ''}`;
  const requestMethod = 'get';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token);
};

export const GetFacilitatorUsers = async (organizationId, token, limit, offset, schoolId, search, status) => {
  const url = `getUser?limit=${limit}&offset=${offset}&schoolId=${!!schoolId ? schoolId : ''}&search=${!!search ? search : ''}&status=${status}&${addOrganizationId(organizationId)}`;
  const requestMethod = 'get';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token);
};

export const GetAssignUser = async (token, schoolId, organizationId) => {
  const url = `getAssignUser?schoolId=${!!schoolId ? schoolId : ''}&${addOrganizationId(organizationId)}`;
  const requestMethod = 'get';

  return await apiWrapper(url, requestMethod, null, token, 201);
};

export const GetFacilitators = async (token, schoolId, groupId, organizationId) => {
  const url = `getFacilitators?schoolId=${!!schoolId ? schoolId : ''}&groupId=${!!groupId ? groupId : ''}&${addOrganizationId(organizationId)}`;
  const requestMethod = 'get';

  return await apiWrapper(url, requestMethod, null, token, 201);
};

export const UserToggleStatus = async (organizationId, userId, token) => {
  const url = `users/${userId}/toggle-status?${addOrganizationId(
    organizationId
  )}`;
  const requestMethod = 'patch';
  const body = {};

  return await apiWrapper(url, requestMethod, body, token);
};

export const CreateOrganization = async (info, token) => {
  const url = `organizations`;
  const requestMethod = 'post';
  const body = info;

  return await apiWrapper(url, requestMethod, body, token);
};

export const CreateUser = async (organizationId, info, token) => {
  const url = `users?${addOrganizationId(organizationId)}`;
  const requestMethod = 'post';
  const body = info;

  return await apiWrapper(url, requestMethod, body, token);
};

export const EditUser = async (organizationId, userId, info, token) => {
  const url = `users/${userId}?${addOrganizationId(organizationId)}`;
  const requestMethod = 'put';
  const body = info;

  return await apiWrapper(url, requestMethod, body, token);
};

export const RemoveUser = async (userId, token) => {
  const url = `users/${userId}`;
  const requestMethod = 'delete';
  const body = {
    headers: authHeader(token),
  };

  return await apiWrapper(url, requestMethod, body, null, 200);
};

export const LogInAsUser = async (organizationId, userId, token) => {
  const url = `users/${userId}/login?${addOrganizationId(organizationId)}`;
  const requestMethod = 'get';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token);
};

export const EditOrganization = async (organizationId, info, token) => {
  const url = `organizations/${organizationId}?${addOrganizationId(
    organizationId
  )}`;
  const requestMethod = 'put';
  const body = info;

  return await apiWrapper(url, requestMethod, body, token);
};

export const GetRoles = async (token) => {
  const url = `roles`;
  const requestMethod = 'get';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token);
};

export const GetAllRoles = async (token) => {
  const url = `getAllRoles`;
  const requestMethod = 'get';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token);
};

export const AddNote = async (organizationId, comment, studentId, token) => {
  const url = `notes`;
  const requestMethod = 'post';
  const body = { comment, studentId, organizationId: organizationId };

  return await apiWrapper(url, requestMethod, body, token, 200);
};

export const GetStudentMaterial = async (
  organizationId,
  studentId,
  materialType,
  token
) => {
  const url = `students/${studentId}/materials/${materialType}?${addOrganizationId(
    organizationId
  )}`;
  const requestMethod = 'get';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token, 200);
};

export const EditNote = async (
  organizationId,
  studentId,
  noteId,
  comment,
  token
) => {
  const url = `students/${studentId}/notes/${noteId}?${addOrganizationId(
    organizationId
  )}`;
  const requestMethod = 'put';
  const body = { comment };

  return await apiWrapper(url, requestMethod, body, token);
};

export const DeleteNote = async (organizationId, studentId, noteId, token) => {
  const url = `students/${studentId}/notes/${noteId}`;
  const requestMethod = 'delete';
  const body = {
    data: { organizationId: organizationId },
    headers: authHeader(token),
  };

  return await apiWrapper(url, requestMethod, body, null);
};

export const AddMaterialToStudent = async function (
  organizationId,
  studentId,
  info,
  token
) {
  const { dob, materialId, groupId, skillIds } = info;
  const url = `students/${studentId}/materials/${materialId}?${addOrganizationId(
    organizationId
  )}`;
  const requestMethod = 'post';
  const body = { details: dob, groupId: groupId, skillIds: skillIds };
  return await apiWrapper(url, requestMethod, body, token, 200);
};

export const RemoveStudentMaterial = async function (
  organizationId,
  studentMaterialId,
  token
) {
  const url = `students/materials/${studentMaterialId}`;
  const requestMethod = 'delete';
  const body = {
    data: { organizationId: organizationId },
    headers: authHeader(token),
  };

  return await apiWrapper(url, requestMethod, body, null, 200);
};

export const UpdateStudentMaterial = async function (
  organizationId,
  studentMaterialId,
  details,
  token
) {
  const url = `students/materials/${studentMaterialId}?${addOrganizationId(
    organizationId
  )}`;
  const requestMethod = 'put';
  const body = { details: details };

  return await apiWrapper(url, requestMethod, body, token, 200);
};

export const GetContactInfo = async (organizationId, token) => {
  const url = `organizations/contact?${addOrganizationId(organizationId)}`;
  const requestMethod = 'get';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token, 200);
};

export const UpdateContactInfo = async (organizationId, contactInfo, token) => {
  const url = `organizations/contact?${addOrganizationId(organizationId)}`;
  const requestMethod = 'put';
  const body = contactInfo;

  return await apiWrapper(url, requestMethod, body, token);
};

export const GetMetricsData = async (organizationId, dateFilters, token) => {
  let url = `reports?${addOrganizationId(organizationId)}`;
  dateFilters.startDate &&
    (url += `&startDate=${dateFilters.startDate}&endDate=${dateFilters.endDate}`);
  const requestMethod = 'get';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token, 200);
};

export const GetSkills = async (organizationId, token) => {
  const url = `skills?${addOrganizationId(organizationId)}`;
  const requestMethod = 'get';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token, 200);
};

export const GetAllSkills = async (token) => {
  const url = `allSkills`;
  const requestMethod = 'get';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token, 200);
};

export const GetApproveSkills = async (token, limit, offset) => {
  const queries = `?limit=${limit}&offset=${offset}&`;
  const url = `getApproveSkills/${queries}`;

  const requestMethod = 'get';

  return await apiWrapper(url, requestMethod, null, token);
};

export const MergeSkills = async (token, info) => {
  const url = `mergeSkill`;
  const requestMethod = 'post';
  const body = info;

  return await apiWrapper(url, requestMethod, body, token, 201);
};

export const SkillToggleStatus = async (skillId, token) => {
  const url = `skills/${skillId}/toggle-status`;
  const requestMethod = 'patch';
  const body = {};

  return await apiWrapper(url, requestMethod, body, token);
};

export const CreateSkill = async (skillInfo, token) => {
  const url = `skills`;
  const requestMethod = 'post';
  const body = skillInfo;

  return await apiWrapper(url, requestMethod, body, token);
};

export const DeleteSkill = async (skillId, token) => {
  const url = `skills/${skillId}`;
  const requestMethod = 'delete';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token);
};

export const GetLessons = async (organizationId, token) => {
  const url = `lessonTopics?${addOrganizationId(organizationId)}`;
  const requestMethod = 'get';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token, 200);
};

export const CreateLesson = async (lessonInfo, token) => {
  const url = `lessonTopics`;
  const requestMethod = 'post';
  const body = lessonInfo;

  return await apiWrapper(url, requestMethod, body, token);
};

export const DeleteLesson = async (lessonId, token) => {
  const url = `lessonTopics/${lessonId}`;
  const requestMethod = 'delete';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token);
};


export const GetArchivesInfo = async (
  token,
  organizationId,
  filters,
  limit,
  offset = 0
) => {
  const requestMethod = 'get';
  let url = `materials/items?limit=${limit}&offset=${offset}&search=${
    filters.search
  }&schoolId=${filters.schoolId}&startDate=${
    filters.dateFilters?.startDate
  }&endDate=${filters.dateFilters?.endDate}&${addOrganizationId(
    organizationId
  )}`;

  return await apiWrapper(url, requestMethod, null, token, 200);
};

export const GetPhotos = async (token, activityId) => {
  const url = `materials/items/${activityId}`;
  const requestMethod = 'get';
  const body = null;

  return await apiWrapper(url, requestMethod, body, token, 200);
};

export const DownloadPhotos = async (token, activityIds) => {
  const url = `materials/items/download`;
  const requestMethod = 'post';
  const body = { activityIds: activityIds };

  return await apiWrapper(url, requestMethod, body, token, 200);
};
