import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons';
import { BrowserRouter } from 'react-router-dom';
import ModalProvider from './contexts/modalContext';
import LogInProvider from './contexts/logInContext';

library.add(fab, faCheckSquare, faCoffee);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <LogInProvider>
        <ModalProvider>
          <App />
        </ModalProvider>
      </LogInProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
