import React, { useState, useContext } from 'react';
import { LessonsContext } from '../../../contexts/lessonsContext';
import { ModalContext } from '../../../contexts/modalContext';
import { isValidNote, errors } from '../../../utils/validation';
import { AddNote } from '../../../api';
import { OrganizationsContext } from '../../../contexts/organizationsContext';
import { LogInContext } from '../../../contexts/logInContext';
import { AddNoteModalWrapper, ModalWrapper } from '../Modals.styles';
import { StudentsContext } from '../../../contexts/studentsContext';
import { addElement, createDate } from '../../../utils/constants';
import { ModalCreateButtons } from '../ModalButtons';
import { warning } from '../../../utils/toastify';

const AddStudentNoteModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal } = useContext(ModalContext);
  const { organizationId } = useContext(OrganizationsContext);
  const { selectedStudent } = useContext(StudentsContext);
  const { userNote, setUserNote, setNotes, notes } = useContext(LessonsContext);
  const { token } = useContext(LogInContext);
  const [error, setError] = useState({});

  const handleNoteInput = (e) => {
    let errorCopy = { ...error };
    setUserNote(e.target.value);
    isValidNote(e.target.value)
      ? delete errorCopy.note
      : (errorCopy.note = errors.noteLengthErrorMessage);
    setError(errorCopy);
  };

  const handleSubmitValidations = () => {
    let errorCopy = { ...error };
    delete errorCopy.apiFail;

    !userNote && (errorCopy.note = errors.noteLengthErrorMessage);

    if (Object.keys(errorCopy).length > 0) {
      warning("Required field/s must be filled");
    }

    setError(errorCopy);
    return Object.keys(errorCopy).length === 0;
  };

  const handleSubmitNote = async () => {
    const isValid = handleSubmitValidations();

    if (!isValid) {
      return false;
    }

    const responseData = await AddNote(
      organizationId,
      userNote,
      selectedStudent.id,
      token
    );

    if (responseData.status === 'success') {
      const newNote = {
        comment: userNote,
        createdAt: createDate(new Date()).dobFormat,
        id: responseData.data.id,
      };
      setShowModal(false);
      addElement(notes, setNotes, newNote);
    } else {
      setError({ apiFail: errors.apiFail });
    }
  };

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <AddNoteModalWrapper>
            <div className="note">
              <h2>Notes</h2>
              <textarea
                placeholder="Your text here *"
                onChange={handleNoteInput}
              />
              {error.note && <h5>{error.note}</h5>}
            </div>
            <ModalCreateButtons
              text="Add Note"
              onClick={handleSubmitNote}
              error={error.apiFail}
              className="modal__buttons--add-note"
            />
          </AddNoteModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default AddStudentNoteModal;
