export const errors = {
  successResponse:
    'You can now access all the information available for the selected organization',
  passwordErrorMessage: 'Incorrect email and/or password',
  passwordRequiredErrorMessage: 'Password is required',
  passwordLengthErrorMessage: 'Password must be more than 3 characters',
  confirmPasswordErrorMessage: 'Passwords must match',
  facilitatorErrorMessage: 'User does not have access to the web platform',
  requiredNameError: 'Organization Name is required',
  requiredCityError: 'City is required',
  requiredRegionError: 'Region/Province is required',
  requiredCountryError: 'Country is required',
  coordinatesErrorMessage: 'Coordinate format is not supported',
  coordinateRequiredMessage: 'Coordinate is required',
  titleErrorMessage: 'Title is required',
  linkErrorMessage: 'The link must be valid',
  websiteLinkErrorMessage: 'The website link must be valid',
  linkRequiredMessage: 'The link is required',
  allLinksErrorMessage: 'All links must be valid',
  gradeRequiredMessage: 'Grade is required',
  lessonTopicIdMessage: 'Lesson is required',
  skillIdsMessage: 'Skill is required',
  schoolRequiredMessage: 'School is required',
  dateErrorMessage: 'Please use dd/mm/yyyy date format',
  dateRequiredErrorMessage: 'Birth Date is required',
  dobRequiredErrorMessage: 'Date is required',
  genderErrorMessage: 'Gender is required',
  countryErrorMessage: 'Country is required',
  uniqueIdentifierRequired: 'Unique ID is required',
  requiredFnameError: 'First Name is required',
  requiredLnameError: 'Last Name is required',
  emailRequiredMessage: "Email is required",
  websiteErrorMessage: "website is required",
  ownerEmailErrorMessage: "Owner Email is required",
  ownerFirstNameErrorMessage: "Owner First Name is required",
  ownerLastNameErrorMessage: "Owner Last Name is required",
  streetRequiredMessage: "Street is required",
  streetNumberRequiredMessage: "Street Number is required",
  cityRequiredMessage: "City is required",
  regionRequiredMessage: "Region/Province is required",
  requiredSchoolNameError: 'School Name is required',
  requiredLatError: 'Latitude is required',
  requiredLongError: 'Longitude is required',
  countryRequiredMessage: "Country is required",
  uniqueIdentifierErrorMessage:
    'National ID must be 13 characters long (may include digits, letters and special characters)',
  emailErrorMessage: 'Invalid email address',
  roleRequiredMessage: 'Role is required',
  noteLengthErrorMessage: 'The note must have at least 10 characters',
  phoneRequiredMessage: 'Phone number is required',
  apiFail:
    'Change Password Operation failed, please try again',
  apiChangeStudentIDFail:
    'Change National ID Operation failed, please try again',
  apiSetPasswordFail:
    'Set Password Operation failed, please try again',
  removalErrorMessage: 'Unsuccessful removal',
  skillsDuplicateError: 'This skill already exists',
  lessonsDuplicateError: 'This lesson already exists',
  downloadMessage: 'This might take up to 5 minutes',
  downloadFailMessage:
    'Maximum archive size reached. Please select fewer activities for photo download',
  skillsLength: 'Skills must be under 20 characters',
  lengthsLength: 'Lenssons must be under 20 characters',
  contactNumberRequiredMessage: 'Contact Number is required',
  contactNumberMessage: 'Contact Number must 5 to 15 digit long',
  countryCodeRequiredMessage: 'Country Code is required',
};

export const isPasswordLengthValid = (input) => {
  return input.length > 3;
};

export const isValidCoordinate = (name, input) => {
  const formatLatitude =
    /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
  const formatLongitude =
    /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;

  if (name === 'lat') {
    return formatLatitude.test(String(input));
  } else {
    return formatLongitude.test(String(input));
  }
};

export const isValidName = (input) => {
  return input.length > 0;
};

export const isValidCity = (input) => {
  return input.length > 0;
};

export const isValidRegion = (input) => {
  return input.length > 0;
};

export const isValidCountry = (input) => {
  return input.length > 0;
};

export const isValidMaterial = (input) => {
  return input.length > 0;
};

export const isValidLink = (input) => {
  const formatLink =
    /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;

  return formatLink.test(String(input));
};

export const isValidDate = (input) => {
  const formatDate =
    /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

  return formatDate.test(String(input));
};

export const isValidEmail = (input) => {
  const formatEmail =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+(.+)*$/;

  return formatEmail.test(String(input));
};

export const toProperCase = (input) => {
  return input.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1);
  });
};

export const isValidNote = (note) => {
  return note.length >= 10;
};

export const isValidUniqueIdentifier = (input) => {
  return input.length === 13;
};

export const isValidContactPhone = (input) => {
  return input.length >= 5 && input.length <= 15;
};

export const isValidSkill = (skill) => {
  return skill.length < 20;
};
