import userCogIcon from '../../assets/icons/user-cog-icon.svg';
import schoolsIcon from '../../assets/icons/schools-icon.svg';
import groupsIcon from '../../assets/icons/groups-icon.svg';
import studentsIcon from '../../assets/icons/students-icon.svg';
import lessonsIcon from '../../assets/icons/lessons-icon.svg';
import skillIcon from '../../assets/icons/skill.svg';
import feedbackIcon from '../../assets/icons/feedback-icon.svg';
import contactIcon from '../../assets/icons/contact-icon.svg';
import metricsIcon from '../../assets/icons/metrics-icon.svg';
import photoIcon from '../../assets/icons/photo-icon.svg';
import approveIcon from '../../assets/icons/approve.svg';
import smsIcon from '../../assets/icons/mail.svg';

export const ADMIN_MenuOptions = [
  {
    title: 'Organizations & Users',
    icon: userCogIcon,
    path: '/users',
  },
  {
    title: 'Schools',
    icon: schoolsIcon,
    path: '/schools',
  },
  {
    title: 'Groups',
    icon: groupsIcon,
    path: '/groups',
  },
  {
    title: 'Students & Results',
    icon: studentsIcon,
    path: '/students',
  },
  {
    title: 'Lessons & Activities',
    icon: lessonsIcon,
    path: '/lessons',
  },
  {
    title: 'Photo Albums',
    icon: photoIcon,
    path: '/photos',
  },
  {
    title: 'Approve',
    icon: approveIcon,
    children: [
      {
        title: 'Students',
        icon: studentsIcon,
        path: '/approve/students',
      },
      {
        title: 'Lessons',
        icon: lessonsIcon,
        path: '/approve/lessons',
      },
      {
        title: 'Skills',
        icon: skillIcon,
        path: '/approve/skills',
      }
    ]
  },
  {
    title: "Platform's Metrics",
    icon: metricsIcon,
    path: '/metrics',
  },
  {
    title: 'Feedback',
    icon: feedbackIcon,
    path: '/feedback',
  },
  {
    title: 'Contact & Support',
    icon: contactIcon,
    path: '/contact',
  },
  {
    title: 'Email Template',
    icon: smsIcon,
    path: '/email-template',
  }
];

export const OWNER_MenuOptions = [
  {
    title: 'Users',
    icon: userCogIcon,
    path: '/users',
  },
  {
    title: 'Schools',
    icon: schoolsIcon,
    path: '/schools',
  },
  {
    title: 'Groups',
    icon: groupsIcon,
    path: '/groups',
  },
  {
    title: 'Students & Results',
    icon: studentsIcon,
    path: '/students',
  },
  {
    title: 'Lessons & Activities',
    icon: lessonsIcon,
    path: '/lessons',
  },
  {
    title: 'Photo Albums',
    icon: photoIcon,
    path: '/photos',
  },
  {
    title: "Platform's Metrics",
    icon: metricsIcon,
    path: '/metrics',
  },
  {
    title: 'Feedback',
    icon: feedbackIcon,
    path: '/feedback',
  },
  {
    title: 'Contact & Support',
    icon: contactIcon,
    path: '/contact',
  },
];

export const ORG_ADMIN_MenuOptions = [
  {
    title: 'Users',
    icon: userCogIcon,
    path: '/users',
  },
  {
    title: 'Schools',
    icon: schoolsIcon,
    path: '/schools',
  },
  {
    title: 'Groups',
    icon: groupsIcon,
    path: '/groups',
  },
  {
    title: 'Students & Results',
    icon: studentsIcon,
    path: '/students',
  },
  {
    title: 'Lessons & Activities',
    icon: lessonsIcon,
    path: '/lessons',
  },
  {
    title: 'Photo Albums',
    icon: photoIcon,
    path: '/photos',
  },
  {
    title: "Platform's Metrics",
    icon: metricsIcon,
    path: '/metrics',
  },
  {
    title: 'Feedback',
    icon: feedbackIcon,
    path: '/feedback',
  },
  {
    title: 'Contact & Support',
    icon: contactIcon,
    path: '/contact',
  },
];

export const ORG_CM_MenuOptions = [
  {
    title: 'Users',
    icon: userCogIcon,
    path: '/users',
  },
  {
    title: 'Schools',
    icon: schoolsIcon,
    path: '/schools',
  },
  {
    title: 'Groups',
    icon: groupsIcon,
    path: '/groups',
  },
  {
    title: 'Students & Results',
    icon: studentsIcon,
    path: '/students',
  },
  {
    title: 'Lessons & Activities',
    icon: lessonsIcon,
    path: '/lessons',
  },
  {
    title: 'Photo Albums',
    icon: photoIcon,
    path: '/photos',
  },
  {
    title: "Platform's Metrics",
    icon: metricsIcon,
    path: '/metrics',
  },
  {
    title: 'Contact & Support',
    icon: contactIcon,
    path: '/contact',
  },
];
