import React, { useContext } from 'react';
import { LessonsContext } from '../../../contexts/lessonsContext';
import { ModalContext } from '../../../contexts/modalContext';
import {
  ActionButtonGreen,
  ActionButtonOrangeSecondary,
} from '../../Buttons/ActionButton';
import { ModalWrapper, DiscardModalWrapper } from '../../Modals/Modals.styles'

const DeleteLessonsModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal } = useContext(ModalContext);
  const { deleteLessonsCheck, setDeleteLessonsCheck, lessons, setLessons } =
    useContext(LessonsContext);

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <DiscardModalWrapper>
            <h2>Do you want to delete the folowing lessons?</h2>
            <h4>
              {deleteLessonsCheck.lessons.map((lesson, index) =>
                index === deleteLessonsCheck.lessons.length - 1 ? (
                  <span key={lesson.id}>{lesson.name}</span>
                ) : (
                  <span key={lesson.id}>{`${lesson.name}, `}</span>
                )
              )}
            </h4>

            <div className="modal__buttons--discard-skill">
              <ActionButtonGreen
                text="Delete"
                onClick={() => {
                  setDeleteLessonsCheck({ ...deleteLessonsCheck, check: true });
                  setShowModal(false);
                }}
              />
              <ActionButtonOrangeSecondary
                text="Cancel"
                onClick={() => {
                  setLessons(
                    lessons.map((lesson) => {
                      lesson.toDelete = false;
                      lesson.hideSkill = false;
                      return lesson;
                    })
                  );
                  setShowModal(false);
                }}
              />
            </div>
          </DiscardModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default DeleteLessonsModal;
