import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const FooterWrapper = styled.footer`
  background: var(--blue-200);
  height: 3.5rem;
  padding: 1.5rem 2rem;
  display: flex;
  box-shadow: 0px -3px 10px 0 hsl(0, 0%, 0%, 0.15);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: sticky;
  bottom: 0;
  z-index: 100;

  div {
    font-size: 0.8rem;
  }

  p {
    font-size: 0.9rem;
    color: var(--blue-300);
  }

  a {
    color: var(--blue-300);
    text-decoration: none;
  }
`;
export const StyledIcon = styled(FontAwesomeIcon)`
  margin: 0 0.5rem;

  &:hover {
    color: var(--blue-250);
  }
`;
