import React, { useContext } from 'react';
import { LessonsContext } from '../../contexts/lessonsContext';
import { ModalContext } from '../../contexts/modalContext';
import { ModalWrapper, PhotoModalWrapper } from './Modals.styles';

const ActivityPhotoModal = ({ modalRef, closeModal }) => {
  const { showModal } = useContext(ModalContext);
  const { selectedPhoto } = useContext(LessonsContext);

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <PhotoModalWrapper>
            <img src={selectedPhoto} alt="selected activity"/>
          </PhotoModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default ActivityPhotoModal;
