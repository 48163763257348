import React, { useState } from 'react';
import logInPhoto from '../assets/login-page.svg';
import ForgottenPasswordCard from '../components/Cards/ForgottenPasswordCard';
import LogInCard from '../components/Cards/LogInCard';
import{Grid, Container} from "@mui/material";

const Login = ({loginhandler}) => {
  const [forgottenPassword, setForgottenPassword] = useState(false);
  const [email, setEmail ] = useState("")

  return (
    
    <Container maxWidth="lg" sx={{display:'flex', flexWrap:'wrap'}}> 
      <Grid container spacing={3} alignItems="center" className="loginWrapper">
      <Grid item xs={12} sm={12} md={6}>
        <img src={logInPhoto} alt="" style={{width:'100%'}} />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
      {!forgottenPassword ? (
        <LogInCard setForgottenPassword={setForgottenPassword} setEmail={setEmail} loginhandler={loginhandler} />
      ) : (
        <ForgottenPasswordCard setForgottenPassword={setForgottenPassword} email={email} setEmail={setEmail}/>
      )}
      </Grid>
      
    </Grid>
    </Container>
  
  );
};

export default Login;
