export let inputOrgFields = {
  organization: {
    title: 'Organization Name',
    name: 'name',
    type: 'text',
    required: "*"
  },
  website: {
    title: 'Website',
    name: 'link',
    type: 'website',
  },
  ownerEmail: {
    title: 'Owner Email',
    name: 'email',
    type: 'text',
    required: "*"
  },
  ownerFirstName: {
    title: 'Owner First Name',
    name: 'ownerName',
    type: 'text',
    required: "*"
  },
  ownerLastName: {
    title: 'Owner Last Name',
    name: 'ownerLastName',
    type: 'text',
    required: "*"
  },
  description: {
    title: 'Description',
    name: 'description',
    type: 'text',
  },
  streetNumber: {
    title: 'Business Address',
    id: 'streetNumber',
    placeholder: 'Street Number',
    name: 'streetNumber',
    type: 'number',
    required: "*"
  },
  street: {
    id: 'street',
    placeholder: 'Street',
    name: 'street',
    type: 'text',
  },
  city: {
    id: 'city',
    placeholder: 'City',
    name: 'city',
    type: 'text',
  },
  region: {
    id: 'region',
    placeholder: 'Region/Province',
    name: 'region',
    type: 'text',
  }
};

export let inputUserFields = {
  user: {
    title: 'First Name',
    name: 'name',
    type: 'text',
    required: "*"
  },
  email: {
    title: 'Email',
    name: 'email',
    type: 'email',
    required: "*"
  },
  lastName: {
    title: 'Last Name',
    name: 'lastName',
    type: 'text',
    required: "*"
  },
  city: {
    title: 'City',
    name: 'city',
    type: 'text',
    required: "*"
  },
  contactNumber: {
    title: 'Contact No.',
    name: 'contactPhone',
    type: 'number',
  },
};

export let materialInputFields = {
  material: {
    title: 'Title',
    type: 'text',
    name: 'name',
    required: "*"
  },
  link: {
    title: 'Link',
    type: 'url',
    name: 'details',
    placeholder: 'https://example.com',
    inputTitle: 'Must begin with "https://"',
    required: "*"
  },
  lesson: {
    title: 'Add New Lesson Topic',
    type: 'text',
    name: 'new-lesson'
  },
};

export let inputSchoolFields = {
  school: {
    placeholder: 'School Name',
    name: 'name',
    type: 'text',
    value: '',
    required: "*"
  },
  long: {
    title: 'Longitude',
    id: 'lng',
    name: 'long',
    type: 'text',
    value: '',
    required: "*"
  },
  lat: {
    title: 'Latitude',
    id: 'lat',
    name: 'lat',
    type: 'text',
    value: '',
    required: "*"
  },
  streetNumber: {
    id: 'streetNumber',
    placeholder: 'Street Number',
    name: 'streetNumber',
    type: 'number',
    value: '',
  },
  street: {
    id: 'street',
    placeholder: 'Street',
    name: 'street',
    type: 'text',
    value: '',
  },
  city: {
    id: 'city',
    placeholder: 'City/Town',
    name: 'city',
    type: 'text',
    value: '',
  },
  region: {
    id: 'region',
    placeholder: 'Region/Province',
    name: 'region',
    type: 'text',
    value: '',
  },
  country: {
    id: 'country',
    placeholder: 'Country',
    name: 'country',
    type: 'text',
    value: '',
  },
};

export let inputStudentFields = {
  firstName: {
    title: 'First Name',
    name: 'name',
    type: 'text',
    value: '',
    required: "*"
  },
  lastName: {
    title: 'Last Name',
    name: 'lname',
    type: 'text',
    value: '',
    required: "*"
  },
  uniqueId: {
    title: 'National ID',
    name: 'uniqueId',
    type: 'text',
    value: '',
  },
  birthday: {
    title: 'Birth date (dd/mm/yyyy)',
    name: 'dob',
    value: '',
    type: 'date',
    max: new Date().toLocaleDateString("sv"),
    min: "1851-01-01",
    onKeyDown: (e) => {e.preventDefault()},
    required: "*"
  }
};

export let inputContactFields = {
  person: {
    title: 'Contact Person',
    name: 'name',
    type: 'text',
  },
  email: {
    title: 'Email',
    name: 'email',
    type: 'email',
  },
  phone: {
    title: 'Phone Number',
    name: 'phone',
    type: 'tel',
  },
};

export let inputChangePasswordFields = {
  newPassword: {
    title: 'New Password',
    name: 'newPassword',
    type: 'password',
  },
  checkPassword: {
    title: 'Confirm Password',
    name: 'newPasswordCheck',
    type: 'password',
  },
};

export let inputLoginFields = {
  email: {
    title: 'Email',
    name: 'email',
    type: 'email',
    placeholder: 'john@email.com',
  },
  password: {
    title: 'Password',
    name: 'password',
    type: 'password',
    placeholder: 'Your password',
  },
};

export let inputForgottenPasswordFields = {
  email: {
    title: 'Email',
    name: 'email',
    type: 'email',
  },
};

export let inputSkillsFields = {
  skill: {
    name: 'skill',
    type: 'text',
    placeholder: 'New Skill',
  },
};

export let inputLessonsFields = {
  lesson: {
    name: 'lesson',
    type: 'text',
    placeholder: 'New Lesson Topic',
  },
};