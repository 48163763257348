import React, { useState, useContext, useEffect } from 'react';
import { StudentsContext } from './studentsContext';

export const LessonsContext = React.createContext();

const LessonsProvider = (props) => {
  const { selectedStudent } = useContext(StudentsContext);
  const [activeTab, setActiveTab] = useState('lesson');
  const [selectedMaterial, setSelectedMaterial] = useState({});
  const [userNote, setUserNote] = useState('');
  const [notes, setNotes] = useState([]);
  const [noteId, setNoteId] = useState('');
  const [displayNotes, setDisplayNotes] = useState([]);
  const [studentMaterials, setStudentMaterials] = useState([]);
  const [materialFromStudent, setMaterialFromStudent] = useState(false);
  const [selectedMaterialId, setSelectedMaterialId] = useState('');
  const [materials, setMaterials] = useState([]);
  const [skills, setSkills] = useState([]);
  const [skillsOptions, setSkillsOptions] = useState([]);
  const [deleteSkillsCheck, setDeleteSkillsCheck] = useState({
    skills: [],
    check: false,
  });
  const [editMode, setEditMode] = useState(false);
  const [lessons, setLessons] = useState([]);
  const [lessonsOptions, setLessonsOptions] = useState([]);
  const [deleteLessonsCheck, setDeleteLessonsCheck] = useState({
    lessons: [],
    check: false,
  });
  const [editLessonMode, setEditLessonMode] = useState(false);
  const [archives, setArchives] = useState([]);
  const [selectedArchive, setSelectedArchive] = useState('');
  const [photos, setPhotos] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState('');

  useEffect(() => {
    setNotes(selectedStudent.notes || []);
  }, [selectedStudent]);

  useEffect(() => {
    setSkillsOptions(
      skills.map((skill) => ({
        value: skill.name,
        label: skill.name,
        id: skill.id,
      }))
    );
  }, [skills]);

  useEffect(() => {
    setLessonsOptions(
      lessons.map((lesson) => ({
        value: lesson.name,
        label: lesson.name,
        id: lesson.id,
      }))
    );
  }, [lessons]);

  return (
    <LessonsContext.Provider
      value={{
        materials,
        setMaterials,
        activeTab,
        setActiveTab,
        selectedMaterial,
        setSelectedMaterial,
        notes,
        setNotes,
        userNote,
        setUserNote,
        noteId,
        setNoteId,
        displayNotes,
        setDisplayNotes,
        studentMaterials,
        setStudentMaterials,
        materialFromStudent,
        setMaterialFromStudent,
        selectedMaterialId,
        setSelectedMaterialId,
        skills,
        setSkills,
        skillsOptions,
        deleteSkillsCheck,
        setDeleteSkillsCheck,
        setSkillsOptions,
        editMode,
        setEditMode,
        archives,
        setArchives,
        selectedArchive,
        setSelectedArchive,
        photos,
        setPhotos,
        selectedPhoto,
        setSelectedPhoto,
        // lesson module 
        lessons,
        setLessons,
        lessonsOptions,
        setLessonsOptions,
        deleteLessonsCheck,
        setDeleteLessonsCheck,
        editLessonMode,
        setEditLessonMode
      }}
    >
      {props.children}
    </LessonsContext.Provider>
  );
};

export default LessonsProvider;
