import React, { useState, useContext } from "react";
import { LessonsContext } from "../../../contexts/lessonsContext";
import { ModalContext } from "../../../contexts/modalContext";
import { errors } from "../../../utils/validation";
import { OrganizationsContext } from "../../../contexts/organizationsContext";
import { LogInContext } from "../../../contexts/logInContext";
import { ModalWrapper, RemoveModalWrapper } from "../Modals.styles";
import { DeleteMaterial, RemoveStudentMaterial } from "../../../api";
import { ModalRemoveButtons } from "../ModalButtons";
import { removeElement, removeElementByLinkId } from "../../../utils/constants";

const RemoveMaterialModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal } = useContext(ModalContext);
  const { organizationId } = useContext(OrganizationsContext);
  const {
    selectedMaterial,
    materialFromStudent,
    setMaterialFromStudent,
    selectedMaterialId,
    activeTab,
    materials,
    setMaterials,
    studentMaterials,
    setStudentMaterials,
  } = useContext(LessonsContext);
  const { token } = useContext(LogInContext);
  const [error, setError] = useState({});

  const handleRemoveMaterial = async () => {
    if (materialFromStudent) {
      const isValidRemoval = await RemoveStudentMaterial(
        organizationId,
        selectedMaterialId,
        token
      );
      if (isValidRemoval.status === "success") {
        removeElementByLinkId(
          selectedMaterialId,
          studentMaterials,
          setStudentMaterials
        );
        setShowModal(false);
        setMaterialFromStudent(false);
      } else {
        setError({ responseMessage: errors.removalErrorMessage });
      }
    } else {
      const isValidRemoval = await DeleteMaterial(
        selectedMaterial.id,
        organizationId,
        token
      );
      if (isValidRemoval.status === "success") {
        removeElement(selectedMaterial.id, materials, setMaterials);
        setShowModal(false);
      } else {
        setError({ responseMessage: errors.removalErrorMessage });
      }
    }
  };
  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <RemoveModalWrapper>
            <h3>
              {` Are you sure you want to remove ${
                materialFromStudent
                  ? `this ${activeTab}`
                  : selectedMaterial.name
              } ?`}
            </h3>
            <ModalRemoveButtons
              error={error.responseMessage}
              onClick={handleRemoveMaterial}
            />
          </RemoveModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default RemoveMaterialModal;
