import React, { useEffect, useContext, useState } from "react";
import styled from "styled-components";
import logo from "../assets/logo.svg";
import avatar from "../assets/avatar.svg";
import { MenuIcon } from "./Menu/Menu";
import LogoutButton from "./Buttons/LogoutButton";
import { LogInContext } from "../contexts/logInContext";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { OrganizationsContext } from "../contexts/organizationsContext";
import { GetOrganizations } from "../api";
import { MenuContext } from "../contexts/menuContext";

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 3px 10px 0 hsl(0, 0%, 0%, 0.15);
  width: 100%;
  height: 4rem;
  padding: 1rem;
  background: var(--blue-200);
  gap: 10px;
  position: sticky;
  top: 0;
  z-index: 100;

  div.left-side {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.select-org {
    width: 16rem;
    position: absolute;
    left: 17rem;
    z-index: 300;
  }

  .user {
    display: flex;
  }

  .user_details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .option {
    list-style-type: none;
    background: #f6f7f9;
    box-shadow: 1px 1px 4px lightgray;
    width: 18rem;
    padding: 2rem 1rem 0.5rem 1rem;
    border-radius: 5px;
    position: absolute;
    top: 3rem;
    right: 0.8rem;
    z-index: 100;

    li {
      img {
        margin-bottom: 0.5rem;
        height: 5rem;
      }
    }
  }

  table tr {
    font-size: 13px;
  }

  .nav_title {
    width: 6rem;
    text-align: start;
    color: #0B0A42;
    border-bottom: 2px solid #EEEEEE;
  }

  .nav_text {
    word-break: break-word;
    text-align: start;
    border-bottom: 2px solid #eeeeee;
    padding: 0.5rem 0;
  }

  .last_nav_text {
    border: none;
  }

  .avatar {
    height: 2.5rem;
    width: 2.5rem;
    margin-right: 1rem;
    border: 1px solid white;
    border-radius: 50%;
    display: block;
    cursor: pointer;
  }

  @media screen and (max-width: 575px) {
    .user_details {
      z-index: 300;
    }
    
    .option {
      width: 15rem;

      li {
        img {
          height: 35px;
        }
      }
    }
  }

  @media screen and (max-width: 380px) {
    .user_details {
      z-index: 300;
    }
    
    .option {
      width: 13rem;
      padding: 1rem 1rem 0.1rem 1rem;
    }

    table tr {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 345px) {
    .user_details {
      z-index: 300;
    }
    
    .option {
      right: -1.5rem;
      width: 13rem;
      padding: 1rem 1rem 0.1rem 1rem;
    }

    table tr {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 296px) {
    .user_details {
      z-index: 300;
    }

    .option {
      left: 0rem;
      width: 13rem;
      padding: 1rem 1rem 0.1rem 1rem;

      li {
        img {
          height: 35px;
        }
      }
    }

    table tr {
      font-size: 10px;
    }
  }
`;

const StyledLogo = styled.div`
  position: absolute;
  left: 4rem;
  margin-left: 2rem;
  height: 2rem;
`;

const StyledLogoLink = styled(NavLink)`
  text-decoration: none;
`;

export const Header = ({ loginhandler }) => {
  const [user, setUser] = useState(false);
  const { isLogged } = useContext(LogInContext);
  const { token, role, loggedAs } = useContext(LogInContext);
  const {
    organizationName,
    setOrganizationName,
    setOrganizations,
    organizationId,
    orgOptions,
    setOrganizationId,
  } = useContext(OrganizationsContext);
  const { isHomePage } = useContext(MenuContext);
  let [org, setOrg] = useState([]);

  const isUserAdmin = () => {
    return role === "ADMIN";
  };

  const handleSelectOrganization = (e) => {
    setOrganizationId(e.id);
    setOrganizationName(e.value);
  };

  const showOrganizationsDropdown = () => {
    return isLogged && !isHomePage && isUserAdmin();
  };

  useEffect(() => {
    let arr = [{label: "All", value: "All", id: 0}];
    let mergeArr = [];
    mergeArr = arr.concat(orgOptions);

    setOrg(mergeArr);
  }, [orgOptions]);

  useEffect(() => {
    async function fetchOrganizations() {
      const responseData = await GetOrganizations(token);

      if (responseData.status === "success") {
        setOrganizations(responseData.data.organization);
      } else {
        setOrganizations([]);
      }
    }
    fetchOrganizations();
  }, [setOrganizations, token]);

  return (
    <HeaderWrapper className="main-res-header">
      <div className="left-side">
        <MenuIcon />
        <StyledLogo className="brand_gcc">
          {isUserAdmin() ? (
            <StyledLogoLink to="/">
              <img className="brand_gcc_image" style={{ height: "100%" }} src={logo} alt="GCC" />
            </StyledLogoLink>
          ) : (
            <img className="brand_gcc_image" style={{ height: "100%" }} src={logo} alt="GCC" />
          )}
        </StyledLogo>
        
      </div>
      <div className="select-org">
          {showOrganizationsDropdown() && (
            <Select
              placeholder="Select Organization"
              defaultValue={
                organizationId ? {
                  value: organizationName,
                  label: organizationName,
                } : {
                  value: "All",
                  label: "All"
                }
              }
              options={org}
              onChange={handleSelectOrganization}
            />
          )}
        </div>
      <div className="user">
        <div className="user_details">
          <img
            src={avatar}
            id="demo-simple-select-label"
            onMouseEnter={() => setUser(!user)}
            onMouseLeave={() => setUser(!user)}
            className="avatar"
          />
          {user && (
            <ul id="example-collapse-text" className="option">
              <li>
                <img
                  src={avatar}
                  alt=""
                />
              </li>
              <hr
                style={{
                  color: "#EEEEEE",
                  marginBottom: "0.5rem",
                  marginTop: "0.5rem",
                }}
              />
              <table style={{ width: "100%" }}>
                <tr>
                  <th className="nav_title">
                    Name
                  </th>
                  <td className="nav_text">
                    {!loggedAs.isLoggedAs
                      ? (localStorage.getItem("name") === '' || localStorage.getItem("name") === "null" ? '' : localStorage.getItem("name")) 
                      : loggedAs?.name}
                  </td>
                </tr>
                <tr>
                  <th className="nav_title">
                    Email
                  </th>
                  <td className="nav_text">
                    {" "}
                    {!loggedAs.isLoggedAs
                      ? (localStorage.getItem("email") === '' || localStorage.getItem("email") === "null" ? '' : localStorage.getItem("email"))
                      : loggedAs?.email}
                  </td>
                </tr>
                <tr>
                  <th className="nav_title">
                    Role
                  </th>
                  <td className="nav_text">{localStorage.getItem("role")}</td>
                </tr>
                <tr>
                  <th className="nav_title last_nav_text">
                    Organization
                  </th>
                  <td className="nav_text last_nav_text">
                    {!loggedAs.isLoggedAs
                      ? (localStorage.getItem("org") === '' || localStorage.getItem("org") === "null" ? '' : localStorage.getItem("org"))
                      : loggedAs?.org}
                  </td>
                </tr>
              </table>
            </ul>
          )}
        </div>
        {isLogged && <LogoutButton loginhandler={loginhandler} />}
      </div>
    </HeaderWrapper>
  );
};