import { useContext, useState, useEffect } from "react";
import { LogInContext } from "../../../contexts/logInContext";
import { ModalContext } from "../../../contexts/modalContext";
import { ModalWrapper, MoveStudentModalWrapper } from "../Modals.styles";
import { ModalCreateButtons } from "../ModalButtons";
import Select from "react-select";
import { StudentsContext } from "../../../contexts/studentsContext";
import { GetAllSchoolsList, GetGroups, MoveStudent } from "../../../api";
import { OrganizationsContext } from "../../../contexts/organizationsContext";
import { SchoolsContext } from "../../../contexts/schoolsContext";
import { GroupsContext } from "../../../contexts/groupsContext";

const MoveStudentsModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal } = useContext(ModalContext);
  const { stud, setStud, setModal } = useContext(StudentsContext);
  const { setSelectedArchives, groupId } = useContext(GroupsContext);
  const { schoolId } = useContext(SchoolsContext);
  const { token } = useContext(LogInContext);
  const { organizationId } = useContext(OrganizationsContext);
  let [active, setActive] = useState(true);
  const [reason, setReason] = useState("");
  const [reasonId, setReasonId] = useState(0);
  let [group, setGroup] = useState([]);
  let [school, setSchool] = useState([]);
  const [grpId, setGrpId] = useState(0);
  const [schId, setSchId] = useState(0);
  const option = [
    { value: "Move to another school", label: "Move to another school", id: 1 },
    { value: "Move to another group", label: "Move to another group", id: 2 },
    { value: "Graduated", label: "Graduated", id: 3 },
    {
      value: "No longer participating",
      label: "No longer participating",
      id: 4,
    },
  ];

  const handleMoveStudent = async () => {
    const res = await MoveStudent(grpId, active, reason, reasonId, stud, token);

    if (res.status === "success") {
      setGrpId(0);
      setReason("");
      setSchId(0);
      setSelectedArchives([]);
      setShowModal(false);
      setModal(true);
      setStud([]);
    }
  };

  useEffect(() => {
    async function fetchGroups() {
      const responseData = await GetGroups(schId, organizationId, token);
      let studentPageGroups = [];

      if (responseData.status === "success") {
        studentPageGroups = responseData.data.groups.map((item) => ({
          value: item.name,
          label: item.name,
          id: item.id,
        }));

        let arr = studentPageGroups.filter((item) => item.id.toString() !== groupId) 

        group = arr;

        setGroup(group);
      } else {
        setGroup([]);
      }
    }
    if (schId !== 0) {
      fetchGroups();
    } else {
      setGroup([]);
    }
  }, [schId, organizationId, token]);

  useEffect(() => {
    async function fetchSchools() {
      const responseData = await GetAllSchoolsList(token, organizationId);
      let studentPageSchools = [];

      if (responseData.status === "success") {
        studentPageSchools = responseData.data.schools.map((item) => ({
          value: !!!item?.city || !!!item?.region || !!!item?.country ? item?.name : item?.name + ' (' + item?.city + ", " + item?.region + ", " + item?.country + ')',
          label: !!!item?.city || !!!item?.region || !!!item?.country ? item?.name : item?.name + ' (' + item?.city + ", " + item?.region + ", " + item?.country + ')',
          id: item.id,
        }));

        school = studentPageSchools;
        setSchool(school);
      } else {
        setSchool([]);
      }
    }
    fetchSchools();
  }, [token, organizationId]);

  const handleSelectGroup = async (e) => {
    setGrpId(e?.id);
  };

  const handleSelectSchool = async (e) => {
    setSchId(e?.id);
  };

  const handleSelectReason = async (e) => {
    setReason(e?.label);
    setReasonId(e?.id);
    if (e?.id === 2) {
      setSchId(schoolId);
    }
    if (e?.id === 3 || e?.id === 4) {
      setActive(false);
    } else {
      setActive(true);
    }
  };

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <MoveStudentModalWrapper>
            <div className="select--input">
              <h4 className="title">Reason</h4>
              <Select
                placeholder="Select Reason"
                value={option.find((item) => item.label === reason)}
                options={option}
                onChange={(e) => handleSelectReason(e)}
                className="input--reason"
              />
            </div>
            {reasonId === 1 && (
              <div className="select--input">
                <h4 className="title">School</h4>
                <Select
                  placeholder="Select School"
                  value={school.find((item) => item.id === schId)}
                  options={school}
                  onChange={(e) => handleSelectSchool(e)}
                  className="input--reason"
                />
              </div>
            )}
            {(reasonId === 1 || reasonId === 2) && (
              <div className="select--input">
                <h4 className="title">Group</h4>
                <Select
                  placeholder="Select Group"
                  value={group.find((item) => item.id === grpId)}
                  options={group}
                  onChange={(e) => handleSelectGroup(e)}
                  className="input--reason"
                />
              </div>
            )}
            <div className="select--input">
              <h4 className="title">Status</h4>
              <div className="status">
                <input
                  type="checkbox"
                  checked={active}
                  value={active}
                  onChange={(e) => setActive(e.target.checked)}
                  disabled={reasonId === 3 || reasonId === 4 ? true : false}
                />
                <label className="form-check-label" for="flexCheckDefault">
                  {active ? "Active" : "Inactive"}
                </label>
              </div>
            </div>
            <ModalCreateButtons
              text="Save Changes"
              onClick={handleMoveStudent}
              className="modal__buttons--smaller-modal"
              disabled={
                reasonId === 1 && schId !== 0 && grpId === 0
                  ? true
                  : reasonId === 2 && grpId === 0
                  ? true
                  : false
              }
            />
          </MoveStudentModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default MoveStudentsModal;
