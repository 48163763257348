import React, { useContext } from 'react';
import { ModalContext } from '../../../contexts/modalContext';
import { LogInContext } from '../../../contexts/logInContext';
import { ModalWrapper, RemoveModalWrapper } from '../Modals.styles';
import { ModalCreateButtons } from '../ModalButtons';
import { ApproveStudent } from '../../../api';
import { StudentsContext } from '../../../contexts/studentsContext';

const ApproveStudentModal = ({ modalRef, closeModal }) => {
  const { studentId } = useContext(StudentsContext);
  const { showModal, setShowModal } = useContext(ModalContext);
  const { token } = useContext(LogInContext);

  const handleApproveStudent = async () => {
    const res = await ApproveStudent(studentId, token);

    if (res.status === 'success') {
        setShowModal(false);
    }
  };

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <RemoveModalWrapper>
            <h3>Are you sure you want to change Student ID? </h3>
            <ModalCreateButtons
              text="Save Changes"
              onClick={handleApproveStudent}
            />
          </RemoveModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default ApproveStudentModal;
