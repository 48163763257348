import React, { useContext } from 'react';
import logout from '../../assets/icons/logout-icon.svg';
import { MenuContext } from '../../contexts/menuContext';
import { LogInContext } from '../../contexts/logInContext';
import { OrganizationsContext } from '../../contexts/organizationsContext';
import { ButtonWrapper } from './Buttons.styles';
import { useHistory } from 'react-router-dom';

const LogoutButton = ({ loggedIn, loginhandler }) => {
  const { setMenuDisplay } = useContext(MenuContext);
  const { setIsLogged, setToken, setRole, loggedAs } = useContext(LogInContext);
  const { setOrganizationId, setCurrentUserPage } =
    useContext(OrganizationsContext);
  const history = useHistory();

  const handleClickLogOut = () => {
    localStorage.removeItem('currentGroupPage');
    if (loggedAs.isLoggedAs) {
      const rootUser = JSON.parse(localStorage.getItem('rootUser'));
      setToken(rootUser.token);
      setRole(rootUser.rootUserRole);
      setOrganizationId(rootUser.organizationId);
      setCurrentUserPage(1);
      localStorage.removeItem('rootUser');
      history.push('/users');
    } else {
      localStorage.clear();
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      loginhandler();
      setToken(null);
      setRole(null);
      setOrganizationId(null);
      setIsLogged(false);
      setMenuDisplay(false);
    }
  };

  return (
    <ButtonWrapper
      loggedIn={loggedIn}
      onClick={handleClickLogOut}
      icon={logout}
      color="orange"
    >
      <div></div>
      {loggedAs.isLoggedAs ? `Logout from "${loggedAs.name}"` : 'Logout'}
    </ButtonWrapper>
  );
};

export default LogoutButton;
