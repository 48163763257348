import React, { useEffect, useContext, useState } from "react";
import { ModalContext } from "../../../contexts/modalContext";
import { ModalWrapper, ViewSchoolWrapper } from "../Modals.styles";
import { SchoolsContext } from "../../../contexts/schoolsContext";
import { ActionButtonOrangeSecondary } from "../../Buttons/ActionButton";
import { ModalCreateButtons } from "../ModalButtons";
import { GetSchool, GetSchools, MergeSchool } from "../../../api";
import { LogInContext } from "../../../contexts/logInContext";
import CustomSelect from "../../InputFields/CustomSelect";
import Select from 'react-select';
import { toProperCase } from "../../../utils/validation";
import { success, warning } from "../../../utils/toastify";
import { BsDash } from "react-icons/bs";
import MergeSchoolConfirmModal from "./MergeSchoolConfirmModal";

const MergeSchoolModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal } = useContext(ModalContext);
  const { school, countryOptions } = useContext(SchoolsContext);
  const { token } = useContext(LogInContext);
  let [schoolsList, setSchoolsList] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState([]);
  let [schoolId, setSchoolId] = useState(0);
  let [getSchool, setGetSchool] = useState({});
  let [editSchool, setEditSchool] = useState({});
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    async function fetchSchools() {
      const responseData = await GetSchools(token);

      if (responseData?.status === "success") {
        setSchoolsList(
          responseData?.data?.schools.filter(
            (item) => item?.id !== school?.school?.id
          )
        );
      } else {
        setSchoolsList([]);
      }
    }
    fetchSchools();
  }, [token]);

  useEffect(() => {
    schoolsList = schoolsList.map((school) => ({
      value:
        !!!school?.city || !!!school?.region || !!!school?.country?.name
          ? school?.name
          : school?.name +
            " (" +
            school?.city +
            ", " +
            school?.region +
            ", " +
            school?.country?.name +
            ")",
      label:
        !!!school?.city || !!!school?.region || !!!school?.country?.name
          ? school?.name
          : school?.name +
            " (" +
            school?.city +
            ", " +
            school?.region +
            ", " +
            school?.country?.name +
            ")",
      id: school.id,
    }));

    setSchoolOptions(schoolsList);
  }, [schoolsList]);

  useEffect(() => {
    async function fetchSchool() {
      const responseData = await GetSchool(token, schoolId);

      if (responseData.status === "success") {
        getSchool = responseData.data.school;
        setGetSchool(getSchool);
        setEditSchool(getSchool)
      }
    }

    if (schoolId !== 0) {
      fetchSchool();
    }
  }, [token, schoolId]);

  const handleSelectSchool = (e) => {
    setSchoolId(e.id);
  };

  
  const handleMergeSchool = async () => {
    delete editSchool.createdAt;
    delete editSchool.updatedAt;
    
    let payload = {
      schoolId: school?.school?.id,
      newSchoolId: schoolId,
      school: editSchool

    };
    const responseData = await MergeSchool(payload, token);
    if (responseData.status === "success") {
      setShowModal(false);
      success("Merge School successfully")
      handleClose();
    } else {
      warning("Merge School operation is failed")
      handleClose();
    }
  };

  const handleSelectCountry = (e) => {
    setEditSchool({...editSchool, countryId: e?.id});
  };

  const handleChange = (e) => {
    setEditSchool({...editSchool, [e.target.name]: toProperCase(e.target.value)});
  };

  return (
    <>
      <MergeSchoolConfirmModal
        open={open}
        handleClose={handleClose}
        handleMergeSchool={handleMergeSchool}
        firstSchool={school?.school?.name}
        secondSchool={editSchool?.name}
      />
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <ViewSchoolWrapper style={{ padding: "0em 5em" }}>
            <h1 style={{ marginBottom: "1rem", marginTop: "2rem" }}>
              Merge School
            </h1>
            <div className="view--school">
              <div className="user">
                <ActionButtonOrangeSecondary
                  text={"School Detail"}
                  style={{
                    cursor: "default",
                    margin: 0,
                    padding: 0,
                    marginBottom: "1em",
                    marginTop: "1em",
                  }}
                />
                <div className="main_detail">
                  <div className="nav_title">School Name</div>
                  <div className="nav_text">
                    {!!school?.school?.name ? school?.school?.name : <BsDash />}
                  </div>
                </div>
                <div className="main_detail">
                  <div className="nav_title">Long.</div>
                  <div className="nav_text">
                    {!!school?.school?.longitude ? (
                      school?.school?.longitude
                    ) : (
                      <BsDash />
                    )}
                  </div>
                </div>

                <div className="main_detail">
                  <div className="nav_title">Lat.</div>
                  <div className="nav_text">
                    {!!school?.school?.latitude ? (
                      school?.school?.latitude
                    ) : (
                      <BsDash />
                    )}
                  </div>
                </div>
                <div className="main_detail">
                  <div className="nav_title">Street Number</div>
                  <div className="nav_text">
                    {!!school?.school?.streetNumber ? (
                      school?.school?.streetNumber
                    ) : (
                      <BsDash />
                    )}
                  </div>
                </div>
                <div className="main_detail">
                  <div className="nav_title">Street</div>
                  <div className="nav_text">
                    {!!school?.school?.street ? (
                      school?.school?.street
                    ) : (
                      <BsDash />
                    )}
                  </div>
                </div>
                <div className="main_detail">
                  <div className="nav_title">City/Town</div>
                  <div className="nav_text">
                    {!!school?.school?.city ? school?.school?.city : <BsDash />}
                  </div>
                </div>
                <div className="main_detail">
                  <div className="nav_title">Region/Province</div>
                  <div className="nav_text">
                    {!!school?.school?.region ? (
                      school?.school?.region
                    ) : (
                      <BsDash />
                    )}
                  </div>
                </div>
                <div className="main_detail last_nav_text">
                  <div className="nav_title">Country</div>
                  <div className="nav_text">
                    {!!school?.school?.country?.name ? (
                      school?.school?.country?.name
                    ) : (
                      <BsDash />
                    )}
                  </div>
                </div>
              </div>
              <div className="user">
                <CustomSelect
                  options={schoolOptions}
                  placeholder="Select School"
                  onChange={handleSelectSchool}
                  className="school_select"
                />
                {getSchool && schoolId !== 0 && (
                  <>
                      <div className="main_detail input_country">
                        <div className="nav_title">School Name</div>
                        <div className="nav_text_input">
                          <input
                            name="name"
                            type="text"
                            className="school_input"
                            value={editSchool?.name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="main_detail input_country">
                        <div className="nav_title">Long.</div>
                        <div className="nav_text_input">
                          <input
                            name="longitude"
                            type="text"
                            className="school_input"
                            value={editSchool?.longitude}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="main_detail input_country">
                        <div className="nav_title">Lat.</div>
                        <div className="nav_text_input">
                          <input
                            name="latitude"
                            type="text"
                            className="school_input"
                            value={editSchool?.latitude}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="main_detail input_country">
                        <div className="nav_title">Street Number</div>
                        <div className="nav_text_input">
                          <input
                            name="streetNumber"
                            type="number"
                            className="school_input input--street"
                            value={editSchool?.streetNumber}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="main_detail input_country">
                        <div className="nav_title">Street</div>
                        <div className="nav_text_input">
                          <input
                            name="street"
                            type="text"
                            className="school_input"
                            value={editSchool?.street}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="main_detail input_country">
                        <div className="nav_title">City/Town</div>
                        <div className="nav_text_input">
                          <input
                            name="city"
                            type="text"
                            className="school_input"
                            value={editSchool?.city}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="main_detail input_country">
                        <div className="nav_title">Region/Province</div>
                        <div className="nav_text_input">
                          <input
                            name="region"
                            type="text"
                            className="school_input"
                            value={editSchool?.region}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="main_detail input_country country last_nav_text">
                        <div className="nav_title">Country</div>
                        <div className="nav_text_input country_input">
                          <Select
                            options={countryOptions}
                            placeholder="Select country"
                            onChange={(e) => handleSelectCountry(e)}
                            value={countryOptions.find(
                              (elem) =>
                                elem?.id === editSchool?.countryId
                            )}
                            className="select_country"
                          />
                        </div>
                      </div>
                  </>
                )}
              </div>
            </div>
            <ModalCreateButtons
              className="modify-btn"
              text="Save and merge school"
              disabled={
                schoolId === 0 ? true : 
                ((!!editSchool.name) && (!!editSchool.longitude) && (!!editSchool.latitude) && (!!editSchool.city) && (!!editSchool.region) && (!!editSchool.countryId)) ? false : true
              }
              onClick={() => {
                  handleOpen();
              }}
            />
          </ViewSchoolWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default MergeSchoolModal;