import React, { useContext } from 'react'
import styled from 'styled-components'
import { MenuContext } from '../../contexts/menuContext'

export const MenuButtonWrapper = styled.div`
    margin: .5rem auto;
    display: flex;
    align-items: center;
    text-align: left;
    cursor: pointer;
    padding: 1em 1em;
    width: 100%;
    transition: all .8s;
    justify-content:  ${({display}) => display === "false" && "center"};
    align-items: ${({display}) => display === "false" && "center"} ;

    
    h4{
        margin-left: 1rem;
        font-size: 1rem;
        display: ${({display}) => display === "true" ? "inline" : "none"};

        @media screen and (max-width: 1200px) {
        display: none;
    }
    }

    &:hover, &:focus-visible{
        background: var(--blue-150);
        outline: none;
    }
`

const MenuButton = ({ icon, text, style }) => {
    const context = useContext(MenuContext)
    
    return (
        <MenuButtonWrapper style={style} display={context.menuDisplay ? "true" : "false"}>
            <img src={icon} style={{height:"1.5rem", width:"1.5rem"}} alt=''/>
            <h4>{text}</h4>
        </MenuButtonWrapper>
    )
}

export default MenuButton;
