import React, { useContext } from "react";
import { ModalContext } from "../../../contexts/modalContext";
import { ModalWrapper, ViewSchoolWrapper } from "../Modals.styles";
import {
  ActionButtonGreen,
  ActionButtonOrange,
  ActionButtonOrangeSecondary,
} from "../../Buttons/ActionButton";
import { useState } from "react";
import {
  CreateUserSchool,
  GetAssignUser,
  GetFacilitatorUsers,
  RemoveSchool,
} from "../../../api";
import { jumpToPage } from "../../../utils/constants";
import { LogInContext } from "../../../contexts/logInContext";
import { useEffect } from "react";
import { Pagination } from "../../Buttons/PaginationButtons";
import { Switch, TableContainer, Toolbar } from "@mui/material";
import { TableWrapper } from "../../Tables/Table.styles";
import ClipSpinner from "../../Loaders/ClipSpinner";
import { OrganizationsContext } from "../../../contexts/organizationsContext";
import { SchoolsContext } from "../../../contexts/schoolsContext";
import { success } from "../../../utils/toastify";
import { StyledFilterButtons } from "../../../Pages/Page.styles";
import { CustomSearch } from "../../InputFields/CustomInput";
import NoDataDisplay from "../../Displays/NoDataDisplay";

const AssignSchoolToUserModal = ({ modalRef, closeModal }) => {
  const { token } = useContext(LogInContext);
  const { schoolId } = useContext(SchoolsContext);
  const { organizationId } = useContext(OrganizationsContext);
  const { showModal, setShowModal } = useContext(ModalContext);
  const [displayUsers, setDisplayUsers] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 5;
  const offset = (currentPage - 1) * ITEMS_PER_PAGE;
  const [userPageCount, setUserPageCount] = useState(1);
  const [total, setTotal] = useState(0);
  let [displaySearchValue, setDisplaySearchValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const searchSetters = {
    displaySetter: setDisplaySearchValue,
    valueSetter: setSearchValue,
  };
  let [userIds, setUserIds] = useState([]);
  let [status, setStatus] = useState(true);
  let [loading, setLoading] = useState(true);

  const canNextPage = (displayItemsLength, itemsLength, currentPage) => {
    return (
      displayItemsLength !== ITEMS_PER_PAGE ||
      itemsLength / ITEMS_PER_PAGE === currentPage
    );
  };

  const columns = [
    {
      Header: "First Name",
    },
    {
      Header: "Last Name",
    },
    {
      Header: "Email",
    },
    {
      Header: "",
    },
  ];

  const fetchAssignUser = async () => {
    const responseData = await GetAssignUser(token, schoolId, (!!organizationId || organizationId !== 0) ? organizationId : "");

    if (responseData.status === "success") {
      setUserIds(responseData.data.users.map((item) => item.uid));
    }
  };

  useEffect(() => {
    fetchAssignUser();
  }, [token]);

  async function fetchUsers() {
    const responseData = await GetFacilitatorUsers(
      organizationId,
      token,
      ITEMS_PER_PAGE,
      offset,
      schoolId,
      displaySearchValue,
      status
    );

    if (responseData.status === "success") {
      setUsersData(responseData?.data?.data?.users);
      setLoading(false);
      
      if (
        responseData.data.data.totalRecords ||
        responseData.data.data.totalRecords === 0
      ) {
        setTotal(responseData.data.data.totalRecords);
        setUserPageCount(
          Math.ceil(responseData.data.data.totalRecords / ITEMS_PER_PAGE)
        );
      }
      if (responseData.data.data.users.length === 0) {
        setCurrentPage(1);
      }
      setDisplayUsers(responseData?.data?.data?.users);
    } else {
      setLoading(false);
      setUsersData([]);
    }
  }

  useEffect(() => {
    fetchUsers();
  }, [token, setUsersData, setDisplayUsers, setUserPageCount, currentPage]);

  const handleClick = (step) => {
    setCurrentPage((prevState) => prevState + step);
  };

  const handleAssignSchoolModal = async (userId) => {
    const responseData = await CreateUserSchool(userId, schoolId, token);
    if (responseData.status === "success") {
      fetchUsers();
      fetchAssignUser();
      success("School assign to facilitator successfully.");
    }
  };

  const handleRemoveSchool = async (id) => {
    let payload = {
      schoolIds: [{id: schoolId, toDelete: true}],
      userId: id,
    };

    const res = await RemoveSchool(payload, token);

    if (res.status === "success") {
      fetchUsers();
      fetchAssignUser();
      success("School Unassign to facilitator successfully.");
    }
  };

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <ViewSchoolWrapper style={{ height: "auto", padding: "2em" }}>
            <h1 style={{ marginBottom: "2rem" }}>
              Assign School To Facilitator
            </h1>
            <Toolbar className="custom-toolbar">
              <div
                className="custom-search"
                style={{ maxWidth: "none", flex: "auto" }}
              >
                <CustomSearch
                  value={displaySearchValue}
                  setSearchStates={searchSetters}
                  placeholder={"Search by name"}
                  onChange={(e) => setDisplaySearchValue(e.target.value)}
                />
              </div>
              <div className="reset-filter">
                <StyledFilterButtons>
                  <div
                    className="metrics__button-filters"
                    style={{ width: "max-content" }}
                  >
                    <ActionButtonOrange
                      theme="primary"
                      text="Filter"
                      onClick={fetchUsers}
                    />
                    <ActionButtonOrangeSecondary
                      text="Reset Filters"
                      disabled={!!displaySearchValue ? false : true}
                      onClick={() => {
                        displaySearchValue = "";
                        setDisplaySearchValue(displaySearchValue);
                        fetchUsers();
                      }}
                    />
                  </div>
                </StyledFilterButtons>
              </div>
              <div className="filter-btn" style={{display: "flex", alignItems: "center", gap: "5px"}}>
                <h3 style={{color: "#ff9142", fontSize: "0.9rem", fontWeight: "600", whiteSpace: "nowrap", paddingLeft: "3rem"}}>{status ? "Show All Facilitators" : "Hide No Assign Facilitators"}</h3>

                <Switch
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "#0B0A42",
                    },

                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                      {
                        backgroundColor: "#a7d1fb",
                      },
                  }}
                  size="small"
                  checked={
                    status && "checked"
                  }
                  onClick={() => {
                    status = !status;
                    setStatus(status);
                    fetchUsers();
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            </Toolbar>
            {loading && (
              <TableContainer className="custom_table_container">
                <TableWrapper>
                  <div className="loading-table">
                    <ClipSpinner />
                  </div>
                </TableWrapper>
              </TableContainer>
            )}
            {!loading && usersData.length !== 0 && (
              <TableContainer>
                <TableWrapper>
                    <>
                      <table style={{ textAlign: "start" }}>
                        <thead>
                          <tr style={{ textAlign: "justify" }}>
                            {columns.map((item, index) => (
                              <th key={index}>{item?.Header}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {usersData.map((item, index) => (
                            <tr key={index}>
                              <td>{item?.name}</td>
                              <td>{item?.lastName}</td>
                              <td>{item?.email}</td>
                              <td>
                                {userIds.includes(item?.id) && (
                                  <ActionButtonOrange
                                    style={{ width: "auto" }}
                                    theme={"primary"}
                                    text="Unassign"
                                    size="small"
                                    onClick={() => {
                                      handleRemoveSchool(item?.id);
                                    }}
                                  />
                                )}
                                {!userIds.includes(item?.id) && (
                                  <ActionButtonGreen
                                    style={{
                                      width: "auto",
                                      padding: "0.1em 1.5em"
                                    }}
                                    text="Assign"
                                    onClick={() => {
                                      handleAssignSchoolModal(item?.id);
                                    }}
                                  />
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {Math.ceil(total / ITEMS_PER_PAGE) > 0 && (
                        <Pagination
                          currentPage={currentPage}
                          disabledPrev={currentPage === 1}
                          disabledNext={canNextPage(
                            displayUsers.length,
                            total,
                            currentPage
                          )}
                          onClickPrev={() => handleClick(-1)}
                          onClickNext={() => handleClick(+1)}
                          onClickFirstPage={() => jumpToPage(setCurrentPage)}
                          onClickLastPage={() =>
                            jumpToPage(setCurrentPage, userPageCount)
                          }
                          total={Math.ceil(total / ITEMS_PER_PAGE)}
                        />
                      )}
                    </>
                </TableWrapper>
              </TableContainer>
            )} 
            {!loading && usersData.length === 0 &&(
              <NoDataDisplay style={{marginTop: "1rem"}} message={"No facilitators yet"} />
            )}
            <div style={{ marginTop: "10px", display: "flex" }}>
              <ActionButtonOrangeSecondary
                text="Close"
                onClick={() => {
                  setShowModal(false);
                }}
              />
            </div>
          </ViewSchoolWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default AssignSchoolToUserModal;
