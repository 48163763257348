import React, { useState, useContext } from 'react';
import { LessonsContext } from '../../../contexts/lessonsContext';
import { ModalContext } from '../../../contexts/modalContext';
import { errors } from '../../../utils/validation';
import { UpdateStudentMaterial } from '../../../api';
import { OrganizationsContext } from '../../../contexts/organizationsContext';
import { LogInContext } from '../../../contexts/logInContext';
import { AddLessonModalWrapper, ModalWrapper } from '../Modals.styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import {
  minDate,
  maxDate,
  handleDateInput,
  editElementByLinkId,
  formatDate,
} from '../../../utils/constants';
import { ModalCreateButtons } from '../ModalButtons';

const EditStudentMaterialModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal } = useContext(ModalContext);
  const { organizationId } = useContext(OrganizationsContext);
  const { selectedMaterialId, studentMaterials, setStudentMaterials } =
    useContext(LessonsContext);
  const { token } = useContext(LogInContext);
  const [editedMaterial, setEditedMaterial] = useState({
    date: '',
  });
  const [error, setError] = useState({});
  const materialDetails = studentMaterials.find(
    (elem) => elem.linkId === selectedMaterialId
  ).date;
  const [displayDate, setDisplayDate] = useState(
    new Date(formatDate(materialDetails).newDate)
  );

  const handleSubmitEditMaterial = async () => {
    const responseData = await UpdateStudentMaterial(
      organizationId,
      selectedMaterialId,
      editedMaterial.dob,
      token
    );

    if (responseData.status === 'success') {
      const editedMaterialCopy = { date: editedMaterial.dob };
      const keys = ['date'];
      editElementByLinkId(
        selectedMaterialId,
        studentMaterials,
        setStudentMaterials,
        keys,
        editedMaterialCopy
      );
      setShowModal(false);
    } else {
      setError({ apiFail: errors.apiFail });
    }
  };

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <AddLessonModalWrapper>
            <h2>Change Attended Date </h2>
            <div className="input--date">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  openTo="day"
                  views={['year', 'month', 'day']}
                  minDate={minDate}
                  maxDate={maxDate}
                  value={displayDate}
                  onChange={(e) =>
                    handleDateInput(
                      e,
                      setDisplayDate,
                      editedMaterial,
                      setEditedMaterial,
                      error,
                      setError,
                      true
                    )
                  }
                  renderinput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <ModalCreateButtons
              text="Save"
              onClick={handleSubmitEditMaterial}
              error={error.apiFail}
              className="modal__buttons--smaller-modal"
            />
          </AddLessonModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default EditStudentMaterialModal;
