export const defaultSchoolInfo = {
    name: '',
    long: '',
    lat: '',
    address: '',
    streetNumber: null,
    street: '',
    city: '',
    region: '',
    countryId: null,
    placeId: ''
  };
  
  //Google Maps settings
  export const containerStyle = {
    width: '550px',
    height: '450px',
  };
  