import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CustomMultiSelect from "../../InputFields/CustomMultiSelect";
import { ActionButtonOrangeSecondary } from "../../Buttons/ActionButton";
import { ActionButtonGreen } from "../../Buttons/ActionButton";
import { useEffect, useContext, useState } from "react";
import { CreateGroupsToUser, GetFacilitators } from "../../../api";
import { LogInContext } from "../../../contexts/logInContext";
import { warning, success } from "../../../utils/toastify";
import { OrganizationsContext } from "../../../contexts/organizationsContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "auto",
  bgcolor: "#f6f7f9",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

const FacilitatorModal = ({
  open,
  handleClose,
  schoolId,
  groupId,
  setGroupId,
  fetchGroups,
  userList
}) => {
  const { token } = useContext(LogInContext);
  let [usersList, setUsersList] = useState([]);
  let [userIds, setUserIds] = useState([]);
  const { organizationId } = useContext(OrganizationsContext);
  let [flag, setFlag] = useState(false);

  let users = new Set(
    userList.map((user) => user)
  );

  useEffect(() => {
    async function fetchUsers() {
      const responseData = await GetFacilitators(token, schoolId, groupId, organizationId);

      if (responseData.status === "success") {
        setUsersList(
          responseData?.data?.users.map((item) => ({
            label: item?.user?.name,
            value: item?.user?.name,
            id: item?.user?.id
          }))
          );
          setFlag(true);
      } else {
        setUsersList([]);
      }
    }

    if (!!groupId) {
      fetchUsers();
    }
  }, [token, groupId]);

  const handleSelectUser = async (e) => {
    const selectUsers = new Set(e.map((user) => user.id));

    [...users]
      .filter((user) => !selectUsers.has(user))
      .forEach((elem) => {
        users.add({ id: elem, toDelete: true });
        users.delete(elem);
      });
    [...selectUsers]
      .filter((user) => !users.has(user))
      .forEach((elem) => users.add({ id: elem, isNew: true }));

      setUserIds({ ...userIds, users: [...users] });
  };

  const handleAssignGroupSubmit = async () => {
    let info = {
      userIds: userIds.users,
      schoolId: schoolId,
      groupId: groupId,
    };
    const responseData = await CreateGroupsToUser(info, token);
    if (responseData.status === "success") {
      success("Group assign to facilitator successfully");
      setGroupId("");
      handleClose();
      fetchGroups();
    } else {
      warning("Group assign to facilitator operation failed");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {flag && <CustomMultiSelect
          title="Facilitator"
          defaultValue={usersList.filter((elem) =>
            users.has(elem.id)
          )}
          options={usersList}
          placeholder="Select Facilitator"
          onChange={handleSelectUser}
        />}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            gap: "2px",
            width: "100%",
            paddingTop: "2rem",
          }}
        >
          <ActionButtonGreen
            style={{
              width: "auto",
              padding: "0.1em 1.5em",
            }}
            text="Assign"
            onClick={handleAssignGroupSubmit}
            disabled={userIds.length === 0 ? true : false}
          />
          <ActionButtonOrangeSecondary
            style={{
              width: "auto",
            }}
            inline={true}
            text="Cancel"
            onClick={handleClose}
          />
        </div>
      </Box>
    </Modal>
  );
};

export default FacilitatorModal;
