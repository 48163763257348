import React, { useContext, useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  Autocomplete,
  useLoadScript,
  InfoWindow,
} from "@react-google-maps/api";
import { ModalContext } from "../../../contexts/modalContext";
import {
  isValidCoordinate,
  isValidName,
  errors,
  toProperCase,
  isValidCity,
  isValidRegion,
} from "../../../utils/validation";
import { EditSchool, GetSchoolOrganizations } from "../../../api";
import { OrganizationsContext } from "../../../contexts/organizationsContext";
import { LogInContext } from "../../../contexts/logInContext";
import {
  InputsWrapper,
  ModalWrapper,
  SchoolModalWrapper,
  SchoolSelect,
} from "../Modals.styles";
import { CustomInput } from "../../InputFields/CustomInput";
import { ModalCreateButtons } from "../ModalButtons";
import { inputSchoolFields } from "../../InputFields/InputFields";
import { containerStyle, defaultSchoolInfo } from "./constants";
import { editElement, handleOnSubmit } from "../../../utils/constants";
import { SchoolsContext } from "../../../contexts/schoolsContext";
import makerImg from "../../../assets/icons/maker.svg";
import { success, warning } from "../../../utils/toastify";
import CustomSelect from "../../InputFields/CustomSelect";
import CustomMultiSelect from "../../InputFields/CustomMultiSelect";
import { components } from "react-select";
import ViewSchoolModal from "./ViewSchoolModal";

const EditSchoolModal = ({ modalRef, closeModal }) => {
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;
  const { showModal, setShowModal } = useContext(ModalContext);
  const { organizationId, orgOptions, orgIds } = useContext(OrganizationsContext);
  const { token, role } = useContext(LogInContext);
  let {
    schools,
    setSchools,
    schoolToEdit,
    setSchoolToEdit,
    schoolId,
    countryOptions
  } = useContext(SchoolsContext);
  let editModeCenter = {
    lat: parseFloat(schoolToEdit.lat),
    lng: parseFloat(schoolToEdit.long),
  };
  let [option, setOption] = useState(null);
  const [flag, setFlag] = useState(false);
  const [info, setInfo] = useState({});

  const [error, setError] = useState({});
  const [org, setOrg] = useState([]);
  let [countryName, setCountryName] = useState(!!schoolToEdit?.countryId ? countryOptions?.filter((item) => {
    if (item?.id === schoolToEdit?.countryId) {
      return item?.id;
    }
  })[0]?.label : "");
  let [existError, setExistError] = useState("");
  let [existSchoolId, setExistSchoolId] = useState(0);
  let [existSchool, setExistSchool] = useState({});
  let [existOrg, setExistOrg] = useState(new Set());
  let [selectFlag, setSelectFlag] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapsApiKey,
    libraries: ["places"],
  });

  let assignOrg = new Set(
    orgIds?.map((org) => org)
  );

  const NoOptionsOrg = (props) => {
    return (
      <SchoolSelect>
        <components.NoOptionsMessage {...props}>
          <span>No organisation</span>
        </components.NoOptionsMessage>
      </SchoolSelect>
    );
  };

  useEffect(() => {
    async function fetchOrganizations() {
      const responseData = await GetSchoolOrganizations(token, existSchoolId);

      if (responseData.status === "success") {
        existOrg = new Set(responseData.data.organization.map((org) => org.id));
        setExistOrg(existOrg)
        setSelectFlag(true)
      } 
    }
    existSchoolId !== 0 && fetchOrganizations();
  }, [token, existSchoolId]);

  useEffect(() => {
    schools = schools.map((item) => ({
        ...item,
        flag: false
    }))

    setSchools(schools);
  }, []);

  const handleEditInput = (e) => {
    let errorCopy = { ...error };
    setSchoolToEdit({
      ...schoolToEdit,
      [e.target.name]: toProperCase(e.target.value),
    });

    if (e.target.name === "name") {
      const isValid = isValidName(e.target.value);
      isValid
        ? delete errorCopy.school
        : (errorCopy.school = errors.requiredSchoolNameError);
    } else if (e.target.name === "long" || e.target.name === "lat") {
      const isValid = isValidCoordinate(e.target.name, e.target.value);
      isValid
        ? delete errorCopy[e.target.name]
        : (errorCopy[e.target.name] = errors.coordinatesErrorMessage);
    } else if (e.target.name === "city") {
      const isValid = isValidCity(e.target.value);
      isValid
        ? delete errorCopy.city
        : (errorCopy.city = errors.requiredCityError);
    } else if (e.target.name === "region") {
      const isValid = isValidRegion(e.target.value);
      isValid
        ? delete errorCopy.region
        : (errorCopy.region = errors.requiredRegionError);
    } 

    if (e.target.name === "long") {
      editModeCenter = { ...editModeCenter, lng: parseFloat(e.target.value) };
    } else if (e.target.name === "lat") {
      editModeCenter = { ...editModeCenter, lat: parseFloat(e.target.value) };
    }

    setError(errorCopy);
  };

  const handleMarkerDrag = (e) => {
    let errorCopy = { ...error };
    setSchoolToEdit({
      ...schoolToEdit,
      lat: parseFloat(e.latLng.lat()).toFixed(6),
      long: parseFloat(e.latLng.lng()).toFixed(6),
    });

    delete errorCopy.lat;
    delete errorCopy.long;
    setError(errorCopy);
  };

  const onLoad = (autocomplete) => {
    setOption(autocomplete);
  };

  const onPlaceChanged = () => {
    if (option !== null) {
      let place = option.getPlace();
      schoolToEdit.name = place?.name;
      schoolToEdit.lat = parseFloat(place?.geometry?.location?.lat());
      schoolToEdit.long = parseFloat(place?.geometry?.location?.lng());
      editModeCenter = {
        lat: place?.geometry?.location?.lat(),
        lng: place?.geometry?.location?.lng(),
      };

      schoolToEdit.placeId = place?.place_id;
      place?.address_components?.forEach((el) => {
        if (el?.types.includes("country")) {
          if (!!el?.long_name) {
            setCountryName(el?.long_name);
            schoolToEdit.countryId = countryOptions?.filter((item) => {
              if (item?.label ===  el?.long_name) {
                return item?.id;
              }
            })[0]?.id;

          } else {
            setCountryName(countryName);
            schoolToEdit.countryId = schoolToEdit?.countryId
          }
        }
        if (el?.types.includes("locality")) {
          schoolToEdit.city = !!el?.long_name ? el?.long_name : schoolToEdit?.city;
        }
        if (el?.types.includes("route")) {
          schoolToEdit.street = !!el?.long_name ? el?.long_name : null;
        }
        if (el?.types.includes("administrative_area_level_1")) {
          schoolToEdit.region = !!el?.long_name ? el?.long_name : schoolToEdit?.region;
        }
        if (el?.types.includes("street_number")) {
          schoolToEdit.streetNumber = !!el?.long_name ? el?.long_name : null;
        }
      });

      let arr = [];
      if(!!schoolToEdit?.placeId || !!schoolToEdit?.lat || !!schoolToEdit?.long) {
         for (let i = 0; i < schools.length; i++) {
          const el = schools[i];
          if ((el?.placeId == schoolToEdit?.placeId && el?.id != schoolId)) {
            arr.push(el);
          }
         }
      }

      if (arr.length > 0) {
        existError = "The School is already exits";
        setExistError(existError);
        existSchoolId = arr[0].id;
        setExistSchoolId(existSchoolId);
        existSchool = arr[0];
        setExistSchool(existSchool);
      } else {
        selectFlag = false;
        setSelectFlag(selectFlag);
        existError = "";
        setExistError(existError);
        existSchoolId = 0;
        setExistSchoolId(existSchoolId);
        existSchool = {};
        setExistSchool(existSchool);
        existOrg = new Set();
        setExistOrg(existOrg);
        selectFlag = true;
        setSelectFlag(selectFlag);
      }

      setInfo(place);
      setSchoolToEdit(schoolToEdit);
    }
  };

  const handleSubmitValidations = () => {
    let errorCopy = { ...error };
    delete errorCopy.apiFail;

    !isValidName(schoolToEdit.name) &&
      (errorCopy.school = errors.requiredSchoolNameError);
    !schoolToEdit.lat && (errorCopy.lat = errors.requiredLatError);
    !schoolToEdit.long && (errorCopy.long = errors.requiredLongError);
    !schoolToEdit.city && (errorCopy.city = errors.requiredCityError);
    !schoolToEdit.region && (errorCopy.region = errors.requiredRegionError);
    !schoolToEdit.countryId && (errorCopy.country = errors.requiredCountryError);

    if (Object.keys(errorCopy).length > 0) {
      warning("Required field/s must be filled");
    }

    setError(errorCopy);
    return Object.keys(errorCopy).length === 0;
  };

  const handleEditSchoolSubmit = async () => {
    const isValid = handleSubmitValidations();

    if (!isValid) {
      return false;
    }

    const submitSchoolEdit = {
      name: schoolToEdit.name,
      long: schoolToEdit.long,
      lat: schoolToEdit.lat,
      address: schoolToEdit.address,
      streetNumber: schoolToEdit.streetNumber,
      street: schoolToEdit.street,
      city: schoolToEdit.city,
      region: schoolToEdit.region,
      countryId: schoolToEdit.countryId,
      placeId: schoolToEdit.placeId,
      orgIds: org?.org
    };

    const responseData = await EditSchool(
      organizationId,
      schoolId,
      submitSchoolEdit,
      token
    );

    if (responseData.status === "success") {
      if (role !== "ADMIN") {
        success(
          "Your request to modify the School Detail has been sent to the Admin."
        );
      }

      const editedSchool = {
        latitude: schoolToEdit.lat,
        longitude: schoolToEdit.long,
        name: schoolToEdit.name,
      };
      const keys = ["name", "latitude", "longitude"];
      editElement(schoolId, schools, setSchools, keys, editedSchool);
      setShowModal(false);
      setSchoolToEdit(defaultSchoolInfo);
    } else if (responseData.status === "failed" && responseData.code === 112) {
      setError({
        apiFail: responseData.data,
      });
    } else {
      setError({
        apiFail: errors.apiFail,
      });
    }
  };

  const handleMouseOver = () => {
    setFlag(true);
  };

  const handleMouseExit = () => {
    setFlag(false);
  };

  const handleView = () => {
    handleOpen();
  };

  const mouseOver = (item) => {
    const arr = schools.map(obj => {
      if (obj.id === item.id) {
        return {...obj, flag: true};
      }

      return obj;
    });

    setSchools(arr);
  };

  const mouseExit = (item) => {
    const arr = schools.map(obj => {
      if (obj.id === item.id) {
        return {...obj, flag: false};
      }

      return obj;
    });

    setSchools(arr);
  };

  const handleSelectCountry = (e) => {
    setCountryName(e?.label);
    setSchoolToEdit({ ...schoolToEdit, countryId: e.id });
    delete error.country;
};

const handleSelectAssignOrg = async (e) => {
  const selectOrgs = new Set(e.map((org) => org.id));

  [...assignOrg]
    .filter((org) => !selectOrgs.has(org))
    .forEach((elem) => {
      assignOrg.add({ id: elem, toDelete: true });
      assignOrg.delete(elem);
    });

  [...selectOrgs]
    .filter((org) => !assignOrg.has(org))
    .forEach((elem) => assignOrg.add({ id: elem, isNew: true }));
    setOrg({ ...org, org: [...assignOrg] });
};

  inputSchoolFields.school.value = schoolToEdit?.name;
  inputSchoolFields.long.value = schoolToEdit?.long;
  inputSchoolFields.lat.value = schoolToEdit?.lat;
  inputSchoolFields.streetNumber.value = schoolToEdit?.streetNumber;
  inputSchoolFields.street.value = schoolToEdit?.street;
  inputSchoolFields.city.value = schoolToEdit?.city;
  inputSchoolFields.region.value = schoolToEdit?.region;
  inputSchoolFields.country.value = schoolToEdit?.countryId;

  return (
    <>
      <ViewSchoolModal
        open={open}
        handleClose={handleClose}
        schoolInfo={existSchool}
      />
      {showModal && isLoaded && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <SchoolModalWrapper>
            <InputsWrapper>
              <div
                style={{
                  display: "flex",
                  gap: "30px",
                  marginLeft: "0",
                }}
              >
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={editModeCenter}
                  zoom={14}
                >
                  {schools.map((item) => (
                    <Marker
                      position={{
                        lat: parseFloat(item?.latitude),
                        lng: parseFloat(item?.longitude),
                      }}
                      draggable={false}
                      onDragEnd={handleMarkerDrag}
                      icon={{
                        url: "http://maps.google.com/mapfiles/kml/paddle/grn-circle.png",
                        scaledSize: new window.google.maps.Size(50, 40),
                      }}
                      onMouseOver={() => mouseOver(item)}
                      onMouseOut={() => mouseExit(item)}
                    >
                          {item?.flag && (
                      <InfoWindow position={{
                        lat: parseFloat(item?.latitude),
                        lng: parseFloat(item?.longitude),
                      }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                          }}
                        >
                          <h4 style={{ fontSize: "1rem", fontWeight: "bold" }}>
                            {item?.name}
                          </h4>
                          <p
                            style={{
                              fontSize: "0.80rem",
                              fontWeight: "400",
                              color: "#000",
                              display: "flex",
                              alignItems: "center",
                              gap: "2px",
                            }}
                          >
                            <img
                              src={makerImg}
                              alt="maker"
                              width={"20px"}
                              height={"20px"}
                            />
                            {!!item?.streetNumber && (item?.streetNumber + ", ")}
                            {!!item?.street && (item?.street + ", ")}
                            {!!item?.city && (item?.city + ", ")}
                            {!!item?.region && (item?.region + ", ")}
                            {!!item?.country?.name && item?.country?.name}
                          </p>
                        </div>
                      </InfoWindow>
                    )}
                    </Marker>
                  ))}
                  <Marker
                    position={editModeCenter}
                    draggable={true}
                    onDragEnd={handleMarkerDrag}
                    icon={{
                      url: "http://maps.google.com/mapfiles/kml/paddle/red-circle.png",
                      scaledSize: new window.google.maps.Size(50, 40),
                    }}
                    onMouseOver={() => handleMouseOver()}
                    onMouseOut={() => handleMouseExit()}
                  >
                     {flag && (
                      <InfoWindow position={editModeCenter}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                          }}
                        >
                          <h4 style={{ fontSize: "1rem", fontWeight: "bold" }}>
                            {inputSchoolFields?.school?.value}
                          </h4>
                          <p
                            style={{
                              fontSize: "0.80rem",
                              fontWeight: "400",
                              color: "#000",
                              display: "flex",
                              alignItems: "center",
                              gap: "2px",
                            }}
                          >
                            <img
                              src={makerImg}
                              alt="maker"
                              width={"20px"}
                              height={"20px"}
                            />
                            {!!inputSchoolFields.streetNumber.value && (inputSchoolFields.streetNumber.value + ", ")}
                            {!!inputSchoolFields.street.value && (inputSchoolFields.street.value + ", ")}
                            {!!inputSchoolFields.city.value && (inputSchoolFields.city.value + ", ")}
                            {!!inputSchoolFields.region.value && (inputSchoolFields.region.value + ", ")}
                            {!!countryName &&
                              countryName}
                          </p>
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                </GoogleMap>
                <div>
                  <div className="school-main">
                    <div className="school--name">
                      <Autocomplete
                        onLoad={onLoad}
                        onPlaceChanged={onPlaceChanged}
                      >
                        <input
                          type="text"
                          placeholder="Search School Name..."
                          defaultValue={inputSchoolFields.school.value}
                          style={{
                            boxSizing: `border-box`,
                            border: `2px solid transparent`,
                            borderColor: "#a7d1fb",
                            width: `100%`,
                            height: `1.7rem`,
                            padding: `0 1em`,
                            borderRadius: `25px`,
                            fontSize: `1rem`,
                            outline: `none`,
                            textOverflow: `ellipses`,
                            marginBottom: "0.2em",
                            flexWrap: "wrap",
                          }}
                        />
                      </Autocomplete>
                      <p style={{ marginTop: "0.5rem", color: "red", fontSize: "0.875rem" }}>{existError}</p>
                    </div>
                  </div>
                  <div className="address">
                    <h4>Address <span style={{color: "red"}}>*</span></h4>
                    <div className="address-section">
                      <CustomInput
                        input={inputSchoolFields.school}
                        error={error.school}
                        onChange={handleEditInput}
                        onSubmit={(e) =>
                          handleOnSubmit(e, handleEditSchoolSubmit)
                        }
                        className="input--school"
                      />
                      <CustomInput
                        input={inputSchoolFields.streetNumber}
                        onChange={handleEditInput}
                        onSubmit={(e) =>
                          handleOnSubmit(e, handleEditSchoolSubmit)
                        }
                        className="input--street"
                      />
                      <CustomInput
                        input={inputSchoolFields.street}
                        onChange={handleEditInput}
                        onSubmit={(e) =>
                          handleOnSubmit(e, handleEditSchoolSubmit)
                        }
                      />
                      <CustomInput
                        input={inputSchoolFields.city}
                        error={error.city}
                        onChange={handleEditInput}
                        onSubmit={(e) =>
                          handleOnSubmit(e, handleEditSchoolSubmit)
                        }
                      />
                      <CustomInput
                        input={inputSchoolFields.region}
                        error={error.region}
                        onChange={handleEditInput}
                        onSubmit={(e) =>
                          handleOnSubmit(e, handleEditSchoolSubmit)
                        }
                      />
                      <CustomSelect
                        options={countryOptions}
                        placeholder="Select country"
                        onChange={(e) => handleSelectCountry(e)}
                        value={countryOptions.find(
                          (elem) => elem?.id === inputSchoolFields.country.value
                        )}
                        error={error.country}
                        className="input--country"
                      />
                    </div>
                  </div>
                  <div className="location">
                    <CustomInput
                      input={inputSchoolFields.long}
                      error={error.long}
                      onChange={handleEditInput}
                      onSubmit={(e) =>
                        handleOnSubmit(e, handleEditSchoolSubmit)
                      }
                      className="input--long"
                      disabled={true}
                    />
                    <CustomInput
                      input={inputSchoolFields.lat}
                      error={error.lat}
                      onChange={handleEditInput}
                      onSubmit={(e) =>
                        handleOnSubmit(e, handleEditSchoolSubmit)
                      }
                      className="input--lat"
                      disabled={true}
                    />
                  </div>
                    {((!organizationId || organizationId === 0) && existSchoolId === 0) && <CustomMultiSelect
                        title={"Assign to organization"}
                        options={orgOptions}
                        placeholder="Select Organization"
                        defaultValue={orgOptions.filter((elem) =>
                          assignOrg.has(elem.id)
                        )}
                        onChange={(e) => handleSelectAssignOrg(e)}
                        className="input--org"
                        components={{ NoOptionsOrg }}
                      />}
                      {((!organizationId || organizationId === 0) && existSchoolId !== 0 && selectFlag) && <CustomMultiSelect
                        title={"Assign to organization"}
                        options={orgOptions}
                        placeholder="Select Organization"
                        defaultValue={orgOptions.filter((elem) =>
                          existOrg.has(elem.id)
                        )}
                        className="input--org"
                        components={{ NoOptionsOrg }}
                      />}
                </div>
              </div>
            </InputsWrapper>
            <ModalCreateButtons
              text={ existSchoolId !== 0 ? "View School" : role === "ADMIN" ? "Save Changes" : "Send Request"}
              error={error.apiFail}
              onClick={existSchoolId !== 0 ? handleView : handleEditSchoolSubmit}
              className="modal__buttons--schools"
            />
          </SchoolModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default EditSchoolModal;
