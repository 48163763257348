import React, { useContext, useState } from "react";
import { errors, isPasswordLengthValid } from "../../utils/validation";
import { ActionButtonOrange } from "../Buttons/ActionButton";
import { LogInSubmit } from "../../api";
import { MenuContext } from "../../contexts/menuContext";
import { LogInContext } from "../../contexts/logInContext";
import { ForgottenPassword, LogInCardWrapper } from "./Cards.styles";
import { CustomInput } from "../InputFields/CustomInput";
import { inputLoginFields } from "../InputFields/InputFields";
import { handleOnSubmit } from "../../utils/constants";
import SocialLogin from "./SocialLogin";
import { warning } from "../../utils/toastify";

let defaultError = {
  email: "",
  password: "",
};

const LogInCard = ({ setForgottenPassword, setEmail, loginhandler }) => {
  const { setToken, setIsLogged, setRole } = useContext(LogInContext);
  const { setMenuDisplay } = useContext(MenuContext);

  let [user, setUser] = useState({
    email: "",
    password: "",
  });

  let [error, setError] = useState({
    email: "",
    password: "",
  });

  const handleUserInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });

    if (e.target.name === "email") {
      setEmail(e.target.value);
    }
    delete error.email;
  };

  const handleCheckUser = (res) => {
    if (res.role === "FACILITATOR") {
      setError({ email: "User does not have access to the web platform." });
      return false;
    } else {
      setToken(res.token);
      localStorage.setItem("selectedOrg", res.organizationName);
      localStorage.setItem("name", res.userName);
      localStorage.setItem("email", res.userEmail);
      localStorage.setItem("org", res.organizationName);
      setRole(res?.role);
      setMenuDisplay(true);
      setIsLogged(true);
      res.role !== "ADMIN" &&
        localStorage.setItem("organizationId", res.organizationId);
      loginhandler();
    }
  };

  const handlePasswordInput = (e) => {
    const errorCopy = { ...error };

    setUser({ ...user, [e.target.name]: e.target.value });

    const isValid = isPasswordLengthValid(e.target.value);

    if (!isValid) {
      errorCopy.password = errors.passwordLengthErrorMessage;
      setError({ ...errorCopy });
    } else {
      setError({ ...defaultError });
    }
  };

  const handleLogInSubmit = async () => {
    const errorCopy = { ...error };

    const responseData = await LogInSubmit(user);

    if (responseData.status === "success") {
      handleCheckUser(responseData.data);
    } 
    if (responseData.code === 101) {
      errorCopy.email = errors.facilitatorErrorMessage;
      setError({ ...errorCopy });
    }
    if (responseData.code === 102) {
      warning(responseData.data);
    }
    if (responseData.code === 103) {
      errorCopy.email = errors.passwordErrorMessage;
      setError({ ...errorCopy });
    }
    if (responseData.code === 104) {
      warning(responseData.data);
    }
    if (responseData.code === 105) {
      warning(responseData.data);
    }
    if (responseData.code === 100) {
      warning(responseData.data);
    }
  };

  const handleForgottenPassword = () => {
    setForgottenPassword(true);
  };

  inputLoginFields.email.value = user.email;

  return (
    <>
      <LogInCardWrapper>
        <h2>Enter your credentials</h2>
        <div className="loginCard">
          <CustomInput
            input={inputLoginFields.email}
            onChange={handleUserInput}
            error={error.email}
            onSubmit={(e) => handleOnSubmit(e, handleLogInSubmit)}
            className="input--user-email"
          />
          <CustomInput
            input={inputLoginFields.password}
            onChange={handlePasswordInput}
            error={error.password}
            onSubmit={(e) => handleOnSubmit(e, handleLogInSubmit)}
            className="input--user-password"
          />
          <div>
            <ForgottenPassword onClick={handleForgottenPassword}>
              Forgot your password?
            </ForgottenPassword>
          </div>
        </div>
        <div>
          <ActionButtonOrange
            theme={"primary"}
            text="Login"
            onClick={handleLogInSubmit}
          />
          <div className="social">
            <div className="border-left"></div>
              <span className="content">Or Login with</span>
            <div className="border-right"></div>
          </div>
           <div id="signIn" className="sign-in">
              <SocialLogin loginhandler={loginhandler} />
           </div>
        </div>
      </LogInCardWrapper>
    </>
  );
};

export default LogInCard;
