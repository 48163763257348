import { useContext, useState } from "react";
import { CreateOrganization } from "../../../api";
import { LogInContext } from "../../../contexts/logInContext";
import { ModalContext } from "../../../contexts/modalContext";
import { isValidName, errors, isValidLink } from "../../../utils/validation";
import { AddOrganizationModalWrapper, ModalWrapper } from "../Modals.styles";
import { OrganizationsContext } from "../../../contexts/organizationsContext";
import { ModalCreateButtons } from "../ModalButtons";
import { CustomInput, CustomTextarea } from "../../InputFields/CustomInput";
import { inputOrgFields } from "../../InputFields/InputFields";
import { addElement, handleOnSubmit } from "../../../utils/constants";
import { success, warning } from "../../../utils/toastify";
import { SchoolsContext } from "../../../contexts/schoolsContext";
import CustomSelect from "../../InputFields/CustomSelect";

const AddOrganizationModal = ({ modalRef, closeModal }) => {
  const { organizations, setOrganizations } =
    useContext(OrganizationsContext);
  const { showModal, setShowModal } = useContext(ModalContext);
  const { token } = useContext(LogInContext);
  const [orgInfo, setOrgInfo] = useState({});
  const [error, setError] = useState({});
  const [errorLink, setErrorLink] = useState("");
  const { countryOptions } = useContext(SchoolsContext);

  const handleUserInput = (e) => {
    let errorCopy = { ...error };

    setOrgInfo({ ...orgInfo, [e.target.name]: e.target.value });

    switch (e.target.name) {
      case "name": {
        const isValid = isValidName(e.target.value);
        isValid
          ? delete errorCopy.organization
          : (errorCopy.organization = errors.requiredNameError);
        break;
      }
      case "link": {
        if (!!!e.target.value) {
           setErrorLink("");
        }
        break;
      }
      case "email": {
        const isValid = isValidName(e.target.value);
        isValid
          ? delete errorCopy.ownerEmail
          : (errorCopy.ownerEmail = errors.ownerEmailErrorMessage);
        break;
      }
      case "ownerName": {
        const isValid = isValidName(e.target.value);
        isValid
          ? delete errorCopy.ownerFirstName
          : (errorCopy.ownerFirstName = errors.ownerFirstNameErrorMessage);
        break;
      }
      case "ownerLastName": {
        const isValid = isValidName(e.target.value);
        isValid
          ? delete errorCopy.ownerLastName
          : (errorCopy.ownerLastName = errors.ownerLastNameErrorMessage);
        break;
      }
      case "city": {
        const isValid = isValidName(e.target.value);
        isValid
          ? delete errorCopy.city
          : (errorCopy.city = errors.cityRequiredMessage);
        break;
      }
      case "region": {
        const isValid = isValidName(e.target.value);
        isValid
          ? delete errorCopy.region
          : (errorCopy.region = errors.regionRequiredMessage);
        break;
      }
      default:
        break;
    }
    setError(errorCopy);
  };

  const handleSubmitValidations = () => {
    let errorCopy = { ...error };
    delete errorCopy.apiFail;

    !orgInfo.name && (errorCopy.organization = errors.requiredNameError);
    !orgInfo.email &&
      (errorCopy.ownerEmail = errors.ownerEmailErrorMessage);
    !orgInfo.ownerName &&
      (errorCopy.ownerFirstName = errors.ownerFirstNameErrorMessage);
    !orgInfo.ownerLastName &&
      (errorCopy.ownerLastName = errors.ownerLastNameErrorMessage);
    !orgInfo.city && (errorCopy.city = errors.cityRequiredMessage);
    !orgInfo.region && (errorCopy.region = errors.regionRequiredMessage);
    !orgInfo.countryId && (errorCopy.country = errors.countryRequiredMessage);
    if (!!orgInfo.link) {
      const isValid = isValidLink(orgInfo.link);
      if (!isValid) {
        setErrorLink(errors.websiteLinkErrorMessage);
        if (Object.keys(errorCopy).length > 0) {
          warning("Required field/s must be filled");
        }
    
        setError(errorCopy);
        return false;
      }
    } else {
      if (Object.keys(errorCopy).length > 0) {
        warning("Required field/s must be filled");
      }
  
      setError(errorCopy);
    }

    return Object.keys(errorCopy).length === 0;
  };

  const handleSubmitOrganization = async () => {
    let errorCopy = {};
    const isValid = handleSubmitValidations();

    if (!isValid) {
      return false;
    }

    const responseData = await CreateOrganization(orgInfo, token);

    if (responseData.status === "success") {
      addElement(organizations, setOrganizations, responseData.data.data);
      setShowModal(false);
      success("Invite is sent to the organization.");
    } else {
      if (responseData.data) {
        responseData.code === 100 && (errorCopy.organization = responseData.data);
        responseData.code === 101 && (errorCopy.email = responseData.data);
      } else {
        errorCopy.apiFail = errors.apiFail;
      }
      setError(errorCopy);
    }
  };

  const handleSelectCountry = (e) => {
    setOrgInfo({ ...orgInfo, countryId: e.id });
    delete error.country;
  };

  inputOrgFields.organization.value = orgInfo.name;
  inputOrgFields.website.value = orgInfo.link;
  inputOrgFields.ownerEmail.value = orgInfo.email;
  inputOrgFields.ownerFirstName.value = orgInfo.ownerName;
  inputOrgFields.ownerLastName.value = orgInfo.ownerLastName;
  inputOrgFields.description.value = orgInfo.description;
  inputOrgFields.streetNumber.value = orgInfo.streetNumber;
  inputOrgFields.street.value = orgInfo.street;
  inputOrgFields.city.value = orgInfo.city;
  inputOrgFields.region.value = orgInfo.region;

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <AddOrganizationModalWrapper
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div className="org-field">
              <CustomInput
                input={inputOrgFields.organization}
                error={error.organization}
                onChange={handleUserInput}
                onSubmit={(e) => handleOnSubmit(e, handleSubmitOrganization)}
              />
              <CustomInput
                input={inputOrgFields.website}
                onChange={handleUserInput}
                onSubmit={(e) => handleOnSubmit(e, handleSubmitOrganization)}
                className="input--email"
                error={errorLink}
              />
              <CustomInput
                input={inputOrgFields.ownerEmail}
                error={error.ownerEmail}
                onSubmit={(e) => handleOnSubmit(e, handleSubmitOrganization)}
                onChange={handleUserInput}
              />
              <CustomInput
                input={inputOrgFields.ownerFirstName}
                error={error.ownerFirstName}
                onSubmit={(e) => handleOnSubmit(e, handleSubmitOrganization)}
                onChange={handleUserInput}
              />
              <CustomInput
                input={inputOrgFields.ownerLastName}
                error={error.ownerLastName}
                onSubmit={(e) => handleOnSubmit(e, handleSubmitOrganization)}
                onChange={handleUserInput}
              />

              <CustomTextarea
                textarea={inputOrgFields.description}
                onChange={handleUserInput}
                className="text-area"
              /> 

              <CustomInput
                input={inputOrgFields.streetNumber}
                onSubmit={(e) => handleOnSubmit(e, handleSubmitOrganization)}
                onChange={handleUserInput}
                className="input--street"
              />

              <CustomInput
                input={inputOrgFields.street}
                onSubmit={(e) => handleOnSubmit(e, handleSubmitOrganization)}
                onChange={handleUserInput}
                className="org__street"
              />

              <CustomInput
                input={inputOrgFields.city}
                error={error.city}
                onSubmit={(e) => handleOnSubmit(e, handleSubmitOrganization)}
                onChange={handleUserInput}
              />

              <CustomInput
                input={inputOrgFields.region}
                error={error.region}
                onSubmit={(e) => handleOnSubmit(e, handleSubmitOrganization)}
                onChange={handleUserInput}
              />

              <CustomSelect
                title="Country"
                options={countryOptions}
                placeholder="Select country"
                onChange={(e) => handleSelectCountry(e)}
                error={error.country}
                className="input--name"
              />
            </div>
            <ModalCreateButtons
              text="Add"
              onClick={handleSubmitOrganization}
              error={error.apiFail}
              className="modal__buttons--add-org add__org"
            />
          </AddOrganizationModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default AddOrganizationModal;
