import React, { useContext, useEffect, useState } from "react";
import { ActionButtonOrange } from "../components/Buttons/ActionButton";
import { errors, isPasswordLengthValid } from "../utils/validation";
import { ChangePasswordSubmit, verifyLink } from "../api";
import queryString from "query-string";
import { LogInContext } from "../contexts/logInContext";
import {
  ChangePasswordCardWrapper,
  ChangePasswordPageWrappper,
} from "./Page.styles";
import { inputChangePasswordFields } from "../components/InputFields/InputFields";
import { CustomInput } from "../components/InputFields/CustomInput";
import { MenuContext } from "../contexts/menuContext";

const SetPassword = ({ loginhandler }) => {
  const { setToken, setIsLogged, setRole } = useContext(LogInContext);
  const { setMenuDisplay } = useContext(MenuContext);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordCheck, setNewPasswordCheck] = useState("");
  const [error, setError] = useState({});
  let [link, setLink] = useState(true);

  useEffect(() => {
    const verify = async () => {
      let { email } = queryString.parse(window.location.search);
      const res = await verifyLink(email);

      if (res.status === "success") {
        setLink(true);
      } else {
        setLink(false);
      }
    };

    verify();
  }, []);

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
    let errorCopy = { ...error };

    let isPasswordValid = isPasswordLengthValid(e.target.value);

    !isPasswordValid
      ? (errorCopy.password = errors.passwordLengthErrorMessage)
      : delete errorCopy.password;

    e.target.value !== newPasswordCheck && newPasswordCheck.length > 0
      ? (errorCopy.message = errors.confirmPasswordErrorMessage)
      : delete errorCopy.message;

    setError(errorCopy);
  };

  const handleNewPasswordCheck = (e) => {
    setNewPasswordCheck(e.target.value);
    const errorCopy = { ...error };

    let isPasswordValid = isPasswordLengthValid(newPassword);

    !(newPassword === e.target.value && isPasswordValid)
      ? (errorCopy.message = errors.confirmPasswordErrorMessage)
      : delete errorCopy.message;

    setError(errorCopy);
  };

  const handleSubmitValidations = () => {
    let errorCopy = { ...error };
    delete errorCopy.apiSetPasswordFail;
    !newPassword && (errorCopy.password = errors.passwordRequiredErrorMessage);
    !newPasswordCheck &&
      (errorCopy.message = errors.passwordRequiredErrorMessage);

    setError(errorCopy);
    return Object.keys(errorCopy).length === 0;
  };

  const handleSubmit = async () => {
    let { email, encrypted } = queryString.parse(window.location.search);
    encrypted = encrypted.toString().replace(/ /g, "+");
    const isValid = handleSubmitValidations();

    if (!isValid) {
      return false;
    }

    let info = {
      email: email,
      oldPassword: encrypted,
      newPassword: newPassword,
      isOldPasswordEncrypted: true,
    };

    const passwordData = await ChangePasswordSubmit(info);

    if (passwordData.status === "success") {
      setToken(passwordData.data.token);
      localStorage.setItem("selectedOrg", passwordData.data.organizationName);
      localStorage.setItem("name", passwordData.data.userName);
      localStorage.setItem("email", passwordData.data.userEmail);
      localStorage.setItem("org", passwordData.data.organizationName);
      setRole(passwordData.data.role);
      setMenuDisplay(true);
      passwordData.data.role !== "ADMIN" &&
        localStorage.setItem(
          "organizationId",
          passwordData.data.organizationId
        );
      setIsLogged(true);
      loginhandler();
    } else if (passwordData.code === 104) {
      setError({
        apiFail: passwordData.data,
      });
    } else {
      setError({
        apiFail: errors.apiFail,
      });
    }
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  inputChangePasswordFields.newPassword.value = newPassword;
  inputChangePasswordFields.checkPassword.value = newPasswordCheck;

  return (
    <ChangePasswordPageWrappper className="setpassword-container">
      {link ? (
        <>
          <ChangePasswordCardWrapper>
            <h2> Set Password </h2>
            <div className="set-password-formGroup">
              <CustomInput
                input={inputChangePasswordFields.newPassword}
                error={error.password}
                onChange={handleNewPassword}
                onSubmit={handleOnSubmit}
              />
              <CustomInput
                input={inputChangePasswordFields.checkPassword}
                error={error.message}
                onChange={handleNewPasswordCheck}
                onSubmit={handleOnSubmit}
              />
              <ActionButtonOrange
                theme={"primary"}
                text="Set Password"
                onClick={handleSubmit}
              />
              {error && <h5 className="error--api-fail">{error.apiFail}</h5>}
            </div>
          </ChangePasswordCardWrapper>
        </>
      ) : (
        <h1 className="content">Link is expired.</h1>
      )}
    </ChangePasswordPageWrappper>
  );
};

export default SetPassword;
