import React, { useContext, useState } from "react";
import { ModalContext } from "../../../contexts/modalContext";
import { LogInContext } from "../../../contexts/logInContext";
import {
  ModalWrapper,
  AssignModalWrapper,
  SelectGradeWrapper,
} from "../Modals.styles";
import { ModalCreateButtons } from "../ModalButtons";
import { RemoveSchool } from "../../../api";
import CustomMultiSelect from "../../InputFields/CustomMultiSelect";
import { SchoolsContext } from "../../../contexts/schoolsContext";

const RemoveSchoolModal = ({ modalRef, closeModal }) => {
  const { user, assignSchool } = useContext(SchoolsContext);
  const { showModal, setShowModal } = useContext(ModalContext);
  const { token } = useContext(LogInContext);
  const [editSchool, setEditSchool] = useState(user);

  let schools = new Set(
    user.schools.map((school) => school.id)
  );

  const handleRemoveSchool = async () => {
    let payload = {
      schoolIds: editSchool.schools,
      userId: user?.id,
    };
    const res = await RemoveSchool(payload, token);

    if (res.status === "success") {
      setShowModal(false);
    }
  };

  const handleSelectRemoveSchool = async (e) => {
    const inputMaterialSkills = new Set(e.map((school) => school.id));

    [...schools]
      .filter((school) => !inputMaterialSkills.has(school))
      .forEach((elem) => {
        schools.add({ id: elem, toDelete: true });
        schools.delete(elem);
      });
    [...inputMaterialSkills]
      .filter((school) => !schools.has(school))
      .forEach((elem) => schools.add({ id: elem, isNew: true }));

    setEditSchool({ ...editSchool, schools: [...schools] });
  };

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <AssignModalWrapper>
            <SelectGradeWrapper>
              <CustomMultiSelect
              defaultValue={assignSchool.filter((elem) =>
                schools.has(elem.id)
              )}
              onChange={(e) => handleSelectRemoveSchool(e)}
                title="Remove Schools"
                placeholder="Select school"
                className="input--skills"
              />
            </SelectGradeWrapper>
            <ModalCreateButtons
              className="assign--btn"
              text="Save Changes"
              onClick={handleRemoveSchool}
            />
          </AssignModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default RemoveSchoolModal;