import React, { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../../contexts/modalContext";
import { LogInContext } from "../../../contexts/logInContext";
import {
  ModalWrapper,
  AssignModalWrapper,
  SelectGradeWrapper,
} from "../../Modals/Modals.styles";
import { ModalCreateButtons } from "../../Modals/ModalButtons";
import { GetAllFacilitatorSchools,GetAllSchoolsList, RemoveSchool } from "../../../api";
import CustomMultiSelect from "../../InputFields/CustomMultiSelect";
import { SchoolsContext } from "../../../contexts/schoolsContext";
import { OrganizationsContext } from "../../../contexts/organizationsContext";

const AssignModal = ({ modalRef, closeModal }) => {
  const { user, assignSchool, setAssignSchool } = useContext(SchoolsContext);
  const { showModal, setShowModal } = useContext(ModalContext);
  const { token } = useContext(LogInContext);
  const { organizationId } = useContext(OrganizationsContext);
  const [schoolList, setSchoolsList] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [editSchool, setEditSchool] = useState(user);
  const [flag, setFlag] = useState(false);

  let schools = new Set(
    user.schools.map((school) => school.id)
  );

  useEffect(() => {
    async function fetchSchools() {
      const responseData = await GetAllFacilitatorSchools(token, user?.id);
      let studentPageSchools = [];

      if (responseData.status === "success") {
        studentPageSchools = responseData.data.schools.map((item) => ({
          value: !!!item?.city || !!!item?.region || !!!item?.country ? item?.name : item?.name + ' (' + item?.city + ", " + item?.region + ", " + item?.country + ')',
          label: !!!item?.city || !!!item?.region || !!!item?.country ? item?.name : item?.name + ' (' + item?.city + ", " + item?.region + ", " + item?.country + ')',
          id: item.id,
        }));

        setAssignSchool(studentPageSchools);
        setFlag(true);
      } else {
        setAssignSchool([]);
      }
    }

    if (user?.id !== 0) {
      fetchSchools();
    }
  }, [token, user?.id]);

  useEffect(() => {
    async function fetchSchools() {
      const responseData = await GetAllSchoolsList(token, organizationId);
      if (responseData.status === "success") {
        setSchoolsList(responseData.data.schools);
      } 
    }
    fetchSchools();
  }, [token, organizationId]);

  useEffect(() => {
    setSchoolOptions(
      schoolList.map((school) => ({
        value:
        !!!school?.city || !!!school?.region || !!!school?.country?.name
          ? school?.name
          : school?.name +
            " (" +
            school?.city +
            ", " +
            school?.region +
            ", " +
            school?.country?.name +
            ")",
      label:
        !!!school?.city || !!!school?.region || !!!school?.country?.name
          ? school?.name
          : school?.name +
            " (" +
            school?.city +
            ", " +
            school?.region +
            ", " +
            school?.country?.name +
            ")",
      id: school.id,
      }))
      );
    }, [schoolList]);

  const handleAssignSchool = async () => {
    let payload = {
      schoolIds: editSchool.schools,
      userId: user?.id,
    };
    const res = await RemoveSchool(payload, token);

    if (res.status === "success") {
      setShowModal(false);
    }
  };

  const handleSelectAssignSchool = async (e) => {
    const selectSchools = new Set(e.map((school) => school.id));

    [...schools]
      .filter((school) => !selectSchools.has(school))
      .forEach((elem) => {
        schools.add({ id: elem, toDelete: true });
        schools.delete(elem);
      });
    [...selectSchools]
      .filter((school) => !schools.has(school))
      .forEach((elem) => schools.add({ id: elem, isNew: true }));

    setEditSchool({ ...editSchool, schools: [...schools] });
  };

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <AssignModalWrapper>
            <SelectGradeWrapper>
              {flag && <CustomMultiSelect
                title="Assign / Remove Schools"
                placeholder="Select Assign / Remove school"
                className="input--skills"
                defaultValue={assignSchool.filter((elem) =>
                  schools.has(elem.id)
                )}
                options={schoolOptions}
                onChange={(e) => handleSelectAssignSchool(e)}
              />}
            </SelectGradeWrapper>
            <ModalCreateButtons
              className="assign--btn"
              text="Save Changes"
              onClick={handleAssignSchool}
              disabled={editSchool.schools?.length === 0 ? true : false}
            />
          </AssignModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default AssignModal;