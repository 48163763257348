import { useContext, useState, useEffect } from "react";
import { LogInContext } from "../../../contexts/logInContext";
import { ModalContext } from "../../../contexts/modalContext";
import { ModalCreateButtons } from "../../Modals/ModalButtons";
import { GetAllSkills, MergeSkills } from "../../../api";
import { ModalWrapper, SkillModalWrapper } from "../../Modals/Modals.styles";
import CustomSelect from "../../InputFields/CustomSelect";
import { LessonsContext } from "../../../contexts/lessonsContext";

const RejectSkillModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal } = useContext(ModalContext);
  const { selectedMaterial } = useContext(LessonsContext);
  const { token } = useContext(LogInContext);
  let [skill, setSkill] = useState([]);
  const [skillId, setSkillId] = useState(0);
  const [skillName, setSkillName] = useState(0);

  const handleRejectSkill = async () => {
    let payload = {
        oldSkillId: selectedMaterial?.id,
        newSkillId: skillId,
        name: selectedMaterial?.user?.name,
        email: selectedMaterial?.user?.email,
        newSkill: skillName
    }
    const res = await MergeSkills(token, payload);

    if (res.status === "success") {
      setShowModal(false);
    }
  };

  useEffect(() => {
    async function fetchSkills() {
      const responseData = await GetAllSkills(token);
      let skillData = [];

      if (responseData.status === "success") {
        skillData = responseData.data.skills.map((item) => ({
          value: item.name,
          label: item.name,
          id: item.id,
        }));

        skill = skillData;
        setSkill(skill);
      } else {
        setSkill([]);
      }
    }
    fetchSkills();
  }, [token]);

  const handleSelectSkill = (e) => {
    setSkillId(e.id);
    setSkillName(e.label);
  };

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <SkillModalWrapper>
            <h2> Merge Skill </h2>
            <CustomSelect
              title="Skills"
              options={skill}
              placeholder="Select Skill"
              onChange={handleSelectSkill}
              className="input--skill"
            />
            <ModalCreateButtons
              text="Save Changes"
              onClick={handleRejectSkill}
              disabled={skillId === 0}
            />
          </SkillModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default RejectSkillModal;
