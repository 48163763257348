import styled from "styled-components";
import { LogInCardWrapper } from "../components/Cards/Cards.styles";

export const PageWrapper = styled.div`
  height: calc(100vh - 4rem - 3.5rem);
  display: flex;
  justify-content: space-between;
  overflow: hidden;
`;

export const AuthPageWrapper = styled.div`
  height: calc(100vh - 4rem - 3.5rem);
  display: flex;
  justify-content: space-between;
  overflow-y: auto;
`;

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;

  div {
    width: 100%;
    display: flex;
  }

  .input-org {
    width: 100%;
    border-radius: 25px;
    border: 1px solid #c4c4c4;
    padding: 9px;
    outline: none;
  }

  @media (max-width: 1000px) {
    display: grid;
    grid-template-columns: auto auto;
    -webkit-justify-content: unset !important;
    gap: 10px;
  }

  @media (max-width: 500px) {
    display: grid;
    grid-template-columns: auto;
    -webkit-justify-content: unset !important;
    gap: 10px;
  }
`;

export const TitleWithCTA = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 2.5rem;
  .org__button,
  .school__button,
  .group__button,
  .group-details__buttons {
    button {
      width: auto;
    }
    display: flex;
    justify-content: flex-end;
    width: 29rem;
  }

  .group-details__buttons {
    justify-content: space-between;
    width: 33rem;
    gap: 10px;
  }
`;

export const GoBackButton = styled.div`
  width: 100%;
  margin-top: -0.5rem;
  margin-bottom: 0.8rem;
  padding: 0 0 0rem 1.5rem;

  .go-back__button {
    width: 37rem;

    button {
      padding: 0.2rem 0;
    }
  }

  .go-back__button--students {
    width: 55rem;

    button {
      width: 40%;
      padding: 0.2rem 0;
    }
  }

  @media (max-width: 360px) {
    .go-back__button {
      button {
        width: fit-content !important;
        white-space: unset !important;
        display: flex !important;
        flex-wrap: wrap !important;
        font-size: 0.8rem;
      }
    }
  }
`;

export const SelectWrapper = styled.div`
  width: 25rem;
  margin-top: 2em;

  @media screen and (max-width: 500px) {
    width: inherit;
  }
`;

export const StyledInput = styled.input`
  margin: 0.5em 0;
  background: white;
  border-radius: var(--border-radius);
  border: 2px solid var(--blue-200);
  font-size: 1.1rem;
  width: 100%;
  height: 2rem;
  padding: 0 1em;

  &:focus-visible {
    border: 2px solid var(--blue-200);
    border-radius: var(--border-radius);
    outline: none;
  }

  &:focus {
    background-color: var(--blue-100);
  }
`;

export const StyledPageWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  overflow-y: auto;

  h4.select-org {
    width: 100%;
    padding: 0.5rem 2.5rem;
  }

  .img {
    height: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
  }

  img.image--lessons {
    margin-top: 2rem;
    width: 45vw;
  }

  img.image--users {
    width: 100%;
    height: 25rem;
  }

  img.student-profile__image {
    height: 25rem;
    margin-top: 3rem;
  }

  div.image--group-details {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
      margin-bottom: 2.5rem;
    }

    img {
      width: 40vw;
    }
  }

  .users-filter {
    display: flex;
    align-items: center;
    width: 100%;

    div:first-child {
      input {
        width: inherit;
      }

      button {
        left: auto;
        right: 45px !important;
      }
    }

    .users-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 20px;

      .select-role {
        width: 100%;
        margin-top: 1rem;

        div:first-child {
          div {
            border-radius: 25px !important;
          }
        }
      }

      div:last-child {
        justify-content: flex-end;

        button {
          width: max-content;
          margin: 0;
        }

        button:last-child {
          margin-right: 1.5rem;
        }
      }
    }
  }

  .filter-btn {
    width: 100%;
    padding-right: 3rem;
    margin-top: 0.5rem;

    button {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-end;
    }
  }

  @media (max-width: 840px) {
    .group_detail {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }

  @media (max-width: 650px) {
    .group_detail {
      .__btns {
        display: flex;
        flex-direction: column;
        width: fit-content !important;
      }
    }
  }

  @media (max-width: 330px) {
    .group-details__buttons {
      button {
        width: fit-content !important;
        white-space: unset !important;
        display: flex !important;
        flex-wrap: wrap !important;
      }
    }
  }
`;

export const HomeWrapper = styled(StyledPageWrapper)`
  text-align: center;
  
  .org_add_button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  img {
    margin-top: 2rem;
    height: 18rem;
  }

  h2 {
    margin-top: 2em;
  }

  h4 {
    margin-top: 2em;
  }

  @media screen and (max-width: 500px) {
    img {
      height: 8rem;
    }

    h2 {
      font-size: 1rem;
    }

    h4 {
      font-size: 1rem;
    }
  }
`;
export const StyledSelectWrapper = styled(SelectWrapper)`
  width: 100%;
  padding: 0 2.5rem;
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    font-size: 1rem;
  }
  .selection {
    margin-top: 0.5rem;
    width: 20rem;

    div:first-child {
      div {
        border-radius: 25px !important;
      }
    }
  }

  @media screen and (max-width: 430px) {
    .selection {
      width: 100%;
    }
  }
`;

export const SelectGradeWrapper = styled.div`
  width: 100%;
  position: relative;

  button {
    position: absolute;
    right: 0;
    top: 1.3rem;
    width: auto;
  }
`;

export const CheckboxWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  align-items: center;
  margin-top: 0.5rem;

  div {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
  }
`;

export const StyledTitleWrapper = styled.div`
  width: 100%;
`;

export const GroupsPageWrapper = styled(StyledPageWrapper)`
  h2,
  h4 {
    text-align: left;
    width: 100%;
    padding: 1rem 40px 0 40px;
  }

  div.image--groups {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
      margin-bottom: 2rem;
    }

    img {
      width: 40vw;
    }
  }
`;

export const ChangePasswordPageWrappper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ChangePasswordCardWrapper = styled(LogInCardWrapper)`
  height: 23rem;

  div {
    height: 80%;

    h5 {
      position: fixed;
    }

    button {
      margin-top: -0.5rem;
    }

    h5.error--api-fail {
      position: static;
      padding-top: 0.8rem;
      width: 20rem;
    }

    h4.lower-input {
      margin-top: 2rem;
    }

    .content {
      width: 100%;
      height: calc(100vh - 4rem - 3.5rem);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const StyledEditStudentPage = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .modal__buttons--create {
    height: 4rem;
    margin-top: 1rem;
    width: 100%;
    display: flex;
    padding-top: 3rem;
    flex-direction: column;
    align-items: center;
    position: relative;
    text-align: center;
  }

  .input--edit-student,
  .input--edit-student-id {
    position: relative;
    margin-bottom: 1.2rem;

    h5 {
      position: absolute;
      bottom: -0.6rem;
    }
  }

  .input--edit-student-id {
    margin-bottom: 1.7rem;
    h5 {
      top: 95%;
    }
  }

  .select--edit-student {
    margin-bottom: 1.5rem;
    position: relative;

    h5 {
      position: absolute;
      bottom: -1.1rem;
    }
  }

  .input--country {
    div {
      div {
        border-radius: 25px;
      }
    }
  }
`;

export const ButtonsWrapper = styled.div`
  width: 20rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "0")};
`;
export const StyledEditInfo = styled.div`
  margin-left: 2rem;
  margin-right: 1rem;
  width: 100%;

  h4 {
    font-weight: 600;
    font-size: 1.1rem;
  }
`;

export const StyledInfo = styled.div`
  padding-top: 1rem;
  width: 60%;
  position: absolute;
  left: 23rem;

  h4,
  h2,
  h5 {
    padding-left: 1rem;
  }

  h4 {
    font-weight: 600;
  }

  .edit-mode {
    white-space: nowrap;
    margin: 0.5rem 0 0.2rem 0;
  }
`;

export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.6rem 1rem;
  text-align: left;
  position: relative;
  width: 45rem;

  div.student-info {
    display: flex;

    img {
      margin-left: 2rem;
    }
  }

  img.student-profile {
    margin-left: 2rem;
  }

  .button {
    width: 22rem;
    height: 3.5rem;
    padding: 0 1rem;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .image {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 18rem;
    position: relative;

    h5.error--image {
      position: absolute;
      bottom: 3rem;
    }
  }

  img {
    height: 15rem;
    width: 15rem;
    object-fit: contain;
    object-position: center;
  }

  h3 {
    margin-bottom: 0.5rem;
    padding-left: 1rem;
    font-weight: 700;
  }

  span {
    margin-left: 0.5rem;
    font-weight: 400;
  }
`;

export const StyledContactPage = styled.div`
  width: 100%;

  div.contact__description,
  div.contact__info {
    margin: 1.5rem auto;
    padding: 0 2.5rem;
    width: 100%;
  }
  .contact__description {
    h4 {
      font-size: calc(1.6rem - 10px);
      width: 65%;
    }
  }

  div.contact__info {
    margin-top: 2.5rem;

    .contact__details,
    .contact__input--details {
      margin-top: 1.5rem;
      width: 32rem;

      h3,
      h4 {
        font-weight: 600;
      }

      h4 {
        font-size: calc(1.6rem - 10px);
      }
    }

    .contact__details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 5rem;

      span {
        font-weight: 400;
        margin-left: 1.5rem;
      }
    }
    div.contact__input--details {
      height: 16.5rem;

      .input--contactName,
      .input--contactEmail,
      .input--contactPhone {
        margin-bottom: 1.5rem;
        position: relative;
        h5 {
          position: absolute;
          bottom: -1rem;
        }
      }
    }

    .contact__button {
      width: 25rem;
      margin-top: 2rem;
    }

    .contact__edit-buttons {
      height: 2rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      text-align: center;
      z-index: 0;

      h5 {
        position: absolute;
        bottom: -2rem;
        text-align: left;
      }
    }
  }
`;

export const StyledSMSPage = styled.div`
  width: 100%;

  .sms--body {
    margin: 1.5rem 2.5rem;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 1rem;
    height: 52vh;
    position: relative;

    input {
      width: 100%;
      padding: 0.7rem;
      outline: none;
      border: 1px solid #cccccc;
      border-radius: 5px;
      font-size: 0.9rem;
    }

    .sms--input {
      display: flex;
      flex-direction: column;
      alignitem: center;
      gap: 20px;
    }

    .quill {
      height: 25vh;
      width: 100%;
    }

    .sms--btn{
      position: absolute;
      bottom: 1rem;
      width: max-content;
      right: 1rem;
    }
  }

  @media (max-width: 899px) {
    .sms--body {
      margin: 0rem;
    }
  }

  @media(max-width:600px){
    .sms--body {
      height: 60vh;
    }
  }
`;

export const StyledMetricsPage = styled(StyledPageWrapper)`
  padding-bottom: 0;

  div.metrics__cards {
    width: 100%;
    margin-top: 2.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const StyledFiltersContainer = styled.div`
  width: 100%;
  margin-top: 1.5rem;
  padding: 0 2.5rem;

  h4 {
    margin-bottom: 1rem;
    font-size: 1.1rem;
  }

  .filter {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 10rem;

    .select-org {
      div {
        width: 100%;
      }
    }

    .button--filter {
      div {
        display: flex;
        align-items: center;

        button {
          margin: 0;
        }
      }
    }
  }
`;

export const StyledAlbumsFiltersContainer = styled(StyledFiltersContainer)`
  margin-top: 0rem;
  padding: 0 2rem;

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 25px !important;
  }
`;

export const StyledFilters = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 15px;

  div.date-filters {
    width: 37rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: none;
  }

  div.select {
    width: 100%;
  }

  div.select-org {
    width: 100%;

    div:first-child {
      div {
        border-radius: 25px !important;
        width: 100%;
      }
    }
  }

  h3 {
    color: #c6d5e1;
    cursor: default;
    transform: scaleX(2.5);
  }

  div.album-date-filters {
    width: 35vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 1.5rem;
  }

  div.select-album-filter {
    width: calc((100vw - 35vw) / 2);
  }
`;

export const StyledFilterButtons = styled.div`
  width: 100%;
  height: 2rem;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;

  div.metrics__button-filters {
    width: 18rem;
    display: flex;

    button {
      margin: 0;
    }
  }

  div.metrics__button-download {
    display: flex;
    button {
      white-space: nowrap;
    }
  }

  button {
    font-size: 13px;
  }
`;

export const StyledPhotoAlbumPage = styled(StyledPageWrapper)`
  justify-content: space-between;
  padding-bottom: 1.5rem;
`;
export const StyledTopSection = styled.div`
  width: 100%;

  div.albums_search-box {
    display: flex;
    align-items: center;

    div.albums_reset-button {
      margin-right: 3rem;
      width: 5vw;
    }
  }
`;
