import React from 'react'
import styled from 'styled-components'
import logo from "../assets/logo.svg"
import { MenuIcon } from './Menu/Menu'

const HeaderWrapper = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 4rem;
    padding: 1.5rem 3rem;
    background: var(--blue-200);
    position: sticky;
    top: 0;
    z-index: 100;

    div{
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

export const HeaderLoggedIn = () => {
    return (
        <HeaderWrapper>
            <div>
                <MenuIcon />
                <img style={{height:"2rem", marginLeft:"2rem"}} src={ logo } alt="GCC" />
            </div>
        </HeaderWrapper>
    )
}