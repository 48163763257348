import React, { useState, useEffect, useContext } from "react";
import {
  ActionButtonOrange,
  ActionButtonOrangeSecondary,
} from "../components/Buttons/ActionButton";
import { MenuContext } from "../contexts/menuContext";
import { ModalContext } from "../contexts/modalContext";
import { StyledPageWrapper, TitleWithCTA } from "./Page.styles";
import {
  TableContainer,
  TableWrapper,
} from "../components/Tables/Table.styles";
import NoDataDisplay from "../components/Displays/NoDataDisplay";
import children from "../assets/children-illustration.svg";
import { GetApproveSkills } from "../api";
import { canNextPage, ITEMS_PER_PAGE, jumpToPage } from "../utils/constants";
import { LogInContext } from "../contexts/logInContext";
import ClipSpinner from "../components/Loaders/ClipSpinner";
import { Pagination } from "../components/Buttons/PaginationButtons";
import { LessonsContext } from "../contexts/lessonsContext";

const columns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "User",
    accessor: "user",
  },
  {
    Header: "Organization",
    accessor: "organization",
  },
];

const ApproveSkill = () => {
  const { modalType, showModal, setShowModal, setModalType } =
    useContext(ModalContext);
  const { setSelectedMaterial } = useContext(LessonsContext);
  const { setIsHomePage } = useContext(MenuContext);
  const { token } = useContext(LogInContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingData, setLoadingData] = useState({
    loading: true,
    hasSchools: true,
  });
  const [total, setTotal] = useState(0);
  const [lessonData, setLessonData] = useState([]);
  const [schoolPageCount, setSchoolPageCount] = useState(1);
  const [displayLesson, setDisplaySchool] = useState([]);
  const offset = (currentPage - 1) * ITEMS_PER_PAGE;

  async function fetchLessons() {
    const responseData = await GetApproveSkills(token, ITEMS_PER_PAGE, offset);

    if (responseData.status === "success") {
      setLessonData(responseData.data.data.skills);
      if (
        responseData.data.data.totalRecords ||
        responseData.data.data.totalRecords === 0
      ) {
        setTotal(responseData.data.data.totalRecords);
        setSchoolPageCount(
          Math.ceil(responseData.data.data.totalRecords / ITEMS_PER_PAGE)
        );
      }
      if (responseData.data.data.skills.length === 0) {
        setCurrentPage(1);
      }
      setDisplaySchool(responseData.data.data.skills);
    } else {
      setLessonData([]);
    }
  }

  useEffect(() => {
    fetchLessons();
  }, [token, setLessonData, setDisplaySchool, setSchoolPageCount, currentPage]);

  useEffect(() => {
    setIsHomePage(false);
  }, [setIsHomePage]);

  useEffect(() => {
    setLoadingData({ loading: false, hasSchools: !(lessonData.length === 0) });
  }, [lessonData]);

  useEffect(() => {
    if (modalType == "approve skill" || modalType == "reject skill") {
      fetchLessons();
    }
  }, [modalType, showModal]);

  const handleApproveLessonModal = (item) => {
    setShowModal(true);
    setModalType("approve skill");
    setSelectedMaterial(item);
  };

  const handleClick = (step) => {
    setCurrentPage((prevState) => prevState + step);
  };

  const handleReject = (item) => {
    setShowModal(true);
    setModalType("reject skill");
    setSelectedMaterial(item);
  };

  return (
    <>
      <StyledPageWrapper>
        <TitleWithCTA>
          <h1>Approve Skills</h1>
        </TitleWithCTA>
        {loadingData.hasSchools ? (
          <TableContainer>
            <TableWrapper>
              {loadingData.loading ? (
                <div className="loading-table">
                  <ClipSpinner />
                </div>
              ) : (
                <>
                  <table>
                    <thead>
                      <tr>
                        {columns.map((item, index) => (
                          <th key={index}>{item?.Header}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {lessonData?.map((item, index) => (
                        <tr key={index}>
                          <td>{item?.name}</td>
                          <td>{item?.user?.name}</td>
                          <td>{item?.user?.organization?.name}</td>
                          <td>
                            {" "}
                            <ActionButtonOrange
                              theme={"primary"}
                              text="Approve"
                              size="small"
                              onClick={() => {
                                handleApproveLessonModal(item);
                              }}
                            />
                            <ActionButtonOrangeSecondary
                              text="Reject"
                              size="small"
                              onClick={() => {
                                handleReject(item)
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {Math.ceil(total / ITEMS_PER_PAGE) > 0 && (
                    <Pagination
                      currentPage={currentPage}
                      disabledPrev={currentPage === 1}
                      disabledNext={canNextPage(
                        displayLesson.length,
                        total,
                        currentPage
                      )}
                      onClickPrev={() => handleClick(-1)}
                      onClickNext={() => handleClick(+1)}
                      onClickFirstPage={() => jumpToPage(setCurrentPage)}
                      onClickLastPage={() =>
                        jumpToPage(setCurrentPage, schoolPageCount)
                      }
                      total={Math.ceil(total / ITEMS_PER_PAGE)}
                    />
                  )}
                </>
              )}
            </TableWrapper>
          </TableContainer>
        ) : (
          <NoDataDisplay
            message={"No Skills yet"}
            image={children}
            className="student-profile__image"
            alt="Children playing"
          />
        )}
      </StyledPageWrapper>
    </>
  );
};

export default ApproveSkill;
