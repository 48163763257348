import React, { useContext, useState } from 'react';
import { ModalContext } from '../../../contexts/modalContext';
import { 
  isValidUniqueIdentifier,
  errors, 
} from '../../../utils/validation';
import { StudentsContext } from '../../../contexts/studentsContext';
import {
  AddStudentIDModalWrapper,
  ModalWrapper,
  StyledAvatar,
  StyledStudentInfo,
} from '../Modals.styles';
import { CustomInput } from '../../InputFields/CustomInput';
import { ModalCreateButtons } from '../ModalButtons';
import { decrypt } from '../../../utils/encryption';
import { LogInContext } from '../../../contexts/logInContext';
import { ChangeStudentID } from '../../../api';
import { OrganizationsContext } from '../../../contexts/organizationsContext';
import { success } from '../../../utils/toastify';

const ChangeStudentIDModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal } = useContext(ModalContext);
  const { token } = useContext(LogInContext);
  const { selectedStudent } = useContext(StudentsContext);
  const { organizationId } = useContext(OrganizationsContext);
  const [error, setError] = useState({});
  let [uid, setUid] = useState(!!selectedStudent.uniqueId ? decrypt(selectedStudent.uniqueId) : '');
  let [nid, setNid] = useState('');

  let inputStudentFields = {
    oldStudentID: {
      title: 'Old National ID',
      name: 'oldStudentID',
      type: 'text',
      value: uid,
    },
    newStudentID: {
      title: 'New National ID',
      name: 'newStudentID',
      type: 'text',
      value: nid,
    }
  };

  const handleChange = (e) => {
    let errorCopy = { ...error };

    setNid(e.target.value);

    const isValid = isValidUniqueIdentifier(e.target.value);
    isValid
      ? delete errorCopy.ID
      : (errorCopy.ID = errors.uniqueIdentifierErrorMessage);
      
    setError(errorCopy);
  };

  const handleSubmit = async () => {
    let errorCopy = { };

    if (nid === '') {
      errorCopy.ID = "New National ID required.";
      setError(errorCopy);
      return;
    }

    if (nid === uid) {
      errorCopy.ID = "Please entered New National ID must be unique.";
      setError(errorCopy);
      return;
    }

    let studentInfo = {
      groupId: selectedStudent.group.id,
      schoolId: selectedStudent.group.schoolId,
      newUniqueId: nid,
    };

    const responseData = await ChangeStudentID(organizationId, selectedStudent.id, studentInfo, token);
    
    if (responseData.status === 'success') {
      setShowModal(false);
      success("Your request to change the National ID has been sent to the Admin.");
    } else {
      if (responseData.data) {
        errorCopy.ID = responseData.data;
      } else {
        errorCopy.apiFail = errors.apiChangeStudentIDFail;
      }
    }
    setError(errorCopy);
  };

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <AddStudentIDModalWrapper>
            <StyledStudentInfo>
              <StyledAvatar>
                <div className="right-side-student-details">
                  <CustomInput
                    input={inputStudentFields.oldStudentID}
                    onChange={handleChange}
                    className="input--uniqueId"
                    disabled={true}
                  />
                  <CustomInput
                    input={inputStudentFields.newStudentID}
                    onChange={handleChange}
                    className="input--uniqueId"
                    error={error.ID}
                  />
                </div>
              </StyledAvatar>
            </StyledStudentInfo>
            <ModalCreateButtons
              text="Request for approval"
              error={error.apiFail}
              onClick={handleSubmit}
            />
          </AddStudentIDModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default ChangeStudentIDModal;