import React, { useContext } from 'react';
import { FeedbackContext } from '../../contexts/feedbackContext';
import { ModalContext } from '../../contexts/modalContext';
import { ActionButtonOrange } from '../Buttons/ActionButton';
import { FeedbackModalWrapper, ModalWrapper } from './Modals.styles';

const FeedbackModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal } = useContext(ModalContext);
  const { selectedFeedback } = useContext(FeedbackContext);
  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <FeedbackModalWrapper>
            <h2>Feedback</h2>
            <textarea value={selectedFeedback} readOnly />
            <ActionButtonOrange
              theme="primary"
              text="Done"
              onClick={() => setShowModal(false)}
            />
          </FeedbackModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default FeedbackModal;
