import React, { useState, useContext } from "react";
import { LessonsContext } from "../../../contexts/lessonsContext";
import { ModalContext } from "../../../contexts/modalContext";
import {
  isValidLink,
  isValidMaterial,
  errors,
  toProperCase
} from "../../../utils/validation";
import { CreateLesson, CreateSkill, EditMaterial,GetLessons } from "../../../api";
import { OrganizationsContext } from "../../../contexts/organizationsContext";
import { LogInContext } from "../../../contexts/logInContext";
import { GradesContext } from "../../../contexts/gradesContext";
import {
  EditMaterialWrapper,
  ModalWrapper,
  SelectGradeWrapper,
} from "../Modals.styles";
import { CheckboxWrapper } from "../../../Pages/Page.styles";
import { ModalCreateButtons } from "../ModalButtons";
import { CustomInput } from "../../InputFields/CustomInput";
import { materialInputFields } from "../../InputFields/InputFields";
import { editElement, handleOnSubmit } from "../../../utils/constants";
import CustomMultiSelect from "../../InputFields/CustomMultiSelect";
import CustomSelect from "../../InputFields/CustomSelect";
import CreatableSelect from "react-select/creatable";
import { ActionButtonGreen } from "../../Buttons/ActionButton";
import { warning } from "../../../utils/toastify";

const EditMaterialModal = ({ modalRef, closeModal }) => {
  const { organizationId } = useContext(OrganizationsContext);
  const { token } = useContext(LogInContext);
  const {
    selectedMaterial,
    materials,
    setMaterials,
    skillsOptions,
    lessonsOptions,
    setLessons
  } = useContext(LessonsContext);
  const { gradeOptions } = useContext(GradesContext);
  const { showModal, setShowModal } = useContext(ModalContext);
  const [editMaterial, setEditMaterial] = useState(selectedMaterial);
  const [skillsUpdated, setSkillsUpdated] = useState(false);
  const [addSkill, setAddSkill] = useState(false);
  const [skill, setSkill] = useState([]);
  let [lesson, setLesson] = useState("");
  const [addLesson, setAddLesson] = useState(false);

  let materialSkills = new Set(
    selectedMaterial.skills.map((skill) => skill.id)
  );

  let materialGrades = new Set(
    selectedMaterial.grades.map((grade) => grade.id)
  );

  const [error, setError] = useState({});
  const handleEditInput = (e) => {
    let errorCopy = { ...error };

    setEditMaterial({ ...editMaterial, [e.target.name]: e.target.value });

    if (e.target.name === "name") {
      const isValid = isValidMaterial(e.target.value);
      isValid
        ? delete errorCopy.title
        : (errorCopy.title = errors.titleErrorMessage);
    } else if (e.target.name === "details") {
      const isValid = isValidLink(e.target.value);
      isValid
        ? delete errorCopy.link
        : (errorCopy.link = errors.linkErrorMessage);
    } else if (e.target.name === "new-lesson") {
      if (editMaterial.lessonTopic) {
        delete editMaterial.lessonTopic;
        delete editMaterial.lessonTopicId;
      };
      setLesson(e.target.value);
    }
    setError(errorCopy);
  };

  async function  getlesson() {
    const responseData = await GetLessons(organizationId,token);
    if (responseData.status === 'success') {
      setLessons(
        responseData.data.lessonTopics.map((lesson) => ({
          ...lesson,
          name: toProperCase(lesson.name),
        }))
      );
    } 
  }

  const ids = gradeOptions
    .filter((elem) => materialGrades.has(elem.id))
    .map((check) => check.id);

  const checkIfChecked = (idList) => {
    const checked = ids.filter((id) => id == idList);
    return checked.length;
  };

  const handleSelectGrade = (e) => {
    let errorCopy = { ...error };

    const newOption = gradeOptions.find(
      (grade) => grade.id === parseInt(e.target.id)
    );

    if (e.target.checked && newOption) {
      setEditMaterial({
        ...editMaterial,
        grades: [...editMaterial.grades, newOption],
      });
    } else {
      setEditMaterial({
        ...editMaterial,
        grades: editMaterial.grades.filter((grade) => grade.id != e.target.id),
      });
    }

    delete errorCopy.response;
    delete errorCopy.grade;
    setError(errorCopy);
  };

  const handleSelectSkills = (e) => {
    delete error.apiFail;
    delete error.skillIds;
    const inputMaterialSkills = new Set(e.map((skill) => skill.id));

    [...materialSkills]
      .filter((skill) => !inputMaterialSkills.has(skill))
      .forEach((elem) => {
        materialSkills.add({ id: elem, toDelete: true });
        materialSkills.delete(elem);
      });
    [...inputMaterialSkills]
      .filter((skill) => !materialSkills.has(skill))
      .forEach((elem) => materialSkills.add({ id: elem, isNew: true }));

    setEditMaterial({ ...editMaterial, skills: [...materialSkills] });
    setSkillsUpdated(true);
  };

  const handleSelectLessons = (e) => {
    setEditMaterial({ ...editMaterial, lessonTopicId: e.id });
    if(!!lesson) setLesson("");
    delete error.response;
    delete error.lessonTopicId;
  };

  const handleSubmitValidations = () => {
    let errorCopy = { ...error };
    delete errorCopy.apiFail;

    !editMaterial.name && (errorCopy.title = errors.titleErrorMessage);
    !editMaterial.details && (errorCopy.link = errors.linkRequiredMessage);
    !isValidLink(editMaterial.details) &&
    (errorCopy.link = errors.linkErrorMessage);
    !editMaterial.grades && (errorCopy.grade = errors.gradeRequiredMessage);
    if(!editMaterial.new-lesson){
      !editMaterial.lessonTopicId && (errorCopy.lessonTopicId = errors.lessonTopicIdMessage)
    } else {
      delete errorCopy.lessonTopicId;
    }
    editMaterial.skills.length === 0 && (errorCopy.skillIds = errors.skillIdsMessage)

    if (Object.keys(errorCopy).length > 0) {
      warning("Required field/s must be filled");
    }

    setError(errorCopy);
    return Object.keys(errorCopy).length === 0;
  };

  const handleEditSubmit = async () => {
    const isValid = handleSubmitValidations();

    if (!isValid) {
      return false;
    }

    let skillId = [];
    skillId = editMaterial.skills;

    let arr = [];
    if (skill.length > 0) {
      for (let i = 0; i < skill.length; i++) {
        const responseData = await CreateSkill({name: skill[i].label}, token);
        if (responseData.status === 'success') {
          arr.push({id: responseData.data.id, isNew: true});
        } 
        if (responseData.code === 100) {
          setError({
            apiFail: responseData.data
          });
          return;
        }
      }
    }

    let lessonId;
    if (!!lesson) {
      const responseData = await CreateLesson({name: lesson}, token);
      if (responseData.status === 'success') {
        lessonId = responseData.data.id;
      } 
      if (responseData.code === 100) {
        setError({
          apiFail: responseData.data
        });
        return;
      }
    }

    let newSkillId = [];
    newSkillId = arr.concat(skillId);
    editMaterial.skills = newSkillId;
    editMaterial.lessonTopicId = !!lessonId ? lessonId : editMaterial.lessonTopicId;

    const submitEditMaterial = {
      name: editMaterial.name,
      grades: editMaterial.grades.map((grade) => grade.id),
      details: editMaterial.details,
      organizationId: organizationId,
      skills: editMaterial.skills,
      lessonTopicId: editMaterial.lessonTopicId,
    };

    const responseData = await EditMaterial(
      editMaterial.id,
      submitEditMaterial,
      token
    );

    if (responseData.status === "success") {
      let editMaterialCopy = { ...editMaterial };
      editMaterialCopy.skills = editMaterial.skills
        .map((skill) => {
          if (skill.isNew) {
            return { id: skill.id };
          }
          if (skill.toDelete) {
            return null;
          }

          return { id: skill };
        })
        .filter((skill) => skill !== null);
      let keys = [];
      skillsUpdated
        ? (keys = ["name", "grades", "details", "skills"])
        : (keys = ["name", "grades", "details"]);
      editElement(
        editMaterial.id,
        materials,
        setMaterials,
        keys,
        editMaterialCopy
      );
      setShowModal(false);
      setSkillsUpdated(false);
      getlesson();
    } else {
      setError({
        apiFail: errors.apiFail,
      });
    }
  };

  materialInputFields.material.value = editMaterial.name;
  materialInputFields.link.value = editMaterial.details;

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <EditMaterialWrapper>
            <CustomInput
              input={materialInputFields.material}
              onChange={handleEditInput}
              className="input--title"
              onSubmit={(e) => handleOnSubmit(e, handleEditSubmit)}
              error={error.title}
            />
            <CustomInput
              input={materialInputFields.link}
              onChange={handleEditInput}
              className="input--link"
              onSubmit={(e) => handleOnSubmit(e, handleEditSubmit)}
              error={error.link}
            />
            <h4 className="grade--title">Grade <span style={{color: "red"}}>*</span></h4>
            <CheckboxWrapper>
              {gradeOptions.map((grade) => (
                <div key={grade.id}>
                  <input
                    onChange={handleSelectGrade}
                    id={grade.id}
                    defaultChecked={checkIfChecked(grade.id) > 0 ? true : false}
                    type="checkbox"
                    value={grade.name}
                  />
                  <label htmlFor="grade">{grade.name}</label>
                </div>
              ))}
            </CheckboxWrapper>
            {<p style={{ color: "red", fontSize: "0.8rem", fontWeight: "400", paddingTop: '0.3rem'}}>{error.grade}</p>}
            <SelectGradeWrapper>
              <div style={{display: "flex"}}>
                <h4 className="input--skills">Lesson Topics <span style={{color: "red"}}>*</span></h4>
                <ActionButtonGreen
                  text="Add"
                  size="small"
                  onClick={() => {
                    setAddLesson(true);
                  }}
                  style={{width: "auto", height: "max-content", marginTop: "1.5rem"}}
                />
              </div>
              <CustomSelect
                options={lessonsOptions}
                defaultValue={lessonsOptions.find(
                  (elem) => elem.id === selectedMaterial?.lessonTopicId
                )}
                placeholder="Select Topics"
                onChange={handleSelectLessons}
                className="input--skills input--country"
                isDisabled={addLesson}
              />
              {<p style={{ color: "red", fontSize: "0.8rem", fontWeight: "400", paddingTop: '0.3rem'}} >{error.lessonTopicId}</p>}
            </SelectGradeWrapper>
            {addLesson && (
                <CustomInput
                  input={materialInputFields.lesson}
                  onChange={(e) => {delete error.lessonTopicId; handleEditInput(e);}}
                  error={error.lession}
                  onSubmit={(e) => {handleOnSubmit(e, handleEditSubmit);}}
                  className="input--edit-lesson"
                />
            )}
            <SelectGradeWrapper>
              <div style={{display: "flex"}}>
                <h4 className="input--skills">Skills <span style={{color: "red"}}>*</span></h4>
                <ActionButtonGreen
                  text="Add"
                  size="small"
                  onClick={() => {
                    setAddSkill(true);
                  }}
                  style={{width: "auto", height: "max-content", marginTop: "1.5rem"}}
                />
              </div>
              <CustomMultiSelect
                options={skillsOptions}
                defaultValue={skillsOptions.filter((elem) =>
                  materialSkills.has(elem.id)
                )}
                placeholder="Select Skills"
                onChange={handleSelectSkills}
                className="input--skills input--country"
                menuPlacement={"top"}
              />
              {<p style={{ color: "red", fontSize: "0.8rem", fontWeight: "400", paddingTop: '0.3rem'}}>{error.skillIds}</p>}
            </SelectGradeWrapper>
            {addSkill && (
              <SelectGradeWrapper>
                <h4
                  className="input--skills"
                  style={{ marginBottom: "0.5rem" }}
                >
                  Add New Skills
                </h4>
                <CreatableSelect
                  isMulti
                  name="skills"
                  onChange={(e) => {setSkill(e); delete error.skillIds;}}
                  value={skill}
                  placeholder="Add New Skills"
                  className="select--skills"
                />
              </SelectGradeWrapper>
            )}
            <ModalCreateButtons
              text="Save Changes"
              error={error.apiFail}
              className="modal__buttons--edit-material"
              onClick={handleEditSubmit}
            />
          </EditMaterialWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default EditMaterialModal;
