import CryptoJS from 'crypto-js';
const cyptoEncryptionKey = process.env.REACT_APP_CRYPTO_ENCRYPTION_KEY;

export const encrypt = (text) => {
    let keyutf = CryptoJS.enc.Utf8.parse(cyptoEncryptionKey);
    let iv = CryptoJS.enc.Base64.parse(cyptoEncryptionKey);
    let enc = CryptoJS.AES.encrypt(text, keyutf, { iv: iv });
    return enc.toString();
}

export const decrypt = (text) => {
    let keyutf = CryptoJS.enc.Utf8.parse(cyptoEncryptionKey);
    let iv = CryptoJS.enc.Base64.parse(cyptoEncryptionKey);
    let dec = CryptoJS.AES.decrypt({ ciphertext: CryptoJS.enc.Base64.parse(text) }, keyutf, {iv: iv});
    let decStr = CryptoJS.enc.Utf8.stringify(dec);
    return decStr;
}