import React, { useContext } from 'react';
import { ModalContext } from '../../contexts/modalContext';
import {
  ActionButtonGreen,
  ActionButtonOrange,
  ActionButtonOrangeSecondary,
} from '../Buttons/ActionButton';
import {
  ModalButtonsWrapper,
  ModalRemoveButtonsWrapper,
} from './Modals.styles';

export const ModalCreateButtons = ({
  text,
  onClick,
  error,
  className,
  setState,
  color = null,
  disabled = false
}) => {
  const { setShowModal } = useContext(ModalContext);
  return (
    <div className={`modal__buttons--create ${className}`}>
      {error && <h5>{error}</h5>}
      <ModalButtonsWrapper>
        {!color ? (
          <ActionButtonGreen theme="primary" text={text} onClick={onClick} disabled={disabled} />
        ) : (
          <ActionButtonOrange theme="primary" text={text} onClick={onClick} />
        )}

        <ActionButtonOrangeSecondary
          theme="secondary"
          text="Cancel"
          onClick={() => {
            if (setState) {
              setState.editMode && setState.editMode(false);
              setState.editInfo && setState.editInfo({});
              setState.showModal && setState.showModal(true);
              setState.setModalType && setState.setModalType(setState?.modalType);
            } else {
              setShowModal(false);
            }
          }}
        />
      </ModalButtonsWrapper>
    </div>
  );
};

export const ModalRemoveButtons = ({ onClick, error, text = 'Delete' }) => {
  const { setShowModal } = useContext(ModalContext);
  return (
    <div className="modal__buttons--remove">
      {error && <h5>{error}</h5>}
      <ModalRemoveButtonsWrapper>
        <ActionButtonOrange theme="primary" text={text} onClick={onClick} />
        <ActionButtonOrangeSecondary
          theme="secondary"
          text="Cancel"
          onClick={() => setShowModal(false)}
        />
      </ModalRemoveButtonsWrapper>
    </div>
  );
};
