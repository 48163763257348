import React, { useEffect, useState, useContext } from "react";
import jwtDecode from "jwt-decode";
import { SocialLogInSubmit } from "../../api";
import { LogInContext } from "../../contexts/logInContext";
import { MenuContext } from "../../contexts/menuContext";
import { warning } from "../../utils/toastify";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

const SocialLogin = ({ loginhandler }) => {
  const { setToken, setIsLogged, setRole } = useContext(LogInContext);
  const { setMenuDisplay } = useContext(MenuContext);
  const [user, setUser] = useState();

  const handleCheckUser = (res) => {
    setToken(res.token);
    localStorage.setItem("selectedOrg", res.organizationName);
    localStorage.setItem("name", res.userName);
    localStorage.setItem("email", res.userEmail);
    localStorage.setItem("org", res.organizationName);
    setRole(res?.role);
    setMenuDisplay(true);
    setIsLogged(true);
    res.role !== "ADMIN" &&
      localStorage.setItem("organizationId", res.organizationId);
    loginhandler();
  };

  const handleSocialLogIn = async () => {
    const responseData = await SocialLogInSubmit({ email: user });

    if (responseData.status === "success") {
      handleCheckUser(responseData.data);
    }
    if (responseData.code === 101) {
      warning(responseData.data);
    }
    if (responseData.code === 103) {
      warning(responseData.data);
    }
    if (responseData.code === 104) {
      warning(responseData.data);
    }
    if (responseData.code === 105) {
      warning(responseData.data);
    }
    if (responseData.code === 100) {
      warning(responseData.data);
    }
  };

  useEffect(() => {
    user && handleSocialLogIn();
  }, [user]);

  const handleCallbackResponse = (res) => {
    const response = jwtDecode(res.credential);
    setUser(response?.email);
  };

  return (
    <>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <GoogleLogin onSuccess={handleCallbackResponse} />
      </GoogleOAuthProvider>
    </>
  );
};

export default SocialLogin;
