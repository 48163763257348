import React from 'react';
import {
  StyledPaginationButtons,
  StyledPreviousPageButton,
} from './Buttons.styles';

export const FirstPageButton = ({ disabled, onClick }) => {
  return (
    <StyledPreviousPageButton disabled={disabled} onClick={onClick}>
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.2049 14.5229C13.6023 14.5251 13.9852 14.3743 14.2924 14.0856C14.8917 13.4928 14.897 12.5146 14.3042 11.9154L9.69858 7.24409L14.3699 2.62318C14.9692 2.03037 14.9745 1.05223 14.3817 0.45295C13.7889 -0.146335 12.8108 -0.151644 12.2115 0.44117L6.44913 6.1413C6.15717 6.43011 6.00226 6.81136 6.00002 7.22401C5.99778 7.63666 6.16382 8.01966 6.43735 8.31153L12.1222 14.0738C12.4263 14.3658 12.8228 14.5208 13.2049 14.5229Z"
          fill={disabled ? '#A6D1FB' : 'hsl(241, 74%, 15%)'}
        />
        <path
          d="M7.20491 14.5229C7.60227 14.5251 7.98519 14.3743 8.29243 14.0856C8.89171 13.4928 8.89702 12.5146 8.30421 11.9154L3.69858 7.24409L8.36993 2.62318C8.96922 2.03037 8.97453 1.05223 8.38171 0.45295C7.7889 -0.146335 6.81077 -0.151644 6.21148 0.44117L0.449128 6.1413C0.157169 6.43011 0.00226231 6.81136 2.23791e-05 7.22401C-0.00221755 7.63666 0.163824 8.01966 0.437347 8.31153L6.1222 14.0738C6.42629 14.3658 6.82282 14.5208 7.20491 14.5229Z"
          fill={disabled ? '#A6D1FB' : 'hsl(241, 74%, 15%)'}
        />
      </svg>
    </StyledPreviousPageButton>
  );
};

export const PreviousPageButton = ({ disabled, onClick }) => {
  return (
    <StyledPreviousPageButton disabled={disabled} onClick={onClick}>
      <svg
        width="9"
        height="15"
        viewBox="0 0 9 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.26358 0.019048C7.66095 0.0200992 8.04264 0.173945 8.34754 0.46514C8.94202 1.06277 8.93943 2.04092 8.3418 2.6354L3.69861 7.26932L8.3325 11.9278C8.92698 12.5254 8.92439 13.5036 8.32676 14.0981C7.72912 14.6925 6.75098 14.6899 6.1565 14.0923L0.440355 8.34584C0.150737 8.05469 -0.00108583 7.67219 5.84592e-06 7.25954C0.00109752 6.84689 0.170225 6.46524 0.446096 6.17558L6.17728 0.459398C6.48372 0.169821 6.8815 0.0180372 7.26358 0.019048Z"
          fill={disabled ? '#A6D1FB' : 'hsl(241, 74%, 15%)'}
        />
      </svg>
    </StyledPreviousPageButton>
  );
};

export const NextPageButton = ({ disabled, onClick }) => {
  return (
    <StyledPreviousPageButton disabled={disabled} onClick={onClick}>
      <svg
        width="9"
        height="15"
        viewBox="0 0 9 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.52816 0.019048C1.13079 0.0200992 0.749112 0.173945 0.44421 0.46514C-0.150269 1.06277 -0.147682 2.04092 0.449951 2.6354L5.09314 7.26932L0.45925 11.9278C-0.135229 12.5254 -0.13264 13.5036 0.464993 14.0981C1.06263 14.6925 2.04077 14.6899 2.63525 14.0923L8.35139 8.34584C8.64101 8.05469 8.79283 7.67219 8.79174 7.25954C8.79065 6.84689 8.62152 6.46524 8.34565 6.17558L2.61446 0.459398C2.30803 0.169821 1.91025 0.0180372 1.52816 0.019048Z"
          fill={disabled ? '#A6D1FB' : 'hsl(241, 74%, 15%)'}
        />
      </svg>
    </StyledPreviousPageButton>
  );
};

export const LastPageButton = ({ disabled, onClick }) => {
  return (
    <StyledPreviousPageButton disabled={disabled} onClick={onClick}>
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.62628 14.5625C1.22891 14.5646 0.845997 14.4139 0.538754 14.1251C-0.0605302 13.5323 -0.0658397 12.5542 0.526974 11.9549L5.1326 7.28364L0.461251 2.66273C-0.138034 2.06992 -0.143343 1.09179 0.449471 0.492501C1.04228 -0.106784 2.02042 -0.112093 2.6197 0.480721L8.38205 6.18085C8.67401 6.46966 8.82892 6.85091 8.83116 7.26356C8.8334 7.67621 8.66736 8.05921 8.39384 8.35108L2.70899 14.1134C2.4049 14.4054 2.00836 14.5604 1.62628 14.5625Z"
          fill={disabled ? '#A6D1FB' : 'hsl(241, 74%, 15%)'}
        />
        <path
          d="M7.62628 14.5625C7.22891 14.5646 6.846 14.4139 6.53875 14.1251C5.93947 13.5323 5.93416 12.5542 6.52697 11.9549L11.1326 7.28364L6.46125 2.66273C5.86197 2.06992 5.85666 1.09179 6.44947 0.492501C7.04228 -0.106784 8.02042 -0.112093 8.6197 0.480721L14.3821 6.18085C14.674 6.46966 14.8289 6.85091 14.8312 7.26356C14.8334 7.67621 14.6674 8.05921 14.3938 8.35108L8.70899 14.1134C8.4049 14.4054 8.00836 14.5604 7.62628 14.5625Z"
          fill={disabled ? '#A6D1FB' : 'hsl(241, 74%, 15%)'}
        />
      </svg>
    </StyledPreviousPageButton>
  );
};

export const Pagination = ({
  currentPage,
  disabledPrev,
  disabledNext,
  onClickPrev,
  onClickNext,
  onClickFirstPage,
  onClickLastPage,
  total,
}) => {
  return (
    <StyledPaginationButtons>
      <FirstPageButton disabled={disabledPrev} onClick={onClickFirstPage} />
      <PreviousPageButton disabled={disabledPrev} onClick={onClickPrev} />
      <p>{currentPage} &nbsp; of &nbsp; {total}</p>
      <NextPageButton disabled={disabledNext} onClick={onClickNext} />
      <LastPageButton disabled={disabledNext} onClick={onClickLastPage} />
    </StyledPaginationButtons>
  );
};