const { createGlobalStyle } = require('styled-components');

export const GlobalStyle = createGlobalStyle`

    :root{
      --blue-300: hsl(241, 74%, 15%);
      --blue-250: hsl(241, 74%, 15%, 0.7);
      --blue-200: hsl(210, 91%, 82%);
      --blue-150: hsl(210, 91%, 82%, 0.49);
      --blue-100: hsl(209, 86%, 94%);
      --orange-200:  hsl(25, 100%, 63%);
      --orange-150: hsl(26, 100%, 82%);
      --green-200: hsl(138, 89%, 79%);
      --green-150: hsl(138, 89%, 85%);
      --grey-350: hsl(0, 0%, 60%);
      --grey-300: hsl(220, 7%, 92%);
      --grey-250: hsl(0, 0%, 90%, 0.65);
      --grey-200: hsl(220, 23%, 97%);
      --grey-150: hsl(0, 0%, 94%);

      --shadow-300: 2px 5px 4px 0 hsl(0, 0%, 0%, 0.25);
      --shadow-250: 0 4px 4px 0 hsl(0, 0%, 0%, 0.25);

      --border-radius: 25px;
    }

    html {
        font-size: 16px;
        height: 100vh;
        color: var(--blue-300);
    }

    body {
        overscroll-behavior: none;
    }

    * , *::after, *::before {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: 'Montserrat', sans-serif;
    } 

    h1 {
        font-size: 1.6rem;
        font-weight: 700;
    }

    h2 {
        font-size: 1.5rem;
        font-weight: 600;

    }

    h3 {
        font-size: 1.4rem;
        font-weight: 500;
    }

    h4 {
        font-size: 1.2rem;
        font-weight: 500;
    }


    //for error messages
    h5 {
        font-size: .8rem;
        font-weight: 400;
        color: red;
    }

    p {
        font-size: 1rem;
        font-weight: 400;
    }

    button{
        cursor: pointer;
    }

    div.app__spinner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`;
