import React, { useContext, useEffect, useState } from 'react';
import { LinkStudentAssessment } from '../../api';
import { LogInContext } from '../../contexts/logInContext';
import { ModalContext } from '../../contexts/modalContext';
import { OrganizationsContext } from '../../contexts/organizationsContext';
import { StudentsContext } from '../../contexts/studentsContext';
import { StyledInput } from '../../Pages/Page.styles';
import { editElement } from '../../utils/constants';
import { isValidLink, errors } from '../../utils/validation';
import { ActionButtonOrangeSecondary } from '../Buttons/ActionButton';
import { ModalCreateButtons } from './ModalButtons';
import {
  AssessmentModalWrapper,
  ModalWrapper,
  QWrappers,
  TopSectionWrapper,
} from './Modals.styles';
import { warning } from '../../utils/toastify';

const AssessmentModal = ({ modalRef, closeModal }) => {
  const { showModal } = useContext(ModalContext);
  const { students, setStudents, assessments, selectedStudent, studentId } =
    useContext(StudentsContext);
  const { organizationId } = useContext(OrganizationsContext);
  const { token } = useContext(LogInContext);
  const [assessmentInfo, setAssessmentInfo] = useState({
    studentId: studentId,
  });
  const [error, setError] = useState({});
  const [displayAssessments, setDisplayAssessments] = useState([]);
  const [assessmentsCheck, setAssessmentsCheck] = useState([]);

  useEffect(() => {
    let assessmentOptions = selectedStudent.assessments;

    assessmentOptions.sort((a, b) => a.id - b.id);
    setDisplayAssessments(assessmentOptions);
    setAssessmentsCheck(selectedStudent.assessments);
  }, [selectedStudent, assessments]);

  const handleAssessmentInputChange = (
    e,
    assessmentName,
    assessmentId,
    details
  ) => {
    const errorCopy = { ...error };
    const isValid = isValidLink(e.target.value);

    !isValid
      ? (errorCopy[assessmentId] = errors.linkErrorMessage)
      : delete errorCopy[assessmentId];

    e.target.value === '' && delete errorCopy[assessmentId];

    setError(errorCopy);
    let updatedAssessmentsCheck = assessmentsCheck.map((a) =>
      a.id === assessmentId
        ? {
            link: e.target.value,
            name: assessmentName,
            studentId: studentId,
            details: details,
            id: assessmentId,
          }
        : a
    );

    setError(errorCopy);
    setAssessmentsCheck(updatedAssessmentsCheck);
    setAssessmentInfo({
      ...assessmentInfo,
      link: e.target.value,
      id: assessmentId,
      name: assessmentName,
      details: details,
    });
  };

  const handleSubmitValidations = (link, id) => {
    let errorCopy = { ...error };

    !isValidLink(link)
      ? (errorCopy[id] = errors.linkErrorMessage)
      : delete errorCopy.apiFail;
    setError(errorCopy);

    if (Object.keys(errorCopy).length > 0) {
      warning("Required field/s must be filled");
    }

    return Object.keys(errorCopy).length === 0;
  };

  const handleSubmitAssessmentChanges = async () => {
    const assessmentsToUpdate = assessmentsCheck.filter(
      (a, index) => assessments[index].link !== a.link
    );
    let displayAssessmentsCopy = [...displayAssessments];
    for (let i = 0; i < assessmentsToUpdate.length; i++) {
      const isValid = handleSubmitValidations(
        assessmentsToUpdate[i].link,
        assessmentsToUpdate[i].id
      );
      if (!isValid) {
        setError({ ...error, apiFail: errors.allLinksErrorMessage });
        return false;
      }

      const responseData = await LinkStudentAssessment(
        assessmentsToUpdate[i],
        organizationId,
        token
      );

      if (responseData.status === 'success') {
        const updateId = assessmentsToUpdate[i].id;
        const displayIndex = displayAssessmentsCopy.findIndex(
          (elem) => elem.id === updateId
        );
        displayAssessmentsCopy[displayIndex] = assessmentsToUpdate[i];
      } else {
        setError({ apiFail: errors.apiFail });
      }
    }
    editElement(selectedStudent.id, students, setStudents, ['assessments'], {
      assessments: displayAssessmentsCopy,
    });
    setDisplayAssessments(displayAssessmentsCopy);
  };

  const handleRemoveAssessment = async (
    e,
    assessmentName,
    assessmentId,
    details
  ) => {
    const assessmentInfoCopy = {
      ...assessmentInfo,
      link: '',
      id: assessmentId,
      name: assessmentName,
      details: details,
    };
    let displayAssessmentsCopy = [...displayAssessments];
    let assessmentCheckCopy = [...assessmentsCheck];
    const responseData = await LinkStudentAssessment(
      assessmentInfoCopy,
      organizationId,
      token
    );

    if (responseData.status === 'success') {
      const displayIndex = displayAssessmentsCopy.findIndex(
        (elem) => elem.id === assessmentInfoCopy.id
      );
      displayAssessmentsCopy[displayIndex] = assessmentInfoCopy;
      setDisplayAssessments(displayAssessmentsCopy);

      const assessmentCheckIndex = assessmentsCheck.findIndex(
        (elem) => elem.id === assessmentId
      );
      assessmentCheckCopy[assessmentCheckIndex] = assessments.find(
        (e) => e.id === assessmentId
      );
      setAssessmentsCheck(assessmentCheckCopy);

      editElement(selectedStudent.id, students, setStudents, ['assessments'], {
        assessments: displayAssessmentsCopy,
      });
    } else {
      setError({ apiFail: errors.apiFail });
    }
  };

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <AssessmentModalWrapper>
            <TopSectionWrapper>
              <h2 style={{ marginBottom: '1rem' }}>
                {selectedStudent.name}'s Assessments{' '}
              </h2>

              {displayAssessments.map((assessment, index) => (
                <QWrappers key={index}>
                  <div>
                    <h3> {assessment.name}: </h3>
                    {assessment.link ? (
                      <div>
                        <a
                          href={assessment.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {' '}
                          {assessment.link}
                        </a>
                        <ActionButtonOrangeSecondary
                          text="Remove"
                          size="small"
                          onClick={(e) =>
                            handleRemoveAssessment(
                              e,
                              assessment.name,
                              assessment.id,
                              assessment.details
                            )
                          }
                        />
                      </div>
                    ) : (
                      <>
                        <StyledInput
                          placeholder={assessment.details}
                          type="text"
                          name="link"
                          onChange={(e) =>
                            handleAssessmentInputChange(
                              e,
                              assessment.name,
                              assessment.id,
                              assessment.details
                            )
                          }
                        />
                        {error[assessment.id] && (
                          <h5 className="error">{error[assessment.id]}</h5>
                        )}
                      </>
                    )}
                  </div>
                </QWrappers>
              ))}
            </TopSectionWrapper>
            <ModalCreateButtons
              text="Add Assessments"
              onClick={handleSubmitAssessmentChanges}
              className="modal__buttons--assessment"
              error={error.apiFail}
            />
          </AssessmentModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default AssessmentModal;
