import React, { useContext } from "react";
import { ModalContext } from "../../../contexts/modalContext";
import { ModalWrapper, ViewSchoolWrapper } from "../Modals.styles";
import {
  ActionButtonGreen,
  ActionButtonOrange,
  ActionButtonOrangeSecondary,
} from "../../Buttons/ActionButton";
import { useState } from "react";
import { CreateOrgSchool, GetAssignOrg, GetOrg, RemoveOrg } from "../../../api";
import { jumpToPage } from "../../../utils/constants";
import { LogInContext } from "../../../contexts/logInContext";
import { useEffect } from "react";
import { Pagination } from "../../../components/Buttons/PaginationButtons";
import { TableContainer, Toolbar } from "@mui/material";
import { TableWrapper } from "../../Tables/Table.styles";
import ClipSpinner from "../../Loaders/ClipSpinner";
import { SchoolsContext } from "../../../contexts/schoolsContext";
import { CustomSearch } from "../../InputFields/CustomInput";
import { StyledFilterButtons } from "../../../Pages/Page.styles";
import { success } from "../../../utils/toastify";
import NoDataDisplay from "../../Displays/NoDataDisplay";
import { OrganizationsContext } from "../../../contexts/organizationsContext";

const AssignSchoolToOrgModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal } = useContext(ModalContext);
  const { schoolId } = useContext(SchoolsContext);
  const {organizationId} = useContext(OrganizationsContext);
  const [displayOrgs, setDisplayOrgs] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const { token } = useContext(LogInContext);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 5;
  const offset = (currentPage - 1) * ITEMS_PER_PAGE;
  const [schoolPageCount, setSchoolPageCount] = useState(1);
  const [total, setTotal] = useState(0);
  let [displaySearchValue, setDisplaySearchValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const searchSetters = {
    displaySetter: setDisplaySearchValue,
    valueSetter: setSearchValue,
  };
  let [orgIds, setOrgIds] = useState([]);
  let [status, setStatus] = useState(false);

  const canNextPage = (displayItemsLength, itemsLength, currentPage) => {
    return (
      displayItemsLength !== ITEMS_PER_PAGE ||
      itemsLength / ITEMS_PER_PAGE === currentPage
    );
  };

  let [loading, setLoading] = useState(true);

  const columns = [
    {
      Header: "Organization",
    },
    {
      Header: "Owner First Name",
    },
    {
      Header: "Owner Last Name",
    },
    {
      Header: "Organization Owner Email",
    },
    {
      Header: "",
    },
  ];

  const fetchOrg = async () => {
    const responseData = await GetAssignOrg(token, schoolId);

    if (responseData.status === "success") {
      setOrgIds(
        responseData.data.organization.map((item) => item.organizationId)
      );
    }
  };

  useEffect(() => {
    fetchOrg();
  }, [token]);

  async function fetchOrganizations() {
    const responseData = await GetOrg(
      token,
      ITEMS_PER_PAGE,
      offset,
      schoolId,
      organizationId,
      displaySearchValue,
      status
    );

    if (responseData.status === "success") {
      setOrgData(responseData.data.data.organization);
      setLoading(false);

      if (
        responseData.data.data.totalRecords ||
        responseData.data.data.totalRecords === 0
      ) {
        setTotal(responseData.data.data.totalRecords);
        setSchoolPageCount(
          Math.ceil(responseData.data.data.totalRecords / ITEMS_PER_PAGE)
        );
      }
      if (responseData.data.data.organization.length === 0) {
        setCurrentPage(1);
      }
      setDisplayOrgs(responseData.data.data.organization);
    } else {
      setLoading(false);
      setOrgData([]);
    }
  }

  useEffect(() => {
    fetchOrganizations();
  }, [token, setOrgData, setDisplayOrgs, setSchoolPageCount, currentPage]);

  const handleClick = (step) => {
    setCurrentPage((prevState) => prevState + step);
  };

  const handleAssignSchoolModal = async (orgId) => {
    const responseData = await CreateOrgSchool(orgId, schoolId, token);
    if (responseData.status === "success") {
      fetchOrganizations();
      fetchOrg();
      success("School assign to organization successfully");
    }
  };

  const handleUnssignSchoolModal = async (id) => {
    let payload = {
      schoolId: schoolId,
      organizationId: id,
    };

    const res = await RemoveOrg(payload, token);

    if (res.status === "success") {
      fetchOrganizations();
      fetchOrg();
      success("School Unassign to organization successfully.");
    }
  };

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <ViewSchoolWrapper style={{ height: "auto", padding: "2em" }}>
            <h1 style={{ marginBottom: "0.5rem" }}>
              Assign School To Organization
            </h1>
            <Toolbar className="custom-toolbar">
              <div
                className="custom-search"
                style={{ maxWidth: "none", flex: "auto" }}
              >
                <CustomSearch
                  value={displaySearchValue}
                  setSearchStates={searchSetters}
                  placeholder={"Search by name"}
                  onChange={(e) => setDisplaySearchValue(e.target.value)}
                />
              </div>
              <div className="reset-filter">
                <StyledFilterButtons>
                  <div
                    className="metrics__button-filters"
                    style={{ width: "max-content" }}
                  >
                    <ActionButtonOrange
                      theme="primary"
                      text="Filter"
                      onClick={fetchOrganizations}
                    />
                    <ActionButtonOrangeSecondary
                      text="Reset Filters"
                      disabled={!!displaySearchValue ? false : true}
                      onClick={() => {
                        displaySearchValue = "";
                        setDisplaySearchValue(displaySearchValue);
                        fetchOrganizations();
                      }}
                    />
                  </div>
                </StyledFilterButtons>
              </div>
              <div className="filter-btn">
                <ActionButtonOrangeSecondary
                  text={
                    !status
                      ? "Show All Organizations"
                      : "Hide No Assign Organizations"
                  }
                  onClick={() => {
                    status = !status;
                    setStatus(status);
                    fetchOrganizations();
                  }}
                />
              </div>
            </Toolbar>
            {loading && (
              <TableContainer className="custom_table_container">
                <TableWrapper>
                  <div className="loading-table">
                    <ClipSpinner />
                  </div>
                </TableWrapper>
              </TableContainer>
            )}
            {!loading && orgData.length !== 0 && (
              <TableContainer>
                <TableWrapper>
                  <>
                    <table style={{ textAlign: "start" }}>
                      <thead>
                        <tr style={{ textAlign: "justify" }}>
                          {columns.map((item, index) => (
                            <th
                              style={{ width: "150px", paddingRight: "1em" }}
                              key={index}
                            >
                              {item?.Header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {orgData.map((org, index) => (
                          <tr key={index}>
                            <td style={{ width: "150px", paddingRight: "1em" }}>
                              {org?.name}
                            </td>
                            <td style={{ width: "150px", paddingRight: "1em" }}>
                              {org?.owner}
                            </td>
                            <td style={{ width: "150px", paddingRight: "1em" }}>
                              {org?.ownerLastName}
                            </td>
                            <td
                              style={{
                                justifyContent: "flex-start",
                                width: "150px",
                                paddingRight: "1em",
                              }}
                            >
                              {org?.email}
                            </td>
                            <td>
                              {orgIds.includes(org?.id) && (
                                <ActionButtonOrange
                                  style={{ width: "auto", cursor: "default" }}
                                  theme={"primary"}
                                  text="Unassign"
                                  size="small"
                                  onClick={() => {
                                    handleUnssignSchoolModal(org?.id);
                                  }}
                                />
                              )}
                              {!orgIds.includes(org?.id) && (
                                <ActionButtonGreen 
                                  style={{
                                    width: "auto",
                                    padding: "0.1em 1.5em"
                                  }}
                                  text="Assign"
                                  onClick={() => {
                                    handleAssignSchoolModal(org?.id);
                                  }}
                                />
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {Math.ceil(total / ITEMS_PER_PAGE) > 0 && (
                      <Pagination
                        currentPage={currentPage}
                        disabledPrev={currentPage === 1}
                        disabledNext={canNextPage(
                          displayOrgs.length,
                          total,
                          currentPage
                        )}
                        onClickPrev={() => handleClick(-1)}
                        onClickNext={() => handleClick(+1)}
                        onClickFirstPage={() => jumpToPage(setCurrentPage)}
                        onClickLastPage={() =>
                          jumpToPage(setCurrentPage, schoolPageCount)
                        }
                        total={Math.ceil(total / ITEMS_PER_PAGE)}
                      />
                    )}
                  </>
                </TableWrapper>
              </TableContainer>
            )}
            {!loading && orgData.length === 0 && (
              <NoDataDisplay message={"No organizations yet"} />
            )}
            <div style={{ marginTop: "10px", display: "flex" }}>
              <ActionButtonOrangeSecondary
                text="Close"
                onClick={() => {
                  setShowModal(false);
                }}
              />
            </div>
          </ViewSchoolWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default AssignSchoolToOrgModal;
