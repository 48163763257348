import React, { useState } from 'react';
import { ActionButtonOrange } from '../Buttons/ActionButton';
import { RecoverPassword } from '../../api';
import { LogInCardSmallWrapper } from './Cards.styles';
import { inputForgottenPasswordFields } from '../InputFields/InputFields';
import { CustomInput } from '../InputFields/CustomInput';
import { handleOnSubmit } from '../../utils/constants';

const ForgottenPasswordCard = ({ setForgottenPassword, email, setEmail }) => {
  const [error, setError] = useState('');
  const [successResponse, setSuccessResponse] = useState('');
  const [enable, setEnable] = useState(false);

  const handleChange = (e) => {
    setEmail(e.target.value);
    setError('');
    setSuccessResponse('');
  };

  const handleRecoverySubmit = async () => {
    let errorCopy = {};
    
    if (email === '') {
      errorCopy.error = 'Please enter your email'
      setError(errorCopy);
      return;
    }
    
    setEnable(true);
    const isRecovered = await RecoverPassword(email);

    if (isRecovered.status === 'success') {
      setSuccessResponse(isRecovered.data.message);
    } else {
      setEnable(false);
      errorCopy.error = isRecovered.data;
    }
    setError(errorCopy);
  };

  inputForgottenPasswordFields.email.value = email;

  return (
    <LogInCardSmallWrapper className="forget-password">
      <h2>Enter your credentials</h2>
      <div className='controls-container'>
        <div className="input">
          <CustomInput
            input={inputForgottenPasswordFields.email}
            onChange={handleChange}
            className="input--user-email"
            onSubmit={(e) => handleOnSubmit(e, handleRecoverySubmit)}
          />
          {(error || successResponse) && (
            <h5 className="response-message" id={successResponse && 'success'}>
              {error.error || successResponse}
            </h5>
          )}
        </div>

        <div className="buttons--forgotten-password">
          <ActionButtonOrange
            theme={'secondary'}
            text="Back"
            inline={'inline'}
            onClick={() => {
              setForgottenPassword(false);
            }}
          />
          <ActionButtonOrange
            disabled={enable}
            theme={'primary'}
            text="Recover Password"
            onClick={handleRecoverySubmit}
          />
        </div>
      </div>
    </LogInCardSmallWrapper>
  );
};

export default ForgottenPasswordCard;
