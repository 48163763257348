import styled from "styled-components";

export const ModalWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;

  .modal__buttons--create,
  .modal__buttons--remove,
  .modal__buttons--smaller-modal,
  .modal__buttons--add-note,
  .modal__buttons--assessment,
  .modal__buttons--user {
    height: 4.5rem;
    margin-top: 3rem;
    width: 100%;
    display: flex;
    padding-top: 3rem;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 0;
  }

  .assign--btn {
    height: 1rem;
    margin-top: 2.5rem;
  }

  .modal__buttons--remove,
  .modal__buttons--add-material {
    padding-top: 3rem;
  }

  .modal__buttons--edit-material {
    height: 3rem;
  }

  .modal__buttons--smaller-modal,
  .modal__buttons--add-note,
  .modal__buttons--user {
    height: 4.5rem;
    padding-top: 3rem;
  }

  .modal__buttons--schools {
    margin-top: 2rem;

    button {
      width: 100%;
    }
  }

  .modal__buttons--assessment {
    padding-top: 3rem;
    height: 4rem;
    color: green;
    margin-top: 2rem;

    div {
      width: 25rem;
    }
  }

  .modal__buttons--org {
    padding: 0;
    h5 {
      width: 25rem;
    }
  }

  .modal__buttons--edit-org,
  .modal__buttons--user {
    h5 {
      width: 21rem;
    }
  }

  .modal__buttons--edit-org {
    padding-top: 1.5rem;
    button {
      margin-top: -0.9rem;
    }
  }
  .modal__buttons--add-org {
    h5 {
      width: 26rem;
    }
  }

  .add__org {
    margin-top: 15px;
    height: 0;
  }

  .modal__buttons--org {
    height: 5.5rem;
  }

  .modal__buttons--add-material {
    margin-top: 1rem;
  }

  .modal__buttons--add-note {
    h5 {
      text-align: left;
      padding-left: 0.5rem;
    }
    button {
      margin-top: 0.5rem;
    }
  }

  .modal__buttons--discard-skill {
    display: flex;
    width: 20rem;
    margin-top: 3rem;
  }
`;

export const SchoolSelect = styled.div`
  span {
    color: #29285a;
  }

  &:hover {
    background: #deebff;
    cursor: pointer;
  }
`;

export const SchoolModalWrapper = styled.div`
  padding: 2.5em;
  height: 37.5rem;
  background: var(--grey-200);
  box-shadow: 0 4px 4px 100em hsl(0, 0%, 0%, 0.3),
    2px 4px 4px 0 hsl(0, 0%, 0%, 0.25);
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  button {
    border: 0 !important;
    outline: none !important;
  }

  .input--country {
    div {
      div {
        border-radius: 25px;
      }
    }
  }

  .input--org {
    width: 500px;
    margin-top: 32px;
    
    div {
      div {
        border-radius: 25px;
      }
    }
  }
`;

export const ViewSchoolWrapper = styled.div`
  height: auto;
  background: var(--grey-200);
  box-shadow: 0 4px 4px 100em hsl(0, 0%, 0%, 0.3),
    2px 4px 4px 0 hsl(0, 0%, 0%, 0.25);
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  flex-direction: column;

  .view--school {
    display: flex;
    gap: 5rem;
  }

  .main_detail {
    white-space: wrap;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    align-items: start;
    padding: 0.5rem 0;
    gap: 15px;
    border-bottom: 2px solid #eeeeee;
  }

  .country {
    display: flex;
  }

  .country_input {
    width: calc(100% - 8rem);
  }
  
  .nav_title {
    font-size: 13px;
    font-weight: 700;
    width: 8rem;
    text-align: start;
    color: #0b0a42;
  }

  .nav_text {
    font-size: 13px;
    word-break: break-word;
    text-align: start;
    width: 10rem;
  }

  .nav_text_input {
    word-break: break-word;
    text-align: start;
  }

  .input_country {
    padding: 0.8rem 0;
  }

  .modify-btn {
    margin-top: 1rem;
    
    div {
      width: auto;
    }
    
    button {
      width: auto;
    }
  }

  .school_select {
      width: 15rem;

      div {
        border-radius: 25px !important;
      }
  }

  .school_input {
    background: white;
    border-radius: var(--border-radius);
    border: 2px solid var(--blue-200);
    font-size: 0.875rem;
    width: 100%;
    height: 1rem;
    padding: 0.5em;
    outline: none;
  }

  .input--street::-webkit-outer-spin-button,
  .input--street::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .select_country {
    width: 100%;

    div {
      border-radius: 25px !important;
    }
  }

  .select_group {
    width: 100%;

    input {
      height: auto !important;
    }

    div {
      div {
        border-radius: 25px !important;
      }
    }
    
    h4 {
      margin: 0;
    }
  }

  .last_nav_text {
    border: none;
  }
`;

export const SchoolViewWrapper = styled.div`
  .view--school {
    display: flex;
    gap: 5rem;
  }

  .main_detail {
    white-space: wrap;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    align-items: start;
    padding: 0.5rem 0;
    gap: 15px;
    border-bottom: 2px solid #eeeeee;
  }

  .country {
    display: flex;
  }

  .country_input {
    width: calc(100% - 8rem);
  }
  
  .nav_title {
    font-size: 13px;
    font-weight: 700;
    width: 8rem;
    text-align: start;
    color: #0b0a42;
  }

  .nav_text {
    font-size: 13px;
    word-break: break-word;
    text-align: start;
    width: 10rem;
  }

  .nav_text_input {
    word-break: break-word;
    text-align: start;
  }

  .input_country {
    padding: 0.8rem 0;
  }

  .modify-btn {
    margin-top: 1rem;
    
    div {
      width: auto;
    }
    
    button {
      width: auto;
    }
  }

  .school_select {
      width: 15rem;

      div {
        border-radius: 25px !important;
      }
  }

  .school_input {
    background: white;
    border-radius: var(--border-radius);
    border: 2px solid var(--blue-200);
    font-size: 0.875rem;
    width: 100%;
    height: 1rem;
    padding: 0.5em;
    outline: none;
  }

  .input--street::-webkit-outer-spin-button,
  .input--street::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .select_country {
    width: 100%;

    div {
      border-radius: 25px !important;
    }
  }

  .select_group {
    width: 100%;

    input {
      height: auto !important;
    }

    div {
      div {
        border-radius: 25px !important;
      }
    }
    
    h4 {
      margin: 0;
    }
  }

  .last_nav_text {
    border: none;
  }
`;


export const InputsWrapper = styled.div`
  display: flex;
  text-align: left;
  flex-direction: column;

  .school-main {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .school--name {
      div {
        height: 1.5rem;
      }

      h5 {
        padding-top: 5px;
      }
    }

    .input--grade {
      h5 {
        margin-top: 0.2rem;
      }
    }
  }

  input {
    margin-bottom: 0.2em;
  }

  .input--street {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .input--school,
  .input--long,
  .input--lat {
    h5 {
      position: absolute;
    }
  }

  div {
    margin: 0 1.5rem;

    div {
      margin: 0;
    }
  }

  .address {
    margin-top: 32px;

    .address-section {
      display: grid;
      grid-template-columns: auto auto;
      gap: 20px;
    }
  }

  .location {
    margin-top: 32px;
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
  }
`;

export const ModalButtonsWrapper = styled.div`
  width: 20rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0px;
`;

export const ModalRemoveButtonsWrapper = styled(ModalButtonsWrapper)`
  width: 15rem;
`;

export const GroupModalWrapper = styled(SchoolModalWrapper)`
  width: 25rem;
  height: 19rem;
  padding: 1.5em 1.5em;
  padding-bottom: 0;

  h5.error {
    margin-top: 0.5rem;
  }

  .input--grade {
    div {
      div {
        border-radius: 25px;
      }
    }
  }
`;

export const GroupToFacilitatorModalWrapper = styled(SchoolModalWrapper)`
  width: 25rem;
  height: auto;
  padding: 1.5em 2em;
  padding-bottom: 0;

  h2 {
    margin-bottom: 1rem;
  }

  .group_user {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;

    h4 {
      text-align: start;
      font-size: 1rem;
    }
  }
`;

export const GroupModalWrapperSmall = styled(SchoolModalWrapper)`
  width: 25rem;
  height: 10rem;
  display: grid;
  place-content: center;
`;

export const SelectGroupModalWrapper = styled.div`
  text-align: start;

  .input--name {
    padding-bottom: 5px;
  }

  .modal__buttons--create {
    height: 4rem !important;
    margin-top: 1rem !important;
    width: 100% !important;
    display: flex !important;
    padding-top: 3rem !important;
    flex-direction: column !important;
    align-items: center !important;
    position: relative !important;
    text-align: center !important;

    div:first-child {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
  }

  .modal__buttons--group {
    margin-top: 3rem;
    padding-top: 3rem;
    height: 5rem !important;

    h5 {
      width: 20rem;
    }
  }

  .input--grade {
    position: relative;

    h5 {
      width: 100%;
      text-align: left;
      position: absolute;
    }
  }
`;
export const GroupModalButtonsWrapper = styled(ModalButtonsWrapper)`
  width: 80%;
`;

export const EditMaterialWrapper = styled(SchoolModalWrapper)`
  width: 45rem;
  text-align: left;
  padding: 2rem 3rem;
  overflow-y: auto;
  height: 40rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  h3 {
    width: 100%;
  }

  .input--title,
  .input--link,
  .input--grade,
  .input--edit-lesson,
  .input--skills {
    width: 100%;
    position: relative;
  }

  .input--skills {
    margin-top: 1.5rem;
  }

  h5,
  h5.error {
    position: absolute;
    margin-top: -6px;
  }

  h5.error {
    bottom: -3rem;
    width: 25rem;
    text-align: center;
  }

  .grade--title {
    width: 100%;
  }

  .select--skills {
    div {
      border-radius: 25px;
    }
  }
`;

export const RemoveModalWrapper = styled(SchoolModalWrapper)`
  height: 13rem;
  padding: 2.5rem 3rem;
`;

export const AssignModalWrapper = styled(SchoolModalWrapper)`
  height: auto;
  width: 25rem;
  padding: 2rem 3rem;
`;

export const SkillModalWrapper = styled(SchoolModalWrapper)`
  height: 15rem;
  width: 25rem;
  padding: 2rem 3rem;

  h2 {
    margin-bottom: 1rem;
  }

  .input--skill {
    width: 100%;

    div {
      border-radius: 25px;
    }

    h4 {
      display: flex;
    }
  }

  .modal__buttons--create {
    margin-top: 2rem;
  }
`;

export const SelectGradeWrapper = styled.div`
  width: 100%;
  text-align: start;
`;

export const FeedbackModalWrapper = styled(SchoolModalWrapper)`
  width: 35rem;
  height: 30rem;
  h2 {
    margin-bottom: 1rem;
  }

  textarea {
    width: 100%;
    height: 80%;
    margin-bottom: 2rem;
    border-radius: var(--border-radius);
    border: 2px solid var(--blue-200);
    padding: 1rem;
    resize: none;
    &:focus {
      outline: none;
    }
  }
`;

export const AddStudentIDModalWrapper = styled(SchoolModalWrapper)`
  width: 25%;
  height: 20rem;
  align-items: center;

  .modal__buttons--create {
    margin-top: 0 !important;

    button {
      width: auto;
    }
  }

  .input--student,
  .input--uniqueId,
  .input--dob,
  .input--gender {
    position: relative;

    h5 {
      position: absolute;
    }
  }

  div.input--student,
  div.input--dob {
    margin-bottom: 1rem;
  }

  div.input--uniqueId {
    margin-bottom: 2.5rem;
  }

  h5.error {
    margin-top: 0.5rem;
  }
`;

export const AddStudentModalWrapper = styled(SchoolModalWrapper)`
  width: 60%;
  height: auto;
  align-items: center;

  .input--student,
  .input--uniqueId,
  .input--dob,
  .input--gender {
    position: relative;

    h5 {
      position: absolute;
    }
  }

  div.input--student,
  div.input--dob {
    margin-bottom: 1.5rem;
  }

  div.input--uniqueId {
    margin-bottom: 2.5rem;
  }

  div.input--nationIdNumber {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  h5.error {
    margin-top: 0.5rem;
  }

  .modal__buttons--create {
    height: 0;
  }
`;
export const StyledStudentInfo = styled.div`
  width: 100%;
`;

export const StyledAvatar = styled.div`
  width: 100%;
  height: 85%;
  display: flex;
  justify-content: space-around;

  .left-side-student-details {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .right-side-student-details {
    display: flex;
    width: 20rem;
    flex-direction: column;
    text-align: left;
  }

  h5 {
    width: 20rem;
  }

  img {
    width: 15rem;
    height: 15rem;
    object-fit: contain;
    object-position: center;
    position: relative;
  }
  h5.error--image {
    position: fixed;
    top: 48%;
    width: 240px;
    text-align: left;
  }
`;
export const StudentModalButtonsWrapper = styled(ModalButtonsWrapper)`
  margin-top: 1rem;
  width: 18rem;
  display: flex;
  justify-content: space-between;
  position: initial;
`;

export const AddOrganizationModalWrapper = styled(SchoolModalWrapper)`
  height: 40rem;
  width: 45rem;
  text-align: left;

  .org-field {
    display: grid;
    grid-template-columns: auto auto;
    gap: 15px;
  }

  button {
    width: 10rem;
  }

  h5 {
    position: fixed;
    margin-top: -0.3rem;
  }

  input {
    width: 18rem;
  }

  .org__street {
    margin-top: 24px;
  }

  .input--street {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .input--name {
    div {
      div {
        border-radius: 25px;
      }
    }

    h5 {
      margin-top: 0rem;
    }
  }
`;

export const AddUserModalWrapper = styled(SchoolModalWrapper)`
  height: 30rem;
  width: 45rem;
  padding: 2.5rem 3rem;
  text-align: left;

  .input--name {
    width: 90%;
    position: relative;

    input {
      margin: 0.2rem 0;
    }
  }

  h5 {
    position: fixed;
  }

  .user--modal {
    display: grid;
    grid-template-columns: auto auto;
    width: 100%;

    .input--name {
      margin-bottom: 1.5rem;
    }
  }

  .add__user {
    height: 0;
    margin: 1rem;
  }

  .input--contact {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .select--name {
    div {
      div {
        border-radius: 25px;
      }
    }
  }
`;

export const MoveStudentModalWrapper = styled(SchoolModalWrapper)`
  height: auto;
  width: 35rem;
  padding: 2rem 3rem;
  text-align: left;

  .select--input {
    width: 100%;
    flex-direction: column;
    display: flex;
    gap: 15px;
    margin-bottom: 1rem;
  }

  .title {
    color: "#0B0A43";
    font-size: 1.2rem;
    font-weight: 500;
  }

  .status {
    display: flex;
    gap: 8px;
  }

  .input--role,
  .input--email,
  .input--name {
    width: 90%;
    position: relative;
  }

  h5 {
    width: 27rem;
    position: fixed;
    margin-top: 0.5rem;
  }

  .modal__buttons--smaller-modal {
    margin-top: 0rem;
    height: 1rem;
  }

  .input--reason {
    div {
      border-radius: 25px;
    }
  }
`;

export const AddUserSmallModalWrapper = styled(SchoolModalWrapper)`
  height: 10rem;
  width: 30rem;
  display: grid;
  place-content: center;
`;

export const EditUserModalWrapper = styled(AddUserModalWrapper)`
  height: 30rem;
  width: 45rem;
  padding: 2.5rem 3rem;
  text-align: left;

  .input--name {
    width: 90%;
    position: relative;

    input {
      margin: 0.2rem 0;
    }
  }

  h5 {
    position: fixed;
  }

  .user--modal {
    display: grid;
    grid-template-columns: auto auto;
    width: 100%;

    .input--name {
      margin-bottom: 1.5rem;
    }
  }

  .add__user {
    height: 0;
    margin: 1rem;
  }

  .input--contact {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .select--name {
    div {
      div {
        border-radius: 25px;
      }
    }
  }
`;

export const LogInAsUserModalWrapper = styled(AddUserModalWrapper)`
  height: 15rem;
  width: 25rem;

  h4 {
    padding-top: 1rem;
  }

  button {
    margin-top: -1rem;
  }

  h5 {
    margin-top: -1rem;
    width: 22rem;
    text-align: center;
  }
`;

export const EditOrgModalWrapper = styled(AddUserModalWrapper)`
  height: 40rem;
  width: 45rem;
  text-align: left;

  .org-field {
    display: grid;
    grid-template-columns: auto auto;
    gap: 15px;
  }

  .input--name {
    width: 90%;
  }

  .input--street {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  button {
    width: 10rem;
  }

  h5 {
    position: fixed;
    margin-top: -0.3rem;
  }

  input {
    width: 18rem;
  }

  .org__street {
    margin-top: 24px;
  }

  .input--name {
    div {
      div {
        border-radius: 25px;
      }
    }

    h5 {
      margin-top: 0rem;
    }
  }

  .add__org {
    margin-top: 40px;
  }
`;

export const AssessmentModalWrapper = styled(SchoolModalWrapper)`
  width: 50rem;
  height: 28.5rem;
  justify-content: flex-start;

  h3 {
    margin-right: 0.5em;
  }

  h5.error {
    padding-left: 0.5rem;
  }
`;

export const TopSectionWrapper = styled.div`
  margin-bottom: 0.5rem;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const QWrappers = styled.div`
  width: 95%;
  border-bottom: 1px solid var(--grey-250);

  div {
    width: 100%;
    margin: 0.5rem auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
  }

  h3 {
    font-weight: 600;
    width: 2.5rem;
  }

  a {
    padding: 0.4rem 0;
    margin-bottom: 0;
    height: 2rem;
    width: 32rem;
    text-align: left;
    margin-left: 1rem;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  input {
    width: 100%;
    font-size: 1.1rem;
    margin: 0.3rem 0;
    &:focus {
      background-color: var(--blue-100);
    }
    &:not(:placeholder-shown) {
      background-color: var(--blue-100);
    }
  }
`;

export const AddNoteModalWrapper = styled(SchoolModalWrapper)`
  width: 35rem;
  height: 33rem;
  h2 {
    margin-bottom: 1rem;
  }

  div.note {
    width: 100%;
    height: 70%;

    h5 {
      text-align: left;
    }
  }
  textarea {
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius);
    border: 2px solid var(--blue-200);
    padding: 1rem;
    resize: none;
    &:focus {
      outline: none;
    }
  }
`;

export const AddLessonModalWrapper = styled(SchoolModalWrapper)`
  height: auto;
  width: 28rem;
  text-align: left;
  padding: 1.5rem;

  .input--material {
    width: 20rem;
    position: relative;
    h5 {
      bottom: -1.2rem;
    }
  }

  .input--date {
    margin-top: 1rem;

    h5.material__error-date {
      width: inherit;
      position: absolute;
      padding-top: 0.5rem;
      text-align: left;
    }
  }

  h5 {
    position: absolute;
    width: 100%;
  }

  .modal__buttons--add-material {
     height: 0;
  }
`;

export const AddMaterialModalWrapper = styled(SchoolModalWrapper)`
  height: auto;
  padding: 2rem 5rem;
  overflow-y: hidden;
  display: flex;
  flex-direction: column-reverse;

  h2 {
    padding: 0;
    text-align: left;
    padding-left: 1.5rem;
  }

  .select--skills {
    div {
      border-radius: 25px;
    }
  }
`;

export const AddMaterialWrapper = styled.div`
  width: 30rem;
  margin-top: 1rem;
  background: var(--grey-250);
  box-shadow: 0 4px 4px 0 hsl(0, 0%, 0%, 0.25);
  border-radius: var(--border-radius);
  padding: 1.5em 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;

  div.response {
    height: 1.5rem;
    width: 100%;
  }

  div.button--save {
    margin-top: 0rem;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    height: 4rem;
  }

  h4 {
    width: 100%;
  }

  h4.select-grade {
    margin-bottom: 0.5rem;
  }

  .grade--title {
    margin-top: 1.5rem;
  }

  .input--grade,
  .input--title,
  .input--new-lession,
  .input--new-skill,
  .input--link,
  .input--skills {
    position: relative;
    h5 {
      position: absolute;
    }
  }

  .input--title,
  .input--new-lession,
  .input--new-skill,
  .input--link {
    width: 100%;
  }

  .input--link,
  .input--grade,
  .input--skills {
    margin-top: 1.5rem;
  }

  h5.error {
    position: absolute;
    bottom: -2.2rem;
  }

  .input--grade {
    h5.error {
      position: absolute;
      bottom: -1.2rem;
    }
  }

  h5.api--response {
    width: 100%;
    text-align: center;
    color: green;
    margin-top: 0.5rem;
  }
`;

export const ActionSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;

  img {
    margin-left: 5rem;
  }

  @media screen and (max-width: 1100px) {
    img {
      display: none;
    }
  }
`;

export const DiscardModalWrapper = styled(RemoveModalWrapper)`
  height: 17rem;
  width: 40rem;

  h4 {
    margin-top: 0.5rem;
  }
`;

export const PhotoModalWrapper = styled(RemoveModalWrapper)`
  width: 40rem;
  height: 40rem;
  padding: 0;

  img {
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius);
    object-fit: cover;
    box-shadow: 5px 7px 30px hsl(0, 0%, 0%, 0.6);
  }
`;
