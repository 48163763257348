import { useEffect } from "react";
import React, { useContext, useState } from "react";
import { ModalContext } from "../../../contexts/modalContext";
import { ActionButtonGreen } from "../../Buttons/ActionButton";
import {
  AddStudentModalWrapper,
  ModalWrapper,
  StyledAvatar,
  StyledStudentInfo,
} from "../Modals.styles";
import { TableContainer } from "@mui/material";
import { TableWrapper } from "../../Tables/Table.styles";
import ClipSpinner from "../../Loaders/ClipSpinner";
import { StudentsContext } from "../../../contexts/studentsContext";
import { CreateAllStudent } from "../../../api";
import { OrganizationsContext } from "../../../contexts/organizationsContext";
import { LogInContext } from "../../../contexts/logInContext";
import { success, warning } from "../../../utils/toastify";

const AddStudentCSVModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal } = useContext(ModalContext);
  let {
    dupStud,
    setDupStud,
    dupGrpStud,
    setDupGrpStud
  } = useContext(StudentsContext);
  const { organizationId } = useContext(OrganizationsContext);
  const { token } = useContext(LogInContext);

  const [loadingData, setLoadingData] = useState({
    loading: true,
    hasSchools: true,
  });
  let [arr, setArr] = useState([]);

  const columns = [
    {
      Header: "National ID",
      accessor: "nationalId",
    },
    {
      Header: "First Name",
      accessor: "firstName",
    },
    {
      Header: "Last Name",
      accessor: "lastName",
    },
    {
      Header: "Date of Birth",
      accessor: "phone",
    },
    {
      Header: "Gender",
      accessor: "gender",
    },
    {
      Header: "Country",
      accessor: "country",
    },
    {
      Header: "Accept",
      accessor: "accept",
    },
    {
      Header: "Reject",
      accessor: "reject",
    },
  ];

  const dupColumns = [
    {
      Header: "National ID",
      accessor: "nationalId",
    },
    {
      Header: "First Name",
      accessor: "firstName",
    },
    {
      Header: "Last Name",
      accessor: "lastName",
    },
    {
      Header: "Date of Birth",
      accessor: "phone",
    },
    {
      Header: "Gender",
      accessor: "gender",
    },
    {
      Header: "Country",
      accessor: "country",
    },
  ];

  useEffect(() => {
    setLoadingData({
      loading: false,
      hasSchools: !(dupStud.length === 0),
    });
  }, [dupStud]);

  useEffect(() => {
    setLoadingData({
      loading: false,
      hasSchools: !(dupGrpStud.length === 0),
    });
  }, [dupGrpStud]);

  const hanleAddSubmit = async () => {
    if (arr.length > 0) {
      const res = await CreateAllStudent(organizationId, arr, token);

      if (res.status === "success") {
        success("Student created successfully.");
        setShowModal(false);
        setDupGrpStud([]);
      } else {
        warning("Student created failed.");
        setShowModal(false);
        setDupGrpStud([]);
      }
    }

    setShowModal(false);
    setDupStud([]);
    setDupGrpStud([]);
  };

  const checkHandler = (item, name) => {
    dupGrpStud =
      Array.isArray(dupGrpStud) &&
      dupGrpStud.map((i) => {
        if (i?.index === item?.index) {
          i.accept =
            name === "accept" ? true : name === "reject" ? false : false;
          i.country = i?.country;
          i.countryId = i?.countryId;
          i.dob = i?.dob;
          i.gender = i?.gender;
          i.groupId = i?.groupId;
          i.lastName = i?.lastName;
          i.name = i?.name;
          i.reject =
            name === "reject" ? true : name === "accept" ? false : false;
          i.schoolId = i?.schoolId;
          i.uniqueId = i?.uniqueId;
          i.uniqueIdentifier = i?.uniqueIdentifier;
        }
        return i;
      });

    setDupGrpStud(dupGrpStud);
  };

  const acceptHandler = (item) => {
    arr.push(item);
    setArr(arr);
  };

  const rejectHandler = (item) => {
    arr = arr.filter((i) => i?.index !== item?.index);
    setArr(arr);
  };

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <AddStudentModalWrapper>
            {dupGrpStud.length > 0 && (
              <StyledStudentInfo>
                <StyledAvatar style={{ flexDirection: "column" }}>
                  <div style={{ marginBottom: "20px", textAlign: "center" }}>
                    <h1 style={{ marginBottom: "10px" }}>Confirm Students</h1>

                    <p>
                      Are you sure you want to add the following students to the
                      school again, as they already exist in the system?
                    </p>
                  </div>

                  <TableContainer className="custom_table_container">
                    <TableWrapper
                      style={{ maxHeight: "15rem", overflowY: "auto" }}
                    >
                      {loadingData.loading ? (
                        <div className="loading-table">
                          <ClipSpinner />
                        </div>
                      ) : (
                        <>
                          <table>
                            <thead>
                              <tr>
                                {columns.map((item, index) => (
                                  <th key={index}>{item?.Header}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {dupGrpStud.map((item, index) => (
                                <tr key={index}>
                                  <td>{item?.uniqueId}</td>
                                  <td>{item?.name}</td>
                                  <td>{item?.lastName}</td>
                                  <td>{item?.dob}</td>
                                  <td>{item?.gender}</td>
                                  <td>{item?.country}</td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      name="accept"
                                      value={item?.accept}
                                      checked={item?.accept}
                                      onChange={() =>
                                        checkHandler(item, "accept")
                                      }
                                      onClick={() => acceptHandler(item)}
                                    />
                                  </td>
                                  <td
                                    style={{
                                      justifyContent: "center",
                                      maxHeight: "60px",
                                      height: "100vh",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      name="reject"
                                      value={item?.reject}
                                      checked={item?.reject}
                                      onChange={() =>
                                        checkHandler(item, "reject")
                                      }
                                      onClick={() => rejectHandler(item)}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </>
                      )}
                    </TableWrapper>
                  </TableContainer>
                </StyledAvatar>
              </StyledStudentInfo>
            )}
            {dupStud.length > 0 && (
              <StyledStudentInfo>
                <StyledAvatar style={{ flexDirection: "column" }}>
                  <div style={{ marginBottom: "20px", textAlign: "center" }}>
                    <h1 style={{ marginBottom: "10px", marginTop: "20px" }}>
                      Duplicate Student/s
                    </h1>

                    <p>
                      Following student(s) cannot be added as they already
                      exists in the group -
                    </p>
                  </div>

                  <TableContainer className="custom_table_container">
                    <TableWrapper
                      style={{ maxHeight: "15rem", overflowY: "auto" }}
                    >
                      {loadingData.loading ? (
                        <div className="loading-table">
                          <ClipSpinner />
                        </div>
                      ) : (
                        <>
                          <table>
                            <thead>
                              <tr>
                                {dupColumns.map((item, index) => (
                                  <th key={index}>{item?.Header}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {dupStud.map((item, index) => (
                                <tr key={index}>
                                  <td>{item?.uniqueId}</td>
                                  <td>{item?.name}</td>
                                  <td>{item?.lastName}</td>
                                  <td>{item?.dob}</td>
                                  <td>{item?.gender}</td>
                                  <td style={{ justifyContent: "center" }}>
                                    {item?.country}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </>
                      )}
                    </TableWrapper>
                  </TableContainer>
                </StyledAvatar>
              </StyledStudentInfo>
            )}
            <div
              className="school__button"
              style={{
                width: "18rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "21px",
              }}
            >
              <ActionButtonGreen
                theme="primary"
                text={
                  (dupStud.length > 0 && dupGrpStud.length > 0) ||
                  dupGrpStud.length > 0
                    ? "Submit"
                    : "Ok"
                }
                onClick={hanleAddSubmit}
              />
            </div>
          </AddStudentModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default AddStudentCSVModal;
