import React, { useState, useContext } from 'react';
import { LessonsContext } from '../../../contexts/lessonsContext';
import { ModalContext } from '../../../contexts/modalContext';
import { errors } from '../../../utils/validation';
import { DeleteNote } from '../../../api';
import { OrganizationsContext } from '../../../contexts/organizationsContext';
import { LogInContext } from '../../../contexts/logInContext';
import { ModalWrapper, RemoveModalWrapper } from '../Modals.styles';
import { StudentsContext } from '../../../contexts/studentsContext';
import { ModalRemoveButtons } from '../ModalButtons';
import { removeElement } from '../../../utils/constants';

const RemoveNoteModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal } = useContext(ModalContext);
  const { selectedStudent } = useContext(StudentsContext);
  const { organizationId } = useContext(OrganizationsContext);
  const { setNotes, noteId, notes } = useContext(LessonsContext);
  const { token } = useContext(LogInContext);
  const [error, setError] = useState({});

  const handleRemoveNoteSubmit = async () => {
    const isValidRemoval = await DeleteNote(
      organizationId,
      selectedStudent.id,
      noteId,
      token
    );

    if (isValidRemoval.status === 'success') {
      removeElement(noteId, notes, setNotes);
      setShowModal(false);
    } else {
      setError({ responseMessage: errors.removalErrorMessage });
    }
  };

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <RemoveModalWrapper>
            <h3>Are you sure you want to remove this note? </h3>
            <ModalRemoveButtons
              onClick={handleRemoveNoteSubmit}
              error={error.responseMessage}
            />
          </RemoveModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default RemoveNoteModal;
