import React, { useState } from 'react';


export const FeedbackContext = React.createContext();

const FeedbackProvider = (props) => {
  const [feedback, setFeedback] = useState([]);
  const [displayFeedback, setDisplayFeedback] = useState([]);
  const [selectedFeedback, setSelectedFeedback] = useState('');

  return (
    <FeedbackContext.Provider
      value={{
        feedback,
        setFeedback,
        displayFeedback,
        setDisplayFeedback,
        setSelectedFeedback,
        selectedFeedback,
      }}
    >
      {props.children}
    </FeedbackContext.Provider>
  );
};

export default FeedbackProvider;
