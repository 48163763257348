export const SocialMedia = [
    {
        link: "https://twitter.com/corps_global",
        icon: ["fab", "twitter"]
    },
    {
        link: "https://www.facebook.com/rhinomanrangers/",
        icon: ["fab",  "facebook-f"]
    },
    {
        link: "https://www.linkedin.com/company/global-conservation-corps",
        icon: ["fab", "linkedin-in"]
    },
    {
        link: "https://www.youtube.com/channel/UCrImfFax9LPb5g8JBrHdQdw",
        icon: ["fab", "youtube"]
    },
    {
        link: "https://www.instagram.com/theglobalconservationcorps/",
        icon: ["fab", "instagram"]
    }
]