import { useContext, useState, useEffect } from "react";
import { CreateUser } from "../../../api";
import { LogInContext } from "../../../contexts/logInContext";
import { ModalContext } from "../../../contexts/modalContext";
import {
  isValidName,
  errors,
  isValidEmail,
  isValidContactPhone,
} from "../../../utils/validation";
import {
  AddUserSmallModalWrapper,
  AddUserModalWrapper,
  ModalWrapper,
} from "../Modals.styles";
import { OrganizationsContext } from "../../../contexts/organizationsContext";
import { ModalCreateButtons } from "../ModalButtons";
import { CustomInput } from "../../InputFields/CustomInput";
import { inputUserFields } from "../../InputFields/InputFields";
import { addElement, handleOnSubmit } from "../../../utils/constants";
import CustomSelect from "../../InputFields/CustomSelect";
import { SchoolsContext } from "../../../contexts/schoolsContext";
import { warning } from "../../../utils/toastify";

const AddUserModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal } = useContext(ModalContext);
  const { organizationId, usersData, setUsersData, roles } =
    useContext(OrganizationsContext);
  const { token } = useContext(LogInContext);
  const [userInfo, setUserInfo] = useState({});
  const [error, setError] = useState({});
  const { countryOptions, countryCodeOptions } = useContext(SchoolsContext);

  const [roleOptions, setRoleOptions] = useState([]);
  const [countryCode, setCountryCode] = useState(null);

  useEffect(() => {
    setRoleOptions(
      roles
        .filter((role) => role !== "OWNER")
        .map((role) => ({
          value: role,
          label: role,
        }))
    );
  }, [roles]);

  const handleUserInput = (e) => {
    let errorCopy = { ...error };

    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });

    if (e.target.name === "name") {
      const isValid = isValidName(e.target.value);
      isValid
        ? delete errorCopy.name
        : (errorCopy.name = errors.requiredFnameError);
    } else if (e.target.name === "lastName") {
      const isValid = isValidName(e.target.value);
      isValid
        ? delete errorCopy.lastName
        : (errorCopy.lastName = errors.requiredLnameError);
    } else if (e.target.name === "email") {
      delete errorCopy.email;
      const isValid = isValidEmail(e.target.value);
      isValid
        ? delete errorCopy.email
        : (errorCopy.email = errors.emailErrorMessage);
    } else if (e.target.name === "city") {
      const isValid = isValidName(e.target.value);
      isValid
        ? delete errorCopy.city
        : (errorCopy.city = errors.requiredCityError);
    }

    setError(errorCopy);
  };

  const handleSelectRole = (e) => {
    setUserInfo({ ...userInfo, role: e.value });
    delete error.role;
  };

  const handleSubmitValidations = () => {
    let errorCopy = { ...error };
    delete errorCopy.apiFail;

    !userInfo.name && (errorCopy.name = errors.requiredFnameError);
    !userInfo.lastName && (errorCopy.lastName = errors.requiredLnameError);
    !userInfo.email && (errorCopy.email = errors.emailRequiredMessage);
    !userInfo.role && (errorCopy.role = errors.roleRequiredMessage);
    !userInfo.countryId && (errorCopy.country = errors.requiredCountryError);
    !userInfo.countryId &&
      (errorCopy.countryCode = errors.countryCodeRequiredMessage);
    !userInfo.city && (errorCopy.city = errors.requiredCityError);
    if (!!userInfo.contactPhone) {
      const isValid = isValidContactPhone(userInfo.contactPhone);
      isValid
        ? delete errorCopy.contactNumber
        : (errorCopy.contactNumber = errors.contactNumberMessage);
    }
    
    if (Object.keys(errorCopy).length > 0) {
      warning("Required field/s must be filled");
    }

    setError(errorCopy);
    return Object.keys(errorCopy).length === 0;
  };

  const handleSubmitUser = async () => {
    const isValid = handleSubmitValidations();

    if (!isValid) {
      return false;
    }

    const responseData = await CreateUser(organizationId, userInfo, token);

    if (responseData.status === "success") {
      addElement(usersData, setUsersData, responseData.data.data);
      setShowModal(false);
    } else {
      if (responseData.data) {
        setError({
          email: `${responseData.data}.`,
        });
      } else {
        setError({
          apiFail: errors.apiFail,
        });
      }
    }
  };

  const handleSelectCountry = (e) => {
    setUserInfo({ ...userInfo, countryId: e.id });
    setCountryCode(e?.id);
    delete error.country;
    delete error.countryCode;
  };

  inputUserFields.user.value = userInfo.name;
  inputUserFields.lastName.value = userInfo.lastName;
  inputUserFields.email.value = userInfo.email;
  inputUserFields.city.value = userInfo.city;
  inputUserFields.contactNumber.value = userInfo.contactPhone;

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
            <AddUserModalWrapper>
              <div className="user--modal">
                <CustomInput
                  input={inputUserFields.user}
                  error={error.name}
                  onChange={handleUserInput}
                  onSubmit={(e) => handleOnSubmit(e, handleSubmitUser)}
                  className="input--name"
                />
                <CustomInput
                  input={inputUserFields.lastName}
                  error={error.lastName}
                  onChange={handleUserInput}
                  onSubmit={(e) => handleOnSubmit(e, handleSubmitUser)}
                  className="input--name"
                />

                <CustomSelect
                  title="Role"
                  options={roleOptions}
                  placeholder="Select role"
                  onChange={handleSelectRole}
                  error={error.role}
                  className="input--name select--name"
                />

                <CustomInput
                  input={inputUserFields.email}
                  error={error.email}
                  onChange={handleUserInput}
                  onSubmit={(e) => handleOnSubmit(e, handleSubmitUser)}
                  className="input--name select--name"
                />

                <CustomSelect
                  title="Country"
                  required="*"
                  options={countryOptions}
                  placeholder="Select country"
                  onChange={(e) => handleSelectCountry(e)}
                  error={error.country}
                  className="input--name select--name"
                />

                <CustomInput
                  input={inputUserFields.city}
                  error={error.city}
                  onChange={handleUserInput}
                  onSubmit={(e) => handleOnSubmit(e, handleSubmitUser)}
                  className="input--name"
                />

                <CustomSelect
                  title="Country Code"
                  required="*"
                  options={countryCodeOptions}
                  value={countryCodeOptions.find(
                    (elem) => elem?.id === countryCode
                  )}
                  placeholder="Select country code"
                  error={error.countryCode}
                  className="input--name select--name"
                />

                <CustomInput
                  input={inputUserFields.contactNumber}
                  error={error.contactNumber}
                  onChange={handleUserInput}
                  onSubmit={(e) => handleOnSubmit(e, handleSubmitUser)}
                  className="input--name input--contact"
                />
              </div>

              <ModalCreateButtons
                text="Add"
                onClick={handleSubmitUser}
                error={error.apiFail}
                className="modal__buttons--smaller-modal add__user"
              />
            </AddUserModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default AddUserModal;
