import styled from "styled-components";

export const ButtonWrapper = styled.button`
  background: ${({ color }) =>
    color === "orange" ? `var(--orange-200)` : "var(--green-200)"};
  box-shadow: 3px 3px 4px 0 hsl(0, 0%, 0%, 0.12);
  border: ${({ color }) =>
    color === "orange"
      ? `2px solid var(--orange-200)`
      : "2px solid var(--green-200)"};
  border-radius: var(--border-radius);
  padding: 0.4em 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  color: ${({ color }) => (color === "orange" ? `white` : "var(--blue-300)")};
  transition: 0.3s;
  cursor: ${({ disabled }) => disabled && "not-allowed"};

  div {
    background: ${({ icon }) => `url(${icon}) center/cover no-repeat`};
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    transition: 0.3s;
  }

  &:hover,
  &:focus-visible {
    box-shadow: 3px 3px 4px 0 hsl(0, 0%, 0%, 0.25);
    outline: none;
  }

  &:active {
    box-shadow: 3px 3px 4px 0 hsl(0, 0%, 0%, 0.2);
  }
`;

export const StyledPaginationButtons = styled.div`
  margin-top: 1rem;
  margin-left: -1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  p {
    font-size: 0.8rem;
  }
`;

export const StyledPreviousPageButton = styled.button`
  display: flex;
  align-items: center;
  margin: 0 0.3em;
  border: none;
  padding: 0 0.4em;
  cursor: ${({ disabled }) => disabled && "not-allowed"};
  background: none;
`;