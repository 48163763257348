import styled, { keyframes } from 'styled-components';

const gradient = keyframes`
	0% {
	 opacity: 1;
	}

	50% {
	opacity: 0.3;
	}

  100% {
    opacity: 1;
  }
`;

export const StyledSpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-top: 1rem;
  }
`;

export const StyledSkeleton = styled.div`
  height: 5rem;
  width: 6rem;
  margin-right: 1rem;
  border-radius: var(--border-radius);
  background: linear-gradient(-45deg, lightgray 20%, white 80%);
  animation: ${gradient} 2s linear infinite;
`;

export const StyledPulseLoader = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 0.9rem;
    margin-left: 1rem;
  }
`;
