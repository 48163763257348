import { warning } from '../../../utils/toastify';
import {
  isValidName,
  errors,
  toProperCase,
  isValidUniqueIdentifier,
} from '../../../utils/validation';

export const defaultStudentInfo = {
  name: '',
  dob: '',
  gender: '',
  photo: null,
};

export const handleStudentUserInput = (
  e,
  state,
  setState,
  groupId,
  error,
  setError
) => {
  let errorCopy = { ...error };

  if (e.target.name === 'name') {
    setState({
      ...state,
      name: toProperCase(e.target.value),
      groupId: groupId,
    });

    const isValid = isValidName(e.target.value);
    isValid
      ? delete errorCopy.studentName
      : (errorCopy.studentName = errors.requiredFnameError);
  } else if (e.target.name === 'lname') {
    setState({
      ...state,
      lastName: toProperCase(e.target.value),
    });

    const isValid = isValidName(e.target.value);
    isValid
      ? delete errorCopy.lastName
      : (errorCopy.lastName = errors.requiredLnameError);
  } else if (e.target.name === 'dob') {
    setState({ ...state, dob: e.target.value });
    const isValid = e.target.value !== '';
    isValid
      ? delete errorCopy.dateError
      : (errorCopy.dateError = errors.dateRequiredErrorMessage);
  } else {
    setState({ ...state, uniqueId: e.target.value });

    const isValid = isValidUniqueIdentifier(e.target.value);
    isValid
      ? delete errorCopy.ID
      : (errorCopy.ID = errors.uniqueIdentifierErrorMessage);
  }

  setError(errorCopy);
};

export const handleSelectGender = (e, state, setState, error) => {
  setState({ ...state, gender: e.value });
  delete error.gender;
};

export const handleSelectCountry = (e, state, setState, error) => {
  setState({ ...state, countryId: e.id });
  delete error.country;
};

export const handleSubmitValidations = (student, error, setError) => {
  let errorCopy = { ...error };
  delete errorCopy.apiFail;

  !student.gender && (errorCopy.gender = errors.genderErrorMessage);
  !student.countryId && (errorCopy.country = errors.countryErrorMessage);
  !student.name && (errorCopy.studentName = errors.requiredFnameError);
  !student.lastName && (errorCopy.lastName = errors.requiredLnameError);
  !student.dob && (errorCopy.dateError = errors.dateRequiredErrorMessage);

  if (Object.keys(errorCopy).length > 0) {
    warning("Required field/s must be filled");
  }

  setError(errorCopy);
  return Object.keys(errorCopy).length === 0;
};
