import React, { useContext, useState } from 'react';
import { ModalContext } from '../../../contexts/modalContext';
import { OrganizationsContext } from '../../../contexts/organizationsContext';
import { errors } from '../../../utils/validation';
import { StudentsContext } from '../../../contexts/studentsContext';
import { LogInContext } from '../../../contexts/logInContext';
import { ModalWrapper, RemoveModalWrapper } from '../Modals.styles';
import { RemoveStudent } from '../../../api';
import { ModalRemoveButtons } from '../ModalButtons';
import { removeElement } from '../../../utils/constants';
import { GroupsContext } from '../../../contexts/groupsContext';

const RemoveStudentModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal } = useContext(ModalContext);
  const { organizationId } = useContext(OrganizationsContext);
  const { selectedStudent, students, setStudents } =
    useContext(StudentsContext);
  const { groupStudents, setGroupStudents } = useContext(GroupsContext);
  const { token } = useContext(LogInContext);
  const [error, setError] = useState({});

  const handleRemoveStudent = async () => {
    const isValidRemoval = await RemoveStudent(
      organizationId,
      selectedStudent.id,
      token
    );

    if (isValidRemoval.status === 'success') {
      if (window.location.pathname.includes('groups')) {
        removeElement(selectedStudent.id, groupStudents, setGroupStudents);
      } else {
        removeElement(selectedStudent.id, students, setStudents);
      }
      setShowModal(false);
    } else {
      setError({ responseMessage: errors.removalErrorMessage });
    }
  };

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <RemoveModalWrapper>
            <h3>Are you sure you want to remove {selectedStudent.name}? </h3>
            <ModalRemoveButtons
              error={error.responseMessage}
              onClick={handleRemoveStudent}
            />
          </RemoveModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default RemoveStudentModal;
