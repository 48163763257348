import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ActionButtonOrangeSecondary } from "../../Buttons/ActionButton";
import { BsDash } from "react-icons/bs";
import { SchoolViewWrapper } from "../Modals.styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "auto",
  bgcolor: "#f6f7f9",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

const ViewSchoolModal = ({
  open,
  handleClose,
  schoolInfo
}) => {
  
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <SchoolViewWrapper>
      <ActionButtonOrangeSecondary
              inline={true}
              text={"School Information"}
              style={{ cursor: "default", marginBottom: "10px" }}
            />
            <div className="main_detail">
              <div className="nav_title">School Name</div>
              <div className="nav_text">
                {!!schoolInfo?.name ? schoolInfo?.name : <BsDash />}
              </div>
            </div>
            <div className="main_detail">
              <div className="nav_title">Long.</div>
              <div className="nav_text">
                {!!schoolInfo?.longitude ? schoolInfo?.longitude : <BsDash />}
              </div>
            </div>

            <div className="main_detail">
              <div className="nav_title">Lat.</div>
              <div className="nav_text">
                {!!schoolInfo?.latitude ? (
                  schoolInfo?.latitude
                ) : (
                  <BsDash />
                )}
              </div>
            </div>
            <div className="main_detail">
              <div className="nav_title">Street Number</div>
              <div className="nav_text">
                {!!schoolInfo?.streetNumber ? (
                  schoolInfo?.streetNumber
                ) : (
                  <BsDash />
                )}
              </div>
            </div>
            <div className="main_detail">
              <div className="nav_title">Street</div>
              <div className="nav_text">
                {!!schoolInfo?.street ? (
                  schoolInfo?.street
                ) : (
                  <BsDash />
                )}
              </div>
            </div>
            <div className="main_detail">
              <div className="nav_title">City/Town</div>
              <div className="nav_text">
                {!!schoolInfo?.city ? schoolInfo?.city : <BsDash />}
              </div>
            </div>
            <div className="main_detail">
              <div className="nav_title">Region/Province</div>
              <div className="nav_text">
                {!!schoolInfo?.region ? schoolInfo?.region : <BsDash />}
              </div>
            </div>
            <div className="main_detail last_nav_text">
              <div className="nav_title">Country</div>
              <div className="nav_text">
                {!!schoolInfo?.country?.name ? (
                  schoolInfo?.country?.name
                ) : (
                  <BsDash />
                )}
              </div>
            </div>
            <div>
              <ActionButtonOrangeSecondary
                text="Close"
                inline={true}
                onClick={() => {
                  handleClose();
                }}
              />
            </div>
          </SchoolViewWrapper>
      </Box>
    </Modal>
  );
};

export default ViewSchoolModal;
