import { useContext, useState } from "react";
import { ModalContext } from "../../contexts/modalContext";
import { OrganizationsContext } from "../../contexts/organizationsContext";
import { AddGroup } from "../../api";
import { SchoolsContext } from "../../contexts/schoolsContext";
import { LogInContext } from "../../contexts/logInContext";
import { GradesContext } from "../../contexts/gradesContext";
import {
  GroupModalWrapper,
  GroupModalWrapperSmall,
  ModalWrapper,
  SelectGroupModalWrapper,
} from "./Modals.styles";
import { errors } from "../../utils/validation";
import CustomSelect from "../InputFields/CustomSelect";
import { ModalCreateButtons } from "./ModalButtons";
import { addElement, createDate } from "../../utils/constants";
import { GroupsContext } from "../../contexts/groupsContext";
import { CustomInput } from "../InputFields/CustomInput";
import { warning } from "../../utils/toastify";

const AddGroupModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal } = useContext(ModalContext);
  const { organizationId } = useContext(OrganizationsContext);
  const { groups, setGroups } = useContext(GroupsContext);
  const { token, role } = useContext(LogInContext);
  const { schoolId } = useContext(SchoolsContext);
  const { gradeOptions } = useContext(GradesContext);
  const [gradeId, setGradeId] = useState(0);
  const [error, setError] = useState({});
  const [friendlyName, setFriendlyName] = useState('');

  const handleSelectGrade = (e) => {
    setGradeId(e.id);
    delete error.grade;
  };

  const handleSubmitValidations = () => {
    let errorCopy = { ...error };
    delete errorCopy.apiFail;

    gradeId === 0 && (errorCopy.grade = errors.gradeRequiredMessage);

    if (Object.keys(errorCopy).length > 0) {
      warning("Required field/s must be filled");
    }

    setError(errorCopy);
    return Object.keys(errorCopy).length === 0;
  };

  const handleAddGroupSubmit = async () => {
    const isValid = handleSubmitValidations();

    if (!isValid) {
      return false;
    }

    const responseData = await AddGroup(
      organizationId,
      schoolId,
      gradeId,
      friendlyName,
      token
    );
    if (responseData.status === "success") {
      responseData.data.group.createdAt = createDate(new Date()).dobFormat;
      addElement(groups, setGroups, responseData.data.group);
      setShowModal(false);
    } else {
      setError({
        apiFail: errors.apiFail,
      });
    }
  };

  const handleCheckSelections = () => {
    if (role === "ADMIN") {
      return !(organizationId && schoolId);
    }
    return schoolId === 0;
  };

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          {handleCheckSelections() ? (
            <GroupModalWrapperSmall>
              <h3>
                Please select {role === "ADMIN" && "both organization and"}{" "}
                school first.
              </h3>
            </GroupModalWrapperSmall>
          ) : (
            <GroupModalWrapper>
              <h2> Add New Group </h2>
              <SelectGroupModalWrapper>
                <CustomInput
                  input={{title: "Friendly Name", id: "name", type: "text", name: "name"}}
                  onChange={(e) => setFriendlyName(e.target.value)}
                  className="input--student input--name"
                />
                <CustomSelect
                  title="Pick a grade"
                  required="*"
                  options={gradeOptions}
                  placeholder="Select Grade"
                  onChange={handleSelectGrade}
                  className="input--grade"
                  error={error.grade}
                />
                <ModalCreateButtons
                  text="Add Group"
                  onClick={handleAddGroupSubmit}
                  className="modal__buttons--group"
                  error={error.apiFail}
                />
              </SelectGroupModalWrapper>
            </GroupModalWrapper>
          )}
        </ModalWrapper>
      )}
    </>
  );
};

export default AddGroupModal;
