import React, { useContext } from "react";
import { ModalContext } from "../../../contexts/modalContext";
import { ModalWrapper, ViewSchoolWrapper } from "../Modals.styles";
import { SchoolsContext } from "../../../contexts/schoolsContext";
import {
  ActionButtonOrange,
  ActionButtonOrangeSecondary,
} from "../../Buttons/ActionButton";
import { useEffect } from "react";
import { GetOrganization } from "../../../api";
import { useState } from "react";
import { LogInContext } from "../../../contexts/logInContext";
import { BsDash } from "react-icons/bs";

const OrganizationInfoModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal, setModalType } = useContext(ModalContext);
  const { org } = useContext(SchoolsContext);
  const [organization, setOrganization] = useState({});
  const { token } = useContext(LogInContext);

  useEffect(() => {
    async function fetchOrganizations() {
      const responseData = await GetOrganization(token, org);

      if (responseData.status === "success") {
        setOrganization(responseData.data.organization);
      } else {
        setOrganization({});
      }
    }

    fetchOrganizations();
  }, [token]);

  const handleEditModal = () => {
    setShowModal(true);
    setModalType("edit organization");
  };

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <ViewSchoolWrapper style={{ height: "auto", padding: "1em 2.5rem" }}>
            <ActionButtonOrangeSecondary
              inline={true}
              text={"Organization Information"}
              style={{ cursor: "default", marginBottom: "10px" }}
            />
            <div className="main_detail">
              <div className="nav_title">Organization Name</div>
              <div className="nav_text">
                {!!organization?.name ? organization?.name : <BsDash />}
              </div>
            </div>
            <div className="main_detail">
              <div className="nav_title">Organization Website</div>
              <div className="nav_text">
                {!!organization?.link ? organization?.link : <BsDash />}
              </div>
            </div>

            <div className="main_detail">
              <div className="nav_title">Owner First Name</div>
              <div className="nav_text">
                {!!organization?.owner?.name ? (
                  organization?.owner?.name
                ) : (
                  <BsDash />
                )}
              </div>
            </div>
            <div className="main_detail">
              <div className="nav_title">Owner Last Name</div>
              <div className="nav_text">
                {!!organization?.owner?.lastName ? (
                  organization?.owner?.lastName
                ) : (
                  <BsDash />
                )}
              </div>
            </div>
            <div className="main_detail">
              <div className="nav_title">Owner Email</div>
              <div className="nav_text">
                {!!organization?.owner?.email ? (
                  organization?.owner?.email
                ) : (
                  <BsDash />
                )}
              </div>
            </div>
            <div className="main_detail">
              <div className="nav_title">Street Number</div>
              <div className="nav_text">
                {!!organization?.streetNumber ? (
                  organization?.streetNumber
                ) : (
                  <BsDash />
                )}
              </div>
            </div>
            <div className="main_detail">
              <div className="nav_title">Street</div>
              <div className="nav_text">
                {!!organization?.street ? organization?.street : <BsDash />}
              </div>
            </div>
            <div className="main_detail">
              <div className="nav_title">City/Town</div>
              <div className="nav_text">
                {!!organization?.city ? organization?.city : <BsDash />}
              </div>
            </div>
            <div className="main_detail">
              <div className="nav_title">Region/Province</div>
              <div className="nav_text">
                {!!organization?.region ? organization?.region : <BsDash />}
              </div>
            </div>
            <div className="main_detail last_nav_text">
              <div className="nav_title">Country</div>
              <div className="nav_text">
                {!!organization?.country?.name ? (
                  organization?.country?.name
                ) : (
                  <BsDash />
                )}
              </div>
            </div>
            <div style={{ marginTop: "15px", display: "flex" }}>
              <ActionButtonOrange
                text="Edit"
                onClick={() => {
                  handleEditModal();
                }}
              />
              <ActionButtonOrangeSecondary
                inline={true}
                text="Close"
                onClick={() => {
                  setShowModal(false);
                }}
              />
            </div>
          </ViewSchoolWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default OrganizationInfoModal;
