import React, { useContext, useState } from "react";
import { AddMaterial, CreateLesson, CreateSkill,GetLessons } from "../../../api";
import { GradesContext } from "../../../contexts/gradesContext";
import { LessonsContext } from "../../../contexts/lessonsContext";
import { LogInContext } from "../../../contexts/logInContext";
import { ModalContext } from "../../../contexts/modalContext";
import { OrganizationsContext } from "../../../contexts/organizationsContext";
import {
  SelectGradeWrapper,
  CheckboxWrapper,
} from "../../../Pages/Page.styles";
import {
  errors,
  isValidLink,
  isValidMaterial,
  toProperCase
} from "../../../utils/validation";
import { CustomInput } from "../../InputFields/CustomInput";
import { materialInputFields } from "../../InputFields/InputFields";
import {
  ActionSectionWrapper,
  AddMaterialModalWrapper,
  AddMaterialWrapper,
  ModalWrapper,
} from "../Modals.styles";
import childrenIllustration from "../../../assets/lessons-illustration2.svg";
import CustomMultiSelect from "../../InputFields/CustomMultiSelect";
import { ModalCreateButtons } from "../ModalButtons";
import { handleOnSubmit } from "../../../utils/constants";
import CustomSelect from "../../InputFields/CustomSelect";
import { ActionButtonGreen } from "../../Buttons/ActionButton";
import CreatableSelect from "react-select/creatable";
import { warning } from "../../../utils/toastify";
// import useDrivePicker from 'react-google-drive-picker';

const defaultMaterial = {};

const AddMaterialModal = ({ modalRef, closeModal }) => {
  const { organizationId } = useContext(OrganizationsContext);
  const { gradeOptions } = useContext(GradesContext);
  const { showModal, setShowModal } = useContext(ModalContext);
  const { token } = useContext(LogInContext);
  const { activeTab, materials, setMaterials, skillsOptions, lessonsOptions,setLessons } =
    useContext(LessonsContext);
  const [newMaterial, setNewMaterial] = useState([]);
  const [error, setError] = useState({});
  const [grades, setGrades] = useState([]);
  const [skill, setSkill] = useState([]);
  const [addSkill, setAddSkill] = useState(false);
  let [lesson, setLesson] = useState("");
  const [addLesson, setAddLesson] = useState(false);
  // const [openPicker, data, authResponse] = useDrivePicker();

  const handleMaterialInput = (e) => {
    let errorCopy = { ...error };
    delete errorCopy.response;

    setNewMaterial({ ...newMaterial, [e.target.name]: e.target.value });

    if (e.target.name === "name") {
      const isValid = isValidMaterial(e.target.value);
      isValid
        ? delete errorCopy.title
        : (errorCopy.title = errors.titleErrorMessage);
    } else if (e.target.name === "details") {
      const isValid = isValidLink(e.target.value);
      isValid
        ? delete errorCopy.link
        : (errorCopy.link = errors.linkErrorMessage);
    } else if (e.target.name === "new-lesson") {
      if (newMaterial.lessonTopicId) {
        newMaterial.lessonTopicId = ""
      };
      setLesson(e.target.value);
    }
    setError(errorCopy);
  };

  const handleSelectGrade = (e) => {
    let errorCopy = { ...error };

    let newGrades = [...grades];

    if (e.target.checked) {
      const newGrade = gradeOptions.find((opt) => opt.id == e.target.id);
      if (newGrade) {
        newGrades.push(newGrade.id);
      }
    } else {
      newGrades = newGrades.filter((grade) => {
        return grade != e.target.id;
      });
    }

    setGrades(newGrades);

    setNewMaterial({ ...newMaterial, grades: newGrades });

    delete errorCopy.response;
    delete errorCopy.grade;
    setError(errorCopy);
  };

  const handleSelectSkills = (e) => {
    delete error.skillIds;
    delete error.response;
    const materialSkills = e.map((skill) => skill.id);
    setNewMaterial({ ...newMaterial, skillIds: materialSkills });
  };

  const handleSelectLessons = (e) => {
    delete error.lessonTopicId;
    setNewMaterial({ ...newMaterial, lessonTopicId: e.id });
    if(!!lesson) setLesson("");
    delete error.response;
  };

  const handleSubmitValidations = () => {
    let errorCopy = { ...error };
    delete errorCopy.apiFail;
    delete errorCopy.lessonTopicId;
    delete errorCopy.skillIds;
    !newMaterial.name && (errorCopy.title = errors.titleErrorMessage);
    !isValidLink(newMaterial.details) &&
      (errorCopy.link = errors.linkErrorMessage);
    !newMaterial.details && (errorCopy.link = errors.linkRequiredMessage);
    !newMaterial.grades && (errorCopy.grade = errors.gradeRequiredMessage);
    if(!newMaterial.new-lesson){
      !newMaterial.lessonTopicId && (errorCopy.lessonTopicId = errors.lessonTopicIdMessage)
    } else {
      delete errorCopy.lessonTopicId;
    }
    !newMaterial.skillIds && (errorCopy.skillIds = errors.skillIdsMessage)

    if (Object.keys(errorCopy).length > 0) {
      warning("Required field/s must be filled");
    }

    setError(errorCopy);
    return Object.keys(errorCopy).length === 0;
  };
  async function  getlesson() {
    const responseData = await GetLessons(organizationId,token);
    if (responseData.status === 'success') {
      setLessons(
        responseData.data.lessonTopics.map((lesson) => ({
          ...lesson,
          name: toProperCase(lesson.name),
        }))
      );
    } 
  }

  const handleAddMaterial = async () => {
    const isValid = handleSubmitValidations();
    
    if (!isValid) {
      return false;
    }
    
    const materialCopy = { ...newMaterial };
    let errorCopy = { ...error };

    let skillId = [];
    skillId = materialCopy.skillIds;
    
    let arr = [];
    if (skill.length > 0) {
      for (let i = 0; i < skill.length; i++) {
        const responseData = await CreateSkill({name: skill[i].label}, token);
        if (responseData.status === 'success') {
          arr.push(responseData.data.id);
        } 
        if (responseData.code === 100) {
          setError({
            apiFail: responseData.data
          });
          return;
        }
      }
    }

    let lessonId;
    if (!!lesson) {
      const responseData = await CreateLesson({ name: lesson }, token);
      if (responseData.status === 'success') {
        lessonId = responseData.data.id;
      } 
      if (responseData.code === 100) {
        setError({
          apiFail: responseData.data
        });
        return;
      }
    }

    let newSkillId = [];
    newSkillId = arr.concat(skillId);

    materialCopy.type = activeTab === "lesson" ? "lesson" : "activity";
    materialCopy.skillIds = newSkillId;
    materialCopy.lessonTopicId = !!lessonId ? lessonId : materialCopy.lessonTopicId;
    
    const responseData = await AddMaterial(organizationId, materialCopy, token);
    if (responseData.status === "success") {
      let materialSkills = [];

      if (newMaterial.skillIds) {
        materialSkills = newMaterial.skillIds.map((skillId) => ({
          id: skillId,
        }));
      }
      responseData.data.grade = newMaterial.grade;
      const addMaterial = { ...responseData.data, skills: materialSkills };
      setMaterials([addMaterial, ...materials]);
      setShowModal(false);
      getlesson();
    } else {
      errorCopy.apiFail = errors.apiFail;
    }

    setNewMaterial(defaultMaterial);
    setError(errorCopy);
  };

  // const handleFilePicker = () => {
  //   openPicker({
  //     clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  //     developerKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  //     viewId: "DOCS",
  //     showUploadView: true,
  //     showUploadFolders: true,
  //     supportDrives: true,
  //     multiselect: false
  //   })
  // };

  // useEffect(() => {
  //    if(data) {
  //     data.docs.map((i) => console.log(i));
  //    }
  // }, [data]);

  materialInputFields.material.value = newMaterial.name;
  materialInputFields.link.value = newMaterial.details;

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <AddMaterialModalWrapper>
            <ActionSectionWrapper>
              <div>
                <h2 className="action--title">
                  Add {activeTab === "lesson" ? "Lesson" : "Activity"}
                </h2>
                {/* <button onClick={() => handleFilePicker()}>Link</button> */}
                <AddMaterialWrapper>
                  <CustomInput
                    input={materialInputFields.material}
                    onChange={handleMaterialInput}
                    error={error.title}
                    onSubmit={(e) => handleOnSubmit(e, handleAddMaterial)}
                    className="input--title"
                  />
                  <CustomInput
                    input={materialInputFields.link}
                    onChange={handleMaterialInput}
                    error={error.link}
                    onSubmit={(e) => handleOnSubmit(e, handleAddMaterial)}
                    className="input--link"
                  />
                  <h4 className="grade--title">Grade <span style={{color: "red"}}>*</span></h4>
                  <CheckboxWrapper>
                    {gradeOptions.map((grade) => (
                      <div key={grade.id}>
                        <input
                          onChange={handleSelectGrade}
                          id={grade.id}
                          type="checkbox"
                          value={grade.value}
                        />
                        <label htmlFor="grade">{grade.value}</label>
                      </div>
                    ))}
                  </CheckboxWrapper>
                  {<p style={{ color: "red", fontSize: "0.8rem", fontWeight: "400", paddingTop: '0.3rem'}}>{error.grade}</p>}
                  <SelectGradeWrapper>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "center"
                      }}
                    >
                      <h4 className="input--skills">Lesson Topics <span style={{color: "red"}}>*</span></h4>
                      <ActionButtonGreen
                        text="Add"
                        size="small"
                        onClick={() => {
                          setAddLesson(true);
                        }}
                      />
                    </div>
                    <CustomSelect
                      options={lessonsOptions}
                      placeholder="Select lessons"
                      onChange={handleSelectLessons}
                      isDisabled={addLesson}
                      className="input--country"
                    />
                     {<p style={{ color: "red", fontSize: "0.8rem", fontWeight: "400", paddingTop: '0.3rem'}} >{error.lessonTopicId}</p>}
                  </SelectGradeWrapper>
                  {addLesson && (
                      <CustomInput
                        input={materialInputFields.lesson}
                        onChange={(e) => {delete error.lessonTopicId; handleMaterialInput(e);}}
                        error={error.lession}
                        onSubmit={(e) => handleOnSubmit(e, handleAddMaterial)}
                        className="input--new-lession"
                      />
                  )}

                  <SelectGradeWrapper>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "center",
                        marginBottom: "0.5rem"
                      }} 
                    >
                      <h4 className="input--skills">Skills <span style={{color: "red"}}>*</span></h4>
                      <ActionButtonGreen
                        text="Add"
                        size="small"
                        onClick={() => {
                          setAddSkill(true);
                        }}
                      />
                    </div>
                    <CustomMultiSelect
                      options={skillsOptions}
                      placeholder="Select Skills"
                      onChange={handleSelectSkills}
                      menuPlacement={"top"}
                      className="input--country"
                    />
                     {<p style={{ color: "red", fontSize: "0.8rem", fontWeight: "400", paddingTop: '0.3rem'}}>{error.skillIds}</p>}
                  </SelectGradeWrapper>
                  {addSkill && (
                    <SelectGradeWrapper>
                    <h4
                      className="input--skills"
                      style={{ marginBottom: "0.5rem" }}
                    >
                      Add New Skills
                    </h4>
                    <CreatableSelect
                      isMulti
                      name="skills"
                      onChange={(e) => {setSkill(e); delete error.skillIds;}}
                      value={skill}
                      placeholder="Add New Skills"
                      className="select--skills"
                    />
                  </SelectGradeWrapper>
                  )}

                  <div className="response"></div>
                  <ModalCreateButtons
                    text={
                      activeTab === "lesson" ? "Save Lesson" : "Save Activity"
                    }
                    onClick={handleAddMaterial}
                    error={error.apiFail}
                    className="button--save"
                  />
                </AddMaterialWrapper>
              </div>

              <img src={childrenIllustration} alt="" />
            </ActionSectionWrapper>
          </AddMaterialModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default AddMaterialModal;
