import React, {
  useContext,
  useMemo,
  useRef,
  useCallback,
  useEffect,
} from "react";
import { ModalContext } from "../../contexts/modalContext";
import AddGroupModal from "./GroupModal";
import FeedbackModal from "./FeedbackModal";
import AssessmentModal from "./AssessmentModal";
import EditOrganizationModal from "./Organization Modals/EditOrganizationModal";
import EditUserModal from "./Organization Modals/EditUserModal";
import AddOrganizationModal from "./Organization Modals/AddOrganizationModal";
import AddUserModal from "./Organization Modals/AddUserModal";
import LoginAsUserModal from "./Organization Modals/LogInAsUserModal";
import EditStudentModal from "./Student Modals/EditStudentModal";
import RemoveStudentModal from "./Student Modals/RemoveStudentModal";
import AddStudentModal from "./Student Modals/AddStudentModal";
import EditMaterialModal from "./Material Modals/EditMaterialModal";
import RemoveMaterialModal from "./Material Modals/RemoveMaterialModal";
import AddStudentMaterialModal from "./Material Modals/AddStudentMaterialModal";
import EditStudentMaterialModal from "./Material Modals/EditStudentMaterialModal";
import AddStudentNoteModal from "./Material Modals/AddStudentNoteModal";
import EditStudentNoteModal from "./Material Modals/EditStudentNoteModal";
import RemoveNoteModal from "./Material Modals/RemoveNoteModal";
import AddMaterialModal from "./Material Modals/AddMaterialModal";
import AddSchoolModal from "./SchoolModals/AddSchoolModal";
import EditSchoolModal from "./SchoolModals/EditSchoolModal";
import DiscardSkillsModal from "./Material Modals/DiscardSkillsModal";
import DeleteSkillsModal from "./Material Modals/DeleteSkillsModal";
import ActivityPhotoModal from "./ActivityPhotoModal";
import MoveStudentsModal from "./Student Modals/MoveStudentsModal";
import ApproveStudentModal from "./Student Modals/ApproveStudentModal";
import ChangeStudentIDModal from "./Student Modals/ChangeStudentIDModal";
import ApproveSchoolModal from "./SchoolModals/ApproveSchoolModal";
import DeleteLessonsModal from "../Cards/Leassons Cards/DeleteLessonsModal";
import ApproveLessonModal from "../Cards/Leassons Cards/ApproveLessonModal";
import RemoveUserModal from "./Organization Modals/RemoveUserModal";
import ApproveSkillModal from "../Cards/Leassons Cards/ApproveSkillModal";
import RejectSkillModal from "../Cards/Leassons Cards/RejectSkillModal";
import AssignModal from "./AssignSchoolModals/AssignModal";
import RemoveSchoolModal from "./AssignSchoolModals/RemoveSchoolModal";
import ModifySchoolApproveModal from "./SchoolModals/ModifySchoolApproveModal";
import AddStudentCSVModal from './Student Modals/AddStudentCSVModal';
import SchoolInfoModal from "./SchoolModals/SchoolInfoModal";
import OrganizationInfoModal from "./Organization Modals/OrganizationInfoModal";
import AssignSchoolToOrgModal from "./SchoolModals/AssignSchoolToOrgModal";
import AssignSchoolToUserModal from "./SchoolModals/AssignSchoolToUserModal";
import MergeSchoolModal from "./SchoolModals/MergeSchoolModal";
import AssignGroupToUserModal from "./SchoolModals/AssignGroupToUserModal";
import StudentListModal from "./SchoolModals/StudentListModal";

const ModalFactory = () => {
  const { showModal, setShowModal, modalType } =
    useContext(ModalContext);
  const modalRef = useRef();

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  const modalKeyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) {
        setShowModal(false);
      }
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", modalKeyPress);
    return () => document.removeEventListener("keydown", modalKeyPress);
  }, [modalKeyPress]);

  const checkModalType = useMemo(() => {
    switch (modalType) {
      case "add school":
        return <AddSchoolModal modalRef={modalRef} closeModal={closeModal} />;

      case "edit school":
        return <EditSchoolModal modalRef={modalRef} closeModal={closeModal} />;

      case "approve school":
        return (
          <ApproveSchoolModal modalRef={modalRef} closeModal={closeModal} />
        );

      case "school info":
        return <SchoolInfoModal modalRef={modalRef} closeModal={closeModal} />;

      case "org info":
        return <OrganizationInfoModal modalRef={modalRef} closeModal={closeModal} />;

      case "assign school":
        return <AssignModal modalRef={modalRef} closeModal={closeModal} />;

      case "modify approve school":
        return (
          <ModifySchoolApproveModal
            modalRef={modalRef}
            closeModal={closeModal}
          />
        );

      case "assign school to organization":
        return (
          <AssignSchoolToOrgModal
            modalRef={modalRef}
            closeModal={closeModal}
          />
        );

      case "assign school to facilitator":
        return (
          <AssignSchoolToUserModal
            modalRef={modalRef}
            closeModal={closeModal}
          />
        );  

      case "assign facilitator to group":
        return (
          <AssignGroupToUserModal
            modalRef={modalRef}
            closeModal={closeModal}
          />
        ); 

      case "merge school":
        return (
          <MergeSchoolModal
            modalRef={modalRef}
            closeModal={closeModal}
          />
        );  

      case "school list":
        return (
          <StudentListModal
            modalRef={modalRef}
            closeModal={closeModal}
          />
        );

      case "remove school":
        return (
          <RemoveSchoolModal modalRef={modalRef} closeModal={closeModal} />
        );

      case "approve lesson":
        return (
          <ApproveLessonModal modalRef={modalRef} closeModal={closeModal} />
        );

      case "approve skill":
        return (
          <ApproveSkillModal modalRef={modalRef} closeModal={closeModal} />
        );

      case "reject skill":
        return <RejectSkillModal modalRef={modalRef} closeModal={closeModal} />;

      case "add group":
        return <AddGroupModal modalRef={modalRef} closeModal={closeModal} />;

      case "add student":
        return <AddStudentModal modalRef={modalRef} closeModal={closeModal} />;

      case "edit student":
        return <EditStudentModal modalRef={modalRef} closeModal={closeModal} />;

      case "move students":
        return (
          <MoveStudentsModal modalRef={modalRef} closeModal={closeModal} />
        );

      case "Add Students from CSV":
        return (
          <AddStudentCSVModal modalRef={modalRef} closeModal={closeModal} />
        );

      case "approve student":
        return (
          <ApproveStudentModal modalRef={modalRef} closeModal={closeModal} />
        );

      case "remove student":
        return (
          <RemoveStudentModal modalRef={modalRef} closeModal={closeModal} />
        );

      case "change student id":
        return (
          <ChangeStudentIDModal modalRef={modalRef} closeModal={closeModal} />
        );

      case "edit assessments":
        return <AssessmentModal modalRef={modalRef} closeModal={closeModal} />;

      case "add material":
        return <AddMaterialModal modalRef={modalRef} closeModal={closeModal} />;

      case "edit material":
        return (
          <EditMaterialModal modalRef={modalRef} closeModal={closeModal} />
        );

      case "remove material":
        return (
          <RemoveMaterialModal modalRef={modalRef} closeModal={closeModal} />
        );

      case "add note":
        return (
          <AddStudentNoteModal modalRef={modalRef} closeModal={closeModal} />
        );

      case "edit note":
        return (
          <EditStudentNoteModal modalRef={modalRef} closeModal={closeModal} />
        );

      case "remove note":
        return <RemoveNoteModal modalRef={modalRef} closeModal={closeModal} />;

      case "view feedback":
        return <FeedbackModal modalRef={modalRef} closeModal={closeModal} />;

      case "add organization":
        return (
          <AddOrganizationModal modalRef={modalRef} closeModal={closeModal} />
        );

      case "add user":
        return <AddUserModal modalRef={modalRef} closeModal={closeModal} />;

      case "edit user":
        return <EditUserModal modalRef={modalRef} closeModal={closeModal} />;

      case "remove user":
        return <RemoveUserModal modalRef={modalRef} closeModal={closeModal} />;

      case "log in as user":
        return <LoginAsUserModal modalRef={modalRef} closeModal={closeModal} />;

      case "edit organization":
        return (
          <EditOrganizationModal modalRef={modalRef} closeModal={closeModal} />
        );

      case "add student material":
        return (
          <AddStudentMaterialModal
            modalRef={modalRef}
            closeModal={closeModal}
          />
        );

      case "edit student material":
        return (
          <EditStudentMaterialModal
            modalRef={modalRef}
            closeModal={closeModal}
          />
        );

      case "display photo":
        return (
          <ActivityPhotoModal modalRef={modalRef} closeModal={closeModal} />
        );

      case "discard skills":
        return (
          <DiscardSkillsModal modalRef={modalRef} closeModal={closeModal} />
        );

      case "delete skills":
        return (
          <DeleteSkillsModal modalRef={modalRef} closeModal={closeModal} />
        );

      case "discard lessons":
        return (
          <DiscardSkillsModal modalRef={modalRef} closeModal={closeModal} />
        );

      case "delete lessons":
        return (
          <DeleteLessonsModal modalRef={modalRef} closeModal={closeModal} />
        );

      default:
        break;
    }
    // eslint-disable-next-line
  }, [modalType]);

  return <div>{showModal && checkModalType}</div>;
};

export default ModalFactory;
