import styled from 'styled-components';
import searchIcon from '../../assets/icons/search-icon.svg';
import closeIcon from '../../assets/icons/close-icon.svg';
export const StyledInput = styled.input`
  margin: 0.5em 0;
  background: white;
  border-radius: var(--border-radius);
  border: 2px solid var(--blue-200);
  font-size: 1rem;
  height: 1.7rem;
  width: 100%;
  padding: 0 1em;

  &:focus-visible {
    border: 2px solid var(--blue-200);
    border-radius: var(--border-radius);
    outline: none;
  }

  &:focus {
    background-color: var(--blue-100);
  }
`;

export const StyledTextarea = styled.textarea`
  margin: 0.5em 0;
  background: white;
  border-radius: var(--border-radius);
  border: 2px solid var(--blue-200);
  font-size: 1rem;
  height: 5rem;
  width: 100%;
  padding: 0.5em 1em;

  &:focus-visible {
    border: 2px solid var(--blue-200);
    border-radius: var(--border-radius);
    outline: none;
  }

  &:focus {
    background-color: var(--blue-100);
  }
`;

export const SelectTitle = styled.h4`
  margin-bottom: 0.5rem;

  span {
    color: red;
  }
`;

export const StyledSearchWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 1rem;
  padding: 0 2rem;

  input {
    padding: 0 2rem;
    width: 50vw;
    position: relative;
  }

  div {
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: 50%;
    left: 2.7rem;
    transform: translate(0, -50%);
    background: url(${searchIcon}) center/cover no-repeat;
  }

  button {
    position: absolute;
    top: 50%;
    right: 18px;
    transform: translate(0, -50%);
    background: url(${closeIcon}) center/cover no-repeat;
    width: 0.7rem;
    height: 0.7rem;
    outline: none;
    border: none;
  }
`;
