import React, { useContext, useEffect, useState } from "react";
import { GetCountry, GetSchools } from "../api";
import { LogInContext } from "./logInContext";

export const SchoolsContext = React.createContext();

const SchoolsProvider = (props) => {
  const { token } = useContext(LogInContext);
  let [schools, setSchools] = useState([]);
  let [country, setCountry] = useState([]);
  const [schoolId, setSchoolId] = useState(0);
  const [schoolName, setSchoolName] = useState("");
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [countryCodeOptions, setCountryCodeOptions] = useState([]);
  let [schoolToEdit, setSchoolToEdit] = useState({
    name: "",
    long: "",
    lat: "",
    address: "",
    streetNumber: "",
    street: "",
    city: "",
    region: "",
    countryId: null,
    placeId: "",
    country: null
  });
  const [school, setSchool] = useState({});
  const [user, setUser] = useState({});
  let [assignSchool, setAssignSchool] = useState([]);
  let [schoolInfo, setSchoolInfo] = useState({});
  let [org, setOrg] = useState("");

  useEffect(() => {
    async function fetchSchools() {
      const responseData = await GetSchools(token);

      if (responseData.status === "success") {
        setSchools(responseData.data.schools);
      } else {
        setSchools([]);
      }
    }
    fetchSchools();
  }, [token]);

  useEffect(() => {
    async function fetchCountry() {
      const responseData = await GetCountry(token);

      if (responseData.status === "success") {
        setCountry(responseData.data.country);
      } else {
        setCountry([]);
      }
    }
    fetchCountry();
  }, [token]);

  useEffect(() => {
    setCountryOptions(
      country.map((item) => ({
        value: item.name,
        label: item.name,
        id: item.id,
      }))
    );

    setCountryCodeOptions(
      country.map((item) => ({
        value: item.phonecode,
        label: item.phonecode,
        id: item.id,
      }))
    );
  }, [country]);

  useEffect(() => {
    setSchoolOptions(
      schools.map((school) => ({
        value: school.name,
        label: school.name,
        id: school.id,
      }))
    );
  }, [schools]);

  return (
    <SchoolsContext.Provider
      value={{
        schools,
        setSchools,
        schoolId,
        setSchoolId,
        schoolName,
        setSchoolName,
        schoolOptions,
        schoolToEdit,
        setSchoolToEdit,
        school,
        setSchool,
        setUser,
        user,
        assignSchool,
        setAssignSchool,
        countryOptions,
        setCountryOptions,
        country,
        countryCodeOptions, 
        setCountryCodeOptions,
        schoolInfo, 
        setSchoolInfo,
        org, 
        setOrg
      }}
    >
      {props.children}
    </SchoolsContext.Provider>
  );
};

export default SchoolsProvider;
