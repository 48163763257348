import React from 'react';
import { handleResetSearchValues } from '../../utils/constants';
import { StyledInput, StyledSearchWrapper, StyledTextarea } from './InputFields.styles';

export const CustomInput = ({
  input,
  error,
  onChange,
  onSubmit,
  className,
  disabled
}) => {
  const { id, title, type, required, name, value, placeholder, max, min, onKeyDown } = input;
  return (
    <div className={className}>
      <form onSubmit={onSubmit}>
        {title && <h4>{title} <span style={{color: "red", marginLeft: "0"}}>{required}</span></h4>}
        <StyledInput
          id={id}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          max={max}
          min={min}
          disabled={disabled}
          onKeyDown={onKeyDown}
        />
        {error && <h5>{error}</h5>}
      </form>
    </div>
  );
};

export const CustomTextarea = ({
  textarea,
  error,
  onChange,
  onSubmit,
  className,
}) => {
  const { id, title, type, name, value, placeholder } = textarea;
  return (
    <div className={className}>
      <form onSubmit={onSubmit}>
        {title && <h4>{title}</h4>}
        <StyledTextarea
          id={id}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
        />
        {error && <h5>{error}</h5>}
      </form>
    </div>
  );
};

export const CustomSearch = ({
  value,
  setSearchStates,
  setCurrentPage,
  onChange,
  setFilterActive,
  resetArray,
  placeholder
}) => {
  return (
    <StyledSearchWrapper>
      <StyledInput
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
      <div className="search-icon"></div>
      {value.length > 0 && (
        <button
          onClick={() => {
            handleResetSearchValues(
              setSearchStates.displaySetter,
              setSearchStates.valueSetter
            );
            setCurrentPage && setCurrentPage(0);
            resetArray && resetArray([]);
            setFilterActive && setFilterActive(false);
          }}
        />
      )}
    </StyledSearchWrapper>
  );
};
