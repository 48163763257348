import React from 'react';

const NoDataDisplay = ({ message, className, image, alt, style }) => {
  return (
    <>
      <h4 className="select-org header_title" style={style}>{message}</h4>
      <div>
        <img className={className} src={image} alt={alt} />
      </div>
    </>
  );
};

export default NoDataDisplay;
