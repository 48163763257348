import { useContext, useState } from "react";
import { EditUser } from "../../../api";
import { LogInContext } from "../../../contexts/logInContext";
import { ModalContext } from "../../../contexts/modalContext";
import {
  isValidName,
  errors,
  isValidEmail,
  isValidContactPhone,
} from "../../../utils/validation";
import { EditUserModalWrapper, ModalWrapper } from "../Modals.styles";
import { OrganizationsContext } from "../../../contexts/organizationsContext";
import { ModalCreateButtons } from "../ModalButtons";
import { CustomInput } from "../../InputFields/CustomInput";
import { inputUserFields } from "../../InputFields/InputFields";
import { editElement, handleOnSubmit } from "../../../utils/constants";
import CustomSelect from "../../InputFields/CustomSelect";
import { SchoolsContext } from "../../../contexts/schoolsContext";
import { warning } from "../../../utils/toastify";

const EditUserModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal } = useContext(ModalContext);
  const { organizationId, selectedUser, usersData, setUsersData } =
    useContext(OrganizationsContext);
  const { token } = useContext(LogInContext);
  const [editUserInfo, setEditUserInfo] = useState(selectedUser);
  const [error, setError] = useState({});
  const { countryOptions, countryCodeOptions } = useContext(SchoolsContext);
  const [countryCode, setCountryCode] = useState(
    !!editUserInfo?.country?.id ? editUserInfo?.country?.id : null
  );

  const handleEditUserInput = (e) => {
    let errorCopy = { ...error };

    setEditUserInfo({ ...editUserInfo, [e.target.name]: e.target.value });

    if (e.target.name === "name") {
      const isValid = isValidName(e.target.value);
      isValid
        ? delete errorCopy.name
        : (errorCopy.name = errors.requiredNameError);
    } else if (e.target.name === "email") {
      delete errorCopy.emailRequired;
      const isValid = isValidEmail(e.target.value);
      isValid
        ? delete errorCopy.email
        : (errorCopy.email = errors.emailErrorMessage);
    } else if (e.target.name === "lastName") {
      const isValid = isValidName(e.target.value);
      isValid
        ? delete errorCopy.lastName
        : (errorCopy.lastName = errors.requiredLnameError);
    } else if (e.target.name === "city") {
      const isValid = isValidName(e.target.value);
      isValid
        ? delete errorCopy.city
        : (errorCopy.city = errors.requiredCityError);
    } 

    setError(errorCopy);
  };

  const handleSubmitValidations = () => {
    let errorCopy = { ...error };
    delete errorCopy.apiFail;

    !editUserInfo.name && (errorCopy.name = errors.requiredFnameError);
    !editUserInfo.lastName && (errorCopy.lastName = errors.requiredLnameError);
    !editUserInfo.email && (errorCopy.email = errors.emailRequiredMessage);
    !editUserInfo.countryId &&
      (errorCopy.country = errors.requiredCountryError);
    !editUserInfo.countryId &&
      (errorCopy.countryCode = errors.countryCodeRequiredMessage);
    !editUserInfo.city && (errorCopy.city = errors.requiredCityError);
    if (!!editUserInfo.contactPhone) {
      const isValid = isValidContactPhone(editUserInfo.contactPhone);
      isValid
        ? delete errorCopy.contactNumber
        : (errorCopy.contactNumber = errors.contactNumberMessage);
    }

    if (Object.keys(errorCopy).length > 0) {
      warning("Required field/s must be filled");
    }

    setError(errorCopy);
    return Object.keys(errorCopy).length === 0;
  };

  const handleSubmitEdit = async () => {
    const isValid = handleSubmitValidations();

    if (!isValid) {
      return false;
    }

    const responseData = await EditUser(
      organizationId,
      selectedUser.id,
      editUserInfo,
      token
    );

    if (responseData.status === "success") {
      const keys = ["name", "email"];
      editElement(selectedUser.id, usersData, setUsersData, keys, editUserInfo);
      setShowModal(false);
    } else {
      setError({
        email: responseData.data,
      });
    }
  };

  const handleSelectCountry = (e) => {
    setEditUserInfo({ ...editUserInfo, countryId: e.id });
    setCountryCode(e?.id);
    delete error.country;
    delete error.countryCode;
  };

  inputUserFields.user.value = editUserInfo?.name;
  inputUserFields.lastName.value = editUserInfo?.lastName;
  inputUserFields.email.value = editUserInfo?.email;
  inputUserFields.city.value = editUserInfo?.city;
  inputUserFields.contactNumber.value = editUserInfo?.contactPhone;

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <EditUserModalWrapper>
            <div className="user--modal">
              <CustomInput
                input={inputUserFields.user}
                error={error.name}
                onChange={handleEditUserInput}
                onSubmit={(e) => handleOnSubmit(e, handleSubmitEdit)}
                className="input--name"
              />
              <CustomInput
                input={inputUserFields.lastName}
                error={error.lastName}
                onChange={handleEditUserInput}
                onSubmit={(e) => handleOnSubmit(e, handleSubmitEdit)}
                className="input--name"
              />
              <CustomInput
                input={inputUserFields.email}
                error={error.email}
                onChange={handleEditUserInput}
                onSubmit={(e) => handleOnSubmit(e, handleSubmitEdit)}
                className="input--name"
              />

              <CustomSelect
                title="Country"
                required="*"
                options={countryOptions}
                placeholder="Select country"
                defaultValue={countryOptions.find(
                  (elem) => elem?.id === countryCode
                )}
                onChange={(e) => handleSelectCountry(e)}
                error={error.country}
                className="input--name select--name"
              />

              <CustomInput
                input={inputUserFields.city}
                error={error.city}
                onChange={handleEditUserInput}
                onSubmit={(e) => handleOnSubmit(e, handleSubmitEdit)}
                className="input--name"
              />

              <CustomSelect
                title="Country Code"
                required="*"
                options={countryCodeOptions}
                value={countryCodeOptions.find(
                  (elem) => elem?.id === countryCode
                )}
                placeholder="Select country code"
                error={error.countryCode}
                className="input--name select--name"
              />

              <CustomInput
                input={inputUserFields.contactNumber}
                error={error.contactNumber}
                onChange={handleEditUserInput}
                onSubmit={(e) => handleOnSubmit(e, handleSubmitEdit)}
                className="input--name input--contact"
              />
            </div>
            <ModalCreateButtons
              text="Save"
              onClick={handleSubmitEdit}
              error={error.apiFail}
              className="modal__buttons--user add__user"
            />
          </EditUserModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default EditUserModal;
