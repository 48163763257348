import React from "react";
import styled from "styled-components";

const ActionButtonOrangeWrapper = styled.button`
  background: ${({ disabled, theme }) =>
    disabled ? `#e7b693` : theme === "primary" ? `var(--orange-200)` : "white"};
  box-shadow: 3px 3px 4px 0 hsl(0, 0%, 0%, 0.12);
  border: 2px solid
    ${({ disabled }) => (disabled ? "#e7b693" : "var(--orange-200)")};
  border-radius: var(--border-radius);
  padding: ${({ size }) => (size === "small" ? ".1em 1.5em" : ".4em 1.5em")};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: 600;
  color: ${({ theme, disabled }) =>
    disabled ? `white` : theme === "primary" ? "white" : `var(--orange-200)`};
  transition: 0.3s;
  margin-right: ${({ inline }) => (inline ? ".5rem" : "0")};
  white-space: nowrap;
  width: ${({ size }) => (size === "small" ? "5rem" : "45%")};
  &:hover,
  &:focus-visible {
    box-shadow: 3px 3px 4px 0 hsl(0, 0%, 0%, 0.25);
    outline: none;
    cursor: ${({ disabled }) => disabled && "not-allowed"};
  }

  &:active {
    box-shadow: 3px 3px 4px 0 hsl(0, 0%, 0%, 0.2);
  }
`;

const ActionButtonOrangeSecondaryWrapper = styled(ActionButtonOrangeWrapper)`
  width: 100%;
  margin: ${({ inline }) => (inline ? "0" : "0 0 0 1.5em")};
  padding: ${({ size }) => (size === "small" ? ".1em 0em" : ".5em 1.5em")};
  background: none;
  border: none;
  box-shadow: none;
  color: ${({ disabled }) =>
    disabled ? `var(--orange-150)` : `var(--orange-200)`};

  &:hover {
    cursor: ${({ disabled }) => disabled && "not-allowed"};
    background: none;
    color: ${({ disabled }) =>
      disabled ? `var(--orange-150)` : `var(--blue-300)`};
    box-shadow: none;
  }
`;

const ActionButtonGreenWrapper = styled(ActionButtonOrangeWrapper)`
  background: ${({ disabled }) => disabled ? "#e2f3e7" : "var(--green-200)"};
  color: ${({ disabled }) => disabled ? "#a9a9a9" : "var(--blue-300)"};
  border: ${({ disabled }) => disabled ? "2px solid #e2f3e7" : "2px solid var(--green-200)"};
  white-space: nowrap;
  width: 45%;
  &:hover,
  &:focus-visible {
    cursor: ${({ disabled }) => disabled && "not-allowed"};
    color: ${({ disabled }) => disabled ? "#a9a9a9" : "var(--blue-300)"};
    box-shadow: 2px 3px 4px 0 hsl(0, 0%, 0%, 0.25);
    background:  ${({ disabled }) => disabled ? "#e2f3e7" : "var(--green-200)"};
    outline: none;
  }

  &:active {
    box-shadow: 3px 3px 4px 0 hsl(0, 0%, 0%, 0.2);
  }
`;

export const ActionButtonOrange = ({
  disabled,
  size,
  theme,
  text,
  onClick,
  inline,
  style
}) => {
  return (
    <ActionButtonOrangeWrapper
      disabled={disabled}
      theme={theme}
      size={size}
      onClick={onClick}
      inline={inline}
      style={style}
    >
      {text}
    </ActionButtonOrangeWrapper>
  );
};

export const ActionButtonOrangeSecondary = ({
  text,
  onClick,
  size,
  inline,
  disabled = false,
  style
}) => {
  return (
    <ActionButtonOrangeSecondaryWrapper
      onClick={onClick}
      size={size}
      inline={inline}
      disabled={disabled}
      style={style}
    >
      {text}
    </ActionButtonOrangeSecondaryWrapper>
  );
};

export const ActionButtonGreen = ({ theme, text, size, onClick, disabled = false, style, className }) => {
  return (
    <ActionButtonGreenWrapper className={className} theme={theme} size={size} onClick={onClick} disabled={disabled} style={style}>
      {text}
    </ActionButtonGreenWrapper>
  );
};
