import React, { useState, useEffect, useContext } from "react";
import { LessonsContext } from "../../../contexts/lessonsContext";
import { ModalContext } from "../../../contexts/modalContext";
import { toProperCase, errors } from "../../../utils/validation";
import { AddMaterialToStudent, GetMaterials, GetSkills } from "../../../api";
import { OrganizationsContext } from "../../../contexts/organizationsContext";
import { LogInContext } from "../../../contexts/logInContext";
import { AddLessonModalWrapper, ModalWrapper } from "../Modals.styles";
import { StudentsContext } from "../../../contexts/studentsContext";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StaticDatePicker from "@mui/lab/StaticDatePicker";
import {
  minDate,
  maxDate,
  handleDateInput,
  addElement,
  createDate,
} from "../../../utils/constants";
import { ModalCreateButtons } from "../ModalButtons";
import CustomSelect from "../../InputFields/CustomSelect";
import { warning } from "../../../utils/toastify";
import CustomMultiSelect from "../../InputFields/CustomMultiSelect";

const AddStudentMaterialModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal } = useContext(ModalContext);
  const { organizationId } = useContext(OrganizationsContext);
  const { selectedStudent } = useContext(StudentsContext);
  const { activeTab, studentMaterials, setStudentMaterials, skillsOptions, setSkills } =
    useContext(LessonsContext);
  const { token } = useContext(LogInContext);
  const [error, setError] = useState({});
  const [materialOptions, setMaterialOptions] = useState({
    value: "",
    label: "",
  });
  const [addedMaterialInfo, setAddedMaterialInfo] = useState({
    name: "",
    groupId: selectedStudent?.group?.id,
  });
  const [defaultDate, setDefaultDate] = useState(new Date());

  useEffect(() => {
    async function fetchMaterials() {
      const responseData = await GetMaterials(organizationId, token);

      if (responseData.status === "success") {
        const materials = responseData.data
          .reverse()
          .filter((material) => material.type === activeTab);

        setMaterialOptions(
          materials.map((lesson) => ({
            value: lesson.name,
            label: lesson.name,
            details: lesson.details,
            materialId: lesson.id,
          }))
        );
      }
    }
    fetchMaterials();
    // eslint-disable-next-line
  }, [activeTab, selectedStudent]);

  const handleMaterialSelect = (e) => {
    setAddedMaterialInfo({
      ...addedMaterialInfo,
      name: e.value,
      materialId: e.materialId,
      date: e.details,
      groupId: selectedStudent?.group?.id,
    });
    delete error.title;
  };

  const handleSubmitValidations = () => {
    let errorCopy = {};

    if (!addedMaterialInfo.dob) {
      addedMaterialInfo.dob = `${createDate(defaultDate).dobFormat}`
    }

    !addedMaterialInfo.name && (errorCopy.title = errors.titleErrorMessage);
    !addedMaterialInfo.dob &&
      (errorCopy.dateError = errors.dobRequiredErrorMessage);

    if (Object.keys(errorCopy).length > 0) {
      warning("Required field/s must be filled");
    }

    setError(errorCopy);
    return Object.keys(errorCopy).length === 0;
  };

  const handleSubmitMaterial = async () => {
    const isValid = handleSubmitValidations();

    if (!isValid) {
      return false;
    }

    const responseData = await AddMaterialToStudent(
      organizationId,
      selectedStudent.id,
      addedMaterialInfo,
      token
      );

    if (responseData.status === "success") {
      responseData.data.name = addedMaterialInfo.name;
      responseData.data.linkId = responseData.data.id;
      responseData.data.skills = [];
      responseData.data.date = addedMaterialInfo.dob;
      addElement(studentMaterials, setStudentMaterials, responseData.data, 1);
      setShowModal(false);
    } else {
      setError({
        apiFail: errors.apiFail,
      });
    }
  };

  async function fetchSkills() {
    const responseData = await GetSkills(organizationId, token);
    if (responseData.status === 'success') {
      setSkills(
        responseData.data.skills.map((skill) => ({
          ...skill,
          name: toProperCase(skill.name),
        }))
      );
    } else {
      setSkills([]);
    }
  }

  useEffect(() => {
    fetchSkills();
  }, [token, setSkills, organizationId]);

  const handleSelectSkills = (e) => {
    const materialSkills = e.map((skill) => skill.id);
    setAddedMaterialInfo({ ...addedMaterialInfo, skillIds: materialSkills });
  };

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <AddLessonModalWrapper>
            <h2>Add {toProperCase(activeTab)}</h2>
           <div style={{display: "flex", flexDirection: "column", gap: "15px"}}>
           <CustomSelect
              placeholder={`${toProperCase(activeTab)} title *`}
              options={materialOptions}
              onChange={handleMaterialSelect}
              error={error.title}
              className="input--material input--country"
            />

            <CustomMultiSelect
              options={skillsOptions}
              placeholder="Select Skills"
              onChange={handleSelectSkills}
              className="input--material input--country"
            />
           </div>

            <div className="input--date">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  openTo="day"
                  views={["year", "month", "day"]}
                  minDate={minDate}
                  maxDate={maxDate}
                  value={defaultDate}
                  onChange={(e) =>
                    handleDateInput(
                      e,
                      setDefaultDate,
                      addedMaterialInfo,
                      setAddedMaterialInfo,
                      error,
                      setError,
                      true
                    )
                  }
                  renderinput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              {error.dateError && (
                <h5 className="material__error-date">{error.dateError}</h5>
              )}
            </div>
            <ModalCreateButtons
              text="Add"
              onClick={handleSubmitMaterial}
              error={error.apiFail}
              className="modal__buttons--add-material"
            />
          </AddLessonModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default AddStudentMaterialModal;
