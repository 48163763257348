import React, { useContext } from 'react';
import styled from 'styled-components';
import hamburgerMenu from '../../assets/hamburger-menu.svg';
import { MenuContext } from '../../contexts/menuContext';
import { LogInContext } from '../../contexts/logInContext';

const MenuIconWrapper = styled.div`
  background: white;
  cursor: pointer;
  border-radius: 50%;
  padding: 0.7rem;
  display: grid;
  place-content: center;
  box-shadow: 0px 4px 4px 0 hsl(0, 0%, 0%, 0.25);

  &:hover {
    box-shadow: 0px 4px 4px 0 hsl(0, 0%, 0%, 0.25),
      0px 4px 4px 0 hsl(0, 0%, 0%, 0.1);
  }

  &:active {
    box-shadow: 0px 4px 4px 0 hsl(0, 0%, 0%, 0.25);
  }
`;

export const MenuIcon = () => {
  const context = useContext(MenuContext);
  const contextLogIn = useContext(LogInContext);

  const handleClickMenu = () => {
    context.setMenuDisplay((prevState) => !prevState);
  };
  return (
    <div>
      {contextLogIn.isLogged && (
        <MenuIconWrapper  className="nav_menu_container" onClick={handleClickMenu}>
          <img className="nav_menu" style={{ height: '1rem' }} src={hamburgerMenu} alt="" />
        </MenuIconWrapper>
      )}
    </div>
  );
};
