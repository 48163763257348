import React, { useContext, useState } from "react";
import { ModalContext } from "../../../contexts/modalContext";
import { ModalWrapper, ViewSchoolWrapper } from "../Modals.styles";
import { SchoolsContext } from "../../../contexts/schoolsContext";
import { ActionButtonOrangeSecondary } from "../../Buttons/ActionButton";
import { ModalCreateButtons } from "../ModalButtons";
import { useEffect } from "react";
import { ApproveModifySchool, GetModifySchool } from "../../../api";
import { LogInContext } from "../../../contexts/logInContext";
import { BsDash } from "react-icons/bs";

const ModifySchoolApproveModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal } = useContext(ModalContext);
  const { school } = useContext(SchoolsContext);
  const { token } = useContext(LogInContext);
  const [newSchool, setNewSchool] = useState();

  useEffect(() => {
    async function fetchSchool() {
      const responseData = await GetModifySchool(token, school?.school?.id);

      if (responseData.status === "success") {
        setNewSchool(responseData.data.school);
      }
    }

    if (school?.school?.isModify === 1) {
      fetchSchool();
    }
  }, []);

  const handleModifyApproveStudent = async () => {
    const res = await ApproveModifySchool(school?.school?.id, token);

    if (res.status === "success") {
      setShowModal(false);
    }
  };

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <ViewSchoolWrapper
            style={{
              padding: `${
                school?.school?.isModify === 0 ? "0em 5em" : "0em 2.5em"
              }`,
            }}
          >
            <h1 style={{ marginTop: "1em" }}>Approve School</h1>
            <div className="view--school">
              <div className="user">
                <ActionButtonOrangeSecondary
                  inline={true}
                  text={
                    school?.school?.isModify === 0
                      ? "New School Detail"
                      : "Old School Detail"
                  }
                  style={{ cursor: "default", marginTop: "1em" }}
                />
                <div className="main_detail">
                  <div className="nav_title">School Name</div>
                  <div className="nav_text">
                    {!!school?.school?.name ? school?.school?.name : <BsDash />}
                  </div>
                </div>
                <div className="main_detail">
                  <div className="nav_title">Long.</div>
                  <div className="nav_text">
                    {!!school?.school?.longitude ? (
                      school?.school?.longitude
                    ) : (
                      <BsDash />
                    )}
                  </div>
                </div>

                <div className="main_detail">
                  <div className="nav_title">Lat.</div>
                  <div className="nav_text">
                    {!!school?.school?.latitude ? (
                      school?.school?.latitude
                    ) : (
                      <BsDash />
                    )}
                  </div>
                </div>
                <div className="main_detail">
                  <div className="nav_title">Street Number</div>
                  <div className="nav_text">
                    {!!school?.school?.streetNumber ? (
                      school?.school?.streetNumber
                    ) : (
                      <BsDash />
                    )}
                  </div>
                </div>
                <div className="main_detail">
                  <div className="nav_title">Street</div>
                  <div className="nav_text">
                    {!!school?.school?.street ? (
                      school?.school?.street
                    ) : (
                      <BsDash />
                    )}
                  </div>
                </div>
                <div className="main_detail">
                  <div className="nav_title">City/Town</div>
                  <div className="nav_text">
                    {!!school?.school?.city ? school?.school?.city : <BsDash />}
                  </div>
                </div>
                <div className="main_detail">
                  <div className="nav_title">Region/Province</div>
                  <div className="nav_text">
                    {!!school?.school?.region ? (
                      school?.school?.region
                    ) : (
                      <BsDash />
                    )}
                  </div>
                </div>
                <div className="main_detail last_nav_text">
                  <div className="nav_title">Country</div>
                  <div className="nav_text">
                    {!!school?.school?.country?.name ? (
                      school?.school?.country?.name
                    ) : (
                      <BsDash />
                    )}
                  </div>
                </div>
              </div>
              {school?.school?.isModify === 1 && (
                <div className="user">
                  <ActionButtonOrangeSecondary
                    inline={true}
                    text={"New School Detail"}
                    style={{ cursor: "default", marginTop: "1em" }}
                  />
                  <div className="main_detail">
                    <div className="nav_title">School Name</div>
                    <div className="nav_text">
                      {!!newSchool?.name ? newSchool?.name : <BsDash />}
                    </div>
                  </div>
                  <div className="main_detail">
                    <div className="nav_title">Long.</div>
                    <div className="nav_text">
                      {!!newSchool?.longitude ? (
                        newSchool?.longitude
                      ) : (
                        <BsDash />
                      )}
                    </div>
                  </div>

                  <div className="main_detail">
                    <div className="nav_title">Lat.</div>
                    <div className="nav_text">
                      {!!newSchool?.latitude ? newSchool?.latitude : <BsDash />}
                    </div>
                  </div>
                  <div className="main_detail">
                    <div className="nav_title">Street Number</div>
                    <div className="nav_text">
                      {!!newSchool?.streetNumber ? (
                        newSchool?.streetNumber
                      ) : (
                        <BsDash />
                      )}
                    </div>
                  </div>
                  <div className="main_detail">
                    <div className="nav_title">Street</div>
                    <div className="nav_text">
                      {!!newSchool?.street ? newSchool?.street : <BsDash />}
                    </div>
                  </div>
                  <div className="main_detail">
                    <div className="nav_title">City/Town</div>
                    <div className="nav_text">
                      {!!newSchool?.city ? newSchool?.city : <BsDash />}
                    </div>
                  </div>
                  <div className="main_detail">
                    <div className="nav_title">Region/Province</div>
                    <div className="nav_text">
                      {!!newSchool?.region ? newSchool?.region : <BsDash />}
                    </div>
                  </div>
                  <div className="main_detail last_nav_text">
                    <div className="nav_title">Country</div>
                    <div className="nav_text">
                      {!!newSchool?.country?.name ? (
                        newSchool?.country?.name
                      ) : (
                        <BsDash />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <ModalCreateButtons
              className="modify-btn"
              text="Approve"
              onClick={handleModifyApproveStudent}
            />
          </ViewSchoolWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default ModifySchoolApproveModal;
