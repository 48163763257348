import React, { useContext } from "react";
import { ModalContext } from "../../../contexts/modalContext";
import { ModalWrapper, ViewSchoolWrapper } from "../Modals.styles";
import { SchoolsContext } from "../../../contexts/schoolsContext";
import { ActionButtonOrange, ActionButtonOrangeSecondary } from "../../Buttons/ActionButton";
import { BsDash } from "react-icons/bs";

const SchoolInfoModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal, setModalType } = useContext(ModalContext);
  const { schoolInfo, setSchoolToEdit, setSchoolId } = useContext(SchoolsContext);

  const handleEditSchoolModal = (item) => {
    setShowModal(true);
    setModalType("edit school");
    setSchoolToEdit({
      id: item?.id,
      name: item?.name,
      long: item?.longitude,
      lat: item?.latitude,
      address: item?.address,
      streetNumber: item?.streetNumber,
      street: item?.street,
      city: item?.city,
      region: item?.region,
      country: item?.country,
      placeId: item?.placeId,
      countryId: item?.countryId
    });
    setSchoolId(item?.id);
  };

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <ViewSchoolWrapper style={{ height: "auto", padding: "1em 2em" }}>
            <ActionButtonOrangeSecondary
              inline={true}
              text={"School Information"}
              style={{ cursor: "default", marginBottom: "10px" }}
            />
            <div className="main_detail">
              <div className="nav_title">School Name</div>
              <div className="nav_text">
                {!!schoolInfo?.name ? schoolInfo?.name : <BsDash />}
              </div>
            </div>
            <div className="main_detail">
              <div className="nav_title">Long.</div>
              <div className="nav_text">
                {!!schoolInfo?.longitude ? schoolInfo?.longitude : <BsDash />}
              </div>
            </div>

            <div className="main_detail">
              <div className="nav_title">Lat.</div>
              <div className="nav_text">
                {!!schoolInfo?.latitude ? (
                  schoolInfo?.latitude
                ) : (
                  <BsDash />
                )}
              </div>
            </div>
            <div className="main_detail">
              <div className="nav_title">Street Number</div>
              <div className="nav_text">
                {!!schoolInfo?.streetNumber ? (
                  schoolInfo?.streetNumber
                ) : (
                  <BsDash />
                )}
              </div>
            </div>
            <div className="main_detail">
              <div className="nav_title">Street</div>
              <div className="nav_text">
                {!!schoolInfo?.street ? (
                  schoolInfo?.street
                ) : (
                  <BsDash />
                )}
              </div>
            </div>
            <div className="main_detail">
              <div className="nav_title">City/Town</div>
              <div className="nav_text">
                {!!schoolInfo?.city ? schoolInfo?.city : <BsDash />}
              </div>
            </div>
            <div className="main_detail">
              <div className="nav_title">Region/Province</div>
              <div className="nav_text">
                {!!schoolInfo?.region ? schoolInfo?.region : <BsDash />}
              </div>
            </div>
            <div className="main_detail last_nav_text">
              <div className="nav_title">Country</div>
              <div className="nav_text">
                {!!schoolInfo?.country?.name ? (
                  schoolInfo?.country?.name
                ) : (
                  <BsDash />
                )}
              </div>
            </div>
            <div style={{ marginTop: "15px", display: "flex" }}>
               <ActionButtonOrange
                text="Edit"
                onClick={() => {
                  handleEditSchoolModal(schoolInfo);
                }}
              />
              <ActionButtonOrangeSecondary
                text="Close"
                inline={true}
                onClick={() => {
                  setShowModal(false);
                }}
              />
            </div>
          </ViewSchoolWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default SchoolInfoModal;
