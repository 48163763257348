import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ActionButtonOrangeSecondary } from "../../Buttons/ActionButton";
import { ActionButtonGreen } from "../../Buttons/ActionButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: "auto",
  bgcolor: "#f6f7f9",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

const MergeSchoolConfirmModal = ({
  open,
  handleClose,
  handleMergeSchool,
  firstSchool,
  secondSchool,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <h3
          style={{
            fontSize: "1.1rem",
            fontWeight: "500",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            gap: "10px"
          }}
        >
          <span>
            You are about to merge {firstSchool} and all of its data with{" "}
            {secondSchool}.
          </span>{" "}
          <span>This action cannot be undone.</span>
          <span>Are you sure you want to proceed?</span>
        </h3>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            gap: "2px",
            width: "100%",
            paddingTop: "2rem",
          }}
        >
          <ActionButtonGreen
            style={{
              width: "auto",
              padding: "0.1em 1.5em",
            }}
            text="Yes"
            onClick={handleMergeSchool}
          />
          <ActionButtonOrangeSecondary
            style={{
              width: "auto",
            }}
            inline={true}
            text="Cancel"
            onClick={handleClose}
          />
        </div>
      </Box>
    </Modal>
  );
};

export default MergeSchoolConfirmModal;
