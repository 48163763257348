import { useContext, useState } from 'react';
import { LogInAsUser } from '../../../api';
import { LogInContext } from '../../../contexts/logInContext';
import { ModalContext } from '../../../contexts/modalContext';
import { errors } from '../../../utils/validation';
import { LogInAsUserModalWrapper, ModalWrapper } from '../Modals.styles';
import { OrganizationsContext } from '../../../contexts/organizationsContext';
import { ModalRemoveButtons } from '../ModalButtons';

const LoginAsUserModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal } = useContext(ModalContext);
  const {
    organizationId,
    setOrganizationId,
    selectedUser,
    setCurrentUserPage,
  } = useContext(OrganizationsContext);
  const { token, setToken, role, setRole, setLoggedAs} =
    useContext(LogInContext);
  const [error, setError] = useState({});

  const handleRememberAdmin = (user) => {
    const rootUser = {
      token: token,
      organizationId: organizationId,
      rootUserRole: role,
      loggedAsUser: user?.userName,
      email: user?.userEmail,
      org: user?.organizationName
    };
    localStorage.setItem('rootUser', JSON.stringify(rootUser));
  };

  const handleLoginAs = async () => {
    const responseData = await LogInAsUser(
      organizationId,
      selectedUser.id,
      token
    );
    if (responseData.status === 'success') {
      handleRememberAdmin(responseData.data);
      setToken(responseData.data.token);
      setRole(responseData.data.role);
      setOrganizationId(responseData.data.organizationId);
      setShowModal(false);
      setLoggedAs({
        name: responseData.data.userName,
        email: responseData.data.userEmail,
        org: responseData.data.organizationName,
        isLoggedAs: true,
      });
      setCurrentUserPage(1);
    } else {
      setError({
        apiFail: errors.apiFail,
      });
    }
  };

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <LogInAsUserModalWrapper>
            <h2>Log in as</h2>
            <h4>{selectedUser.name}</h4>
            <ModalRemoveButtons
              text="Log in"
              onClick={handleLoginAs}
              error={error.apiFail}
            />
          </LogInAsUserModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default LoginAsUserModal;