import './App.css';
import React, { useEffect, Suspense, lazy, useState } from 'react';
import { useHistory } from 'react-router';
import { Header } from './components/Header';
import { HeaderLoggedIn } from './components/HeaderLoggedIn';
import { GlobalStyle } from './globalStyles';
import { Switch, Route } from 'react-router-dom';
import { AuthPageWrapper, PageWrapper } from './Pages/Page.styles';
import Footer from './components/Footer/Footer';
import MenuDrawer from './components/Menu/MenuDrawer';
import Login from './Pages/Login';
import MenuProvider from './contexts/menuContext';
import ChangePassword from './Pages/ChangePassword';
import SetPassword from './Pages/SetPassword';
import OrganizationsProvider from './contexts/organizationsContext';
import ModalFactory from './components/Modals/ModalFactory';
import LessonsProvider from './contexts/lessonsContext';
import SchoolsProvider from './contexts/schoolsContext';
import GroupsProvider from './contexts/groupsContext';
import StudentsProvider from './contexts/studentsContext';
import GradesProvider from './contexts/gradesContext';
import FeedbackProvider from './contexts/feedbackContext';
import ClipSpinner from './components/Loaders/ClipSpinner';
import ApproveSkill from './Pages/ApproveSkill';
import { ToastContainer } from 'react-toastify';

const Home = lazy(() => import('./Pages/Home'));
const SchoolsPage = lazy(() => import('./Pages/SchoolsPage'));
const GroupsPage = lazy(() => import('./Pages/GroupsPage'));
const GroupDetailsPage = lazy(() => import('./Pages/GroupDetailsPage'));
const LessonsPage = lazy(() => import('./Pages/LessonsPage'));
const StudentsPage = lazy(() => import('./Pages/StudentsPage'));
const FeedbackPage = lazy(() => import('./Pages/FeedbackPage'));
const StudentDetailsPage = lazy(() =>
import('./Pages/StudentProfile/StudentDetailsPage')
);
const OrganizationsPage = lazy(() => import('./Pages/OrganizationsPage'));
const ContactPage = lazy(() => import('./Pages/ContactPage'));
const MetricsPage = lazy(() => import('./Pages/MetricsPage'));
const PhotoAlbumPage = lazy(() => import('./Pages/PhotoAlbumPage'));
const ApproveStudent = lazy(() => import('./Pages/ApproveStudent'));
const ApproveLesson = lazy(() => import('./Pages/ApproveLesson'));
const AssignSchool = lazy(() => import('./Pages/AssignSchool'));
const SMSPage = lazy(() => import('./Pages/SMSPage'));

function App() {
  const history = useHistory();
  let [isLogin, setIsLogin] = useState(null);

  useEffect(() => {
    if (window.location.protocol == 'http:') {       
      window.location.href = window.location.href.replace('http:', 'https:');
    }
  }, [window.location.protocol]);

  const loginhandler = () => {
    isLogin = localStorage.getItem("token") && localStorage.getItem("token") != null ? true : false;
    setIsLogin(isLogin);
    if (localStorage.getItem("role") && localStorage.getItem("role") === 'ADMIN' && (window.location.pathname === '/login' || window.location.pathname === '/change-password' || window.location.pathname === '/set-password')) {
      history.push('/');
    } else if (localStorage.getItem("role") && localStorage.getItem("role") === 'ADMIN' && (window.location.pathname !== '/' || window.location.pathname !== '/login')) {
      history.push(window.location.pathname);
    } else if (localStorage.getItem("role") && localStorage.getItem("role") !== 'ADMIN' && (window.location.pathname === '/login' || window.location.pathname === '/' || window.location.pathname === '/change-password' || window.location.pathname === '/set-password')) {
      history.push('/users');
    } else if (localStorage.getItem("role") && localStorage.getItem("role") !== 'ADMIN' && (window.location.pathname !== '/login' || window.location.pathname !== '/users')) {
      history.push(window.location.pathname);
    } else if (!localStorage.getItem("token") && window.location.pathname === '/change-password') {
      history.push(`/change-password${window.location.search}`);
    } else if (!localStorage.getItem("token") && window.location.pathname === '/set-password') {
      history.push(`/set-password${window.location.search}`);
    } else if (!localStorage.getItem("token") && (window.location.pathname !== '/change-password' || window.location.pathname !== '/set-password')) {
      history.push('/login');
    } else {
        localStorage.getItem("token") && window.location.pathname === '/login' && history.push('/');
    }
  };

  useEffect(() => {
    loginhandler();
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      {isLogin != null && (
        <>
          {!isLogin ? (
            <MenuProvider>
               <GlobalStyle />
                <ModalFactory />
                <HeaderLoggedIn />
                <AuthPageWrapper>
                    <Suspense fallback={<div className="app__spinner"> <ClipSpinner/></div>}>
                      <Switch>
                       <Route exact path="/login">
                           <Login loginhandler={loginhandler} />
                       </Route>
                       <Route path="/change-password">
                        <ChangePassword loginhandler={loginhandler} />
                       </Route>
                       <Route path="/set-password">
                        <SetPassword loginhandler={loginhandler} />
                       </Route>
                      </Switch>
                    </Suspense>
                  </AuthPageWrapper>
              <Footer />
            </MenuProvider>
          ) : (
            <>
       <MenuProvider>
        <OrganizationsProvider>
          <SchoolsProvider>
            <GroupsProvider>
              <GradesProvider>
                <StudentsProvider>
                  <LessonsProvider>
                    <FeedbackProvider>
                      <GlobalStyle />
                      <ModalFactory />
                      <Header loginhandler={loginhandler} />
                      <PageWrapper>
                        <MenuDrawer />
                        <Suspense fallback={<div className="app__spinner"> <ClipSpinner/></div>}>
                          <Switch>
                            <Route exact path="/" component={Home} />
                            <Route
                              path="/users"
                              component={OrganizationsPage}
                            />
                             <Route
                              path="/schools/assign"
                              component={AssignSchool}
                            />
                            <Route path="/schools" component={SchoolsPage} />
                            <Route path="/lessons" component={LessonsPage} />
                            <Route path="/photos" component={PhotoAlbumPage} />
                            <Route path="/approve/students" component={ApproveStudent} />
                            <Route path="/approve/lessons" component={ApproveLesson} />
                            <Route path="/approve/skills" component={ApproveSkill} />
                            <Route path="/feedback" component={FeedbackPage} />
                            <Route
                              exact
                              path="/students"
                              component={StudentsPage}
                            />
                            <Route
                              path="/students/:id"
                              render={(routeProps) => (
                                <StudentDetailsPage routeProps={routeProps} />
                              )}
                            />
                            <Route
                              exact
                              path="/groups"
                              component={GroupsPage}
                            />
                            <Route
                              path="/groups/:id"
                              render={(routeProps) => (
                                <GroupDetailsPage routeProps={routeProps} />
                              )}
                            />
                            <Route path="/contact" component={ContactPage} />
                            <Route path="/metrics" component={MetricsPage} />
                            <Route path="/email-template" component={SMSPage} />
                          </Switch>
                        </Suspense>
                      </PageWrapper>
                    </FeedbackProvider>
                  </LessonsProvider>
                </StudentsProvider>
              </GradesProvider>
            </GroupsProvider>
          </SchoolsProvider>
        </OrganizationsProvider>
        <Footer />
      </MenuProvider>
            </>
             )}
        </>
      )}
    </div>
  );
}

export default App;
