import React, { useContext, useState } from "react";
import { ModalContext } from "../../../contexts/modalContext";
import { OrganizationsContext } from "../../../contexts/organizationsContext";
import { errors } from "../../../utils/validation";
import { LogInContext } from "../../../contexts/logInContext";
import { ModalWrapper, RemoveModalWrapper } from "../Modals.styles";
import { RemoveUser } from "../../../api";
import { ModalRemoveButtons } from "../ModalButtons";

const RemoveUserModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal } = useContext(ModalContext);
  const { selectedUser } = useContext(OrganizationsContext);
  const { token } = useContext(LogInContext);
  const [error, setError] = useState({});

  const handleRemoveUser = async () => {
    const isValidRemoval = await RemoveUser(selectedUser.id, token);

    if (isValidRemoval.status === "success") {
      setShowModal(false);
    } else {
      setError({ responseMessage: errors.removalErrorMessage });
    }
  };

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <RemoveModalWrapper style={{height: "14rem"}}>
            <h3
              style={{
                fontSize: "1.1rem",
                fontWeight: "500",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: "10px",
              }}
            >
              <span>
                Are you sure that you want to permanently delete {selectedUser.name}?
              </span>
              <span>This action cannot be undone.</span>
              <span>The email address cannot be re-used.</span>
            </h3>
            <ModalRemoveButtons
              error={error.responseMessage}
              onClick={handleRemoveUser}
            />
          </RemoveModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default RemoveUserModal;
