import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { StyledSpinnerContainer } from './Loaders.styles';

const ClipSpinner = ({ loading }) => {
  return (
    <StyledSpinnerContainer>
      <ClipLoader color={'#0B0A42'} size={30} loading={loading} />
      <p>Loading...</p>
    </StyledSpinnerContainer>
  );
};

export default ClipSpinner;
