import React, { useState, useEffect } from 'react';

export const LogInContext = React.createContext();

const LogInProvider = (props) => {
  let [isLogged, setIsLogged] = useState(false);
  let [token, setToken] = useState(localStorage.getItem('token'));
  let [role, setRole] = useState(localStorage.getItem('role'));
  let [loggedAs, setLoggedAs] = useState({
    name: '',
    email: '',
    org: '',
    isLoggedAs: false,
  });

  useEffect(() => {
    token
      ? localStorage.setItem('token', token)
      : localStorage.removeItem('token');

    role ? localStorage.setItem('role', role) : localStorage.removeItem('role');

    if (role !== 'FACILITATOR') {
      token && setIsLogged(true);
    }

    const rootUser = JSON.parse(localStorage.getItem('rootUser'));
    rootUser
      ? setLoggedAs({ name: rootUser.loggedAsUser, email: rootUser.email, org: rootUser.org, isLoggedAs: true })
      : setLoggedAs({ name: '', email: '', org: '', isLoggedAs: false });
  }, [role, token]);

  useEffect(() => {
    const logged = localStorage.getItem('token');
    logged && setToken(logged);
  }, []);

  return (
    <LogInContext.Provider
      value={{
        isLogged,
        setIsLogged,
        token,
        setToken,
        role,
        setRole,
        loggedAs,
        setLoggedAs,
      }}
    >
      {props.children}
    </LogInContext.Provider>
  );
};

export default LogInProvider;