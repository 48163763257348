import React from 'react';
import { SocialMedia } from './SocialMediaList';
import { FooterWrapper, StyledIcon } from './Footer.styles';

const Footer = () => {
  return (
    <FooterWrapper>
      <p>&copy; {new Date().getFullYear()} Global Conservation Corps.</p>
      <div>
        {SocialMedia.map((social, index) => (
          <a key={index} href={social.link} target="_blank" rel="noopener noreferrer">
            <StyledIcon icon={social.icon} className="fa-lg" />
          </a>
        ))}
      </div>
    </FooterWrapper>
  );
};

export default Footer;
