import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import MenuButton, { MenuButtonWrapper } from "../Buttons/MenuButton";
import {
  ADMIN_MenuOptions,
  ORG_CM_MenuOptions,
  ORG_ADMIN_MenuOptions,
  OWNER_MenuOptions,
} from "./MenuOptions";
import { MenuContext } from "../../contexts/menuContext";
import { LogInContext } from "../../contexts/logInContext";

import { NavLink } from "react-router-dom";
import downArrow from "../../assets/icons/down-arrow.svg";
import upArrow from "../../assets/icons/up-arrow.svg";

const MenuDrawerWrapper = styled.div`
  width: ${({ display }) => (display === "true" ? "20rem" : "4.5rem")};
  height: calc(100vh - 3.5rem - 4rem);
  overflow-y: auto;
  box-shadow: 3px 0px 10px 0 hsl(0, 0%, 0%, 0.15);
  background: var(--grey-200);
  transition: all 0.3s ease-out;
  display: ${({ loggedIn }) => (loggedIn ? "block" : "none")};

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .drowbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

     div {
      display: flex;
      align-items: center;
     }
  }

  @media screen and (max-width: 1200px) {
    width: 4.5rem;
  }
`;

const StyledLinks = styled.div`
  a {
    text-decoration: none;
    color: var(--blue-300);
    width: 100%;

    &:visited {
      color: var(--blue-300);
    }
  }

  .active div:first-child {
    color: var(--blue-300);
    background: var(--blue-150);
  }
`;

const MenuDrawer = () => {
  const { isLogged, role } = useContext(LogInContext);
  const { menuDisplay } = useContext(MenuContext);
  const [menuOptions, setMenuOptions] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    switch (role) {
      case "ADMIN":
        setMenuOptions(ADMIN_MenuOptions);
        break;
      case "ORG_CM":
        setMenuOptions(ORG_CM_MenuOptions);
        break;
      case "OWNER":
        setMenuOptions(OWNER_MenuOptions);
        break;
      case "ORG_ADMIN":
        setMenuOptions(ORG_ADMIN_MenuOptions);
        break;
      default:
        break;
    }
  }, [role]);

  return (
    <MenuDrawerWrapper
      display={menuDisplay ? "true" : "false"}
      loggedIn={isLogged}
    >
      {menuOptions.map((button, index) => (
        <StyledLinks key={index}>
          {button?.path ? (
            <NavLink to={button.path}>
              <MenuButton
                path={button.path}
                key={index}
                icon={button.icon}
                text={button.title}
              />
            </NavLink>
          ) : (
            <>
              <MenuButtonWrapper display={menuDisplay ? "true" : "false"}>
                <div className="drowbar" onClick={() => setOpen(!open)}>
                  <div>
                    <img
                      src={button.icon}
                      style={{ height: "1.5rem", width: "1.5rem" }}
                      alt=""
                    />
                    <h4>{button.title}</h4>
                  </div>
                   {!open ? <img
                    src={downArrow}
                    style={{ height: "1.2rem", width: "1.2rem" }}
                    alt=""
                    /> :
                   <img
                    src={upArrow}
                    style={{ height: "0.9rem", width: "0.9rem" }}
                    alt=""
                    />}
                </div>
              </MenuButtonWrapper>
              {open && Array.isArray(button?.children) &&
                button?.children.map((item, index) => (
                  <NavLink to={item?.path}>
                    <MenuButton
                      path={item?.path}
                      key={index}
                      icon={item?.icon}
                      text={item?.title}
                      style={{paddingLeft: "3rem"}}
                    />
                  </NavLink>
                ))}
            </>
          )}
        </StyledLinks>
      ))}
    </MenuDrawerWrapper>
  );
};

export default MenuDrawer;
