import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const ContainerWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TableContainer = styled.div`
  width: 100%;
  padding: 1.5rem;
  padding-bottom: 0;
`;

export const TabsTableContainer = styled(TableContainer)`
  padding-top: 0rem;
`;

export const TableWrapper = styled.div.attrs((props) => ({
  className: props.className,
}))`
  background: var(--grey-250);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-250);
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  padding: 1rem 2rem;
  overflow-x: auto;

  table {
    table-layout: fixed;
    width: -webkit-fill-available;
  }

  &.auto-table {
    table {
      table-layout: auto;
      width: 100%;
    }
  }

  td {
    padding: 0.5rem 0;
  }

  td:not(:last-child) {
    padding-right: 2.5rem;
  }

  th {
    padding-bottom: 0.5rem;
    padding-right: 1rem;
  }

  td {
    font-size: 13px;

    &.material__date {
      white-space: nowrap;
    }
  }

  thead,
  tr {
    box-shadow: 0px 1px 0px 0px white;
  }

  div.schools__action-button {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }

  td:last-child {
    display: flex;
    justify-content: flex-end;

    &.feedback__text,
    &.students__assessments {
      justify-content: flex-start;
    }

    &.feedback__text {
      width: 10rem;
    }
  }
  .feedback {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    margin: 0;
    font-size: 13px;
    color: blue;
    text-decoration: underline;
  }

  .groups__action-buttons {
    display: flex;
    justify-content: space-between;
  }

  .user--status,
  .reverse-user-status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .reverse-user-status {
    width: 5rem;
    flex-direction: row-reverse;
    justify-content: space-between;
    input {
      margin-left: 0rem;
    }
  }

  th.group-table-heading.right-align {
    text-align: right;
    padding-right: 0;
  }
  th {
    font-weight: 600;
  }

  div.user-actions {
    width: 10rem;
    display: flex;
    justify-content: space-between;
  }
  p {
    margin: 0 0.5em;
  }

  td.action-button {
    width: 10vw;
  }

  span.user-status {
    margin-left: 1rem;
  }

  div.loading-table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10rem;

    p {
      margin-top: 1rem;
    }
  }

  @media screen and (max-width: 1600px) {
    table {
      table-layout: auto;
    }
  }
`;

export const StyledTabsTable = styled(TableWrapper)`
  box-shadow: var(--shadow-300);
  border-radius: 0 0 25px 25px;
  padding-top: 0.5rem;
  overflow-x: auto;

  div.lessons__actions {
    display: flex;
    justify-content: space-between;
  }

  td.spacing-left {
    margin-left: 9vw;
  }
  .note-date {
    text-decoration: underline;
    font-weight: 500;
    margin-bottom: 0.5rem;
    font-size: 14px;
  }

  .comment {
    width: 27rem;
    white-space: normal;
    font-size: 13px;
  }

  .skills {
    width: 15rem;
    white-space: normal;
    text-align: left;
    justify-content: flex-start;
  }

  .comment--truncated {
    width: 27rem;
    font-size: 13px;
    white-space: nowrap;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  div.large--comment {
    justify-content: flex-start;
    align-items: flex-end;
  }

  #read-more {
    display: inline;
    padding: 0 0.5rem;
    border: none;
    color: var(--orange-200);
    font-weight: 600;
    margin-left: 0.2rem;
  }

  td.format__student-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: -0.5rem;
  }

  td.note__container {
    div {
      display: flex;
      white-space: nowrap;
    }
  }
`;

export const TabsWrapper = styled.div`
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  h4 {
    margin-top: 0.5rem;
    font-weight: 600;
  }

  .tab {
    border-radius: 25px 25px 0 0;
    background: var(--grey-200);
  }
  .tab:hover {
    background: var(--blue-150);
  }
  .tab-active {
    border-radius: 25px 25px 0 0;
    background: var(--grey-250);
    position: relative;
    h4 {
      &:after {
        content: '';
        width: 25%;
        margin: 0.25rem auto;
        height: 4px;
        display: block;
        justify-content: center;
        border-radius: 50px;
        transition: all 0.3s ease-out;
        background: var(--blue-200);
      }
    }
  }
`;

export const StyledTab = styled.div`
  width: 100%;
  height: 100%;
  h4 {
    font-size: 1rem;
  }
`;

export const StyledStudentLink = styled(NavLink)`
  color: blue;
`;

export const LazyLoadingTableWrapper = styled.div`
  background: var(--grey-250);
  box-shadow: var(--shadow-250);
  border-radius: 15px;
  border-collapse: collapse;
  font-size: 0.9rem;
  overflow-x: auto;
  overflow-y: auto;
  height: 45vh;

  table {
    table-layout: fixed;
    width: 100%;
  }

  tr:not(:last-child) {
    box-shadow: 0px 1px 0px 0px white;
  }

  td {
    padding: 0.8rem 0;
    padding-right: 0.5rem;

    img {
      cursor: pointer;
    }
  }

  td:first-child,
  td:last-child {
    padding-left: 1rem;
  }

  thead {
    th {
      padding-bottom: 0.8rem;
      padding-right: 0.5rem;
      padding-top: 1.5rem;
    }

    tr {
      box-shadow: 0px 1px 0px 0px white;
    }
  }

  th:last-child,
  th:first-child {
    width: 3rem;
    padding-left: 1rem;
  }

  div.loading {
    margin-top: 1rem;
    padding-bottom: 2rem;
  }

  tr.photo-album-row {
    td {
      background: var(--grey-200);
      outline: 1px solid var(--grey-200);
    }
  }

  tr.photo-album-accordion,
  tr.selected-photo-album-accordion {
    box-shadow: 0px 1px 0px 0px white;
    td.photos-container {
      padding-left: 0;
    }
  }

  tr.photo-album-accordion {
    td {
      background: var(--grey-200);
      outline: 2px solid var(--grey-200);
    }
  }

  tr.selected-photo-album-accordion {
    td {
      background: var(--blue-150);
      outline: 1px solid var(--blue-150);
    }
  }

  tr.selected-photo-album-row {
    td {
      background: var(--blue-150);
      outline: 1px solid var(--blue-150);
    }
  }

  tr {
    div.photos-container {
      width: 100%;
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      img {
        width: 6rem;
        height: 5rem;
        margin-top: 0.5rem;
        margin-right: 1rem;
        border-radius: 10px;
        object-fit: cover;
      }

      p {
        font-size: 0.8rem;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    table {
      table-layout: auto;
    }
  }
  @media screen and (min-width: 1800px) {
    height: 60vh;
  }
`;

export const AlbumTableContainer = styled.div`
  margin-top: 1.5rem;
  padding: 0 2rem;
`;
