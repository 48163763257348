import React, { useContext, useRef, useState } from "react";
import { ModalContext } from "../../../contexts/modalContext";
import {
  ActionButtonGreen,
  ActionButtonOrangeSecondary,
} from "../../Buttons/ActionButton";
import Avatar from "../../../assets/avatar.svg";
import { OrganizationsContext } from "../../../contexts/organizationsContext";
import { errors } from "../../../utils/validation";
import { GroupsContext } from "../../../contexts/groupsContext";
import { AddStudent } from "../../../api";
import {
  genders,
  handleUploadImage,
  handleRemoveImage,
  addElement,
  handleOnSubmit,
} from "../../../utils/constants";
import { LogInContext } from "../../../contexts/logInContext";
import {
  AddStudentModalWrapper,
  ModalWrapper,
  StudentModalButtonsWrapper,
  StyledAvatar,
  StyledStudentInfo,
} from "../Modals.styles";
import { CustomInput } from "../../InputFields/CustomInput";
import CustomSelect from "../../InputFields/CustomSelect";
import { ModalCreateButtons } from "../ModalButtons";
import { inputStudentFields } from "../../InputFields/InputFields";
import {
  defaultStudentInfo,
  handleSelectCountry,
  handleSelectGender,
  handleStudentUserInput,
  handleSubmitValidations,
} from "./constants";
import { SchoolsContext } from "../../../contexts/schoolsContext";
import { StudentsContext } from "../../../contexts/studentsContext";

const AddStudentModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal, setModalType } = useContext(ModalContext);
  const { organizationId } = useContext(OrganizationsContext);
  const { schoolId, countryOptions } = useContext(SchoolsContext);
  const {
    setDupGrpStud,
    setDupStud } = useContext(StudentsContext);
  const { groupId, groupStudents, setGroupStudents } =
    useContext(GroupsContext);
  const { token } = useContext(LogInContext);
  let [studentInfo, setStudentInfo] = useState({
    name: "",
    lastName: "",
    dob: "",
    gender: "",
    countryId: null,
    photo: null,
    uniqueIdentifier: ""
  });
  const fileInput = useRef();
  const [error, setError] = useState({});
  const [countryName, setCountryName] = useState("");
  let [stud, setStud] = useState([]);

  const handleAddStudentSubmit = async () => {
    let errorCopy = {};

    studentInfo = {...studentInfo, uniqueIdentifier: 
      (studentInfo?.name + "_" + studentInfo?.lastName + "_" + studentInfo?.dob + "_" + countryName).toLocaleLowerCase().trim()}

    setStudentInfo(studentInfo);

    let studentInfoCopy = { ...studentInfo };

    let photoLink = null;
    if (studentInfo.photo) {
      photoLink = studentInfo.photo.includes("base64,")
        ? studentInfo.photo.split("base64,")[1]
        : studentInfo.photo;
    }
    studentInfoCopy.photo = photoLink;
    studentInfoCopy.schoolId = schoolId;
    studentInfoCopy.country = countryName;
    stud.push(studentInfoCopy);
    setStud(stud);

    const isValid = handleSubmitValidations(studentInfo, error, setError);

    if (!isValid) {
      return false;
    }

    const isValidSubmission = await AddStudent(
      organizationId,
      studentInfoCopy,
      token
    );

    if (isValidSubmission.status === "success") {
      if (window.location.pathname.includes("groups")) {
        isValidSubmission.data.group = { id: groupId, schoolId: schoolId };
        isValidSubmission.data.skills = [];
        addElement(groupStudents, setGroupStudents, isValidSubmission.data);
      }

      setShowModal(false);
      setStudentInfo(defaultStudentInfo);
    } else {
      if (isValidSubmission.data && isValidSubmission.code === 107) {
        setShowModal(true);
        setModalType("Add Students from CSV");
        setDupStud(stud);
        // errorCopy.ID = isValidSubmission.data;
      } else if (isValidSubmission.data && isValidSubmission.code === 105) {
        setShowModal(true);
        setModalType("Add Students from CSV");
        setDupStud(stud);
        // errorCopy.apiFail = isValidSubmission.data;
      } else if (isValidSubmission.data && isValidSubmission.code === 103) {
        setShowModal(true);
        setModalType("Add Students from CSV");
        setDupGrpStud(stud);
        // errorCopy.apiFail = isValidSubmission.data;
      } else {
        errorCopy.apiFail = errors.apiFail;
      }
    }
    setError(errorCopy);
  };

  const handleChange = (e) => {
    setCountryName(e?.label);
  };

  inputStudentFields.firstName.value = studentInfo.name;
  inputStudentFields.lastName.value = studentInfo.lastName;
  inputStudentFields.uniqueId.value = studentInfo.uniqueId;
  inputStudentFields.birthday.value = studentInfo.dob;

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <AddStudentModalWrapper>
            <StyledStudentInfo>
              <StyledAvatar>
                <div className="left-side-student-details">
                  <img
                    src={studentInfo.photo ? studentInfo.photo : Avatar}
                    alt="student avatar"
                  />
                  {error.imageError && (
                    <h5 className="error--image">{error.imageError}</h5>
                  )}
                  <StudentModalButtonsWrapper>
                    <React.Fragment>
                      <input
                        ref={fileInput}
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          handleUploadImage(
                            e,
                            studentInfo,
                            setStudentInfo,
                            error,
                            setError
                          );
                        }}
                      />

                      <ActionButtonGreen
                        theme="primary"
                        text="Add Photo"
                        onClick={() => fileInput.current.click()}
                      />
                    </React.Fragment>
                    <ActionButtonOrangeSecondary
                      text="Delete Photo"
                      onClick={() =>
                        handleRemoveImage(
                          studentInfo,
                          setStudentInfo,
                          error,
                          setError
                        )
                      }
                    />
                  </StudentModalButtonsWrapper>
                </div>

                <div className="right-side-student-details">
                  <CustomInput
                    input={inputStudentFields.firstName}
                    onChange={(e) =>
                      handleStudentUserInput(
                        e,
                        studentInfo,
                        setStudentInfo,
                        groupId,
                        error,
                        setError
                      )
                    }
                    className="input--student"
                    onSubmit={(e) => handleOnSubmit(e, handleAddStudentSubmit)}
                    error={error.studentName}
                  />

                  <CustomInput
                    input={inputStudentFields.lastName}
                    onChange={(e) =>
                      handleStudentUserInput(
                        e,
                        studentInfo,
                        setStudentInfo,
                        groupId,
                        error,
                        setError
                      )
                    }
                    className="input--student"
                    onSubmit={(e) => handleOnSubmit(e, handleAddStudentSubmit)}
                    error={error.lastName}
                  />
                  <CustomInput
                    input={inputStudentFields.uniqueId}
                    onChange={(e) =>
                      handleStudentUserInput(
                        e,
                        studentInfo,
                        setStudentInfo,
                        groupId,
                        error,
                        setError
                      )
                    }
                    className="input--student"
                    onSubmit={(e) => handleOnSubmit(e, handleAddStudentSubmit)}
                    error={error.ID}
                  />
                  <CustomInput
                    input={inputStudentFields.birthday}
                    onChange={(e) =>
                      handleStudentUserInput(
                        e,
                        studentInfo,
                        setStudentInfo,
                        groupId,
                        error,
                        setError
                      )
                    }
                    className="input--dob"
                    onSubmit={(e) => handleOnSubmit(e, handleAddStudentSubmit)}
                    error={error.dateError}
                  />

                  <CustomSelect
                    title="Gender"
                    required="*"
                    options={genders}
                    placeholder="Select Gender"
                    onChange={(e) => {
                      handleSelectGender(e, studentInfo, setStudentInfo, error);
                    }}
                    className="input--student input--country"
                    error={error.gender}
                  />

                  <CustomSelect
                    title="Country"
                    required="*"
                    options={countryOptions}
                    placeholder="Select Country"
                    onChange={(e) => {
                      handleSelectCountry(e, studentInfo, setStudentInfo, error);
                      handleChange(e);
                    }}
                    className="input--gender input--country"
                    error={error.country}
                  />
                </div>
              </StyledAvatar>
            </StyledStudentInfo>
            <ModalCreateButtons
              text="Add Student"
              error={error.apiFail}
              onClick={handleAddStudentSubmit}
            />
          </AddStudentModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default AddStudentModal;