import React, { useState, useContext } from 'react';
import { LessonsContext } from '../../../contexts/lessonsContext';
import { ModalContext } from '../../../contexts/modalContext';
import { isValidNote, errors } from '../../../utils/validation';
import { EditNote } from '../../../api';
import { OrganizationsContext } from '../../../contexts/organizationsContext';
import { LogInContext } from '../../../contexts/logInContext';
import { AddNoteModalWrapper, ModalWrapper } from '../Modals.styles';
import { StudentsContext } from '../../../contexts/studentsContext';
import { ModalCreateButtons } from '../ModalButtons';
import { editElement } from '../../../utils/constants';
import { warning } from '../../../utils/toastify';

const EditStudentNoteModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal } = useContext(ModalContext);
  const { organizationId } = useContext(OrganizationsContext);
  const { selectedStudent } = useContext(StudentsContext);
  const { noteId, notes, setNotes } = useContext(LessonsContext);
  const { token } = useContext(LogInContext);
  const [error, setError] = useState({});
  const [editedNote, setEditedNote] = useState(
    notes.find((note) => note.id === noteId).comment
  );

  const handleNoteChange = (e) => {
    let errorCopy = {};
    setEditedNote(e.target.value);

    isValidNote(e.target.value)
      ? delete errorCopy.note
      : (errorCopy.note = errors.noteLengthErrorMessage);
    setError(errorCopy);
  };

  const handleSubmitValidations = () => {
    let errorCopy = { ...error };
    delete errorCopy.apiFail;

    !editedNote && (errorCopy.note = errors.noteLengthErrorMessage);

    if (Object.keys(errorCopy).length > 0) {
      warning("Required field/s must be filled");
    }

    setError(errorCopy);
    return Object.keys(errorCopy).length === 0;
  };

  const handleSubmitEditNote = async () => {
    const isValid = handleSubmitValidations();

    if (!isValid) {
      return false;
    }

    const responseData = await EditNote(
      organizationId,
      selectedStudent.id,
      noteId,
      editedNote,
      token
    );

    if (responseData.status === 'success') {
      editElement(noteId, notes, setNotes, ['comment'], { comment: editedNote });
      setShowModal(false);
    } else {
 
      setError({ apiFail: errors.apiFail });
    }
  };

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <AddNoteModalWrapper>
            <div className="note">
              <h2>Notes</h2>
              <textarea value={editedNote} onChange={handleNoteChange} />
              {error.note && <h5>{error.note}</h5>}
            </div>
            <ModalCreateButtons
              text="Save Changes"
              onClick={handleSubmitEditNote}
              className="modal__buttons--add-note"
              error={error.apiFail}
            />
          </AddNoteModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default EditStudentNoteModal;
