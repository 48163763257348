import React, { useState, useEffect } from 'react';

export const OrganizationsContext = React.createContext();

const OrganizationsProvider = (props) => {
  const [organizations, setOrganizations] = useState([]);
  const [organizationId, setOrganizationId] = useState(
    +localStorage.getItem('organizationId')
  );
  const [organizationName, setOrganizationName] = useState(
    localStorage.getItem('selectedOrg') || ''
  );
  const [organizationsSelect, setOrganizationsSelect] = useState([]);
  const [orgIds, setOrgIds] = useState([]);
  const [orgOptions, setOrgOptions] = useState([]);
  const [orgActiveTab, setOrgActiveTab] = useState('organization');
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedOrg, setSelectedOrg] = useState({});
  const [usersData, setUsersData] = useState([]);
  const [roles, setRoles] = useState([]);
  const [currentUserPage, setCurrentUserPage] = useState(1);
  const [userPageCount, setUserPageCount] = useState(1);

  useEffect(() => {
    if (organizationId) {
      localStorage.setItem('organizationId', organizationId);
    } else {
      localStorage.removeItem('organizationId');
      localStorage.removeItem('selectedOrg');
    }
  }, [organizationId]);

  useEffect(() => {
    if (organizationName) {
      localStorage.setItem('selectedOrg', organizationName);
    } else {
      localStorage.removeItem('selectedOrg');
    }
  }, [organizationName]);

  useEffect(() => {
    setOrgOptions(
      organizations.map((organization) => ({
        value: organization.name,
        label: organization.name,
        id: organization.id,
      }))
    );
  }, [organizations]);

  return (
    <OrganizationsContext.Provider
      value={{
        organizations,
        setOrganizations,
        organizationId,
        setOrganizationId,
        organizationsSelect,
        setOrganizationsSelect,
        orgOptions,
        orgActiveTab,
        setOrgActiveTab,
        selectedUser,
        setSelectedUser,
        selectedOrg,
        setSelectedOrg,
        organizationName,
        setOrganizationName,
        usersData,
        setUsersData,
        currentUserPage,
        setCurrentUserPage,
        userPageCount,
        setUserPageCount,
        roles,
        setRoles,
        orgIds, 
        setOrgIds
      }}
    >
      {props.children}
    </OrganizationsContext.Provider>
  );
};

export default OrganizationsProvider;
