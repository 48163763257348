import React, { useState } from 'react';

export const GroupsContext = React.createContext();

const GroupsProvider = (props) => {
  const [groups, setGroups] = useState([]);
  const [groupStudents, setGroupStudents] = useState([]);
  const [groupId, setGroupId] = useState(0);
  const [groupName, setGroupName] = useState('');
  const [backFromEdit, setBackFromEdit] = useState(false);
  let [selectedArchives, setSelectedArchives] = useState([]);


  return (
    <GroupsContext.Provider
      value={{
        groups,
        setGroups,
        groupId,
        setGroupId,
        backFromEdit,
        setBackFromEdit,
        groupStudents,
        setGroupStudents,
        groupName,
        setGroupName,
        selectedArchives, 
        setSelectedArchives
      }}
    >
      {props.children}
    </GroupsContext.Provider>
  );
};

export default GroupsProvider;
