import React from 'react';
import Select from 'react-select';
import { SelectTitle } from './InputFields.styles';

const CustomSelect = ({
  title,
  className,
  options,
  placeholder,
  menuPlacement,
  defaultValue,
  onChange,
  error,
  isDisabled,
  components,
  onMenuOpen,
  onMenuClose,
  menuIsOpen,
  value,
  inputValue,
  onInputChange,
  required
}) => {
  return (
    <div className={className}>
      <SelectTitle>{title} <span style={{marginLeft: "0"}}>{required}</span></SelectTitle>
      <Select
        defaultValue={defaultValue}
        placeholder={placeholder}
        options={options}
        onChange={onChange}
        menuPlacement={menuPlacement}
        isDisabled={isDisabled}
        components={components}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        menuIsOpen={menuIsOpen}
        value={value}
        inputValue={inputValue}
        onInputChange={onInputChange}
      />
      {error && <h5 className="error">{error}</h5>}
    </div>
  );
};

export default CustomSelect;
