import React from 'react';
import { SelectTitle } from './InputFields.styles';
import { default as Select } from "react-select";

const CustomMultiSelect = ({
  options,
  className,
  title,
  error,
  defaultValue,
  placeholder,
  menuPlacement,
  onChange,
  isDisabled,
  components
}) => {
  return (
    <div className={className}>
      <SelectTitle>{title}</SelectTitle>
      <Select
        closeMenuOnSelect={false}
        placeholder={placeholder}
        defaultValue={defaultValue}
        isMulti
        isDisabled={isDisabled}
        options={options}
        onChange={onChange}
        menuPlacement={menuPlacement}
        components={components}
      />
      {error && <h5 className="error">{error}</h5>}
    </div>
  );
};

export default CustomMultiSelect;
