import React, { useState, useEffect, useContext } from 'react';

import { OrganizationsContext } from '../contexts/organizationsContext';
import { LogInContext } from '../contexts/logInContext';
import { GetStudents, GetStudentsFilters } from '../api';

export const StudentsContext = React.createContext();

const StudentsProvider = (props) => {
  const [studentId, setStudentId] = useState({});
  const [selectedStudent, setSelectedStudent] = useState({ notes: [] });
  const [assessments, setAssessments] = useState([]);
  const [groupsOptions, setGroupsOptions] = useState([]);
  const [facilitatorsOptions, setFacilitatorsOptions] = useState([]);
  const [groups, setGroups] = useState([]);
  const [facilitators, setFacilitators] = useState([]);
  const [students, setStudents] = useState([]);
  const [years, setYears] = useState([]);
  const [stud, setStud] = useState([]);
  const [dupStud, setDupStud] = useState([]);
  let [dupGrpStud, setDupGrpStud] = useState([]);
  const [modal, setModal] = useState(false);

  const { organizationId } = useContext(OrganizationsContext);
  const { token } = useContext(LogInContext);

  useEffect(() => {
    setAssessments(selectedStudent.assessments);
  }, [selectedStudent]);

  useEffect(() => {
    async function fetchStudents() {
      const responseData = await GetStudents(
        token,
        organizationId,
        null
      );

      if (responseData.status === 'success') {
        setStudents(responseData.data.students);
      } else {
        setStudents([]);
      }
    }
    fetchStudents();
  }, []);

  useEffect(() => {
    async function fetchFilters() {
      const responseData = await GetStudentsFilters(
        organizationId,
        token
      );

      if (responseData.status === 'success') {
        setGroupsOptions(responseData.data.groups);
        setFacilitatorsOptions(responseData.data.facilitators);
      } else {
        setGroupsOptions([]);
        setFacilitatorsOptions([]);
      }
    }
    if (organizationId) {
      fetchFilters();
    }
  }, [organizationId]);

  useEffect(() => {
    setGroups(
      groupsOptions.map((groupOption) => ({
        value: groupOption?.id,
        label: `${groupOption?.name} - ${groupOption?.school?.name}`,
        id: groupOption?.id
      }))
    );
    
  }, [groupsOptions]);

  useEffect(() => {
    setFacilitators(
      facilitatorsOptions.map((facilitatorsOption) => ({
        value: facilitatorsOption.name,
        label: facilitatorsOption.name,
        id: facilitatorsOption.id
      }))
    );
  }, [facilitatorsOptions])

  function generateYears(startYear = 2020) {
    const endDate = new Date().getFullYear();
    let years = [];
    for (let i = startYear; i <= endDate; i++) {
      years.push(startYear);
      startYear++;
    }
    return years;
  }

  const yearsRange = generateYears().reverse();

  useEffect(() => {
    setYears(
      yearsRange.map((year) => ({
        value: year,
        label: year,
        id: year
      }))
    )
  }, [])

  return (
    <StudentsContext.Provider
      value={{
        studentId,
        setStudentId,
        selectedStudent,
        setSelectedStudent,
        assessments,
        setAssessments,
        students,
        setStudents,
        groups,
        facilitators,
        years,
        stud, 
        setStud,
        modal, 
        setModal,
        dupStud, 
        setDupStud,
        dupGrpStud, 
        setDupGrpStud
      }}
    >
      {props.children}
    </StudentsContext.Provider>
  );
};

export default StudentsProvider;
