import React, { useContext, useRef, useState } from "react";
import { ModalContext } from "../../../contexts/modalContext";
import {
  ActionButtonGreen,
  ActionButtonOrangeSecondary,
} from "../../Buttons/ActionButton";
import Avatar from "../../../assets/avatar.svg";
import { OrganizationsContext } from "../../../contexts/organizationsContext";
import {
  isValidName,
  isValidUniqueIdentifier,
  errors,
  toProperCase,
} from "../../../utils/validation";
import { GroupsContext } from "../../../contexts/groupsContext";
import { EditStudent } from "../../../api";
import { StudentsContext } from "../../../contexts/studentsContext";
import {
  genders,
  handleUploadImage,
  handleRemoveImage,
  editElement,
  handleOnSubmit,
} from "../../../utils/constants";
import { LogInContext } from "../../../contexts/logInContext";
import {
  AddStudentModalWrapper,
  ModalWrapper,
  StudentModalButtonsWrapper,
  StyledAvatar,
  StyledStudentInfo,
} from "../Modals.styles";
import { CustomInput } from "../../InputFields/CustomInput";
import CustomSelect from "../../InputFields/CustomSelect";
import { ModalCreateButtons } from "../ModalButtons";
import { inputStudentFields } from "../../InputFields/InputFields";
import {
  defaultStudentInfo,
  handleSelectCountry,
  handleSelectGender,
  handleStudentUserInput,
  handleSubmitValidations,
} from "./constants";
import { decrypt, encrypt } from "../../../utils/encryption";
import { SchoolsContext } from "../../../contexts/schoolsContext";

const EditStudentModal = ({ modalRef, closeModal }) => {
  const fileInput = useRef();
  const { showModal, setShowModal } = useContext(ModalContext);
  const { selectedStudent, setSelectedStudent } = useContext(StudentsContext);
  const { organizationId } = useContext(OrganizationsContext);
  const { groupId, groupStudents, setGroupStudents } =
    useContext(GroupsContext);
  const { token } = useContext(LogInContext);
  const [studentInfoEdited, setStudentInfoEdited] = useState(selectedStudent);
  const [error, setError] = useState({});
  let [uid, setUid] = useState(
    !!selectedStudent.uniqueId ? decrypt(selectedStudent.uniqueId) : ""
  );
  const { countryOptions } = useContext(SchoolsContext);
  const [countryName, setCountryName] = useState(selectedStudent?.country?.name);
  let [countryId, setCountryId] = useState(selectedStudent?.countryId);

  const handleDisplayImage = () => {
    if (studentInfoEdited.photo) {
      if (studentInfoEdited.photo.includes("http")) {
        return `${studentInfoEdited.photo}?${performance.now()}`;
      }
      return studentInfoEdited.photo;
    }
    return Avatar;
  };

  const handleUserInput = (e) => {
    let errorCopy = { ...error };

    if (e.target.name === "uniqueId") {
      uid = e.target.value;
      setUid(uid);
    }

    if (e.target.name === "name") {
      setStudentInfoEdited({
        ...studentInfoEdited,
        name: toProperCase(e.target.value),
        groupId: groupId,
      });

      const isValid = isValidName(e.target.value);
      isValid
        ? delete errorCopy.studentName
        : (errorCopy.studentName = errors.requiredFnameError);
    }
    if (e.target.name === "lname") {
      setStudentInfoEdited({
        ...studentInfoEdited,
        lastName: toProperCase(e.target.value),
        groupId: groupId,
      });

      const isValid = isValidName(e.target.value);
      isValid
        ? delete errorCopy.lastName
        : (errorCopy.lastName = errors.requiredLnameError);
    } else {
      setStudentInfoEdited({ ...studentInfoEdited, uniqueId: e.target.value });

      const isValid = isValidUniqueIdentifier(e.target.value);
      isValid
        ? delete errorCopy.ID
        : (errorCopy.ID = errors.uniqueIdentifierErrorMessage);
    }

    setError(errorCopy);
  };

  const handleEditStudentSubmit = async () => {
    let errorCopy = {};
    let photoLink = null;
    if (studentInfoEdited.photo) {
      photoLink = studentInfoEdited.photo.includes("base64,")
        ? studentInfoEdited.photo.split("base64,")[1]
        : studentInfoEdited.photo;
    }

    const studentInfo = {
      name: studentInfoEdited.name,
      lastName: studentInfoEdited.lastName,
      dob: studentInfoEdited.dob,
      gender: studentInfoEdited.gender,
      countryId: countryId,
      photo: photoLink,
      groupId: studentInfoEdited.group.id,
      schoolId: studentInfoEdited.group.schoolId,
      uniqueId: !!studentInfoEdited.uniqueId
        ? decrypt(studentInfoEdited.uniqueId)
        : "",
      uniqueIdentifier: (studentInfoEdited?.name + "_" + studentInfoEdited?.lastName + "_" + studentInfoEdited?.dob + "_" + countryName).toLocaleLowerCase().trim()
    };

    const isValid = handleSubmitValidations(studentInfoEdited, error, setError);

    if (!isValid) {
      return false;
    }

    const isValidSubmission = await EditStudent(
      organizationId,
      selectedStudent.id,
      studentInfo,
      token
    );

    if (isValidSubmission.status === "success") {
      const keys = [
        "name",
        "lastname",
        "email",
        "dob",
        "gender",
        "photo",
        "uniqueId",
      ];
      editElement(
        selectedStudent.id,
        groupStudents,
        setGroupStudents,
        keys,
        studentInfoEdited
      );
      setShowModal(false);
      setStudentInfoEdited(defaultStudentInfo);
      setSelectedStudent({
        ...studentInfoEdited,
        uniqueId: !!uid ? encrypt(uid) : "",
        studentId: uid,
      });
    } else {
      if (isValidSubmission.data && isValidSubmission.code === 107) {
        errorCopy.ID = isValidSubmission.data;
      } else if (isValidSubmission.data && isValidSubmission.code === 105) {
        errorCopy.apiFail = isValidSubmission.data;
      } else {
        errorCopy.apiFail = errors.apiFail;
      }
    }
    setError(errorCopy);
  };

  const handleChange = (e) => {
    setCountryId(e?.id);
    setCountryName(e?.label);
  };

  inputStudentFields.firstName.value = studentInfoEdited.name;
  inputStudentFields.lastName.value = studentInfoEdited.lastName;
  inputStudentFields.uniqueId.value = uid;
  inputStudentFields.birthday.value = studentInfoEdited.dob;

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <AddStudentModalWrapper>
            <StyledStudentInfo>
              <StyledAvatar>
                <div className="left-side-student-details">
                  <img src={handleDisplayImage()} alt="student avatar" />
                  {studentInfoEdited.photo && (
                    <h5 className="error--image">{error.imageError}</h5>
                  )}
                  <StudentModalButtonsWrapper>
                    <React.Fragment>
                      <input
                        ref={fileInput}
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          handleUploadImage(
                            e,
                            studentInfoEdited,
                            setStudentInfoEdited,
                            error,
                            setError
                          );
                        }}
                      />
                      <ActionButtonGreen
                        theme="primary"
                        text="Edit Photo"
                        onClick={() => fileInput.current.click()}
                      />
                    </React.Fragment>
                    <ActionButtonOrangeSecondary
                      text="Delete Photo"
                      onClick={() =>
                        handleRemoveImage(
                          studentInfoEdited,
                          setStudentInfoEdited,
                          error,
                          setError
                        )
                      }
                    />
                  </StudentModalButtonsWrapper>
                </div>

                <div className="right-side-student-details">
                  <CustomInput
                    input={inputStudentFields.firstName}
                    onChange={(e) =>
                      handleStudentUserInput(
                        e,
                        studentInfoEdited,
                        setStudentInfoEdited,
                        groupId,
                        error,
                        setError
                      )
                    }
                    className="input--student"
                    onSubmit={(e) => handleOnSubmit(e, handleEditStudentSubmit)}
                    error={error.studentName}
                  />
                  <CustomInput
                    input={inputStudentFields.lastName}
                    onChange={(e) =>
                      handleStudentUserInput(
                        e,
                        studentInfoEdited,
                        setStudentInfoEdited,
                        groupId,
                        error,
                        setError
                      )
                    }
                    className="input--student"
                    onSubmit={(e) => handleOnSubmit(e, handleEditStudentSubmit)}
                    error={error.lastName}
                  />
                  <CustomInput
                    input={inputStudentFields.uniqueId}
                    onChange={handleUserInput}
                    className="input--student"
                    onSubmit={(e) => handleOnSubmit(e, handleEditStudentSubmit)}
                    error={error.ID}
                    disabled={true}
                  />
                  <CustomInput
                    input={inputStudentFields.birthday}
                    
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={(e) =>
                      handleStudentUserInput(
                        e,
                        studentInfoEdited,
                        setStudentInfoEdited,
                        groupId,
                        error,
                        setError
                      )
                    }
                    className="input--dob"
                    onSubmit={(e) => handleOnSubmit(e, handleEditStudentSubmit)}
                    error={error.dateError}
                  />
                  <CustomSelect
                    title="Gender"
                    required="*"
                    options={genders}
                    defaultValue={genders.find(
                      (gender) =>
                        gender.value === toProperCase(studentInfoEdited.gender)
                    )}
                    onChange={(e) =>
                      handleSelectGender(
                        e,
                        studentInfoEdited,
                        setStudentInfoEdited,
                        error
                      )
                    }
                    className="input--student input--country"
                    error={error.gender}
                  />

                  <CustomSelect
                    title="Country"
                    required="*"
                    options={countryOptions}
                    placeholder="Select Country"
                    defaultValue={countryOptions.find(
                      (country) =>
                      country.id === countryId
                    )}
                    onChange={(e) => {
                      handleSelectCountry( e,
                        studentInfoEdited,
                        setStudentInfoEdited,
                        error);
                      handleChange(e);
                    }}
                    className="input--gender input--country"
                    error={error.country}
                  />
                </div>
              </StyledAvatar>
            </StyledStudentInfo>
            <ModalCreateButtons
              text="Save Changes"
              error={error.apiFail}
              onClick={handleEditStudentSubmit}
            />
          </AddStudentModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default EditStudentModal;