import React, { useContext } from 'react';
import { LessonsContext } from '../../../contexts/lessonsContext';
import { ModalContext } from '../../../contexts/modalContext';
import {
  ActionButtonGreen,
  ActionButtonOrangeSecondary,
} from '../../Buttons/ActionButton';
import { ModalWrapper, DiscardModalWrapper } from '../Modals.styles';

const DiscardSkillsModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal } = useContext(ModalContext);
  const { setEditMode, setSkills, skills } = useContext(LessonsContext);
  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <DiscardModalWrapper>
            <h2>Discard newly added skills</h2>
            <h4> Are you sure you want to discard the last changes? </h4>

            <div className="modal__buttons--discard-skill">
              <ActionButtonGreen
                text="Yes"
                onClick={() => {
                  setSkills(
                    skills.map((skill) => {
                      skill.toDelete = false;
                      skill.hideSkill = false;
                      return skill;
                    })
                  );
                  setEditMode(false);
                  setShowModal(false);
                }}
              />
              <ActionButtonOrangeSecondary
                text="Cancel"
                onClick={() => setShowModal(false)}
              />
            </div>
          </DiscardModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default DiscardSkillsModal;
