import React, { useContext, useState, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  Autocomplete,
  useLoadScript,
  InfoWindow,
} from "@react-google-maps/api";
import { ModalContext } from "../../../contexts/modalContext";
import {
  isValidCoordinate,
  isValidName,
  errors,
  toProperCase,
  isValidCity,
  isValidRegion,
} from "../../../utils/validation";
import {
  CreateOrgSchool,
  CreateSchool,
  GetAllSchools,
  GetSchool,
  GetSchoolOrganizations,
} from "../../../api";
import { OrganizationsContext } from "../../../contexts/organizationsContext";
import { LogInContext } from "../../../contexts/logInContext";
import {
  InputsWrapper,
  ModalWrapper,
  SchoolModalWrapper,
  SchoolSelect,
} from "../Modals.styles";
import {
  addElement,
  defaultCoordinates,
  handleOnSubmit,
} from "../../../utils/constants";
import { CustomInput } from "../../InputFields/CustomInput";
import { ModalCreateButtons } from "../ModalButtons";
import { inputSchoolFields } from "../../InputFields/InputFields";
import { containerStyle, defaultSchoolInfo } from "./constants";
import { SchoolsContext } from "../../../contexts/schoolsContext";
import CustomSelect from "../../InputFields/CustomSelect";
import { components } from "react-select";
import makerImg from "../../../assets/icons/maker.svg";
import { success, warning } from "../../../utils/toastify";
import CustomMultiSelect from "../../InputFields/CustomMultiSelect";
import ViewSchoolModal from "./ViewSchoolModal";

const AddSchoolModal = ({ modalRef, closeModal }) => {
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;
  const { showModal, setShowModal } = useContext(ModalContext);
  const { organizationId, orgOptions } = useContext(OrganizationsContext);
  let { schools, setSchools, countryOptions } = useContext(SchoolsContext);
  const { token, role } = useContext(LogInContext);
  let [newSchoolInfo, setNewSchoolInfo] = useState(defaultSchoolInfo);
  const [center, setCenter] = useState(defaultCoordinates);
  const [error, setError] = useState({});
  let [schoolsList, setSchoolsList] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [nameError, setNameError] = useState("");
  let [schoolId, setSchoolId] = useState((!!organizationId || organizationId !== 0) ? "" : 0);
  const [getSchool, setGetSchool] = useState({});
  let [option, setOption] = useState(null);
  let [isMenuOpen, setIsMenuOpen] = useState(false);
  const [flag, setFlag] = useState(false);
  let [show, setShow] = useState(false);
  const [info, setInfo] = useState({});
  let [clear, setClear] = useState("");
  let [selectedOption, setSelectedOption] = useState(null);
  let [message, setMessage] = useState("");
  let [search, setSearch] = useState("");
  let [countryName, setCountryName] = useState("");
  let [newOrg, setNewOrg] = useState([]);
  let [existError, setExistError] = useState("");
  let [existSchoolId, setExistSchoolId] = useState(0);
  let [existSchool, setExistSchool] = useState({});
  let [assignOrg, setAssignOrg] = useState(new Set());
  let [selectFlag, setSelectFlag] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapsApiKey,
    libraries: ["places"],
  });

  const onMenuOpen = () => setIsMenuOpen(true);
  const onMenuClose = () => setIsMenuOpen(false);

  const NoOptionsMessage = (props) => {
    return (
      <SchoolSelect>
        <components.NoOptionsMessage {...props}>
          <span
            onClick={() => {
              setSelectedOption(null);
              setClear("");
              setSchoolId(0);
              onMenuClose();
              setShow(false);
            }}
          >
            Can't find school? Click here to search
          </span>
        </components.NoOptionsMessage>
      </SchoolSelect>
    );
  };

  const NoOptionsOrg = (props) => {
    return (
      <SchoolSelect>
        <components.NoOptionsMessage {...props}>
          <span>No organisation</span>
        </components.NoOptionsMessage>
      </SchoolSelect>
    );
  };

  useEffect(() => {
    schools = schools.map((item) => ({
      ...item,
      flag: false,
    }));

    setSchools(schools);
  }, []);

  useEffect(() => {
    async function fetchSchools() {
      const responseData = await GetAllSchools(token, organizationId);

      if (responseData.status === "success") {
        setSchoolsList(responseData.data.schools);
      } else {
        setSchoolsList([]);
      }
    }
    fetchSchools();
  }, [token]);


  useEffect(() => {
    schoolsList = schoolsList.map((school) => ({
      value:
        !!!school?.city || !!!school?.region || !!!school?.country?.name
          ? school?.name
          : school?.name +
            " (" +
            school?.city +
            ", " +
            school?.region +
            ", " +
            school?.country?.name +
            ")",
      label:
        !!!school?.city || !!!school?.region || !!!school?.country?.name
          ? school?.name
          : school?.name +
            " (" +
            school?.city +
            ", " +
            school?.region +
            ", " +
            school?.country?.name +
            ")",
      id: school.id,
    }));

    setSchoolOptions(schoolsList);
  }, [schoolsList]);

  useEffect(() => {
    async function fetchOrganizations() {
      const responseData = await GetSchoolOrganizations(token, existSchoolId);

      if (responseData.status === "success") {
        assignOrg = new Set(responseData.data.organization.map((org) => org.id));
        setAssignOrg(assignOrg)
        setSelectFlag(true)
      } 
    }
    existSchoolId !== 0 && fetchOrganizations();
  }, [token, existSchoolId]);

  useEffect(() => {
    async function fetchSchool() {
      const responseData = await GetSchool(token, schoolId);

      if (responseData.status === "success") {
        setGetSchool(responseData.data.school);
        setCenter({
          lat: parseFloat(responseData?.data?.school.latitude),
          lng: parseFloat(responseData?.data?.school.longitude),
        });
      }
    }

    if (schoolId !== 0 && schoolId !== "") {
      fetchSchool();
    }
  }, [token, schoolId]);

  useEffect(() => {
    if ((schoolId === 0) && (!!organizationId || organizationId !== 0)) {
      let inputSearch = document.getElementById("search-name");
      inputSearch.focus();
    }
  }, [schoolId]);

  useEffect(() => {
    if (show) {
      message = "";
      setMessage(message);
    }
  }, [show]);

  const handleSelectSchool = (e) => {
    setSelectedOption(e);
    setSchoolId(e.id);
    setNameError("");
    setShow(true);
  };

  const handleNewSchoolInput = (e) => {
    let errorCopy = { ...error };

    setNewSchoolInfo({
      ...newSchoolInfo,
      [e.target.name]: toProperCase(e.target.value),
    });

    if (e.target.name === "name") {
      const isValid = isValidName(e.target.value);
      isValid
        ? delete errorCopy.school
        : (errorCopy.school = errors.requiredSchoolNameError);
    } else if (e.target.name === "long" || e.target.name === "lat") {
      const isValid = isValidCoordinate(e.target.name, e.target.value);
      isValid
        ? delete errorCopy[e.target.name]
        : (errorCopy[e.target.name] = errors.coordinatesErrorMessage);
    } else if (e.target.name === "city") {
      const isValid = isValidCity(e.target.value);
      isValid
        ? delete errorCopy.city
        : (errorCopy.city = errors.requiredCityError);
    } else if (e.target.name === "region") {
      const isValid = isValidRegion(e.target.value);
      isValid
        ? delete errorCopy.region
        : (errorCopy.region = errors.requiredRegionError);
    } 

    if (e.target.name === "long") {
      setCenter({ ...center, lng: parseFloat(e.target.value) });
    } else if (e.target.name === "lat") {
      setCenter({ ...center, lat: parseFloat(e.target.value) });
    }

    setError(errorCopy);
  };

  const handleMarkerDrag = (e) => {
    let errorCopy = { ...error };
    setNewSchoolInfo({
      ...newSchoolInfo,
      lat: parseFloat(e.latLng.lat()).toFixed(6),
      long: parseFloat(e.latLng.lng()).toFixed(6),
    });

    document.querySelector("#lat").value = `${parseFloat(
      e.latLng.lat()
    ).toFixed(6)}`;
    document.querySelector("#lng").value = `${parseFloat(
      e.latLng.lng()
    ).toFixed(6)}`;

    delete errorCopy.lat;
    delete errorCopy.long;
    setError(errorCopy);
  };

  const handleSubmitValidations = () => {
    let errorCopy = { ...error };
    delete errorCopy.apiFail;

    !newSchoolInfo.name && (errorCopy.school = errors.requiredSchoolNameError);
    !newSchoolInfo.lat && (errorCopy.lat = errors.requiredLatError);
    !newSchoolInfo.long && (errorCopy.long = errors.requiredLongError);
    !newSchoolInfo.city && (errorCopy.city = errors.requiredCityError);
    !newSchoolInfo.region && (errorCopy.region = errors.requiredRegionError);
    !newSchoolInfo.countryId && (errorCopy.country = errors.requiredCountryError);

    if (Object.keys(errorCopy).length > 0) {
      warning("Required field/s must be filled");
    }

    setError(errorCopy);
    return Object.keys(errorCopy).length === 0;
  };

  const handleSubmit = async () => {
    schoolId === "" && setNameError(errors.schoolRequiredMessage);
    schoolId === "" && warning("Required field/s must be filled");

    const responseData = await CreateOrgSchool(organizationId, schoolId, token);
    if (responseData.status === "success") {
      setShowModal(false);
    }
  };

  const handleAddSchoolSubmit = async () => {
    const isValid = handleSubmitValidations();

    if (!isValid) {
      return false;
    }

    newSchoolInfo.orgIds = newOrg;

    const responseData = await CreateSchool(
      organizationId,
      newSchoolInfo,
      token
    );
    if (responseData.status === "success") {
      addElement(schools, setSchools, responseData.data);
      setNewSchoolInfo(defaultSchoolInfo);
      setShowModal(false);
      if (role !== "ADMIN") {
        success(
          "Great Work! Your school will be available in the system within 24 hours."
        );
      }
    } else if (responseData.status === "failed" && responseData.code === 112) {
      setError({
        apiFail: responseData.data,
      });
    } else {
      setError({
        apiFail: errors.apiFail,
      });
    }
  };

  const onLoad = (autocomplete) => {
    setOption(autocomplete);
  };

  const onPlaceChanged = () => {
    if (option !== null) {
      let place = option.getPlace();
      newSchoolInfo.name = place?.name;
      newSchoolInfo.lat = place?.geometry?.location?.lat();
      newSchoolInfo.long = place?.geometry?.location?.lng();
      setCenter({
        lat: parseFloat(place?.geometry?.location?.lat()),
        lng: parseFloat(place?.geometry?.location?.lng()),
      });

      newSchoolInfo.placeId = place?.place_id;
      place?.address_components?.forEach((el) => {
        if (el?.types.includes("country")) {
          if (!!el?.long_name) {
            setCountryName(el?.long_name);
            newSchoolInfo.countryId = countryOptions?.filter((item) => {
              if (item?.label ===  el?.long_name) {
                return item?.id;
              }
            })[0]?.id;

          } else {
            setCountryName(countryName);
            newSchoolInfo.countryId = newSchoolInfo?.countryId
          }
        }
        if (el?.types.includes("locality")) {
          newSchoolInfo.city = !!el?.long_name
            ? el?.long_name
            : newSchoolInfo?.city;
        }
        if (el?.types.includes("route")) {
          newSchoolInfo.street = !!el?.long_name ? el?.long_name : null;
        }
        if (el?.types.includes("administrative_area_level_1")) {
          newSchoolInfo.region = !!el?.long_name
            ? el?.long_name
            : newSchoolInfo?.region;
        }
        if (el?.types.includes("street_number")) {
          newSchoolInfo.streetNumber = !!el?.long_name ? el?.long_name : null;
        }
      });

      setShow(true);
      setInfo(place);

      let arr = [];
      if(!!newSchoolInfo?.placeId || !!newSchoolInfo?.lat || !!newSchoolInfo?.long) {
         for (let i = 0; i < schools.length; i++) {
          const el = schools[i];
          if (el?.placeId == newSchoolInfo?.placeId) {
            arr.push(el);
          }
         }
      }

      if (arr.length > 0) {
        existError = "The School is already exits";
        setExistError(existError);
        existSchoolId = arr[0].id;
        setExistSchoolId(existSchoolId);
        existSchool = arr[0];
        setExistSchool(existSchool);
      } else {
        selectFlag = false;
        setSelectFlag(selectFlag);
        existError = "";
        setExistError(existError);
        existSchoolId = 0;
        setExistSchoolId(existSchoolId);
        existSchool = {};
        setExistSchool(existSchool);
        assignOrg = new Set();
        setAssignOrg(assignOrg);
        selectFlag = true;
        setSelectFlag(selectFlag);
      }

      setNewSchoolInfo(newSchoolInfo);
    }
  };

  const typeHandler = (e) => {
    search = e.target.value;
    setSearch(search);
    if (
      role === "ADMIN" &&
      search.length >= 5 &&
      document?.getElementsByClassName("pac-container pac-logo")[0]
        .childElementCount === 0
    ) {
      message =
        "If the school doesn't exist the press Enter to add the school manually. You can move the location pin on the map to pinpoint the location.";
      setMessage(message);
    } else {
      message = "";
      setMessage(message);
    }
  };

  const keyHandler = (e) => {
    if (e.key === "Enter") {
      setShow(true);
      search = "";
      message = "";
      setSearch(search);
      setMessage(message);
      setCenter(defaultCoordinates);
      setNewSchoolInfo(defaultSchoolInfo);
    }
  };

  const handleMouseOver = () => {
    setFlag(true);
  };

  const handleMouseExit = () => {
    setFlag(false);
  };

  const handleView = () => {
    handleOpen();
  };

  const mouseOver = (item) => {
    const arr = schools.map((obj) => {
      if (obj.id === item.id) {
        return { ...obj, flag: true };
      }

      return obj;
    });

    setSchools(arr);
  };

  const mouseExit = (item) => {
    const arr = schools.map((obj) => {
      if (obj.id === item.id) {
        return { ...obj, flag: false };
      }

      return obj;
    });

    setSchools(arr);
  };

  const handleSelectCountry = (e) => {
      setCountryName(e?.label);
      setNewSchoolInfo({ ...newSchoolInfo, countryId: e.id });
      delete error.country;
  };

  const handleSchool = (item) => {
    setSelectedOption({
      value:
        !!!item?.city || !!!item?.region || !!!item?.country?.name
          ? item?.name
          : item?.name +
            " (" +
            item?.city +
            ", " +
            item?.region +
            ", " +
            item?.country?.name +
            ")",
      label:
        !!!item?.city || !!!item?.region || !!!item?.country?.name
          ? item?.name
          : item?.name +
            " (" +
            item?.city +
            ", " +
            item?.region +
            ", " +
            item?.country?.name +
            ")",
      id: item.id,
    });
    setSchoolId(item?.id);
    setNameError("");
    setShow(true);
  };

  const handleSelectAssignOrg = (e) => {
    const org = e.map((org) => org.id);
    setNewOrg(org);
  };

  inputSchoolFields.school.value =
    schoolId === 0
      ? newSchoolInfo.name
      : getSchool?.name === null
      ? ""
      : getSchool?.name;
  inputSchoolFields.long.value =
    schoolId === 0
      ? newSchoolInfo.long
      : getSchool?.longitude === null
      ? ""
      : getSchool?.longitude;
  inputSchoolFields.lat.value =
    schoolId === 0
      ? newSchoolInfo.lat
      : getSchool?.latitude === null
      ? ""
      : getSchool?.latitude;
  inputSchoolFields.streetNumber.value =
    schoolId === 0
      ? newSchoolInfo.streetNumber
      : getSchool?.streetNumber === null
      ? null
      : getSchool?.streetNumber;
  inputSchoolFields.street.value =
    schoolId === 0
      ? newSchoolInfo.street
      : getSchool?.street === null
      ? ""
      : getSchool?.street;
  inputSchoolFields.city.value =
    schoolId === 0
      ? newSchoolInfo.city
      : getSchool?.city === null
      ? ""
      : getSchool?.city;
  inputSchoolFields.region.value =
    schoolId === 0
      ? newSchoolInfo.region
      : getSchool?.region === null
      ? ""
      : getSchool?.region;
  inputSchoolFields.country.value =
    schoolId === 0
      ? newSchoolInfo.countryId
      : getSchool?.country?.name === null
      ? ""
      : getSchool?.country?.name;

  return (
    <>
      <ViewSchoolModal
        open={open}
        handleClose={handleClose}
        schoolInfo={existSchool}
      />
      {showModal && isLoaded && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <SchoolModalWrapper>
            <InputsWrapper>
              <div
                style={{
                  display: "flex",
                  gap: "30px",
                  marginLeft: "0",
                }}
              >
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={14}
                >
                  {schools.map((item) => (
                    <Marker
                      position={{
                        lat: parseFloat(item?.latitude),
                        lng: parseFloat(item?.longitude),
                      }}
                      draggable={false}
                      onDragEnd={handleMarkerDrag}
                      icon={{
                        url: "http://maps.google.com/mapfiles/kml/paddle/grn-circle.png",
                        scaledSize: new window.google.maps.Size(50, 40),
                      }}
                      onMouseOver={() => mouseOver(item)}
                      onMouseOut={() => mouseExit(item)}
                      onClick={() => handleSchool(item)}
                    >
                      {item?.flag && (
                        <InfoWindow position={center}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "5px",
                            }}
                          >
                            <h4
                              style={{ fontSize: "1rem", fontWeight: "bold" }}
                            >
                              {item?.name}
                            </h4>
                            <p
                              style={{
                                fontSize: "0.80rem",
                                fontWeight: "400",
                                color: "#000",
                                display: "flex",
                                alignItems: "center",
                                gap: "2px",
                              }}
                            >
                              <img
                                src={makerImg}
                                alt="maker"
                                width={"20px"}
                                height={"20px"}
                              />
                              {!!item?.streetNumber &&
                                item?.streetNumber + ", "}
                              {!!item?.street && item?.street + ", "}
                              {!!item?.city && item?.city + ", "}
                              {!!item?.region && item?.region + ", "}
                              {!!item?.country?.name && item?.country?.name}
                            </p>
                          </div>
                        </InfoWindow>
                      )}
                    </Marker>
                  ))}
                  <Marker
                    position={center}
                    draggable={schoolId === 0 ? true : false}
                    onDragEnd={handleMarkerDrag}
                    icon={{
                      url: "http://maps.google.com/mapfiles/kml/paddle/red-circle.png",
                      scaledSize: new window.google.maps.Size(50, 40),
                    }}
                    onMouseOver={() => handleMouseOver()}
                    onMouseOut={() => handleMouseExit()}
                  >
                    {flag && Object.keys(info).length !== 0 && (
                      <InfoWindow position={center}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                          }}
                        >
                          <h4 style={{ fontSize: "1rem", fontWeight: "bold" }}>
                            {info?.name}
                          </h4>
                          <p
                            style={{
                              fontSize: "0.80rem",
                              fontWeight: "400",
                              color: "#000",
                              display: "flex",
                              alignItems: "center",
                              gap: "2px",
                            }}
                          >
                            <img
                              src={makerImg}
                              alt="maker"
                              width={"20px"}
                              height={"20px"}
                            />
                            {!!inputSchoolFields.streetNumber.value &&
                              inputSchoolFields.streetNumber.value + ", "}
                            {!!inputSchoolFields.street.value &&
                              inputSchoolFields.street.value + ", "}
                            {!!inputSchoolFields.city.value &&
                              inputSchoolFields.city.value + ", "}
                            {!!inputSchoolFields.region.value &&
                              inputSchoolFields.region.value + ", "}
                            {!!countryName &&
                              countryName}
                          </p>
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                </GoogleMap>

                <div style={{ width: "500px" }}>
                  <div className="school-main">
                    {(!!organizationId || organizationId !== 0) && <CustomSelect
                      options={schoolOptions}
                      placeholder="Select School"
                      value={selectedOption}
                      onChange={handleSelectSchool}
                      className="input--grade"
                      error={schoolId === "" ? nameError : ""}
                      components={{ NoOptionsMessage }}
                      onMenuOpen={onMenuOpen}
                      onMenuClose={onMenuClose}
                      menuIsOpen={isMenuOpen}
                      inputValue={clear}
                      onInputChange={(e) => setClear(e)}
                    />}
                    {schoolId === 0 && (
                      <div className="school--name">
                        <Autocomplete
                          onLoad={onLoad}
                          onPlaceChanged={onPlaceChanged}
                        >
                          <input
                            type="text"
                            id="search-name"
                            placeholder="Search School Name..."
                            onInput={(e) => typeHandler(e)}
                            onKeyDown={(e) => keyHandler(e)}
                            value={search}
                            style={{
                              boxSizing: `border-box`,
                              border: `2px solid transparent`,
                              borderColor: "#a7d1fb",
                              width: `100%`,
                              height: `1.7rem`,
                              padding: `0 1em`,
                              borderRadius: `25px`,
                              fontSize: `1rem`,
                              outline: `none`,
                              textOverflow: `ellipses`,
                              marginBottom: "0.2em",
                              flexWrap: "wrap",
                            }}
                          />
                        </Autocomplete>
                        <p style={{ marginTop: "1rem" }}>{message}</p>
                        <p style={{ marginTop: "-0.5rem", color: "red", fontSize: "0.875rem" }}>{existError}</p>
                      </div>
                    )}
                  </div>
                  {show && (
                    <>
                      <div className="address">
                        <h4>Address <span style={{color: "red"}}>*</span></h4>
                        <div className="address-section">
                          <CustomInput
                            input={inputSchoolFields.school}
                            error={error.school}
                            onChange={handleNewSchoolInput}
                            onSubmit={(e) =>
                              handleOnSubmit(e, handleAddSchoolSubmit)
                            }
                            className="input--school"
                          />
                          <CustomInput
                            input={inputSchoolFields.streetNumber}
                            onChange={handleNewSchoolInput}
                            onSubmit={(e) =>
                              handleOnSubmit(e, handleAddSchoolSubmit)
                            }
                            className="input--street"
                          />
                          <CustomInput
                            input={inputSchoolFields.street}
                            onChange={handleNewSchoolInput}
                            onSubmit={(e) =>
                              handleOnSubmit(e, handleAddSchoolSubmit)
                            }
                          />
                          <CustomInput
                            input={inputSchoolFields.city}
                            error={error.city}
                            onChange={handleNewSchoolInput}
                            onSubmit={(e) =>
                              handleOnSubmit(e, handleAddSchoolSubmit)
                            }
                          />
                          <CustomInput
                            input={inputSchoolFields.region}
                            error={error.region}
                            onChange={handleNewSchoolInput}
                            onSubmit={(e) =>
                              handleOnSubmit(e, handleAddSchoolSubmit)
                            }
                          />

                          {schoolId === 0 && <CustomSelect
                            options={countryOptions}
                            placeholder="Select country"
                            onChange={(e) => handleSelectCountry(e)}
                            value={countryOptions.find(
                              (elem) => elem?.id === inputSchoolFields.country.value
                            )}
                            error={error.country}
                            className="input--country"
                          />}
                         {schoolId !== 0 && <CustomInput
                            input={inputSchoolFields.country}
                            error={error.country}
                            onChange={handleNewSchoolInput}
                            onSubmit={(e) =>
                              handleOnSubmit(e, handleAddSchoolSubmit)
                            }
                            disabled={true}
                         />}
                        </div>
                      </div>
                      <div className="location">
                        <CustomInput
                          input={inputSchoolFields.long}
                          error={error.long}
                          onChange={handleNewSchoolInput}
                          onSubmit={(e) =>
                            handleOnSubmit(e, handleAddSchoolSubmit)
                          }
                          className="input--long"
                          disabled={true}
                        />
                        <CustomInput
                          input={inputSchoolFields.lat}
                          error={error.lat}
                          onChange={handleNewSchoolInput}
                          onSubmit={(e) =>
                            handleOnSubmit(e, handleAddSchoolSubmit)
                          }
                          className="input--lat"
                          disabled={true}
                        />
                      </div>
                      {((!organizationId || organizationId === 0) && selectFlag) && <CustomMultiSelect
                        title={"Assign to organization"}
                        options={orgOptions}
                        placeholder="Select Organization"
                        defaultValue={orgOptions.filter((elem) =>
                          assignOrg.has(elem.id)
                          )}
                        onChange={(e) => handleSelectAssignOrg(e)}
                        className="input--org"
                        components={{ NoOptionsOrg }}
                      />}
                    </>
                  )}
                </div>
              </div>
            </InputsWrapper>
            <ModalCreateButtons
              text={
                role === "ADMIN" && schoolId === 0 && existSchoolId === 0
                  ? "Add School"
                  : schoolId === 0 && existSchoolId === 0
                  ? "Submit For Approval"
                  : existSchoolId !== 0
                  ? "View School"
                  : "Add School"
              }
              onClick={schoolId === 0 && existSchoolId !== 0 ? handleView : schoolId === 0 && existSchoolId === 0 ? handleAddSchoolSubmit : handleSubmit}
              error={error.apiFail}
              className="modal__buttons--schools"
            />
          </SchoolModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default AddSchoolModal;
