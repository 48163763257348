import styled from 'styled-components';

export const ForgottenPassword = styled.button`
  border: none;
  background: none;
  text-decoration: underline;
  cursor: pointer;
`;

export const LogInCardWrapper = styled.div`
  max-width: 30rem;
  width:100%;
  background: var(--blue-100);
  margin: 0 auto 30px;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 4px 0 hsl(0, 0%, 0%, 0.25);

  .sign-in {
    width: 100%;
    display: flex;
    justify-content: center;

    div:first-child {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      height: auto !important;
    }

    div:last-child {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
    }
  }

  .social {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-top: 10px;
    padding-bottom: 0px !important;

    .border-left {
      border-top : 1px solid #cdc5c5;
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }
    
    .content {   
      white-space: nowrap;
      padding-left: 3px !important;
      padding-right: 3px !important;
    }

    .border-right {
      border-top : 1px solid #cdc5c5;
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }
  }

  .input--user-email,
  .input--user-password {
    position: relative;

    input {
      height: 2rem;
    }
    h5 {
      position: absolute;
      bottom: -0.5rem;
    }
  }

  .input--user-password {
    margin-bottom: 0.3rem;
  }

  h2 {
    width: 100%;
    text-align: center;
    padding: 0.5rem;
    background: var(--blue-200);
    border-radius: 25px 25px 0 0;
  }

  p {
    text-decoration: underline;
    cursor: pointer;
  }

  div.buttons--forgotten-password {
    max-width: 30rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0.4em 2.5em;
    text-align: left;

    div {
      display: block;
      h4 {
        margin-top: 0.5em;
      }
      h4.password {
        margin-top: 1.5rem;
      }
    }
  }
`;

export const LogInCardSmallWrapper = styled(LogInCardWrapper)`
  height: 15rem;

  div {
    position: relative;

    div.input {
      max-width: 30rem;

      input {
        margin-bottom: 1.5rem;
      }
      h5.response-message {
        position: absolute;
        top: 90px;
        padding: 0 2.5rem;
      }

      h5#success {
        color: green;
      }
    }
  }
`;

export const StyledMetricsCard = styled.div`
  background-color: var(--grey-150);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 21rem;
  height: 11rem;
  border-radius: 27px;
  margin: 1rem .5rem;
  margin-top: 0;

  h2 {
    font-size: 4.5rem;
    font-weight: 700;
  }

  h4 {
    font-weight: 600;
    width: 45%;
    text-align: center;
  }
`;
