import React, { useContext } from "react";
import { ModalContext } from "../../../contexts/modalContext";
import { ModalWrapper, ViewSchoolWrapper } from "../Modals.styles";
import {
  ActionButtonOrange,
  ActionButtonOrangeSecondary,
} from "../../Buttons/ActionButton";
import { useState } from "react";
import { GetStudList, StudentToggleStatus } from "../../../api";
import { jumpToPage } from "../../../utils/constants";
import { LogInContext } from "../../../contexts/logInContext";
import { useEffect } from "react";
import { Pagination } from "../../../components/Buttons/PaginationButtons";
import { TableContainer, Toolbar } from "@mui/material";
import { TableWrapper } from "../../Tables/Table.styles";
import ClipSpinner from "../../Loaders/ClipSpinner";
import { SchoolsContext } from "../../../contexts/schoolsContext";
import { CustomSearch } from "../../InputFields/CustomInput";
import { StyledFilterButtons } from "../../../Pages/Page.styles";
import NoDataDisplay from "../../Displays/NoDataDisplay";
import { OrganizationsContext } from "../../../contexts/organizationsContext";
import { decrypt } from "../../../utils/encryption";

const StudentListModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal } = useContext(ModalContext);
  const { schoolId } = useContext(SchoolsContext);
  const {organizationId} = useContext(OrganizationsContext);
  const [displayOrgs, setDisplayOrgs] = useState([]);
  const [studData, setStudData] = useState([]);
  const { token } = useContext(LogInContext);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 5;
  const offset = (currentPage - 1) * ITEMS_PER_PAGE;
  const [schoolPageCount, setSchoolPageCount] = useState(1);
  const [total, setTotal] = useState(0);
  let [displaySearchValue, setDisplaySearchValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const searchSetters = {
    displaySetter: setDisplaySearchValue,
    valueSetter: setSearchValue,
  };
  let [isActive, setIsActive] = useState("");

  const canNextPage = (displayItemsLength, itemsLength, currentPage) => {
    return (
      displayItemsLength !== ITEMS_PER_PAGE ||
      itemsLength / ITEMS_PER_PAGE === currentPage
    );
  };

  let [loading, setLoading] = useState(true);

  let columns = [
      {
        Header: "National ID"
      },
      {
        Header: "First Name"
      },
      {
        Header: "Last Name"
      },
      {
        Header: "Birth Date"
      },
      {
        Header: "Gender"
      },
      {
        Header: "No. of Skills"
      },
      {
        Header: "Status"
      }
    ];

  async function fetchStudents() {
    const responseData = await GetStudList(
      token,
      organizationId,
      displaySearchValue,
      ITEMS_PER_PAGE,
      offset,
      schoolId,
      isActive
    );

    if (responseData.status === "success") {
      setStudData(Array.isArray(responseData?.data?.data?.students) &&
      responseData?.data?.data?.students?.map((student) => ({
        ...student,
        nationalId: !!student.uniqueId && decrypt(student.uniqueId),
        skillsCount: student.skills.length,
      })));
      setLoading(false);

      if (
        responseData.data.data.totalRecords ||
        responseData.data.data.totalRecords === 0
      ) {
        setTotal(responseData.data.data.totalRecords);
        setSchoolPageCount(
          Math.ceil(responseData.data.data.totalRecords / ITEMS_PER_PAGE)
        );
      }
      if (responseData.data.data.students.length === 0) {
        setCurrentPage(1);
      }
      setDisplayOrgs(responseData.data.data.students);
    } else {
      setLoading(false);
      setStudData([]);
    }
  }

  useEffect(() => {
    fetchStudents();
  }, [token, setStudData, setDisplayOrgs, setSchoolPageCount, currentPage]);

  const handleClick = (step) => {
    setCurrentPage((prevState) => prevState + step);
  };

  const handleAction = async (e, studentId) => {
    const responseData = await StudentToggleStatus(studentId, token);

    if (responseData.status === "success") {
      fetchStudents();
    }
  };

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
          <ViewSchoolWrapper style={{ height: "auto", padding: "2em" }}>
            <h1 style={{ marginBottom: "0.5rem" }}>
              All Students
            </h1>
            <Toolbar className="custom-toolbar">
              <div
                className="custom-search"
                style={{ maxWidth: "none", flex: "auto" }}
              >
                <CustomSearch
                  value={displaySearchValue}
                  setSearchStates={searchSetters}
                  placeholder={"Search by name"}
                  onChange={(e) => setDisplaySearchValue(e.target.value)}
                />
              </div>
              <div className="reset-filter">
                <StyledFilterButtons>
                  <div
                    className="metrics__button-filters"
                    style={{ width: "max-content" }}
                  >
                    <ActionButtonOrange
                      theme="primary"
                      text="Filter"
                      onClick={fetchStudents}
                    />
                    <ActionButtonOrangeSecondary
                      text="Reset Filters"
                      disabled={!!displaySearchValue ? false : true}
                      onClick={() => {
                        displaySearchValue = "";
                        setDisplaySearchValue(displaySearchValue);
                        fetchStudents();
                      }}
                    />
                  </div>
                </StyledFilterButtons>
              </div>
              <div className="filter-btn">
                <ActionButtonOrangeSecondary
                  text={!!!isActive ? "Show All Students" : "Hide Inactive Students"}
                    onClick={() => {
                        isActive === 1 ? (isActive = "") : (isActive = 1);
                        setIsActive(isActive);
                        fetchStudents();
                    }}
                />
              </div>
            </Toolbar>
            {loading && (
              <TableContainer className="custom_table_container">
                <TableWrapper>
                  <div className="loading-table">
                    <ClipSpinner />
                  </div>
                </TableWrapper>
              </TableContainer>
            )}
            {!loading && studData.length !== 0 && (
              <TableContainer>
                <TableWrapper>
                  <>
                    <table style={{ textAlign: "start" }}>
                      <thead>
                        <tr style={{ textAlign: "justify" }}>
                          {columns.map((item, index) => (
                            <th
                              style={{ width: "50px", paddingRight: "1em" }}
                              key={index}
                            >
                              {item?.Header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {studData.map((item, index) => (
                          <tr key={index}>
                              <td style={{width: "50px"}}>{item?.nationalId}</td>
                              <td style={{width: "50px"}}>{item?.name}</td>
                              <td style={{width: "50px"}}>{item?.lastName}</td>
                              <td style={{width: "50px"}}>{item?.dob}</td>
                              <td style={{width: "50px"}}>{item?.gender}</td>
                              <td style={{width: "50px"}}>{item?.skillsCount}</td>
                              <td style={{width: "70px", justifyContent: "start"}}>
                                <div className="reverse-user-status">
                                    <span>
                                    {item.isActive === 1
                                        ? "Active"
                                        : "Inactive"}
                                    </span>
                                    <input
                                    type="checkbox"
                                    className="checkbox"
                                    checked={
                                        item.isActive === 1 && "checked"
                                    }
                                    onClick={(e) =>
                                        handleAction(e, item.id)
                                    }
                                    />
                                </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {Math.ceil(total / ITEMS_PER_PAGE) > 0 && (
                      <Pagination
                        currentPage={currentPage}
                        disabledPrev={currentPage === 1}
                        disabledNext={canNextPage(
                          displayOrgs.length,
                          total,
                          currentPage
                        )}
                        onClickPrev={() => handleClick(-1)}
                        onClickNext={() => handleClick(+1)}
                        onClickFirstPage={() => jumpToPage(setCurrentPage)}
                        onClickLastPage={() =>
                          jumpToPage(setCurrentPage, schoolPageCount)
                        }
                        total={Math.ceil(total / ITEMS_PER_PAGE)}
                      />
                    )}
                  </>
                </TableWrapper>
              </TableContainer>
            )}
            {!loading && studData.length === 0 && (
              <NoDataDisplay message={"No Student yet"} />
            )}
            <div style={{ marginTop: "10px", display: "flex" }}>
              <ActionButtonOrangeSecondary
                text="Close"
                onClick={() => {
                  setShowModal(false);
                }}
              />
            </div>
          </ViewSchoolWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default StudentListModal;
