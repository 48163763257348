import { useContext, useState } from 'react';
import { EditOrganization } from '../../../api';
import { LogInContext } from '../../../contexts/logInContext';
import { ModalContext } from '../../../contexts/modalContext';
import { isValidName, errors, isValidLink } from '../../../utils/validation';
import {  EditOrgModalWrapper, ModalWrapper } from '../Modals.styles';
import { OrganizationsContext } from '../../../contexts/organizationsContext';
import { ModalCreateButtons } from '../ModalButtons';
import { CustomInput, CustomTextarea } from '../../InputFields/CustomInput';
import { inputOrgFields } from '../../InputFields/InputFields';
import { editElement, handleOnSubmit } from '../../../utils/constants';
import { warning } from '../../../utils/toastify';
import { SchoolsContext } from '../../../contexts/schoolsContext';
import CustomSelect from "../../InputFields/CustomSelect";

const EditOrganizationModal = ({ modalRef, closeModal }) => {
  const { showModal, setShowModal } = useContext(ModalContext);
  const { organizations, setOrganizations, selectedOrg } =
    useContext(OrganizationsContext);
  const { token } = useContext(LogInContext);
  const [editOrgInfo, setEditOrgInfo] = useState(selectedOrg);
  const [error, setError] = useState({});
  const { countryOptions } = useContext(SchoolsContext);
  const [errorLink, setErrorLink] = useState("");
  const [country, setCountry] = useState(
    !!editOrgInfo?.country?.id ? editOrgInfo?.country?.id : null
  );

  const handleEditOrgInput = (e) => {
    let errorCopy = { ...error };

    setEditOrgInfo({ ...editOrgInfo, [e.target.name]: e.target.value });

    switch (e.target.name) {
      case "name": {
        const isValid = isValidName(e.target.value);
        isValid
          ? delete errorCopy.organization
          : (errorCopy.organization = errors.requiredNameError);
        break;
      }
      case "link": {
        if (!!!e.target.value) {
           setErrorLink("");
        }
        break;
      }
      case "email": {
        const isValid = isValidName(e.target.value);
        isValid
          ? delete errorCopy.ownerEmail
          : (errorCopy.ownerEmail = errors.ownerEmailErrorMessage);
        break;
      }
      case "ownerName": {
        const isValid = isValidName(e.target.value);
        isValid
          ? delete errorCopy.ownerFirstName
          : (errorCopy.ownerFirstName = errors.ownerFirstNameErrorMessage);
        break;
      }
      case "ownerLastName": {
        const isValid = isValidName(e.target.value);
        isValid
          ? delete errorCopy.ownerLastName
          : (errorCopy.ownerLastName = errors.ownerLastNameErrorMessage);
        break;
      }
      case "city": {
        const isValid = isValidName(e.target.value);
        isValid
          ? delete errorCopy.city
          : (errorCopy.city = errors.cityRequiredMessage);
        break;
      }
      case "region": {
        const isValid = isValidName(e.target.value);
        isValid
          ? delete errorCopy.region
          : (errorCopy.region = errors.regionRequiredMessage);
        break;
      }
      default:
        break;
    }

    setError(errorCopy);
  };

  const handleSubmitValidations = () => {
    let errorCopy = { ...error };
    delete errorCopy.apiFail;

    !editOrgInfo.name && (errorCopy.organization = errors.requiredNameError);
    !editOrgInfo.email &&
      (errorCopy.ownerEmail = errors.ownerEmailErrorMessage);
    !editOrgInfo.ownerName &&
      (errorCopy.ownerFirstName = errors.ownerFirstNameErrorMessage);
    !editOrgInfo.ownerLastName &&
      (errorCopy.ownerLastName = errors.ownerLastNameErrorMessage);
    !editOrgInfo.city && (errorCopy.city = errors.cityRequiredMessage);
    !editOrgInfo.region && (errorCopy.region = errors.regionRequiredMessage);
    !editOrgInfo.countryId && (errorCopy.country = errors.countryRequiredMessage);

    if (!!editOrgInfo?.link) {
      const isValid = isValidLink(editOrgInfo?.link);
      if (!isValid) {
        setErrorLink(errors.websiteLinkErrorMessage);
        if (Object.keys(errorCopy).length > 0) {
          warning("Required field/s must be filled");
        }
    
        setError(errorCopy);
        return false;
      }
    } else {
      if (Object.keys(errorCopy).length > 0) {
        warning("Required field/s must be filled");
      }
  
      setError(errorCopy);
    }

    return Object.keys(errorCopy).length === 0;
  };

  const handleSubmitEdit = async () => {
    const isValid = handleSubmitValidations();

    if (!isValid) {
      return false;
    }

    editOrgInfo.ownerId = selectedOrg.ownerId;

    const responseData = await EditOrganization(
      editOrgInfo.id,
      editOrgInfo,
      token
    );

    if (responseData.status === 'success') {
      editElement(
        selectedOrg.id,
        organizations,
        setOrganizations,
        ['name'],
        editOrgInfo
      );
      setShowModal(false);
    } else {
      setError({
        apiFail: errors.apiFail,
      });
    }
  };

  const handleSelectCountry = (e) => {
    setEditOrgInfo({ ...editOrgInfo, countryId: e.id });
    setCountry(e.id);
    delete error.country;
  };

  inputOrgFields.organization.value = editOrgInfo?.name;
  inputOrgFields.website.value = editOrgInfo?.link;
  inputOrgFields.ownerEmail.value = editOrgInfo?.email;
  inputOrgFields.ownerFirstName.value = editOrgInfo?.ownerName;
  inputOrgFields.ownerLastName.value = editOrgInfo?.ownerLastName;
  inputOrgFields.description.value = editOrgInfo?.description;
  inputOrgFields.streetNumber.value = editOrgInfo?.streetNumber;
  inputOrgFields.street.value = editOrgInfo?.street;
  inputOrgFields.city.value = editOrgInfo?.city;
  inputOrgFields.region.value = editOrgInfo?.region;

  return (
    <>
      {showModal && (
        <ModalWrapper ref={modalRef} onClick={closeModal}>
           <EditOrgModalWrapper style={{display : "flex", flexDirection : "column"}}>
            <div className="org-field">
              <CustomInput
                input={inputOrgFields.organization}
                error={error.organization}
                onChange={handleEditOrgInput}
                onSubmit={(e) => handleOnSubmit(e, handleSubmitEdit)}
              />
              <CustomInput
                input={inputOrgFields.website}
                onChange={handleEditOrgInput}
                onSubmit={(e) => handleOnSubmit(e, handleSubmitEdit)}
                className="input--email"
                error={errorLink}
              />
              
              <CustomInput
                input={inputOrgFields.ownerFirstName}
                error={error.ownerFirstName}
                onSubmit={(e) => handleOnSubmit(e, handleSubmitEdit)}
                onChange={handleEditOrgInput}
              />
              <CustomInput
                input={inputOrgFields.ownerLastName}
                error={error.ownerLastName}
                onSubmit={(e) => handleOnSubmit(e, handleSubmitEdit)}
                onChange={handleEditOrgInput}
              />
              <CustomTextarea
                textarea={inputOrgFields.description}
                onChange={handleEditOrgInput}
                className="text-area"
              />

              <br />

              <CustomInput
                input={inputOrgFields.streetNumber}
                onSubmit={(e) => handleOnSubmit(e, handleSubmitEdit)}
                onChange={handleEditOrgInput}
                className='input--street'
              />

              <CustomInput
                input={inputOrgFields.street}
                onSubmit={(e) => handleOnSubmit(e, handleSubmitEdit)}
                onChange={handleEditOrgInput}
                className="org__street"
              />

              <CustomInput
                input={inputOrgFields.city}
                error={error.city}
                onSubmit={(e) => handleOnSubmit(e, handleSubmitEdit)}
                onChange={handleEditOrgInput}
              />

              <CustomInput
                input={inputOrgFields.region}
                error={error.region}
                onSubmit={(e) => handleOnSubmit(e, handleSubmitEdit)}
                onChange={handleEditOrgInput}
              />

              <CustomSelect
                title="Country"
                options={countryOptions}
                placeholder="Select country"
                defaultValue={countryOptions.find(
                  (elem) => elem?.id === country
                )}
                onChange={(e) => handleSelectCountry(e)}
                error={error.country}
                className="input--name"
              />
            </div>
            <ModalCreateButtons
              text="Save"
              onClick={handleSubmitEdit}
              className="modal__buttons--edit-org add__org"
              error={error.apiFail}
            />
          </EditOrgModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default EditOrganizationModal;
