import React, { useState } from 'react';

export const ModalContext = React.createContext();

const ModalProvider = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');

  return (
    <ModalContext.Provider
      value={{
        showModal,
        setShowModal,
        modalType,
        setModalType,
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
